<div class="container white">
    <h2>Leerlingen</h2>

    <div *ngIf="userManagement">
        <mat-radio-group [(ngModel)]="onlythisschool">
            <mat-radio-button [value]="false">
                Toon {{studentcount_all}} leerlingen uit alle scholen
            </mat-radio-button>
            <br>
            <mat-radio-button [value]="true">
                Toon {{studentcount_selected}} leerlingen uit {{LocalStorage?.school?.name}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="row">
        <mat-form-field class="col-24 col-md">
            <mat-label>Zoeken</mat-label>
            <input [(ngModel)]="searchQuery" matInput placeholder="Zoek op naam of email" type="text">
        </mat-form-field>
        <mat-form-field class="col-24 col-md"
                        *ngIf="LocalStorage.school && LocalStorage.school.is_direct && LocalStorage.school.type !== 'master'">
            <mat-label>Groepen</mat-label>
            <mat-select [formControl]="fcFilter" multiple>
                <mat-option value="all">Toon alle groepen</mat-option>
                <mat-option value="own">Toon eigen groepen</mat-option>
                <mat-option [value]="classroom.id" *ngFor="let classroom of classrooms">
                    Groep {{classroom.classroom_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-24 col-md" *ngIf="LocalStorage.school && LocalStorage.school.is_direct && LocalStorage.school.type !== 'master'">
            <mat-label>Toon toestemmingen</mat-label>
            <mat-select [formControl]="fcShowPermissions" multiple>
                <mat-option [value]="permission.id" *ngFor="let permission of permissions">
                    {{permission.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th (click)="sortList('name')">
                        Naam
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="name"></app-sorting-icon>
                    </th>
                    <th *ngFor="let permission of shownPermissions">
                        <div class="overflow-ellipsis">
                            {{permission.name}}
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr (click)="editStudent($event, student.id)" *ngFor="let student of list | search:'name,number':searchQuery" style="cursor:pointer;">
                    <td>{{student.name}}</td>
                    <td *ngFor="let permission of shownPermissions">
                        {{(student.permissions | byId : permission.id : 'permission_question_id')?.answer}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
