<table class="table table-striped" *ngIf="overview">
    <thead>
    <tr>
        <th (click)="sortList('school')">
            School
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="school"></app-sorting-icon>
        </th>
        <th (click)="sortList('payment')">
            Naam
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="name"></app-sorting-icon>
        </th>
        <th (click)="sortList('amount')" matTooltip="Totaal van alle betalingen voor dit betalingsverzoek" class="curhelp">
            Totaal
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="amount"></app-sorting-icon>
        </th>
        <th (click)="sortList('month_amount')" class="curhelp" matTooltip="Totaal van alle betalingen voor dit betalingsverzoek deze maand">
            Deze maand
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="month_amount"></app-sorting-icon>
        </th>
        <th (click)="sortList('last_month_amount')" class="curhelp" matTooltip="Totaal van alle betalingen voor dit betalingsverzoek afgelopen maand">
            Afgelopen maand
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="last_month_amount"></app-sorting-icon>
        </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let payment of list" style="cursor:pointer;" (click)="openPayment(payment)">
        <td>{{payment.school}}</td>
        <td>
            <i matTooltip="Naschools aanbod" class="fa-duotone fa-volleyball" *ngIf="payment.type === 'offer'"></i>
            {{payment.payment}}
        </td>
        <td class="text-right">
            <ng-container *ngIf="payment.count > 0">
                {{payment.amount | currency : 'EUR'}}
                <small class="curhelp" matTooltip="Aantal betalingen">({{payment.count}})</small>
            </ng-container>
            <ng-container *ngIf="payment.count == 0">
                -
            </ng-container>
        </td>
        <td class="text-right">
            <ng-container *ngIf="payment.month_count > 0">
                {{payment.month_amount | currency : 'EUR'}}
                <small>({{payment.month_count}})</small>
            </ng-container>
            <ng-container *ngIf="payment.month_count == 0">
                -
            </ng-container>
        </td>
        <td class="text-right">
            <ng-container *ngIf="payment.last_month_count > 0">
                {{payment.last_month_amount | currency : 'EUR'}}
                <small class="curhelp" matTooltip="Aantal betalingen">({{payment.last_month_count}})</small>
            </ng-container>
            <ng-container *ngIf="payment.last_month_count == 0">
                -
            </ng-container>
        </td>
    </tr>
    </tbody>
</table>
