import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PhoneDialogComponent} from './phone-dialog/phone-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class PhoneService {

    constructor(public dialog: MatDialog) {
    }

    call(phoneNumber: string, name?: string) {
        this.dialog.open(PhoneDialogComponent, {
            panelClass: 'confirm-dialog',
            maxWidth: '400px',
            minWidth: '250px',
            disableClose: true,
            closeOnNavigation: true,
            data: {
                phoneNumber,
                name
            }
        });
    }
}
