import {UntypedFormControl} from '@angular/forms';

export function handleTimeValueChange(value: string, formControl: UntypedFormControl) {
    if (value) {
        let newValue = value.replace('.', ':');
        const splitted = newValue.split(':'), hour = +splitted[0], minute = +splitted[1];
        if (splitted.length === 2 && hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59) {
            newValue = '' + hour + ':' + (minute < 10 ? '0' : '') + minute;
        } else {
            const testHour = +newValue;
            if (newValue.length <= 2 && testHour >= 0 && testHour <= 23) {
                newValue = newValue + ':00';
            } else if (newValue.length > 2) {
                for (let place = 2; place >= 1; place--) {
                    const tempValue = [newValue.slice(0, place), newValue.slice(place)].join(':');
                    const tempSplitted = tempValue.split(':'), tempHour = +tempSplitted[0],
                        tempMinute = +tempSplitted[1];
                    if (tempSplitted.length === 2 && tempHour >= 0 && tempHour <= 23 && tempMinute >= 0 && tempMinute <= 59) {
                        newValue = tempValue;
                        break;
                    }
                }

            }
        }
        if (value !== newValue) {
            formControl.setValue(newValue);
        }
    }
}
