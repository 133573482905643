import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {Settings} from '../../settings.class';

import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {FileService} from '../../services/file/file.service';
import {ApiService} from '../../services/api/api.service';

@Component({
    selector: 'app-file-add-item',
    templateUrl: './file-add-item.component.html',
    styleUrls: ['./file-add-item.component.scss']
})
export class FileAddItemComponent {

    uploading;
    dragOver = false;
    saving = false;
    file: string;
    filesize: number;
    ext: string;
    isFolder = false;
    noFilesAllowed = false;
    path: string;

    form = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)])
    });

    constructor(private fileService: FileService,
                private apiService: ApiService,
                private confirmDialogService: ConfirmDialogService,
                public dialogRef: MatDialogRef<FileAddItemComponent>,
                @Inject(MAT_DIALOG_DATA) {path, files, isFolder}) {
        this.path = path;
        if (files) {
            this.uploadFiles(files);
        }

        this.isFolder = isFolder;

    }

    @HostListener('dragover', ['$event'])
    ondragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    ondragleave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (evt.srcElement.classList.contains('dialog-container') || evt.srcElement.classList.contains('overlay')) {
            this.dragOver = false;
        }
    }

    @HostListener('drop', ['$event'])
    ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = false;
        const files = evt.dataTransfer.files;
        if (this.noFilesAllowed) {
            this.confirmDialogService.confirm(
                'Geen hoofdstuk',
                `Je kunt alleen bestanden uploaden onder een hoofdstuk`,
                'Oké', null);
        } else {
            this.uploadFiles(files);
        }
    }

    save() {
        this.saving = true;
        this.fileService.createFolder(this.form.value['name'], this.path).subscribe(() => {
            this.saving = false;
            this.dialogRef.close();
        }, error => {
            this.confirmDialogService.confirm(
                'Error bij opslaan',
                `Er is iets fout gegaan bij het opslaan`,
                'Oké', null);
        });

    }

    uploadFiles(files: File[]) {
        const url = `${Settings.API_ENDPOINT}file/upload?path=${this.path}`;
        const uploadedFiles = [];
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                this.uploading = true;
                if (files[i].size < 110664300) { // ~100MB
                    this.apiService.makeFileRequest$(
                        'file/upload',
                        files[i],
                        {path: this.path})
                        .subscribe(data => {
                            if (data['success'] && files.length === 1) {
                                this.uploading = false;
                                this.file = data['file'];
                                this.ext = data['ext'];
                                this.filesize = data['filesize'];

                                if (!this.form.value['name']) {
                                    this.form.controls['name'].setValue(this.file);
                                }
                            } else if (data['success'] && files.length > 1) {
                                uploadedFiles.push(files[i]);
                            } else {
                                this.uploading = false;
                                this.uploadErrorMessage(data['error']['upload'][0]);
                            }
                        }, error => {
                            this.uploading = false;
                            this.uploadErrorMessage(`Bestand: ${files[i].name}<br>${error['errormessage'] ? error['errormessage'] : 'Je kunt dit niet uploaden!'}`);
                        });
                } else {
                    if (files.length === 1) {
                        this.uploading = false;
                    }
                    this.confirmDialogService.confirm(
                        'Bestand te groot',
                        `${files[i].name} is te groot, de maximale grootte is 100MB.`,
                        'Oké', null);
                }
            }
        }
    }

    uploadFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    private uploadErrorMessage(message) {
        this.confirmDialogService.confirm(
            'Er is iets foutgegaan bij het uploaden',
            `${message}`,
            'Oké', null);
    }

}
