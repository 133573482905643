import {Component, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {startWith} from 'rxjs/operators';
import {CodaltComponent} from '../../codalt.component';
import {ActivatedRoute} from '@angular/router';
import {Discussion} from '../../classes/discussion.class';
import {DiscussionService} from '../../services/discussion.service';

@Component({
    selector: 'app-discussions-overview',
    templateUrl: './discussions-overview.component.html',
    styleUrls: ['./discussions-overview.component.scss']
})
export class DiscussionsOverviewComponent extends CodaltComponent implements OnInit {

    discussions: Discussion[];

    constructor(private discussionService: DiscussionService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school) {
                this.discussions = null;
                this.subscriptions.add(this.discussionService.getHome().subscribe(result => {
                    this.discussions = result.data;
                }));
            }
        }));
    }


}
