import {Pipe, PipeTransform} from '@angular/core';
import {User} from '../../classes/user.class';

@Pipe({
    name: 'chatSearch'
})
export class ChatSearchPipe implements PipeTransform {

    transform(value: User[], searchText = ''): unknown {
        if (!searchText) return value;
        const regex = /([\\\- \/.])/g;
        return (value || []).filter(u =>
            !!u.students?.find(ca => ca?.name?.toLowerCase().replace(regex, '')
                .indexOf(searchText.toLowerCase().replace(regex, '')) !== -1)
            || u?.name?.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );
    }

}
