import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {ClassroomService} from '../services/classroom/classroom.service';
import {Classroom} from '../classes/classroom.class';
import {LocalStorage} from '../storage.class';
import {CodaltComponent} from '../codalt.component';

@Component({
    selector: 'app-classrooms',
    templateUrl: './classroom-detail.component.html'
})
export class ClassroomDetailComponent extends CodaltComponent implements OnInit {

    classroom: Classroom;
    classroomId: number;

    constructor(private classroomService: ClassroomService,
                private router: Router,
                private route: ActivatedRoute) {
        super();
        this.classroom = new Classroom();

    }


    ngOnInit() {
        this.classroom = null;

        this.subscriptions.add(this.route.params.subscribe(params => {
            this.classroomId = +params['id'];
        }));

        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.getClassroom(this.classroomId);
        }));

        if (LocalStorage.school) {
            this.getClassroom(this.classroomId);
        }

    }

    getClassroom(classroomId: number) {
        this.classroomService.getClassroom(classroomId).subscribe(classroom => {
            this.classroom = classroom.data;
        });
    }

}
