import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {ArticleReply} from '../classes/article-reply';
import {ArticleReplyLike} from '../classes/article-reply-like';

@Injectable({
    providedIn: 'root'
})
export class ReplyService {


    constructor(private apiService: ApiService) {
    }

    getArticleReplies(articleId: number) {
        return this.apiService.getCall$<{
            replies: ArticleReply[],
            userLikes: ArticleReplyLike[],
            mayNotLike: boolean
        }>(`replies/${articleId}`);
    }

    sendArticleReply(userId: number, articleId: number, replyText: string, parentReplyId?: number) {
        return this.apiService.postCall$<ArticleReply>('replies', {
            userId, articleId, replyText, parentReplyId
        });
    }

    deleteArticleReply(replyId: number) {
        return this.apiService.deleteCall$(`replies/${replyId}`);
    }

    voteArticleReply(replyId: number, positive: boolean) {
        return this.apiService.postCall$<{
            reply: ArticleReply,
            like: ArticleReplyLike
        }>('replies/vote', {
            replyId, positive
        });
    }
}
