import {Component, OnInit} from '@angular/core';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {Role} from '../../classes/role.class';
import {combineLatest} from 'rxjs';
import {Feature} from '../../classes/feature.class';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {RoleFeature} from '../../classes/rolefeature.class';
import {MatDialog} from '@angular/material/dialog';
import {UserFieldDialogComponent} from './user-field-dialog/user-field-dialog.component';

@Component({
    selector: 'app-auth-roles',
    templateUrl: './auth-roles.component.html',
    styleUrls: ['./auth-roles.component.scss']
})
export class AuthRolesComponent implements OnInit {

    public roles: Role[];
    public features: Feature[];

    public featureHasRoleMatrix: FeatureHasRole[];


    constructor(private authorisationService: AuthorisationService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.initMatrix();
    }

    checkFeature(event: MatCheckboxChange, feature: Feature, role: Role) {
        if (event.checked) {
            this.authorisationService.addFeatureToRole(feature.id, role.id).subscribe();
        } else {
            this.authorisationService.removeFeatureFromRole(feature.id, role.id).subscribe();
        }
    }

    setUserFieldForFeature(event, featureHasRole: FeatureHasRole, role: Role) {
        event.preventDefault();
        let roleFeature = featureHasRole.hasRoles.get(role.id);
        if (!roleFeature) {
            roleFeature = new RoleFeature();
            roleFeature.role_id = role.id;
            roleFeature.feature_id = featureHasRole.feature.id;
        }
        const dialogRef = this.dialog.open(UserFieldDialogComponent, {
            panelClass: 'confirm-dialog',
            disableClose: true,
            data: {roleFeature: roleFeature, role}
        });
        let subs = dialogRef.afterClosed().subscribe(() => {
            this.initMatrix();
            subs.unsubscribe();
        });
    }

    private initMatrix() {
        const roles$ = this.authorisationService.getRoles();
        const features$ = this.authorisationService.getFeatures();
        combineLatest([roles$, features$]).subscribe(([roles, features]) => {
            this.roles = roles;
            this.features = features;
            this.featureHasRoleMatrix = [];
            features.forEach(feature => {
                const featureHasRole = new FeatureHasRole();
                featureHasRole.feature = feature;
                featureHasRole.hasRoles = new Map<number, RoleFeature>();
                feature.role_features.forEach(rf => {
                    featureHasRole.hasRoles.set(rf.role_id, rf);
                });
                this.featureHasRoleMatrix.push(featureHasRole);
            });
        });
    }
}

export class FeatureHasRole {
    public feature: Feature;
    public hasRoles: Map<number, RoleFeature>;

}
