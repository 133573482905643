import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AbsenceService} from '../services/absence.service';
import {AbsenceReason} from '../classes/absence-reason';
import {Student} from '../classes/student.class';
import {LocalStorage} from '../storage.class';
import {StudentService} from '../services/student/student.service';
import {Absence} from '../classes/absence';
import {Utils} from '../utils.class';
import {ActivatedRoute, Router} from '@angular/router';
import {Routenames} from '../route-names.enum';
import {requiredConditionalFn} from '../validators/required-conditional-fn.validator';
import {CordovaService} from '../services/cordova.service';

@Component({
    selector: 'app-absence-register',
    templateUrl: './absence-register.component.html',
    styleUrls: ['./absence-register.component.scss']
})
export class AbsenceRegisterComponent extends CodaltComponent implements OnInit {

    students: Student[];
    reasons: AbsenceReason[];
    form: UntypedFormGroup;

    durations = [
        'ochtend', 'middag', 'dag', 'specifiek'
    ];

    durationWithEndDate = 'dag';
    durationWithComment = 'specifiek';
    showDurationComment = false;

    teacher = false;

    currentDate = new Date();

    constructor(private absenceService: AbsenceService,
                private studentService: StudentService,
                private cordovaService: CordovaService,
                private route: ActivatedRoute,
                private router: Router) {
        super();
        this.teacher = !!LocalStorage.user.schools?.find(s => s.id === LocalStorage.school.id);
        if (this.teacher) {
            this.currentDate = null;
        }
    }

    ngOnInit(): void {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigateByUrl(`/${Routenames.absence}`);
        });
        this.subscriptions.add(this.route.params.subscribe((params: { id: number }) => {
            this.subscriptions.add(this.absenceService.getReasons().subscribe(reasons => {
                this.reasons = reasons.data;
            }));
            if (this.teacher) {
                this.subscriptions.add(this.studentService.getAll(true).subscribe(students => {
                    this.students = students.data;
                }));
            } else {
                this.students = LocalStorage.user.students.filter(s => !!s.classrooms.find(c => c.school_id === LocalStorage.school?.id));
            }
            if (params.id) {
                this.subscriptions.add(this.absenceService.getAbsence(params.id).subscribe(absence => {
                    this.createForm(absence.data);
                }));
            } else {
                this.createForm();
            }
        }));
    }

    createForm(absence?: Absence) {
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(absence?.id),
            student_id: new UntypedFormControl(absence?.student_id, Validators.required),
            reason_id: new UntypedFormControl(absence?.reason_id, Validators.required),
            duration: new UntypedFormControl((this.isDurationWithComment(absence?.duration) && absence?.id) ? this.durationWithComment : absence?.duration, Validators.required),
            duration_comment: new UntypedFormControl(absence?.duration),
            start_date: new UntypedFormControl(absence?.start_date, Validators.required),
            end_date: new UntypedFormControl(absence?.end_date),
            comment: new UntypedFormControl(absence?.comment),
            approved_comment: new UntypedFormControl(absence?.approved_comment),
            approved: new UntypedFormControl(absence?.approved),
            approved_at: new UntypedFormControl(absence?.approved_at),
            taken_date: new UntypedFormControl(absence?.taken_date)
        });
        this.form.get('duration_comment').addValidators(requiredConditionalFn(() => this.form.get('duration').value === this.durationWithComment));
        this.durationChange();
        if (absence?.id) {
            this.form.get('id').disable();
            this.form.get('student_id').disable();
            this.form.get('reason_id').disable();
            this.form.get('duration').disable();
            this.form.get('duration_comment').disable();
            this.form.get('start_date').disable();
            this.form.get('comment').disable();
        }
        if (absence?.approved_at) {
            this.form.get('approved_comment').disable();
        }
        this.form.get('duration').valueChanges.subscribe(() => {
            this.durationChange();
        });
    }

    durationChange() {
        this.showDurationComment = this.isDurationWithComment(this.form.get('duration').value);
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            const absence = new Absence();
            if (this.teacher) {
                this.form.get('taken_date').setValue(new Date());
                this.form.get('approved_at').setValue(new Date());
                this.form.get('approved').setValue(!this.reasons.find(r => r.id === +this.form.get('reason_id').value)?.unauthorized);
            }
            Object.assign(absence, this.form.value);
            if (absence.duration === this.durationWithComment) {
                absence.duration = this.form.get('duration_comment').value;
            }
            this.absenceService.saveAbsence(absence).subscribe(result => {
                this.router.navigateByUrl(`/${Routenames.absence}`);
            });
        }
    }

    reportPresence() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.form.get('end_date').setValue(new Date());
            const absence = new Absence();
            Object.assign(absence, this.form.getRawValue());
            this.absenceService.saveAbsence(absence).subscribe(result => {
                this.router.navigateByUrl(`/${Routenames.absence}`);
            });
        }
    }

    approve() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.form.get('taken_date').setValue(new Date());
            this.form.get('approved_at').setValue(new Date());
            this.form.get('approved').setValue(true);
            const absence = new Absence();
            Object.assign(absence, this.form.getRawValue());
            this.absenceService.saveAbsence(absence).subscribe(result => {
                this.router.navigateByUrl(`/${Routenames.absence}`);
            });
        }
    }

    disapprove() {
        this.form.get('approved_comment').addValidators(Validators.required);
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.form.get('approved_at').setValue(new Date());
            this.form.get('approved').setValue(false);
            const absence = new Absence();
            Object.assign(absence, this.form.getRawValue());
            this.absenceService.saveAbsence(absence).subscribe(result => {
                this.router.navigateByUrl(`/${Routenames.absence}`);
            });
        } else {
            this.form.get('approved_comment').removeValidators(Validators.required);
        }
    }

    forced() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.form.get('taken_date').setValue(new Date());
            const absence = new Absence();
            Object.assign(absence, this.form.getRawValue());
            this.absenceService.saveAbsence(absence).subscribe(result => {
                this.router.navigateByUrl(`/${Routenames.absence}`);
            });
        }
    }

    private isDurationWithComment(duration) {
        return this.durationWithComment === duration || this.durations.indexOf(duration) === -1;
    }
}
