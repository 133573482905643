<div class="login-container">
    <div class="login">
        <div class="info">
            <ng-container *ngIf="validToken == ValidationState.Validating">
                De wachtwoordvalidatie token wordt gevalideerd.
            </ng-container>
            <ng-container *ngIf="validToken == ValidationState.Invalid">
                <h2 class="mb-5">Deze link is niet meer geldig.</h2>
                <button class="big white" color="primary" mat-flat-button routerLink="/{{Routenames.lostpw}}">Vraag hier een nieuwe link aan</button>
            </ng-container>
            <ng-container *ngIf="message">
                {{message}}
            </ng-container>
        </div>

        <div *ngIf="validToken == ValidationState.Valid">
            <form [formGroup]="form" [style.fontSize.px]="19">
                <div class="title">
                    <h1 class="h4 condensed">Stel een nieuw wachtwoord in</h1>
                </div>
                <mat-form-field id="password">
                    <mat-label>Wachtwoord</mat-label>
                    <input autocomplete="new-password"
                           formControlName="password"
                           matInput
                           name="password"
                           placeholder="Je nieuwe wachtwoord"
                           type="password">
                    <mat-error [control]="form.get('password')"></mat-error>
                </mat-form-field>
                <mat-form-field id="passwordconf">
                    <mat-label>Wachtwoord (herhaling)</mat-label>
                    <input autocomplete="new-password"
                           formControlName="passwordconf"
                           matInput
                           name="passwordconf"
                           placeholder="Je wachtwoord ter bevestiging"
                           type="password">
                    <mat-error [control]="form.get('passwordconf')"></mat-error>
                </mat-form-field>
                <div class="login-buttons">
                    <button (click)="setNewPassword()" class="big w-100 dark" color="primary"
                            id="btn-setpassword" mat-flat-button>
                        {{loading ? 'We stellen je nieuwe wachtwoord in...' : 'Wachtwoord instellen'}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>