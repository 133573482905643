import {Component, Inject, OnInit} from '@angular/core';
import {CodaltComponent} from '../../../codalt.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Chat} from '../../../classes/chat';
import {ChatMessage} from '../../../classes/chat-message';
import {ArticleService} from '../../../services/article.service';
import {Article} from '../../../classes/article.class';
import {UntypedFormControl} from '@angular/forms';
import {combineLatest} from 'rxjs';
import {ConfirmDialogService} from '../../../services/confirm-dialog-service/confirm-dialog.service';
import {Router} from '@angular/router';
import {Routenames} from '../../../route-names.enum';

@Component({
    selector: 'app-copy-to-article',
    templateUrl: './copy-to-article.component.html',
    styleUrls: ['./copy-to-article.component.scss']
})
export class CopyToArticleComponent extends CodaltComponent implements OnInit {

    saving = false;

    fcArticleId = new UntypedFormControl();

    articles: Article[];

    constructor(public dialogRef: MatDialogRef<CopyToArticleComponent>,
                private confirmDialog: ConfirmDialogService,
                @Inject(MAT_DIALOG_DATA) public data: {
                    chat: Chat, messages: ChatMessage[]
                },
                private router: Router,
                private articleService: ArticleService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.articleService.getList(
            100,
            0,
            null,
            null,
            [],
            '',
            null,
            true,
            ['image_thumb'],
            true
        ).subscribe(articles => {
            this.articles = articles.data;
        }));
    }

    copy() {
        const saveCalls = [];
        this.data.messages.forEach(message => {
            const item = JSON.parse(message.message);

            if (item.type === 'image') {
                saveCalls.push(this.articleService.addChatImage(this.fcArticleId.value, item.path, this.data.chat.id));
            }
            if (item.type === 'video') {
                saveCalls.push(this.articleService.addChatVideo(this.fcArticleId.value, item.video_id));
            }

        });
        this.saving = true;
        combineLatest(...saveCalls).subscribe(result => {
            this.confirmDialog.confirm(
                'Kopiëren voltooid',
                `Alle geselecteerde foto's en/of video's zijn toegevoegd aan het artikel`,
                'Naar artikel',
                'Sluiten'
            ).then(() => {
                this.dialogRef.close();
                this.router.navigateByUrl(`${Routenames.articles}/${this.fcArticleId.value}`);
            }, () => {
                this.dialogRef.close();
            });
            this.saving = false;
        }, error => {
            this.confirmDialog.confirm(
                'Kopiëren voltooid met fouten',
                `Niet alle geselecteerde foto's en/of video's zijn toegevoegd aan het artikel`,
                'Naar artikel',
                'Sluiten'
            ).then(() => {
                this.dialogRef.close();
                this.router.navigateByUrl(`${Routenames.articles}/${this.fcArticleId.value}`);
            }, () => {
                this.dialogRef.close();
            });
            this.saving = false;
        });
    }

}

