import {Component, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {startWith} from 'rxjs/operators';
import {CodaltComponent} from '../../codalt.component';
import {PaymentRequest} from '../../classes/payment-request';
import {PaymentService} from '../../services/payment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Routenames} from '../../route-names.enum';
import {formatCurrency} from '@angular/common';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-pay-payment-request',
    templateUrl: './pay-payment-request.component.html',
    styleUrls: ['./pay-payment-request.component.scss']
})
export class PayPaymentRequestComponent extends CodaltComponent implements OnInit {

    paying = false;
    paymentRequest: PaymentRequest;
    currentSaldo = LocalStorage?.user?.transactionAmount;

    fc: {
        price: UntypedFormControl
    };
    form: UntypedFormGroup;

    constructor(private paymentService: PaymentService,
                private confirmDialog: ConfirmDialogService,
                private route: ActivatedRoute,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school) {
                this.getPaymentRequest(params['id'], params['amount'] || null);
            }
        }));
    }

    pay() {
        if ((this.fc.price.value * this.paymentRequest.students.length) > this.currentSaldo) {
            const extra = Math.ceil((this.fc.price.value * 100) * this.paymentRequest.students.length) / 100;
            const paymentRequestId = this.paymentRequest.id;
            this.router.navigate([Routenames.transactionTopUp, {extra, paymentRequestId}]);
        } else {
            this.paying = true;
            this.paymentService.payPaymentRequest(this.paymentRequest.id, this.fc.price.value).subscribe(result => {
                this.paying = false;
                this.confirmDialog.confirm(
                    'Betaald',
                    `U heeft het betaalverzoek ${this.paymentRequest.name} betaald!`,
                    'Sluiten', null).then(() => {
                    this.router.navigate([Routenames.transactionsOverview]);
                }, () => {
                    this.router.navigate([Routenames.transactionsOverview]);
                });
            }, error => {
                const errorData = error.data as { lowCredit: boolean, price: number, credit: number };
                if (errorData.lowCredit) {
                    this.confirmDialog.confirm(
                        'Fout',
                        `U heeft ${formatCurrency(errorData.credit, 'nl', '€')}
                     tegoed op uw account. Er is ${formatCurrency(errorData.price, 'nl', '€')} benodigd.`,
                        'Opwaarderen', 'Annuleren').then(() => {
                        this.router.navigate([Routenames.transactionTopUp]);
                    }, () => {

                    });
                }
                this.paying = false;
            });
        }
    }

    private getPaymentRequest(id, amount?: number) {

        this.paymentService.getPaymentRequest(id).subscribe(request => {
            this.paymentRequest = request.data;
            const validators = [Validators.required];
            if (!this.paymentRequest?.price_lower) {
                validators.push(Validators.min(this.paymentRequest.price));
            } else {
                validators.push(Validators.min(1));
            }
            if (!this.paymentRequest?.price_higher) {
                validators.push(Validators.max(this.paymentRequest.price));
            }
            this.fc = {
                price: new UntypedFormControl(this.paymentRequest.price, validators)
            };
            this.form = new UntypedFormGroup(this.fc);
            if (amount) {
                this.fc.price.setValue(amount / this.paymentRequest.students.length);
                this.pay();
            }
            this.subscriptions.add(this.fc.price.valueChanges.subscribe(price => {
                this.paymentRequest.priceToPay = this.paymentRequest.students?.length * price;
            }));
        }, () => {

        });
    }

}
