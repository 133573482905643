import {ChatUser} from './chat-user';
import {ChatMessage} from './chat-message';
import {User} from './user.class';
import {School} from './school.class';
import {Classroom} from './classroom.class';
import {ChatClassroom} from './chat-classroom';

export class Chat {
    id: number;
    name: string;
    chat_users: ChatUser[] = [];
    chat_classrooms: ChatClassroom[] = [];
    chatMessages: ChatMessage[];
    creator: User;
    school: School;
    school_id: number;
    school_type?: 'master' | 'subsite' | 'offer';
    only_admin_messages: boolean;
    classsroom: Classroom;
    lastMessage: ChatMessage;
    lastReadMessageIndex: number;
    updated_at: Date;
    deleted_at: Date;
}
