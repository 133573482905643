import {User} from '../../classes/user.class';
import {Role} from '../../classes/role.class';

export class ConditionalFunctions {

    static hasSchool(user: User, role: Role, params?: object) {
        if (params && user.anySchools) {
            return !!user.anySchools.find(p => +p.id === +params['school']);
        }
        return false;
    }

    static hasDirectSchool(user: User, role: Role, params?: object) {
        if (params && user.anySchools) {
            return !!user.anySchools.filter(p => p.is_direct)
                         .find(p => +p.id === +params['school']);
        }
        return false;
    }

}
