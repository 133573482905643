import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Event} from '../classes/event.class';
import {Image} from '../classes/image.class';
import {Document} from '../classes/document.class';
import {EventarticlejobService} from './eventarticlejob.service';
import {LocalStorage} from '../storage.class';
import {map} from 'rxjs/operators';

@Injectable()
export class EventService implements EventarticlejobService {

    constructor(private apiService: ApiService) {
    }

    getList(
        limit?: number,
        skip?: number,
        history?: boolean,
        showAllClassrooms?: boolean,
        classroomIds = [],
        search = '',
        noAfterSchoolOffer = false,
        onlyCurrentSchool = false
    ) {
        history = history || false;
        showAllClassrooms = LocalStorage.school?.type !== 'subsite' ? true : (showAllClassrooms || false);
        classroomIds = LocalStorage.school?.type !== 'subsite' ? [] : classroomIds;
        onlyCurrentSchool = LocalStorage.school?.type === 'offer';
        return this.apiService.getCall$<Event[]>(`events/${limit}/${skip}`, {
            history,
            showAllClassrooms,
            classroomIds,
            search,
            onlyCurrentSchool,
            noAfterSchoolOffer
        });
    }

    get(slug: string, copy?: boolean) {
        const params = copy ? {copy} : {};
        return this.apiService.getCall$<Event>(`events/${slug}`, params).pipe(map(event => {
            event.data.dateranges.forEach(dr => {
                dr.start_date = new Date(dr.start_date);
                dr.end_date = new Date(dr.end_date);
            });
            event.data.all_subscriptions?.sort((a, b) => {
                if (a.student.name < b.student.name) {
                    return -1;
                }
                if (a.student.name > b.student.name) {
                    return 1;
                }
                return 0;
            });
            return event;
        }));
    }

    delete(id: number) {
        return this.apiService.deleteCall$(`events/${id}`);
    }

    save(event: Event) {
        return this.apiService.postCall$<Event>('events', event);
    }

    reserve() {
        return this.apiService.postCall$<Event>('events/reserve');
    }

    sortImages(event: Event) {
        return this.apiService.postCall$<Image>('events/sort-images', {
            id: event.id,
            images: event.images.map(p => p.id)
        });
    }

    addImage(id, path) {
        return this.apiService.postCall$<Image>('events/add-image', {id, path});
    }

    rmImage(id) {
        return this.apiService.deleteCall$(`events/rm-image/${id}`);
    }

    addDocument(id, path) {
        return this.apiService.postCall$<Document>('events/add-document', {id, path});
    }

    sortDocuments(event: Event) {
        return this.apiService.postCall$<Document>('events/sort-documents', {
            id: event.id,
            documents: event.documents.map(p => p.id)
        });
    }

    rmDocument(id) {
        return this.apiService.deleteCall$(`events/rm-document/${id}`);
    }
}
