import {AfterViewInit, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {LocalStorage} from '../storage.class';

declare var tinymce: any;

@Component({
    selector: 'simple-tiny',
    template: `
        <div id="{{elementId}}{{propertyName}}"></div>`
})
export class SimpleTinyComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @HostBinding('class.ng-touched') ngTouched: boolean = false;
    @HostBinding('class.ng-invalid') ngInvalid: boolean = false;

    @Input() contentCSS;
    @Input() showMenuBar = true;
    @Input() elementId: String;
    @Input() content: any;
    @Input() height: number;
    @Input() propertyName = 'content';
    @Input() colorSetName = 'common';
    @Output() onEditorKeyup = new EventEmitter<any>();
    editor;
    private colorSet = {
        common: [
            'bfedd2', 'Lichtgroen',
            'fbeeb8', 'Lichtgeel',
            'f8cac6', 'Lichtrood',
            'eccafa', 'Lichtgpaars',
            'c2e0f4', 'Lichtblauw',

            '2dc26b', 'Groen',
            'f1c40f', 'Geel',
            'e03e2d', 'Rood',
            'b96ad9', 'Paars',
            '3598db', 'Blauw',

            '169179', 'Donkergroen',
            'e67e23', 'Donkergeel',
            'ba372a', 'Donkerrood',
            '843fa1', 'Donkerpaars',
            '236fa1', 'Donkerblauw',

            'ffffff', 'Wit',
            'f2f4f6', 'Kindwijs grijs 1',
            'dbe1e6', 'Kindwijs grijs 2',
            '74899a', 'Kindwijs grijs 3',
            '8199ab', 'Kindwijs grijs 4',
            'd51968', 'Kindwijs paars',
            '000000', 'Zwart'
        ]
    };

    subscriptions = new Subscription();

    private currentColorSet = this.colorSet.common;

    constructor(private matDialog: MatDialog) {
    }

    updateview(editor) {
        const editorcontent = editor.getContent({format: 'html'}).trim();
        if (this.content instanceof UntypedFormControl) {
            this.content.setValue(editorcontent);
            setTimeout(() => {
                this.ngTouched = true;
                this.ngInvalid = this.content.invalid;
            });
        } else {
            this.content[this.propertyName] = editorcontent;
        }
    }

    ngOnChanges(changes) {
        if (this.editor) {
            if (!this.content[this.propertyName]) {
                this.content[this.propertyName] = '';
            }
            let content = this.content[this.propertyName];
            if (this.content instanceof UntypedFormControl) {
                content = this.content.value || '';
                if (this.content.disabled) {
                    this.editor.mode.set('readonly');
                }

            }
            this.editor.setContent(content);
            this.editor.undoManager?.clear();
        }
        if (this.content instanceof UntypedFormControl) {
            this.subscriptions.add(this.content.statusChanges.subscribe(() => {
                this.ngInvalid = this.content.invalid;
                this.ngTouched = this.content.touched;
            }));
        }
    }

    ngAfterViewInit() {
        this.setColorSet();
        setTimeout(() => {

            tinymce.init({
                selector: '#' + this.elementId + this.propertyName,
                base_url: '/tinymce',
                suffix: '.min',
                browser_spellcheck: true,
                contextmenu: false,
                language: 'nl',
                menubar: this.showMenuBar ? 'insert edit format table view' : '',
                menu: {
                    insert: {title: 'Invoegen', items: 'charmap'},
                    edit: {title: 'Bewerken', items: 'undo redo | cut copy paste pastetext | selectall | code '},
                    format: {title: 'Stijl', items: ' bold italic underline | align | forecolor backcolor | removeformat '},
                    table: {title: 'Tabel', items: 'inserttable tableprops | cell row column'}
                },
                plugins: ['link', 'media', 'paste', 'table', 'lists', 'advlist', 'fullscreen', 'charmap', 'autolink'],
                toolbar1: 'undo redo | bold italic | bullist numlist | link | indent outdent | fullscreen ',
                toolbar2: '',
                toolbar3: '',
                quickbars_selection_toolbar: 'bold italic underline quicklink h2 h3 charmap',
                skin: 'oxide',
                table_default_styles: {
                    width: '100%'
                },
                table_default_attributes: {
                    cellspacing: 0
                },
                cleanup: true,
                paste_block_drop: true,
                paste_data_images: false,
                paste_preprocess: function (plugin, args) {
                    const keepElements = ['b', 'i', 'u', 'br', 'p', 'strong', 'em'];

                    args.content = args.content.replace(/<\/?([a-z]+) ?[^>]*>/g, function (match, tagName) {
                        if (keepElements.indexOf(tagName) !== -1) {
                            return match;
                        }
                        return '';
                    });
                },
                invalid_elements: 'div,main',
                extended_valid_elements: 'iframe[src|title|frameborder|allowfullscreen]',
                content_css: this.contentCSS ? `/assets/tinycss/${this.contentCSS}` : null,
                style_formats: [
                    {title: 'Kop 1', block: 'h1'},
                    {title: 'Kop 2', block: 'h2'},
                    {title: 'Kop 3', block: 'h3'},
                    {title: 'Kleiner', inline: 'span', classes: 'textsmall'},
                    {title: 'Groter', inline: 'span', classes: 'textlarge'}
                ],
                relative_urls: false,
                color_map: this.currentColorSet,
                color_cols: 5,
                color_cols_foreground: 5,
                color_cols_background: 5,
                color_cols_text: 5,
                min_height: this.height || 400,
                init_instance_callback: editor => {
                    if (!this.content[this.propertyName]) {
                        this.content[this.propertyName] = '';
                    }

                    let content = this.content[this.propertyName];
                    if (this.content instanceof UntypedFormControl) {
                        content = this.content.value || '';
                        if (this.content.disabled) {
                            this.editor.mode.set('readonly');
                        }
                    }

                    this.editor.setContent(content);
                },
                setup: editor => {
                    this.editor = editor;

                    editor.on('ExecCommand', () => {
                        editor.save();
                    });

                    editor.on('change', () => {
                        editor.save();
                        if (this.content instanceof UntypedFormControl) {
                            this.content.markAllAsTouched();

                            if (editor.getContent({format: 'html'}).trim().length !== this.content?.value?.length) {
                                this.content.markAsDirty();
                            }
                        }
                        this.updateview(editor);

                    });

                    editor.on('ObjectResized', () => {
                        editor.save();
                        this.updateview(editor);
                    });

                }
            });

        });
    }

    ngOnDestroy() {
        tinymce.remove(this.editor);
        this.subscriptions.unsubscribe();
    }

    ngOnInit() {
        this.elementId += '' + (Math.round(Math.random() * 9999));
    }

    private setColorSet(): void {
        this.currentColorSet = this.colorSet[this.colorSetName];
        if (LocalStorage.school) {
            let index = 1;
            LocalStorage.school.colors?.forEach(additionalColor => {
                if (this.currentColorSet.indexOf(additionalColor.toLowerCase()) === -1) {
                    this.currentColorSet.push(additionalColor.toLowerCase());
                    this.currentColorSet.push(`${LocalStorage.school.name} kleur ${index}`);
                    index++;
                }
            });
        }
    }

}
