import {EventEmitter, Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {ConfirmDialogService} from './confirm-dialog-service/confirm-dialog.service';
import {AuthenticationService} from './auth/authentication.service';
import {LocalStorage} from '../storage.class';
import {Settings} from '../settings.class';
import {catchError, finalize, map, mergeMap, retryWhen} from 'rxjs/operators';
import {Observable, of, throwError, timer} from 'rxjs';

interface WebsocketMessage<T> {
    type: string;
    data: T;
    success: boolean;
    reinit: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    websocketOpen = new EventEmitter<boolean>();
    private websocket;

    constructor(private confirmDialogService: ConfirmDialogService,
                private authService: AuthenticationService) {

    }

    disconnect() {
        console.log('ws disconnect');
        this.websocket?.unsubscribe();
        this.websocket = null;
    }

    checkIsOpen() {
        return !this.websocket.closed && !this.websocket.isStopped;
    }

    public getWebsocket<T>(type: string[]) {
        return this.getFullWebsocket().pipe(map((data: WebsocketMessage<T>) => {
                if (typeof data.data !== 'undefined' && (data.data as any as string) === 'Unauthorized.') {
                    this.authService.logout();
                } else if (type.includes(data.type) || !data.success) {
                    return data;
                }
            }), retryWhen(genericRetryStrategy({
                scalingDuration: 500,
                excludedStatusCodes: [500],
                maxRetryAttempts: 20
            })),
            catchError(error => of(error)));

    }

    public doRequest(type: string, data?: any, method?: string) {
        if (data && !method) {
            method = 'post';
        }
        this.getFullWebsocket().next({type, data, method, access_token: LocalStorage.getUserToken()});
    }

    private getFullWebsocket(): WebSocketSubject<object> {
        if (!this.websocket) {
            const schoolId = LocalStorage.school ? `${LocalStorage.school.id}` : '';
            this.websocket = webSocket({
                url: `${Settings.WS_ENDPOINT}?access_token=${LocalStorage.getUserToken()}&school=${schoolId}`,
                openObserver: {
                    next: () => {
                        this.websocketOpen.emit(true);
                    }
                },
                closeObserver: {
                    next: () => {
                        this.websocketOpen.emit(false);
                    }
                }
            });
        }
        return this.websocket;
    }
}

export const genericRetryStrategy = ({
                                         maxRetryAttempts = 3,
                                         scalingDuration = 1000,
                                         excludedStatusCodes = []
                                     }: {
    maxRetryAttempts?: number,
    scalingDuration?: number,
    excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
    return attempts.pipe(
        mergeMap((error, i) => {
            const retryAttempt = i + 1;
            // if maximum number of retries have been met
            // or response is a status code we don't wish to retry, throw error
            if (
                retryAttempt > maxRetryAttempts ||
                excludedStatusCodes.find(e => e === error.status)
            ) {
                console.error('Websocket maximum reconnect tries reached');
                return throwError(error);
            }
            console.warn(
                `Websocket reconnect attempt ${retryAttempt}: retrying in ${retryAttempt *
                scalingDuration}ms`
            );
            return timer(retryAttempt * scalingDuration);
        }),
        finalize(() => console.log('End Retry Websocket'))
    );
};
