import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentService} from '../../services/payment.service';
import {UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {formatCurrency} from '@angular/common';

@Component({
    selector: 'app-add-cash-credit',
    templateUrl: './add-cash-credit.component.html',
    styleUrls: ['./add-cash-credit.component.scss']
})
export class AddCashCreditComponent extends CodaltComponent implements OnInit {

    paying = false;
    users: User[];
    form: UntypedFormGroup;
    fc: {
        amount: UntypedFormControl,
        payed_by: UntypedFormControl
    };

    constructor(private paymentService: PaymentService,
                private userService: UserService,
                private confirmDialog: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        this.createForm();
    }

    pay() {
        if (this.form.valid) {
            this.paying = true;
            this.paymentService.addCashCredit(this.fc.amount.value, this.fc.payed_by.value).subscribe(transaction => {
                this.paying = false;
                const user = this.users.find(u => u.id === this.fc.payed_by.value);
                this.confirmDialog.confirm(
                    'Account opgewaardeerd',
                    `Het account van ${user.name} is opgewaardeerd met ${formatCurrency(transaction.data.amount, 'nl', '€')}`,
                    'Sluiten',
                    null
                ).then(() => {
                    this.createForm();
                }, () => {

                });
            }, () => {
                this.paying = false;
            });
        }
    }

    private createForm() {
        this.users = [];

        this.userService.getParnassysUsers(true).subscribe(users => {
            this.users = users.data;
        });

        this.fc = {
            amount: new UntypedFormControl(null, [Validators.required, Validators.min(5), Validators.max(250)]),
            payed_by: new UntypedFormControl(null, [Validators.required])
        };
        this.form = new UntypedFormGroup(this.fc);
    }

}
