export class PaymentDefault {
    id: number;
    name: string;
    price: number;
    price_higher = false;
    price_lower = false;
    auto_write_off = false;
    low_permissions = false;
    school_id: number;
    created_by: Date;
    updated_at: Date;
    created_at: Date;
}
