<ng-container *ngIf="destinationUrl">
    <a [routerLink]="destinationUrl" [style.right.px]="right" class="add-button index{{index}}"
       matTooltip="{{tooltip}}"
       matTooltipPosition="before">
        <i class="fa {{iconClass}}"></i>
    </a>
</ng-container>
<ng-container *ngIf="!destinationUrl">
    <a [style.right.px]="right" class="curpoint add-button index{{index}}"
       matTooltip="{{tooltip}}"
       matTooltipPosition="before">
        <i *ngIf="!pending" class="fa {{iconClass}}"></i>
        <i *ngIf="pending" class="fa fa-spinner fa-spin"></i>
    </a>
</ng-container>