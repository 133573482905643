<div [class.dragover]="dragOver" [class.uploading]="uploading" class="dialog-container" mat-dialog-content>
    <h1 class="condensed dialog-title">
        {{isFolder ? 'Map toevoegen' : 'Bestand uploaden'}}
    </h1>
    <div class="dialog-content mt-3">
        <div *ngIf="!file && !isFolder" class="mb-3">
            Geen bestand toegevoegd
            <button color="primary" mat-button style="padding: 0">
                <label style="margin: 0; padding: 0 16px;">
                    Bestand uploaden <input (change)="uploadFile($event)" hidden multiple type="file">
                </label>
            </button>
        </div>
        <form [formGroup]="form">
            <div *ngIf="file">
                <b>Geüpload bestand:</b> {{file}}
                <br>
            </div>
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Naam</mat-label>
                <input formControlName="name" id="name" matInput name="name" placeholder="Naam"
                       type="text" (keyup.enter)="save()">
                <mat-error [control]="form.get('name')"></mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="d-flex flex-wrap justify-content-between">
        <button [mat-dialog-close]="false" mat-button>Annuleren</button>
        <div class="spacer"></div>
        <button (click)="save()" [disabled]="saving || !form.valid" cdkFocusInitial color="primary" mat-raised-button>
            Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
        </button>
    </div>
    <div class="overlay">
        <div class="overlay-wrapper drag">
            <div class="upload-icon">
                <i class="fas fa-upload"></i>
            </div>
        </div>
        <div class="overlay-wrapper uploading">
            <div class="upload-icon">
                <i class="fas fa-circle-notch fa-spin"></i>
            </div>
            <div>
                Bezig met uploaden...
            </div>
        </div>
    </div>
</div>
