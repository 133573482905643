import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Discussiontime} from '../classes/discussiontime.class';
import {Discussion} from '../classes/discussion.class';
import {Classroom} from '../classes/classroom.class';

@Injectable()
export class DiscussionService {

    constructor(private apiService: ApiService) {
    }

    getOpenDiscussionCount() {
        return this.apiService.getCall$<{school_id: number, open: number}[]>('discussions/open-count');
    }

    getList(limit?: number, skip?: number) {
        return this.apiService.getCall$<Discussion[]>(`discussions/${limit}/${skip}`);
    }

    getHome() {
        return this.apiService.getCall$<Discussion[]>(`discussions/home`);
    }

    getClassrooms() {
        return this.apiService.getCall$<Classroom[]>(`discussions/classrooms`);
    }

    get(id: number) {
        return this.apiService.getCall$<Discussion>(`discussions/${id}`);
    }

    delete(id: number) {
        return this.apiService.deleteCall$(`discussions/${id}`);
    }

    unavailableDateClassroom(discussiontime: Discussiontime) {
        return this.apiService.postCall$(`discussions/unavailableDateClassroom`, {
            classroom_id: discussiontime.classroom_id,
            date: discussiontime.datetime,
            discussion_id: discussiontime.discussion_id,
            available: discussiontime.unavailable
        });
    }

    unavailableNextClassroom(discussiontime: Discussiontime) {
        return this.apiService.postCall$(`discussions/unavailableNextClassroom`, {
            classroom_id: discussiontime.classroom_id,
            date: discussiontime.datetime,
            discussion_id: discussiontime.discussion_id,
            available: discussiontime.unavailable
        });
    }

    unavailablePrevClassroom(discussiontime: Discussiontime) {
        return this.apiService.postCall$(`discussions/unavailablePrevClassroom`, {
            classroom_id: discussiontime.classroom_id,
            date: discussiontime.datetime,
            discussion_id: discussiontime.discussion_id,
            available: discussiontime.unavailable
        });
    }

    save(discussion: Discussion) {
        return this.apiService.postCall$<Discussion>('discussions', discussion);
    }

    updateTime(discussiontime: Discussiontime) {
        return this.apiService.postCall$<Discussiontime>('discussions/updateTime', discussiontime);
    }
}
