import {EventEmitter, Injectable} from '@angular/core';
import {WebsocketService} from './websocket.service';
import {Chat} from '../classes/chat';
import {Observable} from 'rxjs';
import {ApiService} from './api/api.service';
import {User} from '../classes/user.class';
import {ChatMessage} from '../classes/chat-message';
import {ChatUser} from '../classes/chat-user';
import {LocalStorage} from '../storage.class';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    static doNotDisconnect = false;

    private archivedItems: Chat[] = [];
    private items: Chat[] = [];
    private itemChanged = new EventEmitter<number[]>();
    private retryTimeoutPointer;

    private init = false;

    constructor(private websocketService: WebsocketService, private apiService: ApiService) {
        if (!!LocalStorage.getUserToken()) {
            this.websocketInit();
        }
        this.websocketService.websocketOpen.subscribe(isOpen => {
            if (isOpen) {
                this.websocketService.doRequest('chats/overview');
            } else {
                this.retryWebsocketInit();
            }
        });
    }

    archive(chatIds) {
        return this.apiService.deleteCall$('chats/archive', {chatIds});
    }

    deArchive(chatIds) {
        return this.apiService.deleteCall$('chats/archive', {chatIds, restore: true});
    }

    clearData() {
        this.items = [];
    }

    checkIsOpen() {
        return this.websocketService.checkIsOpen();
    }

    disconnect() {
        if (!ChatService.doNotDisconnect || !this.checkIsOpen()) {
            this.websocketService.disconnect();
            this.init = false;
        }
    }

    public getList(clean?: boolean, archived?: boolean): Observable<Chat[]> {
        if (!this.init) {
            this.websocketInit();
        }
        return new Observable<Chat[]>((observer) => {
            const itemChangedSubscription = this.itemChanged.subscribe(() => {
                if (archived) {
                    observer.next(this.archivedItems);
                } else {
                    observer.next(this.items);
                }

            });

            if (clean) {
                this.websocketService.doRequest(archived ? 'chats/archive' : 'chats/overview');
            } else if (this.items?.length && !archived) {
                observer.next(this.items);
            } else if (this.archivedItems?.length && archived) {
                observer.next(this.archivedItems);
            }

            return {
                unsubscribe(): void {
                    itemChangedSubscription.unsubscribe();
                }
            };
        });
    }

    public getChatUserMap(chatId): Observable<Map<number, User>> {
        return new Observable((observer) => {
            this.getChat(chatId)
                .pipe(map((chat: Chat) => {
                    const map = new Map<number, User>();
                    if (chat) {
                        chat.chat_users.forEach(cu => {
                            if (cu.user) {
                                map.set(cu.user.id, cu.user);
                            }
                        });
                    }
                    return map;
                }))
                .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev.keys()) === JSON.stringify(curr.keys())))
                .subscribe(map => observer.next(map));
        });
    }

    public getChat(chatId, archived?: boolean): Observable<Chat> {
        return new Observable<Chat>((observer) => {
            this.getList(false, archived).subscribe((chats: Chat[]) => {
                observer.next(chats.find(i => i.id === chatId));
            });
        });
    }

    public saveChat(chat: {
        id?: number,
        name: string,
        users?: number[],
        classrooms?: number[]
    }) {
        return this.apiService.postCall$<Chat>('chats', chat);
    }

    public leaveChat(chat: Chat) {
        const chatUser = chat.chat_users.find(u => u.user_id === LocalStorage.user.id);
        return this.apiService.deleteCall$('chats', {id: chatUser.id}).pipe(map(() => {
            this.items.splice(this.items.indexOf(chat), 1);
            this.itemChanged.emit(this.items.map(i => +i.id));
        }));
    }

    public lastRead(messageId, chatId) {
        return this.apiService.postCall$('chats/message/read', {messageId, chatId}).pipe(map(() => {
            this.itemChanged.emit();
        }));
    }

    public muteChat(chat: Chat) {
        const chatUser = chat.chat_users.find(u => u.user_id === LocalStorage.user.id);
        return this.apiService.getCall$('chats/mute', {id: chatUser.id});
    }

    onlyAdminMessages(chat: Chat) {
        return this.apiService.postCall$('chats/only-admin', {
            chatId: chat.id,
            only_admin_messages: chat.only_admin_messages
        });
    }

    public makeUserAdmin(user: ChatUser) {
        return this.apiService.postCall$<ChatUser>('chats/admin', user);
    }

    public getUsers() {
        return this.apiService.getCall$<User[]>('chats/users');
    }

    public newMessage(message: ChatMessage) {
        this.websocketService.doRequest('chatMessage', message);
    }

    private retryWebsocketInit() {
        this.init = false;
        if (this.retryTimeoutPointer) {
            clearTimeout(this.retryTimeoutPointer);
        }
        this.retryTimeoutPointer = setTimeout(() => {
            this.websocketInit();
        }, 1000);
    }

    private websocketInit() {
        this.init = true;
        this.websocketService.getWebsocket<{
            chats: Chat[],
            users: User[],
            archived: number
        }>(['chats/overview', 'chats/archive']).subscribe(
            (items: { data: { chats: Chat[], users: User[] }, reinit: boolean }) => {

                if (Array.isArray(items?.data?.chats)) {
                    items.data.chats.forEach(chat => {
                        chat.chat_users.filter(cu => !cu.user).forEach(cu => {
                            cu.user = items.data.users.find(u => u.id === cu.user_id);
                        });
                    });
                    this.websocketHandling(items.data.chats);
                } else if (!!items?.reinit) {
                    this.retryWebsocketInit();
                }
            },
            () => {
                this.retryWebsocketInit();
            },
            () => {
                this.retryWebsocketInit();
            }
        );
    }

    private websocketHandling(items: Chat[]) {
        items.forEach(item => {
            const currentItem = this.items.find(p => p.id === +item.id);
            const currentArchiveItem = this.archivedItems.find(p => p.id === +item.id);
            if (item.deleted_at) {
                if (currentItem) {
                    this.items.splice(this.items.indexOf(currentItem), 1);
                }
                if (currentArchiveItem) {
                    Object.assign(currentArchiveItem, item);
                } else {
                    this.archivedItems.push(item);
                }
            } else {
                if (currentArchiveItem) {
                    this.archivedItems.splice(this.archivedItems.indexOf(currentArchiveItem), 1);
                }
                if (currentItem) {
                    Object.assign(currentItem, item);
                } else {
                    this.items.push(item);
                }
            }
        });
        this.items.sort((a, b) => {
            if ((a?.lastMessage?.updated_at || a.updated_at) > (b?.lastMessage?.updated_at || b.updated_at)) {
                return -1;
            }
            if ((a?.lastMessage?.updated_at || a.updated_at) < (b?.lastMessage?.updated_at || b.updated_at)) {
                return 1;
            }
            return 0;
        });
        this.archivedItems.sort((a, b) => {
            if ((a?.lastMessage?.updated_at || a.updated_at) > (b?.lastMessage?.updated_at || b.updated_at)) {
                return -1;
            }
            if ((a?.lastMessage?.updated_at || a.updated_at) < (b?.lastMessage?.updated_at || b.updated_at)) {
                return 1;
            }
            return 0;
        });
        this.itemChanged.emit(items.map(i => +i.id));
    }
}
