import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {CustobType} from '../../classes/custob-type.class';


@Injectable()
export class CustobTypeService {

    constructor(public apiService: ApiService) {
    }

    getAll(limit: number = null): Promise<Array<CustobType>> {
        return this.apiService.getCall(`custobtype/all`);
    }

    getSingle(custobTypeId): Promise<CustobType> {
        return this.apiService.getCall(`custobtype/detail/${custobTypeId}`);
    }

    save(custobType: CustobType): Promise<Object> {
        return this.apiService.postCall(`custobtype/save`, {
            custobType: custobType
        });
    }
}
