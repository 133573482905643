<div class="items">
    <virtual-scroller #virtualScroll (vsEnd)="scroll($event)" [items]="discussions" class="item-list">
        <div *ngFor="let discussion of virtualScroll.viewPortItems" class="item"
             routerLink="/{{Routenames.discussions}}/{{discussion.id}}">
            <div class="detail__block">
                <h3>{{discussion.name}}</h3>
                <div class="details">
                    <div *ngIf="!discussion.subscription_open">
                        Inschrijven mogelijk vanaf
                        {{discussion.pre_subscription_start | date:'EEE d MMM H:mm'}}
                        / {{discussion.subscription_start | date:'EEE d MMM yyyy H:mm'}}
                    </div>
                    <div *ngIf="discussion.subscription_open" class="item__details">
                        <ng-container *ngFor="let date of discussion.times; let first = first">
                            {{first ? '' : ' | '}} {{date.start_date | date:'EEE d MMM yyyy'}}
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="item-spacer"></div>
        <h2 class="title">Oudergesprekken</h2>
    </virtual-scroller>
    <div class="floating-buttons">
        <button *ngIf="AuthorisationService.hasFeature('createDiscussion')" color="primary" mat-fab
                routerLink="/{{Routenames.discussionsPlanner}}/edit/new">
            <mat-icon class="fas fa-plus"></mat-icon>
        </button>
    </div>
    <div *ngIf="discussions.length === 0 && !loading" class="not-found">
        Er zijn nog geen oudergesprekken gepland
    </div>
</div>
