<div class="container-fluid page-options space-for-sidemodal">
    <div *ngIf="page" class="container">
        <div class="row">
            <div class="col">
                <h2 class="page-title">Pagina beheren</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="w100">
                    <input #pageName [(ngModel)]="page.name" matInput
                           name="pagename"
                           placeholder="Pagina naam" required type="text">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="w100">
                    <mat-select [(ngModel)]="page.parent_page_id" placeholder="Subpagina van">
                        <mat-option [value]="null">
                            Geen
                        </mat-option>
                        <ng-container *ngFor="let parentPage of availableParentPages">
                            <mat-option *ngIf="parentPage.id != page.id || parentPage.children.length > 0" [disabled]="pageDepth > 1"
                                        [value]="parentPage.id">
                                {{parentPage.name}}
                                <ng-container *ngIf="pageDepth > 1">
                                    (Niet mogelijk)
                                </ng-container>
                            </mat-option>
                            <ng-container *ngFor="let sub1 of parentPage.children">
                                <ng-container *ngIf="sub1.id != page.id || sub1.children.length > 0">
                                    <mat-option *ngIf="pageDepth < 1" [disabled]="pageDepth >= 1" [value]="sub1.id">
                                        &raquo; {{sub1.name}}
                                    </mat-option>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div #pageurlcontainer class="col pageurl">
                <mat-form-field (click)="copyUrl()" class="spacer w100">
                    <div class="inputs">
                        <div class="animated">{{fullUrl}}</div>
                        <div class="animated animate">{{fullUrl}}</div>
                        <input #urlInput [value]="fullUrl" matInput name="pageurl" placeholder="Pagina URL" readonly type="text">
                    </div>
                </mat-form-field>
            </div>
            <div class="col-auto">
                <button (click)="seoOptionsDialog()" class="mt-2 pull-right" mat-button>SEO opties</button>
            </div>
        </div>
        <div class="row">
        </div>


        <div class="page-option-buttons row">
            <div class="col">
                <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="page.accessibility">
                    <mat-button-toggle *ngFor="let option of accesibilityOptions" [value]="option.value">
                        <mat-icon [ngClass]="option.icon" class="fa"></mat-icon>
                        {{option.text}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="col-auto">
                <div class="buttons-container mt-2">
                    <button (click)="create(false)" class="add-button" mat-button>{{action}}</button>
                    <button (click)="create(true)" class="add-button button-stay" color="primary" mat-raised-button>
                        {{actionstay}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid space-for-sidemodal">
    <div class="row">
        <div class="container">
            <div [hidden]="pageDepth == 0 || page.structure.length == 0" class="alert alert-danger">
                Let op: deze pagina heeft zowel subpagina's als inhoud. <br>
                <b>Laat deze pagina daarom leeg, of verwijder de subpagina's.</b>
            </div>
        </div>
    </div>
</div>
<app-elements
    #elements
    (doScroll)="doScroll.emit($event)"
    (saveChanges)="create(true, null, $event)"
    (showSideBar)="showSideBar.emit($event)"
    [elementHolderLastChanged]="pageLastChanged"
    [elementHolder]="'page/' + page?.id"
    [elements]="page?.structure"
    [values]="page?.content"></app-elements>
