import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {AbsenceReason} from '../classes/absence-reason';
import {Absence} from '../classes/absence';

@Injectable({
    providedIn: 'root'
})
export class AbsenceService {

    constructor(private apiService: ApiService) {
    }

    getAbsenceHomeStats() {
        return this.apiService.getCall$<AbsenceStats>('absences/stats');
    }

    getReasons() {
        return this.apiService.getCall$<AbsenceReason[]>('absences/reasons');
    }

    saveAbsence(absence: Absence) {
        return this.apiService.postCall$<Absence>('absences', absence);
    }

    deleteAbsence(id: number) {
        return this.apiService.deleteCall$(`absences/${id}`);
    }

    getAbsences(studentIds?: number[], classroomIds?: number[], done?: boolean, unauthorized?: boolean) {
        let params = {};
        if (studentIds) {
            params = {...params, studentIds};
        }
        if (classroomIds) {
            params = {...params, classroomIds};
        }
        if (done) {
            params = {...params, done};
        }
        if (unauthorized) {
            params = {...params, unauthorized};
        }
        return this.apiService.getCall$<Absence[]>('absences', params);
    }

    getAbsence(id) {
        return this.apiService.getCall$<Absence>(`absences/${id}`);
    }

    getAbsenceCounts() {
        return this.apiService.getCall$<AbsenceCount[]>(`absences/counts`);
    }
}

export class AbsenceCount {
    name: string;
    student_id: number;
    approved: number;
    notapproved: number;
}

export class AbsenceStats {
    notApprovedLastWeek: number;
    future: number;
}
