import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChatService} from '../services/chat.service';
import {Chat} from '../classes/chat';
import {MatDialog} from '@angular/material/dialog';
import {NewChatDialogComponent} from './new-chat-dialog/new-chat-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CodaltComponent} from '../codalt.component';
import {combineLatest, Subscription} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {LocalStorage} from '../storage.class';
import {startWith} from 'rxjs/operators';
import {School} from '../classes/school.class';
import {UntypedFormControl} from '@angular/forms';
import {Routenames} from '../route-names.enum';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {FileService} from '../services/file/file.service';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent extends CodaltComponent implements OnInit, OnDestroy {

    fileIconMap = FileService.fileIconMap;

    archive: boolean;

    subscriptions = new Subscription();
    chats: Chat[];
    unreadOtherSchoolChats = new Map<School, Chat[]>();
    fcSearch = new UntypedFormControl();
    loading = false;
    childActive: boolean;

    selectedItems = new Map<number, Chat>();

    constructor(private chatService: ChatService,
                private confirmDialog: ConfirmDialogService,
                private breakpointObserver: BreakpointObserver,
                private router: Router,
                private route: ActivatedRoute,
                private dialog: MatDialog) {
        super();
    }

    active(active) {
        setTimeout(() => {
            this.childActive = active;
        });
    }

    selectStart(chat: Chat) {
        if (this.selectedItems.size < 1) {
            this.selectedItems.set(chat.id, chat);
        }
    }

    tapSelect(chat: Chat) {
        if (this.selectedItems.size > 0) {
            if (this.selectedItems.has(chat.id)) {
                this.selectedItems.delete(chat.id);
            } else {
                this.selectedItems.set(chat.id, chat);
            }
        } else if (!this.selectedItems?.size) {
            this.router.navigateByUrl(`/${this.archive ? Routenames.chatArchive : Routenames.chat}/${chat.id}`);
        }
    }

    ngOnInit() {
        this.chatService.disconnect();
        const routeData = this.route.snapshot.data as { archive: boolean };
        this.archive = routeData?.archive;

        this.getChats();

        document.addEventListener('pause', this.chatComponentPause);
        document.addEventListener('resume', this.chatComponentResume);
    }

    archiveChats() {
        const selectedChats = Array.from(this.selectedItems.values());
        const notAdminFromChats = selectedChats.filter(c => !c.chat_users.find(u => u.user_id === this.LocalStorage.user.id)?.admin);
        let extraInfo = '';
        if (notAdminFromChats?.length) {
            const archive = this.archive ? 'dearchiveren' : 'archiveren';
            const chatNames = notAdminFromChats.map(c => c.name).join(', ');
            extraInfo = `<p><br><i>Je bent geen beheerder van de volgende chats:<br> ${chatNames} <br>Deze kun je niet ${archive}</i></p>`;
        }
        if (this.archive) {
            this.confirmDialog.confirm(
                'Chats dearchiveren',
                `Weet je zeker dat je deze chats terug wilt plaatsen vanuit het archief?<br>${extraInfo}`,
                'Terugplaatsen',
                'Annuleren'
            ).then(() => {
                this.chatService.deArchive(Array.from(this.selectedItems.keys())).subscribe(() => {
                    this.selectedItems.clear();
                });
            }, () => {

            });
        } else {
            this.confirmDialog.confirm(
                'Chats archiveren',
                `Weet je zeker dat je deze chats wilt archiveren?<br>${extraInfo}`,
                'Archiveren',
                'Annuleren'
            ).then(() => {
                this.chatService.archive(Array.from(this.selectedItems.keys())).subscribe(() => {
                    this.selectedItems.clear();
                });
            }, () => {

            });
        }
    }

    private getChats() {
        this.loading = true;
        this.subscriptions?.unsubscribe();
        this.subscriptions = new Subscription();
        const school$ = LocalStorage.schoolChange.pipe(startWith(LocalStorage.school));
        const chats$ = this.chatService.getList(true, this.archive);
        this.subscriptions.add(combineLatest([chats$, school$]).subscribe(([chats, school]) => {
            this.loading = false;
            this.chats = chats.filter(c => c.school_id === LocalStorage.school.id || !c.school_id || c.school_type === 'offer').sort();
            const unreadOtherSchoolChats = chats.filter(c => !this.chats.find(cc => c.id === cc.id))
                .filter(chat => chat.lastMessage && chat?.lastMessage?.id > (chat?.chat_users?.find(u => u.user_id === LocalStorage.user.id))?.last_read);
            this.unreadOtherSchoolChats.clear();
            unreadOtherSchoolChats.forEach(chat => {
                const chatSchool = LocalStorage.schools.find(s => s.id === chat.school_id);
                const items = this.unreadOtherSchoolChats.get(chatSchool) ?? [];
                items.push(chat);

                this.unreadOtherSchoolChats.set(chatSchool, items);
            });
        }));
    };

    private chatComponentPause = () => {
        setTimeout(() => {
            this.subscriptions?.unsubscribe();
            this.subscriptions = new Subscription();
        });
    };
    private chatComponentResume = () => {
        setTimeout(() => {
            this.getChats();
        }, 650);
    };

    toSchool(school: School) {
        LocalStorage.school = school;
    }

    newChat() {
        const isMobile = this.breakpointObserver.isMatched('(max-width: 450px)');
        let dialogRef = this.dialog.open(NewChatDialogComponent, {
            maxHeight: '98vh',
            minHeight: isMobile ? '100%' : '500px',
            height: isMobile ? '100%' : '700px',
            minWidth: isMobile ? '100%' : '400px',
            width: isMobile ? '100%' : '400px',
            panelClass: 'chat-dialog'
        });
        const ref = dialogRef.afterClosed().subscribe(chat => {
            if (chat) {
                this.router.navigateByUrl(`${this.Routenames.chat}/${chat.id}`);
            }
            ref.unsubscribe();
        });
    }

    ngOnDestroy() {
        document.removeEventListener('pause', this.chatComponentPause);
        document.removeEventListener('resume', this.chatComponentResume);
        super.ngOnDestroy();
    }

}
