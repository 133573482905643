<div class="container white">
    <h2 class="page-title">Structures beheren</h2>
    <table class="table">
        <thead>
        <tr>
            <th>
                Structure
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let structure of structures">
            <td (click)="editStructure(structure.id)" class="curpoint">{{structure.name}}</td>
        </tr>
        </tbody>
    </table>
</div>
<app-edit-button destinationUrl="/structures/new" iconClass="fa-plus" tooltip="Toevoegen"></app-edit-button>