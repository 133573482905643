import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Chat} from '../../classes/chat';
import {ChatMessage} from '../../classes/chat-message';
import {CodaltComponent} from '../../codalt.component';

@Component({
    selector: 'app-chat-message-info-dialog',
    templateUrl: './chat-message-info-dialog.component.html',
    styleUrls: ['./chat-message-info-dialog.component.scss']
})
export class ChatMessageInfoDialogComponent extends CodaltComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ChatMessageInfoDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    chat: Chat, message: ChatMessage
                }) {
        super();
    }

    ngOnInit(): void {
    }

}
