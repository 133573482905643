import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {StudentService} from '../services/student/student.service';
import {LocalStorage} from '../storage.class';
import {Routenames} from '../route-names.enum';
import {SortingComponent} from '../sorting.component';
import {Student} from '../classes/student.class';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {Classroom} from '../classes/classroom.class';
import {ClassroomService} from '../services/classroom/classroom.service';
import {debounceTime, pairwise, startWith} from 'rxjs/operators';
import {AuthorisationService} from '../services/auth/authorisation.service';
import {PermissionQuestion} from '../classes/permission-question';
import {PermissionService} from '../services/permission.service';

@Component({
    selector: 'students-component',
    templateUrl: './students.component.html'
})
export class StudentsComponent extends SortingComponent<Student> implements OnInit {

    shownPermissions: PermissionQuestion[];
    permissions: PermissionQuestion[];

    LocalStorage = LocalStorage;
    studentcount_all: number;
    studentcount_selected: number;
    onlythisschoolBool = true;
    searchQuery = '';

    fcShowPermissions = new FormControl<number[]>([]);
    fcFilter = new UntypedFormControl();
    classrooms: Classroom[];

    students: Student[];

    userManagement = false;

    constructor(private studentService: StudentService,
                private classroomService: ClassroomService,
                private permissionService: PermissionService,
                private router: Router) {
        super();
    }

    get onlythisschool() {
        return this.onlythisschoolBool;
    }

    set onlythisschool(onlythisschool) {
        this.onlythisschoolBool = onlythisschool;
        this.getStudents();
    }

    ngOnInit() {
        this.subscriptions.add(this.fcShowPermissions.valueChanges.subscribe(permissionIds => {
            this.shownPermissions = this.permissions.filter(p => permissionIds.includes(p.id));
        }));
        this.userManagement = AuthorisationService.hasFeature('userManagement');
        if (!LocalStorage.showAllClassroomsStudentList && !LocalStorage.showAllClassroomsStudentList && !LocalStorage.showClassroomsStudentList?.length) {
            this.fcFilter.setValue(['own']);
        } else if (LocalStorage.showAllClassroomsStudentList) {
            this.fcFilter.setValue(['all']);
        } else {
            this.fcFilter.setValue(LocalStorage.showClassroomsStudentList);
        }

        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.getclassrooms();
            this.getStudentsCount();
            this.getStudents();
        }));

        if (LocalStorage.school) {
            this.getclassrooms();
            this.getStudents();
            this.getStudentsCount();
        }

        this.subscriptions.add(this.fcFilter.valueChanges.pipe(
            debounceTime(200),
            startWith(this.fcFilter.value),
            pairwise())
            .subscribe(([prev, next]: [any[], any[]]) => {
                if (prev.indexOf('all') === -1 && next.indexOf('all') !== -1) {
                    this.fcFilter.setValue(['all']);
                }
                if (prev.indexOf('own') === -1 && next.indexOf('own') !== -1) {
                    this.fcFilter.setValue(['own']);
                }
                this.classrooms.forEach(classroom => {
                    if (prev.indexOf(classroom.id) === -1 && next.indexOf(classroom.id) !== -1) {
                        const vals = this.fcFilter.value?.filter(v => v !== 'all' && v !== 'own');
                        this.fcFilter.setValue(vals);
                    }
                });
                if (!next.length) {
                    this.fcFilter.setValue(['own']);
                }

                LocalStorage.showAllClassroomsStudentList = !!next?.find(f => f === 'all');
                LocalStorage.showClassroomsStudentList = (next ? next.filter(v => v !== 'all' && v !== 'own') : []) as [];
                this.filterOnClassrooms();
            }));
    }

    filterOnClassrooms() {
        if (this.fcFilter.value.indexOf('all') === -1) {
            const classroomIds = this.fcFilter.value.indexOf('own') === -1 ? this.fcFilter.value : LocalStorage.user.classrooms.map(c => c.id);
            this.list = this.students.filter(s => {
                return !!s.classroomIds.find(c => classroomIds.includes(c));
            });
        } else {
            this.list = this.students;
        }
    }

    getStudents() {
        this.studentService.getAll(this.onlythisschoolBool).subscribe(students => {
            this.students = students.data.map(s => {
                s.classroomIds = `${s.classroomIds}`.split(',').map(c => +c);
                return s;
            });
            this.list = this.students;
            this.filterOnClassrooms();
            this.sortList('name');
        });
    }

    getStudentsCount() {
        this.studentService.getCount().subscribe(data => {
            this.studentcount_selected = data.data.studentcount_selected;
            this.studentcount_all = data.data.studentcount_all;
        });
    }

    editStudent(event: PointerEvent, studentId) {
        if (event.ctrlKey) {
            window.open(`/${this.Routenames.parnassysStudent}/${studentId}`);
        } else {
            this.router.navigate([Routenames.parnassysStudent, studentId]);
        }
    }

    private getclassrooms() {
        if (LocalStorage.school && LocalStorage.school.is_direct && LocalStorage.school.type !== 'master') {
            this.subscriptions.add(this.classroomService.getClassrooms(true, true).subscribe(classrooms => {
                this.classrooms = classrooms.data;
                if (!LocalStorage.showClassroomsStudentList?.filter(c => this.classrooms.map(d => d.id).indexOf(c) !== -1).length) {
                    if (!this.fcFilter.value.filter(v => ['all', 'own', 'school'].indexOf(v) !== -1)?.length) {
                        this.fcFilter.setValue(['own']);
                    }
                }
                this.permissionService.getPermissionWithStudents(this.classrooms.map(c => c.id)).subscribe(permissions => {
                    this.permissions = permissions.data;
                    this.fcShowPermissions.setValue(this.permissions.filter(p => !p.school_id).map(p => p.id));
                });
            }));
        } else {
            this.classrooms = [];
        }
    }

}
