import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'notById'
})
export class NotByIdPipe implements PipeTransform {

    transform<T>(value: T[], id: number, field?: string): T {
        return value?.find(p => p[field || 'id'] !== id);
    }

}
