import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Form} from '../../classes/form.class';

@Injectable()
export class FormService {

    constructor(public apiService: ApiService) {
    }

    public getFormsHistory(skip: number, limit: number) {
        return this.apiService.getCall$<Form[]>(`form/history/${skip}/${limit}`);
    }

    public getFormsHistoryItem(id: number) {
        return this.apiService.getCall$<Form>(`form/historyitem/${id}`);
    }

}
