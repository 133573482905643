import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SortingComponent} from '../../../sorting.component';
import {paymentOverview} from '../../../services/payment.service';
import {Router} from '@angular/router';
import {Routenames} from '../../../route-names.enum';
import {LocalStorage} from '../../../storage.class';

@Component({
    selector: 'app-payment-details',
    templateUrl: './payment-details.component.html',
    styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent extends SortingComponent<paymentOverview> implements OnChanges {

    @Input()
    overview: paymentOverview[];

    constructor(private router: Router) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.list = this.overview;
    }

    openPayment(payment) {
        const school = LocalStorage.user?.anySchools.find(s => s.id === payment.school_id);
        if (school) {

            if (payment.type === 'offer') {
                this.router.navigate(['/', Routenames.events, payment.payment_id]);
            } else {
                if (LocalStorage.school.id !== payment.school_id) {
                    LocalStorage.school = school;
                }
                this.router.navigate(['/', Routenames[payment.type], Routenames.editPart, payment.payment_id]);
            }
        }
    }

}
