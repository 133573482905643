import {Component, Inject, OnInit} from '@angular/core';
import {PageService} from '../services/page/page.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-link-edit',
    templateUrl: './link-edit-dialog.component.html',
    styleUrls: ['./link-edit-dialog.component.scss']
})
export class LinkEditDialogComponent implements OnInit {

    values: Object;
    linkType = 'page';
    pages = [];

    constructor(private pagesService: PageService,
                public dialogRef: MatDialogRef<LinkEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { value, target }) {
    }

    ngOnInit() {
        if (this.data.value) {
            this.linkType = 'text';
        } else {
            this.data.target = '_self';
        }
        this.pagesService.getList().subscribe(pages => {
            this.pages = pages.data;
            this.pages.forEach(page => {
                if (page.url == this.data.value) {
                    this.linkType = 'page';
                }
            });
        });
    }

    typeChanged() {
        if (this.linkType === 'text') {
            this.data.target = '_blank';
        } else {
            this.data.target = '_self';
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
