<section class="home">
    <div class="container-lg pt-2">
        <div class="row justify-content-center">
            <div class="home-buttons col-md-12 mx-auto mb-3" *ngIf="LocalStorage.school?.type === 'subsite'">
                <div class="home-button highlight"
                     *ngIf="viewState.students"
                     routerLink="/{{Routenames.parnassysStudent}}">
                    <i class="fa fa-people"></i>
                    Leerlingen
                </div>
                <div class="home-button"
                     [class.highlight]="LocalStorage.user.transactionAmount !== 0"
                     [class.danger]="LocalStorage.user.transactionAmount < 0"
                     *ngIf="viewState.saldo"
                     routerLink="/{{Routenames.transactionsOverview}}">
                    <i class="fa fa-euro"></i>
                    Saldo: {{LocalStorage.user.transactionAmount | number : '0.2-2'}}
                </div>
                <div class="home-button"
                     *ngIf="viewState.permissions"
                     [class.highlight]="viewState.permissionHighlight"
                     [class.danger]="viewState.permissionDanger"
                     routerLink="/{{Routenames.myPrivacy}}">
                    <i class="fa fa-shield-halved"></i>
                    Toestemmingen
                </div>
                <div class="home-button highlight"
                     *ngIf="viewState.remain"
                     routerLink="/{{Routenames.lowPermissionPayments}}">
                    <i class="fa fa-fork-knife"></i>
                    Overblijven
                </div>
                <div class="home-button highlight"
                     [class.danger]="payments?.length > 0"
                     [class.highlight]="payments?.length > 0"
                     *ngIf="viewState.paymentRequests"
                     routerLink="/{{Routenames.paymentRequests}}">
                    <i class="fa fa-credit-card-front"></i>
                    Betaalverzoeken
                </div>
                <div class="home-button"
                     [class.highlight]="discussions?.length > 0"
                     *ngIf="viewState.discussions"
                     [class.danger]="viewState.discussionsDanger"
                     routerLink="/{{Routenames.discussions}}">
                    <i class="fa fa-person-chalkboard"></i>
                    Oudergesprekken
                </div>
                <div class="home-button highlight"
                     *ngIf="viewState.discussionsPlanner"
                     routerLink="/{{Routenames.discussionsPlanner}}">
                    <i class="fa fa-person-chalkboard"></i>
                    Oudergesprekkenplanner
                </div>
                <div class="home-button"
                     [class.danger]="viewState.absenceDanger"
                     [class.highlight]="viewState.absenceHighlight"
                     routerLink="/{{Routenames.absence}}"
                     *ngIf="viewState.absence">
                    <i class="fa fa-face-thermometer"></i>
                    Absentie
                </div>
                <div class="home-button highlight" routerLink="/{{Routenames.info}}">
                    <i class="fa fa-info-circle"></i>
                    Info & contact
                </div>
            </div>
            <div class="col-24">
                <div class="row justify-content-center">
                    <mat-form-field class="col-24 col-md-12 search-input">
                        <mat-label>Zoeken...</mat-label>
                        <input [formControl]="fcSearch" matInput type="text">
                    </mat-form-field>
                </div>

            </div>
            <div class="col-24 col-sm-12 col-lg-8" *ngIf="afterSchoolEvents?.length">
                <div class="news-home">
                    <h2 class="news__title">Naschools aanbod</h2>

                    <div class="list">
                        <a *ngFor="let event of afterSchoolEvents"
                           [class.alert]="event.alert"
                           [class.employee]="event.access_level === 'EMPLOYEE'"
                           [class.unread]="!event.read"
                           class="item"
                           routerLink="/{{Routenames.events}}/{{event.slug}}">
                            <h3 class="item__title">{{event.title}}</h3>
                            <div class="item__details">
                                <time class="item__date">
                                    <ng-container *ngIf="event.all_day">
                                        {{event.start_date | date:'EE d MMM yyyy'}} -
                                        {{event.end_date | date:'EE d MMM yyyy'}}
                                    </ng-container>
                                    <ng-container *ngIf="!event.all_day">
                                        <ng-container *ngIf="(event.start_date | date:'yyMd') == (event.end_date | date:'yyMd')">
                                            {{event.start_date | date:'EE d MMM yyyy, HH:mm'}} -
                                            {{event.end_date | date:'HH:mm'}}
                                        </ng-container>
                                        <ng-container *ngIf="(event.start_date | date:'yyMd') != (event.end_date | date:'yyMd')">
                                            {{event.start_date | date:'EE d MMM yyyy HH:mm'}} -
                                            {{event.end_date | date:'EE d MMM yyyy HH:mm'}}
                                        </ng-container>
                                    </ng-container>
                                </time>
                                <div class="item__author">{{event.user.name}}
                                    <ng-container *ngFor="let classroom of event.groupedClassrooms; let last = last; let first = first">
                                        {{first ? ' | ' : ''}} {{classroom}}{{last ? '' : ','}}
                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="event.alert" class="item__alert">
                                <svg-icon src="assets/svg/alert.svg"></svg-icon>
                            </div>
                            <div *ngIf="event.access_level === 'EMPLOYEE'" class="item__employee">
                                <svg-icon src="assets/svg/chalkboard-teacher-solid.svg"></svg-icon>
                            </div>
                        </a>
                        <div *ngIf="!afterSchoolEvents?.length">
                            Geen resultaten gevonden
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-24 col-sm-12 col-lg-8" *ngIf="LocalStorage.school?.type !== 'offer'">
                <div class="news-home">
                    <h2 class="news__title">Nieuws</h2>
                    <div class="news-home">
                        <div *ngFor="let article of articles"
                             [class.alert]="article.alert"
                             [class.employee]="article.access_level === 'EMPLOYEE'"
                             [class.unread]="!article.read"
                             class="item"
                             routerLink="/{{Routenames.articles}}/{{article.slug}}">
                            <h3 class="item__title">{{article.title}}</h3>
                            <div class="item__details">
                                <time class="item__date">
                                    {{article.publish_date | date}}
                                </time>
                                <div class="item__author">{{article.user.name}}
                                    <ng-container *ngFor="let classroom of article.groupedClassrooms; let last = last; let first = first">
                                        {{first ? ' | ' : ''}} {{classroom}}{{last ? '' : ','}}
                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="article.alert" class="item__alert">
                                <svg-icon src="assets/svg/alert.svg"></svg-icon>
                            </div>
                            <div *ngIf="article.access_level === 'EMPLOYEE'" class="item__employee">
                                <svg-icon src="assets/svg/chalkboard-teacher-solid.svg"></svg-icon>
                            </div>
                        </div>
                        <div *ngIf="!articles?.length">
                            Geen resultaten gevonden
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-24 col-sm-12 col-lg-8" *ngIf="LocalStorage.school?.type !== 'offer'">
                <div class="news-home">
                    <h2 class="news__title">Kalender</h2>

                    <div class="list">
                        <a *ngFor="let event of events"
                           [class.alert]="event.alert"
                           [class.employee]="event.access_level === 'EMPLOYEE'"
                           [class.unread]="!event.read"
                           class="item"
                           routerLink="/{{Routenames.events}}/{{event.slug}}">
                            <h3 class="item__title">{{event.title}}</h3>
                            <div class="item__details">
                                <time class="item__date">
                                    <ng-container *ngIf="event.all_day">
                                        {{event.start_date | date:'EE d MMM yyyy'}} -
                                        {{event.end_date | date:'EE d MMM yyyy'}}
                                    </ng-container>
                                    <ng-container *ngIf="!event.all_day">
                                        <ng-container *ngIf="(event.start_date | date:'yyMd') == (event.end_date | date:'yyMd')">
                                            {{event.start_date | date:'EE d MMM yyyy, HH:mm'}} -
                                            {{event.end_date | date:'HH:mm'}}
                                        </ng-container>
                                        <ng-container *ngIf="(event.start_date | date:'yyMd') != (event.end_date | date:'yyMd')">
                                            {{event.start_date | date:'EE d MMM yyyy HH:mm'}} -
                                            {{event.end_date | date:'EE d MMM yyyy HH:mm'}}
                                        </ng-container>
                                    </ng-container>
                                </time>
                                <div class="item__author">{{event.user.name}}
                                    <ng-container *ngFor="let classroom of event.groupedClassrooms; let last = last; let first = first">
                                        {{first ? ' | ' : ''}} {{classroom}}{{last ? '' : ','}}
                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="event.alert" class="item__alert">
                                <svg-icon src="assets/svg/alert.svg"></svg-icon>
                            </div>
                            <div *ngIf="event.access_level === 'EMPLOYEE'" class="item__employee">
                                <svg-icon src="assets/svg/chalkboard-teacher-solid.svg"></svg-icon>
                            </div>
                        </a>
                        <div *ngIf="!events?.length">
                            Geen resultaten gevonden
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
