<div class="container white">
    <h2>Auth log</h2>
    <table class="table table-striped">
        <thead>
        <tr>
            <th class="type">
                Gebruiker
            </th>
            <th (click)="sortList('type')" class="type">
                Type
                <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="type"></app-sorting-icon>
            </th>
            <th (click)="sortList('ip')" class="ip">
                IP
                <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="ip"></app-sorting-icon>
            </th>
            <th (click)="sortList('browser')" class="browser">
                Browser
                <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="browser"></app-sorting-icon>
            </th>
            <th (click)="sortList('created_at')" class="date">
                Datum
                <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="created_at"></app-sorting-icon>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let log of list">
            <td>
                {{log.user?.name}}
            </td>
            <td>
                {{log.type}}
            </td>
            <td>
                {{log.ip}}
            </td>
            <td>
                {{log.browser}}
            </td>
            <td>
                {{log.created_at | date:'d MMM yyyy HH:mm:ss'}}
            </td>
        </tr>
        </tbody>
    </table>

</div>
