import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-sorting-icon',
    template: `<i [class.fa-sort-down]="!desc && currentSort === property"
                  [class.fa-sort-up]="desc && currentSort === property"
                  [class.fa-sort]="currentSort !== property"
                  class="fas"></i>
    `
})
export class SortingIconComponent extends Component {
    @Input() currentSort: string;
    @Input() desc: boolean;
    @Input() property: string;
}