import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {LocalStorage} from '../../storage.class';
import {AuthResponse} from '../../classes/auth-response';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AuthenticationLog} from '../../classes/authenticationlog.class';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {PushLog} from '../../classes/pushlog.class';
import {AuthorisationLog} from '../../classes/authorisationlog.class';
import {AppLog} from '../../classes/applog.class';

declare var cordova;

@Injectable()
export class AuthenticationService {

    static filledInEmail = '';

    private loggedIn = new EventEmitter<boolean>();
    private currentToken: string;
    onLogin = this.loggedIn.asObservable();

    constructor(public apiService: ApiService,
                private firebase: FirebaseX) {
    }

    updateFirebaseToken(token) {
        this.currentToken = token;
        return this.apiService.postCall$<boolean>('auth/firebase', {token});
    }

    loginEmail(email) {
        return this.apiService.postCall$<boolean>('auth/login-email', {email});
    }

    login(email, password) {
        return this.apiService.postCall$<AuthResponse>('auth/login', {email, password})
                   .pipe(map(response => this.handleResponse(response.data as AuthResponse)));
    }

    refresh(token?: string) {
        if (token) {
            return this.apiService.postCall$<AuthResponse>('auth/refresh', {token})
                       .pipe(map(response => this.handleResponse(response.data as AuthResponse)));
        }
        return this.apiService.getCall$<AuthResponse>('auth/refresh')
                   .pipe(map(response => this.handleResponse(response.data)));
    }

    logout(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            if (typeof cordova !== 'undefined') {
                this.firebase.getToken().then(token => {
                    this.logoutCall(token).subscribe(() => {
                        observer.next(true);
                    }, () => {
                        observer.error();
                    });
                });
            } else {
                this.logoutCall().subscribe(() => {
                    observer.next(true);
                }, () => {
                    observer.error();
                });
            }
        });
    }

    logoutCall(token?: string) {
        return this.apiService.postCall$('auth/logout', {token})
                   .pipe(map(response => {
                       this.loggedIn.emit(false);
                       LocalStorage.logoutUser();
                       return response;
                   }), catchError(error => {
                       this.loggedIn.emit(false);
                       LocalStorage.logoutUser();
                       return throwError(error);
                   }));
    }

    switchToParent() {
        const currentSchoolId = LocalStorage.school.id;
        const parentToken = LocalStorage.getParentUserToken();
        LocalStorage.logoutUser();
        this.refresh(parentToken).subscribe(() => {
            LocalStorage.updateSelectedSchoolIfWrong(currentSchoolId);
        });
    }

    switchToProfile(token) {
        const parentToken = LocalStorage.getUserToken();
        const parentUser = LocalStorage.user;
        LocalStorage.logoutUser();
        LocalStorage.setParentUser(parentToken, parentUser);
        return this.refresh(token);
    }

    requestRestorePassword(email): Promise<any> {
        return this.apiService.postCall('auth/request-restore', {email});
    }

    verifyResetToken(token: string): Promise<Object> {
        return this.apiService.getCall('auth/verify-reset-token', {token});
    }

    restorePassword(token: string, password: string) {
        return this.apiService.postCall$<AuthResponse>('auth/restore', {password, token})
                   .pipe(map(authResponse => this.handleResponse(authResponse.data as AuthResponse)));
    }

    log(user_id) {
        return this.apiService.getCall$<AuthenticationLog[]>(`auth/log/${user_id}`);
    }

    authorisationLog(user_id) {
        return this.apiService.getCall$<AuthorisationLog[]>(`auth/authorisationlog/${user_id}`);
    }

    pushLog(user_id) {
        return this.apiService.getCall$<PushLog[]>(`auth/pushlog/${user_id}`);
    }

    appLog(user_id) {
        return this.apiService.getCall$<AppLog[]>(`auth/applog/${user_id}`);
    }

    fullLog() {
        return this.apiService.getCall$<AuthenticationLog[]>(`auth/log`);
    }

    loginAsUser(userId: number) {
        return this.apiService.postCall$<string>('auth/loginAsUser', {user_id: userId});
    }

    private handleResponse(authResponse: AuthResponse) {
        LocalStorage.setUserToken(authResponse.access_token);
        LocalStorage.setUser(authResponse.user);
        LocalStorage.setExpireTimeToken(authResponse.expires_in);
        LocalStorage.schools = authResponse.user.anySchools;
        this.loggedIn.emit(true);
        return authResponse;
    }
}
