export class SEO {

    public pagetitle = '';

    public description = '';
    public keywords = '';

    public og_title = '';
    public og_type = '';
    public og_description = '';
    public og_image = '';

    public twitter_card = '';
    public twitter_title = '';
    public twitter_description = '';
    public twitter_image = '';
}
