<div class="container white">
    <div class="d-flex align-items-center justify-content-between mb-3">
        <h2 class="mb-0">Overzicht absenties per leerling</h2>
        <div class="d-flex">

        </div>
    </div>
    <div class="row">
        <div class="col">
            <table class="table table-striped">
                <thead>
                <tr class="sticky-top">
                    <th (click)="sortList('name')">
                        Leerling
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="name"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('approved')">
                        Geoorloofd
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="approved"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('notapproved')">
                        Ongeoorloofd
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="notapproved"></app-sorting-icon>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let absence of list">
                    <td>
                        {{absence.name}}
                    </td>
                    <td>
                        {{absence.approved}}
                    </td>
                    <td>
                        {{absence.notapproved}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
