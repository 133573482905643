<div class="detail__block">
    @if (subscribeEnded) {
        <h1 class="detail__title">
            Inschrijving en aanwezigheid
        </h1>
        <app-event-presence [event]="event"></app-event-presence>
    } @else {
        <h1 class="detail__title">
            Inschrijven
        </h1>
        <div class="content">
            <div class="mt-3">
                <div class="mb-3" *ngIf="subscribed?.length">
                    <b>De volgende leerling{{subscribed?.length > 1 ? 'en zijn' : ' is'}}  ingeschreven voor dit naschools aanbod.</b>
                    <div class="subscriptions">
                        @for (subscription of subscribed; track subscription.id) {
                            <div class="p-1">
                                <i class="fa-solid fa-check mr-1"></i> {{subscription.student.name}}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

        @if (subscribeEnded) {
            <div class="content">
                De inschrijfperiode voor deze activiteit is afgelopen, inschrijven is niet meer mogelijk.
            </div>
        } @else if (students?.length || subscribed?.length) {
            <div class="content">
                <div class="mt-3">
                    <div>
                        <form class="row">
                            <div class="col-24 col-lg-17 col-xl-16">
                                @if (event.max_subscriptions) {
                                    <p>
                                        Er geld een maximum van <b>{{event.max_subscriptions}}</b> inschrijvingen voor dit aanbod.
                                        <br>
                                        Er zijn nog <b>{{event.max_subscriptions - event.currentSubscriptionCount}}</b> van de <b>{{event.max_subscriptions}}</b> plaatsen over.
                                    </p>
                                }
                                @if (!event.max_subscriptions || event.max_subscriptions > event.currentSubscriptionCount) {
                                    <b>U kunt de volgende leerlingen inschrijven voor dit naschools aanbod.</b>
                                    @if (event.dateranges?.length > 1) {
                                        De kosten zijn
                                        {{event.price | currency : 'EUR'}} per leerling voor de gehele reeks van {{event.dateranges?.length}} activiteiten.
                                    } @else {
                                        <br>De kosten zijn {{event.price | currency : 'EUR'}} per leerling.
                                    }

                                    <mat-selection-list class="w-100" [formControl]="form.controls.studentIds">
                                        @for (student of students; track student.id) {
                                            <mat-list-option
                                                matTooltip="Er zijn geen plaatsten meer beschikbaar"
                                                [matTooltipDisabled]="!disableNotCheckedStudents || !!(form.controls.studentIds.value | byValue : student.id)"
                                                [disabled]="disableNotCheckedStudents && !(form.controls.studentIds.value | byValue : student.id)"
                                                [value]="student.id"
                                                togglePosition="before">
                                                {{student.name}}
                                            </mat-list-option>
                                        }
                                    </mat-selection-list>
                                    <div class="mb-2">
                                        <b>Aanvullende gegevens</b>
                                    </div>
                                    <mat-form-field class="w-100 mb-4">
                                        <mat-label>Telefoonnummer</mat-label>
                                        <input type="text"
                                               placeholder="Bekende telefoonnummers"
                                               aria-label="Telefoonnummer"
                                               matInput
                                               [formControl]="form.controls.phone"
                                               [matAutocomplete]="auto">
                                        <mat-autocomplete #auto="matAutocomplete">
                                            @if (LocalStorage.user.students[0]?.data?.emergencyNumber) {
                                                @for (number of LocalStorage.user.students[0]?.data?.emergencyNumber; track number.telefoonnummer) {
                                                    <mat-option [value]="number.telefoonnummer"><small>{{number.naam}}</small><br>{{number.telefoonnummer}}</mat-option>
                                                }
                                            }
                                            <mat-option *ngIf="LocalStorage.user.phone" [value]="LocalStorage.user.phone"><small>Telefoon</small><br>{{LocalStorage.user.phone}}
                                            </mat-option>
                                            <mat-option *ngIf="LocalStorage.user.phonework" [value]="LocalStorage.user.phonework">
                                                <small>Telefoonwerk</small><br>{{LocalStorage.user.phonework}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error [control]="form.controls.phone"></mat-error>
                                        <mat-hint>
                                            Vul een telefoonnummer in waarop u bereikbaar bent tijdens het aanbod. Telefoonnummer en opmerking zijn zichtbaar voor de organisator en
                                            kunnen aangepast worden tot het einde van het aanbod.
                                        </mat-hint>
                                    </mat-form-field>
                                    <mat-form-field class="w-100">
                                        <mat-label>Opmerking</mat-label>
                                        <textarea [formControl]="form.controls.comment" matInput placeholder="Vul hier eventueel een opmerking in"></textarea>
                                    </mat-form-field>
                                } @else {
                                    <b>Je kunt je niet meer inschrijven voor dit aanbod omdat het maximum aantal inschrijvingen is bereikt.</b>
                                }
                            </div>
                        </form>
                        @if (!event.max_subscriptions || event.max_subscriptions > event.currentSubscriptionCount) {
                            <div class="mb-2 font-weight-bold" *ngIf="form.value.studentIds?.length">
                                De totale kosten zijn {{(event.price * form.value.studentIds?.length) | currency : 'EUR'}}
                            </div>
                            <div class="mb-3" *ngIf="form.value.studentIds?.length">
                                <mat-checkbox [formControl]="form.controls.agree">Ik accepteer de</mat-checkbox>&nbsp;<a href="https://kindwijs.org/voorwaarden-naschools-aanbod">voorwaarden</a>
                                <mat-error [control]="form.controls.agree" customMessage="Accepteer de algemene voorwaarden"></mat-error>
                            </div>

                            <button color="primary" mat-raised-button (click)="subscribe()">
                                {{(!form.value.studentIds?.length && subscribed?.length) ? 'Opslaan' : 'Inschrijven'}}
                            </button>
                        }
                    </div>
                </div>
            </div>
        } @else {
            <div class="content">
                Je kunt niet inschrijven voor <b>{{event.title}}</b> omdat op
                <ng-container *ngFor="let school of event.schools; let last = last">
                    {{school.name}}{{last ? '' : ','}}
                </ng-container>
                geen kinderen zitten waar je ouder/verzorger van bent.
            </div>
        }
    }
</div>
