import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {validTime} from '../../validators/valid-time.validator';
import {handleTimeValueChange} from '../../utils/handle-time-value-change';
import {CodaltComponent} from '../../codalt.component';
import {combineLatest, Subscription} from 'rxjs';
import {DiscussiontimesGroup} from '../../classes/discussiontimes-group.class';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {Classroom} from '../../classes/classroom.class';
import {Utils} from '../../utils.class';
import {Routenames} from '../../route-names.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {DiscussionService} from '../../services/discussion.service';
import {Discussion} from '../../classes/discussion.class';
import {LocalStorage} from '../../storage.class';
import {startWith} from 'rxjs/operators';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {CordovaService} from '../../services/cordova.service';

@Component({
    selector: 'app-discussion-edit',
    templateUrl: './discussion-edit.component.html',
    styleUrls: ['./discussion-edit.component.scss']
})
export class DiscussionEditComponent extends CodaltComponent implements OnInit {

    faDiscussiontimesGroups: UntypedFormArray;
    fcClassrooms: UntypedFormControl;
    discussiontimesGroups: DiscussiontimesGroup[];
    subscriptions = new Subscription();
    classrooms: Classroom[];
    form: UntypedFormGroup;
    saving = false;
    discussion: Discussion;

    constructor(private classroomService: ClassroomService,
                private router: Router,
                private route: ActivatedRoute,
                private cordovaService: CordovaService,
                private confirmDialogService: ConfirmDialogService,
                private discussionService: DiscussionService) {
        super();
    }

    ngOnInit(): void {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigateByUrl(`/${Routenames.discussionsPlanner}`);
        });
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school && !this.discussion) {
                if (+params['id'] > 0) {
                    this.getDiscussion(+params['id']);
                } else {
                    this.discussion = new Discussion();
                    this.setupForm();
                }
            }
        }));
        this.classroomService.getClassrooms().subscribe(response => {
            this.classrooms = response.data;
        });
    }

    deleteTime(time: DiscussiontimesGroup) {
        time.deleted = true;
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.saving = true;
            Object.assign(this.discussion, this.form.value);
            this.discussion.times = (this.discussion.times || []).concat(this.discussiontimesGroups);
            delete this.discussion.discussiontimes;
            this.discussionService.save(this.discussion).subscribe(() => {
                this.saving = false;
                this.form.markAsPristine();
                this.router.navigateByUrl(Routenames.discussionsPlanner);
            }, error => {
                this.discussion.times = this.discussion.times.filter(p => !p.times);
                this.confirmDialogService.confirm('Opslaan mislukt', error.message, 'Oke', null);
                this.saving = false;
            });
        }
    }

    setupForm() {
        this.faDiscussiontimesGroups = new UntypedFormArray([], this.discussion.id ? null : Validators.required);
        this.discussiontimesGroups = [];
        const fcPreStart = new UntypedFormControl(this.discussion.pre_subscription_start, Validators.required);
        const fcPreStartTime = new UntypedFormControl(Utils.getFcTime(this.discussion.pre_subscription_start), {
            validators: [validTime(), Validators.required],
            updateOn: 'blur'
        });
        const fcStart = new UntypedFormControl(this.discussion.subscription_start, Validators.required);
        const fcStartTime = new UntypedFormControl(Utils.getFcTime(this.discussion.subscription_start), {
            validators: [validTime(), Validators.required],
            updateOn: 'blur'
        });
        const fcEnd = new UntypedFormControl(this.discussion.subscription_end, Validators.required);
        const fcEndTime = new UntypedFormControl(Utils.getFcTime(this.discussion.subscription_end), {
            validators: [validTime(), Validators.required],
            updateOn: 'blur'
        });
        this.fcClassrooms = new UntypedFormControl(this.discussion.classrooms?.map(p => p.id) || [], Validators.required);

        this.form = new UntypedFormGroup({
            name: new UntypedFormControl(this.discussion.name, Validators.required),
            description: new UntypedFormControl(this.discussion.description),
            classrooms: this.fcClassrooms,
            pre_subscription_start: fcPreStart,
            pre_subscription_start_time: fcPreStartTime,
            pre_child_count: new UntypedFormControl(this.discussion.pre_child_count ?? 3, [Validators.required, Validators.min(1), Validators.max(9)]),
            subscription_start: fcStart,
            subscription_start_time: fcStartTime,
            subscription_end: fcEnd,
            subscription_end_time: fcEndTime,
            newtimes: this.faDiscussiontimesGroups,
            limit_registrations: new UntypedFormControl(this.discussion.limit_registrations)
        });
        [fcStartTime, fcEndTime, fcPreStartTime].forEach(fcTime => {
            this.subscriptions.add(fcTime.valueChanges.subscribe(
                value => handleTimeValueChange(value, fcTime)
            ));
        });

        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            [
                {date: fcPreStart, time: fcPreStartTime},
                {date: fcStart, time: fcStartTime},
                {date: fcEnd, time: fcEndTime}
            ].forEach(group => {
                if (group.time.value && group.date.value) {
                    const time = group.time.value.split(':');
                    if (time.length === 2) {
                        const date = new Date(group.date.value);
                        Utils.setTime(date, time[0], time[1]);
                        if (date.toString() !== (new Date(group.date.value)).toString()) {
                            group.date.setValue(date);
                        }
                    }
                }
            });
        }));

    }

    getFirstStartTime() {
        let lowest = new Date();
        const startSubs = new Date(this.form.value.subscription_end);
        this.discussion?.times?.forEach(time => {
            if (time.start_date < lowest) {
                lowest = time.start_date;
            }
        });
        this.faDiscussiontimesGroups?.value?.forEach(time => {
            if (new Date(time.start_date) < lowest) {
                lowest = new Date(time.start_date);
            }
        });
        return startSubs > lowest ? startSubs : lowest;
    }

    createDiscussiontimesGroupForm(discussiontimesGroup: DiscussiontimesGroup) {
        let fcStarttime: UntypedFormControl, fcEndtime: UntypedFormControl, fcDate: UntypedFormControl,
            fcInterval: UntypedFormControl;
        const formGroup = new UntypedFormGroup({
            start_date: fcDate = new UntypedFormControl(discussiontimesGroup.start_date, Validators.required),
            interval: fcInterval = new UntypedFormControl(discussiontimesGroup.interval)
        });

        formGroup.addControl('start_time', fcStarttime = new UntypedFormControl(
            discussiontimesGroup.start_date ? formatDate(discussiontimesGroup.start_date, 'H:mm', 'nl') : null,
            {validators: [validTime(), Validators.required], updateOn: 'blur'}
        ));
        formGroup.addControl('end_time', fcEndtime = new UntypedFormControl(
            discussiontimesGroup.end_date ? formatDate(discussiontimesGroup.end_date, 'H:mm', 'nl') : null,
            {validators: [validTime(), Validators.required], updateOn: 'blur'}
        ));

        this.subscriptions.add(fcStarttime.valueChanges.subscribe(
            value => handleTimeValueChange(value, fcStarttime)
        ));
        this.subscriptions.add(fcEndtime.valueChanges.subscribe(
            value => handleTimeValueChange(value, fcEndtime)
        ));

        this.subscriptions.add(formGroup.valueChanges.subscribe(() => {
            [{date: discussiontimesGroup.start_date, inptime: fcStarttime}, {date: discussiontimesGroup.end_date, inptime: fcEndtime}].forEach(group => {
                if (group.inptime.value && fcDate.value) {
                    const time = group.inptime.value.split(':');
                    if (time.length === 2) {
                        group.date.setFullYear(fcDate.value.getFullYear());
                        group.date.setMonth(fcDate.value.getMonth());
                        group.date.setDate(fcDate.value.getDate());
                        Utils.setTime(group.date, time[0], time[1]);
                    }
                }
            });
            discussiontimesGroup.interval = fcInterval.value;

            this.genTimes(discussiontimesGroup);
        }));

        this.genTimes(discussiontimesGroup);
        return formGroup;
    }

    genTimes(discussiontimesGroup: DiscussiontimesGroup) {
        discussiontimesGroup.times = [];
        if (discussiontimesGroup.start_date && discussiontimesGroup.end_date) {
            for (let cur = discussiontimesGroup.start_date.getTime();
                 cur < discussiontimesGroup.end_date.getTime();
                 cur = cur + (60000 * +discussiontimesGroup.interval)) {
                discussiontimesGroup.times.push(new Date(cur));
            }
        }
    }

    allClassrooms() {
        if (this.fcClassrooms.value && this.fcClassrooms.value.length === this.classrooms.length) {
            this.fcClassrooms.setValue([]);
        } else {
            this.fcClassrooms.setValue(this.classrooms.map(p => p.id));
        }
    }

    addDiscussiontimesGroup() {
        const discussiontimesGroup = new DiscussiontimesGroup();
        if (this.discussiontimesGroups.length > 0) {
            const prevIndex = this.discussiontimesGroups.length - 1;
            discussiontimesGroup.interval = this.discussiontimesGroups[prevIndex].interval;
            discussiontimesGroup.start_date = new Date(this.discussiontimesGroups[prevIndex].start_date);
            discussiontimesGroup.start_date.setDate(discussiontimesGroup.start_date.getDate() + 1);
            discussiontimesGroup.end_date = new Date(this.discussiontimesGroups[prevIndex].end_date);
            discussiontimesGroup.end_date.setDate(discussiontimesGroup.end_date.getDate() + 1);
        } else {
            const subsStart = new Date(this.form.value?.subscription_end);
            discussiontimesGroup.start_date = subsStart > new Date() ? subsStart : new Date();
            Utils.setTime(discussiontimesGroup.start_date, 18, 30);
            discussiontimesGroup.end_date = new Date();
            Utils.setTime(discussiontimesGroup.end_date, 21, 0);
            discussiontimesGroup.interval = 10;
        }

        this.discussiontimesGroups.push(discussiontimesGroup);
        this.faDiscussiontimesGroups.push(this.createDiscussiontimesGroupForm(discussiontimesGroup));
    }

    removeRange(index: number) {
        this.faDiscussiontimesGroups.removeAt(index);
    }

    private getDiscussion(discussionId: number) {
        this.discussionService.get(discussionId).subscribe(response => {
            this.discussion = response.data;
            LocalStorage.updateSelectedSchoolIfWrong(this.discussion.school_id);
            this.setupForm();
        });
    }
}
