import {EventEmitter, Injectable} from '@angular/core';

import {ApiResponse, ApiService} from './../api/api.service';
import {Page} from '../../classes/page.class';
import {tap} from 'rxjs/operators';

/*
  Generated class for the PageManagementService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class PageService {

    pageTreeChange: EventEmitter<any> = new EventEmitter();

    constructor(public apiService: ApiService) {
    }

    emitPageTreeChangeEvent() {
        this.pageTreeChange.emit();
    }

    getPageTreeEmitter() {
        return this.pageTreeChange;
    }

    getList() {
        return this.apiService.getCall$<{page: string, url: string}[]>('pages/list');
    }

    getAllPages(): Promise<ApiResponse<Page[]>> {
        return this.apiService.getCall(`pages`, {});
    }

    createPage(data) {
        return this.apiService.postCall$<any>('pages', data);
    }

    getPage(pageId) {
        return this.apiService.getCall$<Page>(`pages/${pageId}`, {}).pipe(tap(
            p => {
                if (!p.data.content || Array.isArray(p.data.content)) {
                    p.data.content = {};
                }
            }
        ));
    }

    getPageHistory(pageId) {
        return this.apiService.getCall$<Page[]>(`pages/history/${pageId}`);
    }

    restoreHistory(pageHistoryId): Promise<Object> {
        return this.apiService.postCall(`pages/restore_history/${pageHistoryId}`, {});
    }

    saveOrder(pages): Promise<Object> {
        return this.apiService.postCall(`pages/order`, {pages: pages});
    }

    removePage(id): Promise<Object> {
        return this.apiService.postCall(`pages/delete`, {id: id});
    }

    duplicate(id): Promise<Object> {
        return this.apiService.postCall(`pages/duplicate`, {id: id});
    }

}
