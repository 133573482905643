import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {MatBottomSheet} from '@angular/material/bottom-sheet';

declare var cordova;

@Injectable()
export class CordovaService {

    public deviceHasInternet = new EventEmitter<boolean>();
    private backFunction: Function;
    private hasInternet = true;

    constructor(private ngZone: NgZone,
                private router: Router,
                private matDialog: MatDialog,
                private matBottomSheet: MatBottomSheet,
                private matSnackbar: MatSnackBar) {
        if (typeof cordova !== 'undefined') {
            document.addEventListener('backbutton', () => {
                if (this.matDialog.openDialogs.length > 0) {
                    this.ngZone.run(() => {
                        this.matDialog.closeAll();
                    });
                } else if (this.backFunction) {
                    this.ngZone.run(() => {
                        this.backFunction();
                        this.backFunction = null;
                    });
                } else {
                    const urlParts = this.router.url.split('/');
                    this.ngZone.run(() => {
                        if (urlParts.length > 2) {
                            this.router.navigate(urlParts.slice(0, urlParts.length - 1));
                        } else if (urlParts[1] === '') {
                            navigator['app'].exitApp();
                        } else {
                            this.router.navigateByUrl('/');
                        }
                    });

                }
            }, false);
        }

        document.addEventListener('offline', () => {
            console.log('Device goes offline :-(');
            this.deviceHasInternet.emit(false);
            this.hasInternet = false;
            this.ngZone.run(() => {
                this.matSnackbar.open('Geen internetverbinding!', 'Oké', {duration: 3000});
            });
        }, false);

        document.addEventListener('online', () => {
            console.log('Device goes online!');
            this.deviceHasInternet.emit(true);
            if (!this.hasInternet) {
                this.ngZone.run(() => {
                    this.matSnackbar.open('Internetconnectie hersteld!', 'Oké', {duration: 2000});
                });
            }
            this.hasInternet = true;
        }, false);
    }

    public setBackbuttonAction(func: Function) {
        this.backFunction = func;
        let first = true;
        const subs = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (!first) {
                    this.backFunction = null;
                    subs.unsubscribe();
                }
                first = false;
            }
        });
    }

    public setBackbuttonExitAction() {
        this.backFunction = () => {
            navigator['app'].exitApp();
        };

        const subs = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {

                this.backFunction = null;
                subs.unsubscribe();

            }
        });
    }

    public getCurrentBackFunction(): Function {
        return this.backFunction;
    }

    public getAppVersion(): Promise<any> {
        return cordova.getAppVersion.getVersionNumber();
    }
}
