import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {ChatMessage} from '../../classes/chat-message';
import {User} from '../../classes/user.class';
import {VideoService} from '../../services/video.service';
import {Video} from '../../classes/video.class';
import {FileService} from '../../services/file/file.service';
import {Document} from '../../classes/document.class';
import {Settings} from '../../settings.class';
import {LocalStorage} from '../../storage.class';

declare var cordova;

@Component({
    selector: 'app-chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnChanges, OnDestroy {

    @Output() openImage = new EventEmitter<ChatMessage>();

    @Input() usersMap: Map<number, User>;
    @Input() message: ChatMessage;
    @Input() id;

    type: 'text' | 'base64' | 'json-video' | 'json-image' | 'json-document';

    jsonTypeMap = new Map<string, 'json-video' | 'json-image' | 'json-document'>(
        [
            ['video', 'json-video'],
            ['image', 'json-image'],
            ['document', 'json-document']
        ]
    );

    item: {
        processed: boolean,
        thumb: string,
        path: string,
        video_id: number,
        type: 'image' | 'video',
        filetype: string,
        size: number
    };
    video: Video = new Video();

    checkProcessed;

    fileIconMap = FileService.fileIconMap;

    constructor(private ngZone: NgZone,
                private videoService: VideoService,
                private cdref: ChangeDetectorRef) {
        this.cdref.detach();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.type = null;
        if (this.message.message.substring(0, 1) === '{' && this.message.message.substring(this.message.message.length - 1) === '}') {
            try {
                this.item = JSON.parse(this.message.message);
                this.type = this.jsonTypeMap.get(this.item.type);
            } catch (error) {

            }
        }
        if (this.message.message.substring(0, 11) === 'data:image/') {
            this.type = 'base64';
        }
        if (!this.type) {
            this.type = 'text';
        }
        if (this.type === 'json-video') {
            if (!this.message.video) {
                this.videoService.getChatVideo(this.item.video_id).subscribe(video => {
                    this.video = video.data;
                    this.message.video = video.data;
                    this.cdref.detectChanges();
                    if (!this.message.video.processed) {
                        this.checkProcessed = setInterval(() => {
                            if (this.message.video.processed) {
                                this.video = JSON.parse(JSON.stringify(this.message.video));
                                this.cdref.detectChanges();
                                clearInterval(this.checkProcessed);
                            }
                        }, 2000);
                    }
                });
            } else {
                this.video = this.message.video;
            }
        }
        this.cdref.detectChanges();
        this.cdref.reattach();
    }

    openDocument() {
        const url = Settings.API_ENDPOINT +
            'file/file?secure=chat' +
            '&path=/' + this.message.chat_id + '/' + this.item.path +
            '&school=' + LocalStorage?.school?.id +
            '&access_token=' + LocalStorage.getUserToken();
        if (typeof cordova !== 'undefined') {
            window['PreviewAnyFile'].previewPath(
                win => console.log("open status", win),
                error => console.error("open failed", error),
                url, {name: this.item.path}
            );
        } else {
            window.open(url);
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.checkProcessed);
    }
}
