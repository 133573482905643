<b class="curhelp" matTooltip="Saldo wat bij ouders op hun account staat en nog niet gebruikt is van alle scholen bij elkaar. ">Totaal ongebruikt
    saldo: {{total | currency : 'EUR'}}</b><br>
<mat-form-field class="mt-2">
    <mat-label>Kies een maand</mat-label>
    <mat-select (selectionChange)="getData()" [(ngModel)]="selectedMonth">
        <mat-option *ngFor="let month of months" [value]="month">
            {{month | date : 'MMMM yyyy'}}
        </mat-option>
    </mat-select>
</mat-form-field>
<table class="table table-striped">
    <thead>
    <tr>
        <th (click)="sortList('school')">
            School
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="school"></app-sorting-icon>
        </th>
        <th (click)="sortList('amount_out')" class="curhelp"
            matTooltip="Totaal van alle betalingen deze maand voor deze school. Dit is het totaal van alle betaalde betalingsverzoeken en overblijven van deze maand.">
            Betalingen
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="amount_out"></app-sorting-icon>
        </th>
        <th (click)="sortList('cash')" class="curhelp" matTooltip="Totaal contact opgewaardeerd op deze school. Dit geld is contant aanwezig op deze school.">
            Contant opgewaardeerd
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="cash"></app-sorting-icon>
        </th>
        <th (click)="sortList('saldo')" class="curhelp" matTooltip="Saldo wat bij ouders op hun account staat en nog niet gebruikt is.">
            Ongebruikt saldo *
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="saldo"></app-sorting-icon>
        </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let payment of list" style="cursor:pointer;">
        <td>{{payment.school}}</td>
        <td class="text-right">
            <ng-container *ngIf="payment.amount_out > 0">
                {{payment.amount_out | currency : 'EUR'}}
            </ng-container>
            <ng-container *ngIf="payment.amount_out == 0">
                -
            </ng-container>
        </td>
        <td class="text-right">
            <ng-container *ngIf="payment.cash > 0">
                {{payment.cash | currency : 'EUR'}}
            </ng-container>
            <ng-container *ngIf="payment.cash == 0">
                -
            </ng-container>
        </td>
        <td class="text-right">
            {{payment.saldo | currency : 'EUR'}}
        </td>

    </tr>
    </tbody>
</table>
<small>* Cummulatief van deze kolom overschreid het werkelijke totaal i.v.m. leerlingen op meerdere scholen</small>
