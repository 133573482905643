import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {StudentService} from '../services/student/student.service';
import {Student} from '../classes/student.class';
import {LocalStorage} from '../storage.class';
import {CodaltComponent} from '../codalt.component';
import {PermissionQuestion} from '../classes/permission-question';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {PhoneService} from '../services/phone-service/phone.service';

@Component({
    selector: 'app-studentsdetail-component',
    templateUrl: './student-detail.component.html',
    styleUrls: ['./student-detail.component.scss']
})
export class StudentDetailComponent extends CodaltComponent implements OnInit {

    student: Student;
    private studentId: number;

    constructor(private studentService: StudentService,
                public phoneService: PhoneService,
                private confirmDialog: ConfirmDialogService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit() {

        this.student = null;

        this.subscriptions.add(this.route.params.subscribe(params => {
            this.studentId = +params['id'];
        }));
        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.getStudent(this.studentId);
        }));

        if (LocalStorage.school) {
            this.getStudent(this.studentId);
        }
    }

    getStudent(studentId: number) {
        this.studentService.getStudent(studentId).subscribe(student => {
            this.student = student.data;
        });
    }

    info(question: PermissionQuestion) {
        let content = `${question.description.replace('\n', '<br>')}<br><br>`;
        question.options.forEach(level => {
            content += `<b>${level.name}</b> <i>${level.intro}</i><br>`;
            content += `<p>${level.description?.replace('\n', '<br>')}</p>`;
        });

        this.confirmDialog.confirm(question.name,
            content, 'Sluiten', null, '600px').then(() => {
        });
    }
}
