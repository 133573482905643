<div class="container">
    <h1>Autorisatie</h1>
    <div>
        <table class="table table-hover">
            <thead>
            <tr>
                <th class="feature">
                    <b>Feature</b>
                </th>
                <th *ngFor="let role of roles">
                    <div class="role-name">
                        {{role.name}}
                    </div>
                </th>
                <th class="feature">
                    <b>Key</b>
                </th>
            </tr>
            </thead>
            <tr *ngFor="let featureHasRole of featureHasRoleMatrix">
                <th class="feature">
                    {{featureHasRole.feature.name}}
                </th>
                <td (contextmenu)="setUserFieldForFeature($event, featureHasRole, role)" *ngFor="let role of roles"
                    title="{{role.name}}">
                    <mat-checkbox
                        (change)="checkFeature($event, featureHasRole.feature, role)"
                        [checked]="featureHasRole.hasRoles.has(role.id)">
                    <span *ngIf="featureHasRole.hasRoles.has(role.id)">
                        {{featureHasRole.hasRoles.get(role.id).user_field}}
                    </span>
                    </mat-checkbox>

                </td>
                <th class="feature" [title]="featureHasRole.feature.backend_url">
                    {{featureHasRole.feature.key}}
                </th>
            </tr>
        </table>
    </div>
    <div class="row ">
        <div class="col-12">
            <div class="d-flex">
                <button mat-button routerLink="/gebruikers">Terug</button>
            </div>
        </div>
    </div>
</div>
