export enum Routenames {
    editPart = 'bewerken',
    newPart = 'nieuw',
    login = 'inloggen',
    logout = 'uitloggen',
    lostpw = 'wachtwoord-vergeten',
    users = 'gebruikers',
    authRoles = 'auth/roles',
    authLog = 'gebruikers/log',
    home = '/',
    parnassys = 'parnassys',
    pageManagement = 'pagina-management',
    parnassysUsers = 'parnassys/gebruikers',
    parnassysStudent = 'parnassys/leerlingen',
    parnassysClassrooms = 'parnassys/groepen',
    profile = 'profiel',
    info = 'info',
    schoolSettings = 'school-instellingen',
    articles = 'nieuws',
    events = 'kalender',
    formHistory = 'ingevulde-formulieren',
    jobs = 'vacatures',
    chat = 'chat',
    chatArchive = 'chat/archief',
    discussions = 'oudergesprekken',
    discussionsPlanner = 'oudergesprekkenplanner',
    myPrivacy = 'profiel/mijn-privacy-voorkeuren',
    transactionsOverview = 'profiel/transacties',
    transactionTopUp = 'profiel/transacties/opwaarderen',
    transactionTopUpCheck = 'profiel/transacties/betaling-controleren/:id',
    payments = 'kosten',
    paymentRequests = 'betaalverzoeken',
    defaultPayments = 'standaard-betalingen',
    lowPermissionPayments = 'overblijven',
    pay = 'betalen',
    cash = 'contant',
    overview = 'overzicht',
    permissions = 'toestemmingen',
    permissionStats = 'toestemmingen/overzicht',
    absence = 'absentie',
    absenceRegister = 'absentie/registreren',
    absenceCounts = 'absentie/aantallen',
    afterSchoolOffer = 'naschools-aanbod'
}
