import {EventPresence} from './event-presence';

export class Daterange {
    start_date: Date;
    end_date: Date;
    all_day: boolean;
    id: number;
    interval: number;

    presence: EventPresence[];
}
