import {Classroom} from './classroom.class';
import {Permission} from './permission';
import {Student} from './student.class';

export class PermissionQuestion {
    id: number;
    order: number;
    name: string;
    description: string;
    start_date: Date;
    deadline_date: Date;
    end_date: Date;
    school_id: number;
    classrooms: Classroom[];
    options: PermissionQuestionOption[];
    show_news?: boolean;

    _answers: {
        answer: Permission,
        student: Student
    }[];

    permissions: Permission[];
}

export class PermissionQuestionOption {
    name: string;
    shortIntro: string;
    intro: string;
    description: string;
}
