import {Component, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {startWith} from 'rxjs/operators';
import {CodaltComponent} from '../../codalt.component';
import {PaymentService} from '../../services/payment.service';
import {ActivatedRoute} from '@angular/router';
import {PaymentRequest} from '../../classes/payment-request';

@Component({
    selector: 'app-payment-requests',
    templateUrl: './payment-requests.component.html',
    styleUrls: ['./payment-requests.component.scss']
})
export class PaymentRequestsComponent extends CodaltComponent implements OnInit {

    payments: PaymentRequest[];

    constructor(private paymentService: PaymentService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school) {
                this.payments = null;
                this.subscriptions.add(this.paymentService.getOwnPaymentRequests().subscribe(payments => {
                    this.payments = payments.data;
                }));
            }
        }));
    }

}
