<div class="dialog-container">
    <h2 class="condensed dialog-title" mat-dialog-title>{{data.title}}</h2>
    <div class="dialog-content" mat-dialog-content>
        <p [innerHTML]="data.text | safeHtml"></p>
    </div>
    <div class="d-flex flex-wrap justify-content-between" mat-dialog-actions>
        <button (click)="onNoClick()" *ngIf="data.cancel" class="mr-3" mat-button>{{data.cancel}}</button>
        <button *ngIf="data.confirm"
                [mat-dialog-close]="true"
                cdkFocusInitial
                color="primary"
                mat-flat-button>
            {{data.confirm}}
        </button>
    </div>
</div>
