import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../../codalt.component';
import {PaymentDefault} from '../../../classes/payment-default';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentDefaultService} from '../../../services/payment-default.service';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {LocalStorage} from '../../../storage.class';
import {startWith} from 'rxjs/operators';
import {Utils} from '../../../utils.class';
import {Routenames} from '../../../route-names.enum';
import {PaymentService} from '../../../services/payment.service';
import {Transaction} from '../../../classes/transaction';

@Component({
    selector: 'app-default-payment-edit',
    templateUrl: './default-payment-edit.component.html',
    styleUrls: ['./default-payment-edit.component.scss']
})
export class DefaultPaymentEditComponent extends CodaltComponent implements OnInit {

    paymentDefault: PaymentDefault;
    fc: {
        id: UntypedFormControl,
        name: UntypedFormControl,
        price: UntypedFormControl,
        price_higher: UntypedFormControl,
        price_lower: UntypedFormControl,
        auto_write_off: UntypedFormControl,
        low_permissions: UntypedFormControl
    };
    form: UntypedFormGroup;
    saving = false;
    transactions: Transaction[];

    constructor(private paymentDefaultService: PaymentDefaultService,
                private paymentService: PaymentService,
                private route: ActivatedRoute,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school) {
                this.getPaymentRequest(params['id']);
            }
        }));
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.saving = true;
            this.paymentDefaultService.savePaymentDefault(this.form.value).subscribe(data => {
                this.form.markAsPristine();
                this.saving = false;
                this.router.navigate([Routenames.defaultPayments]);
            });
        }
    }

    private getPaymentRequest(id) {
        if (id === Routenames.newPart) {
            this.paymentDefault = new PaymentDefault();
            this.createForm(this.paymentDefault);
        } else {
            this.paymentDefaultService.getPaymentDefault(id).subscribe(request => {
                this.paymentDefault = request.data;
                this.createForm(this.paymentDefault);
            });
            this.subscriptions.add(this.paymentService.getDefaultPaymentRequestTransactions(id).subscribe(transactions => {
                this.transactions = transactions.data;
            }));

        }
    }

    private createForm(paymentDefault: PaymentDefault) {
        this.fc = {
            id: new UntypedFormControl(paymentDefault.id),
            name: new UntypedFormControl(paymentDefault.name, Validators.required),
            price: new UntypedFormControl(paymentDefault.price, [Validators.required, Validators.min(0.01), Validators.max(500)]),
            price_higher: new UntypedFormControl(paymentDefault.price_higher),
            price_lower: new UntypedFormControl(paymentDefault.price_lower),
            auto_write_off: new UntypedFormControl(paymentDefault.auto_write_off),
            low_permissions: new UntypedFormControl(paymentDefault.low_permissions)
        };
        this.fc.low_permissions.valueChanges.subscribe(val => {
            this.fc.auto_write_off.setValue(val);
        });
        this.form = new UntypedFormGroup(this.fc);
    }
}
