import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

export function requiredConditionalFn(dependantOn: () => boolean) {
    return (control: UntypedFormControl | UntypedFormGroup) => {
        let childrenValuesValid = true;
        if (control['controls']) {
            childrenValuesValid = Object.values(control.value).filter(v => !!v).length > 0;
        }
        if ((!control.value || (Array.isArray(control.value) && control.value.length < 1) || !childrenValuesValid) && !!dependantOn.call(this)) {
            return {required: true};
        }
        return null;
    };
}
