<div class="dialog-container">
    <div class="dialog-content" mat-dialog-content>
        <div class="row">
            <div [formGroup]="form" class="col" *ngIf="AuthorisationService.hasFeature('createDiscussion')">
                <h3>Planning aanpassen</h3>
                <h4>{{discussiontime.datetime | date:'EEEE d MMMM yyyy HH:mm'}}</h4>
                <h4>Groep {{classroom.classroom_name}}</h4>
                <ng-select [items]="otherTimes"
                           formControlName="time"
                           [clearable]="false"
                           [searchFn]="discussionTimeSearch"
                           notFoundText="Geen tijden gevonden"
                           placeholder="Tijdstip">

                    <ng-template let-item="item" ng-label-tmp ng-option-tmp>
            <span>
                {{item.datetime  | date:'d MMM yyyy HH:mm (EE)'}}
                {{!(classroom.students | byId:item.student_id) ? '' : ' - ' + (classroom.students | byId:item.student_id).name}}
            </span>
                    </ng-template>

                </ng-select>
                <mat-checkbox formControlName="unavailable">
                    Tijd niet beschikbaar
                </mat-checkbox>
                <mat-checkbox formControlName="private">
                    Priv&eacute; (verzorgers zien elkaars afspraak niet)
                </mat-checkbox>
                <ng-select (change)="studentSelected($event)"
                           [items]="classroom.students"
                           notFoundText="Geen leerlingen gevonden"
                           bindLabel="name"
                           bindValue="id"
                           formControlName="student"
                           placeholder="Leerling">
                </ng-select>
                <ng-select #users
                           (change)="updateOthers()"
                           [items]="selectedStudent?.users"
                           notFoundText="Geen verzorgers gevonden"
                           bindLabel="name"
                           bindValue="id"
                           formControlName="user"
                           placeholder="Verzorger"></ng-select>

                <button (click)="dialogRef.close()" class="button">Annuleren</button>
                <button (click)="save()" class="button float-right" color="primary" mat-raised-button>Opslaan</button>

            </div>
            <div class="{{AuthorisationService.hasFeature('createDiscussion') ? 'col-14' : 'col'}}">
                <h3>Andere afspraken</h3>
                <div *ngIf="selectedStudent">
                    <h4>Afspraken voor {{selectedStudent?.name}}</h4>
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th>Datum / tijd</th>
                            <th>Groep</th>
                            <th>Verzorger</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let time of otherTimesStudent">
                            <td width="130">{{time.datetime | date:'EE d MMM HH:mm'}}</td>
                            <td class="text-center" width="70">{{(allClassrooms | byId:time.classroom_id)?.classroom_name}}</td>
                            <td>{{(selectedStudent.users | byId:time.user_id).name}}</td>
                        </tr>
                        <tr *ngIf="!otherTimesStudent || otherTimesStudent.length == 0">
                            <td class="text-center" colspan="3">
                                Er zijn geen andere afspraken
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="form.get('user').value">
                    <h4>Afspraken voor {{(selectedStudent.users | byId:form.get('user').value)?.name}}</h4>
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th>Datum / tijd</th>
                            <th>Groep</th>
                            <th>Leerling</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let time of otherTimesUser">
                            <td width="130">{{time.datetime | date:'EE d MMM HH:mm'}}</td>
                            <td class="text-center" width="70">{{(allClassrooms | byId:time.classroom_id)?.classroom_name}}</td>
                            <td>{{((allClassrooms | byId:time.classroom_id).students | byId:time.student_id).name}}</td>
                        </tr>
                        <tr *ngIf="!otherTimesUser || otherTimesUser.length == 0">
                            <td class="text-center" colspan="3">
                                Er zijn geen andere afspraken
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="sameUserStudent" class="warning">
                    <i class="fa fa-exclamation-triangle"></i>
                    Er is al een afspraak voor deze verzorger en leerling
                </div>
                <div *ngIf="sameTime" class="warning">
                    <i class="fa fa-exclamation-triangle"></i>
                    Verzorger heeft een dubbele afspraak
                </div>
            </div>
        </div>
    </div>
</div>
