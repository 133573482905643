<div class="dialog-container">
    <div class="dialog-content">
        <div class="title d-flex justify-content-between">
            <div>
                Berichtinformatie
            </div>
            <button class="close" mat-dialog-close="" mat-icon-button tabindex="-1">
                <mat-icon class="fas fa-close"></mat-icon>
            </button>
        </div>
        <div class="d-flex flex-column h-100">

            <mat-list class="flex-fill overflow-auto">
                <div class="pb-0" mat-subheader>Bericht</div>
                <mat-list-item class="message-line">
                    <div class="message" mat-line>
                        <app-chat-message [message]="data.message"></app-chat-message>
                    </div>
                    <small class="text-right" mat-line>
                        <ng-container *ngIf="!Utils.isToday(data.message.updated_at)">
                                        <span class="d-none d-sm-block">
                                            {{data.message.updated_at | date:'d MMM yyyy H:mm'}}
                                        </span>
                            <span class="d-block d-sm-none">
                                          {{data.message.updated_at | date:'d MMM H:mm'}}
                                        </span>
                        </ng-container>
                        <ng-container *ngIf="Utils.isToday(data.message.updated_at)">
                            Vandaag {{data.message.updated_at  | date:'H:mm'}}
                        </ng-container>
                    </small>
                </mat-list-item>
                <mat-divider></mat-divider>
                <div class="pb-0" mat-subheader>Gelezen door</div>
                <ng-container *ngFor="let chatUser of data.chat.chat_users | whereGreaterOrEqual: 'last_read' : data.message.id">
                    <mat-list-item>
                        <div matListItemTitle>
                            {{chatUser.user.name}}
                        </div>
                    </mat-list-item>
                </ng-container>
                <mat-divider></mat-divider>
                <div *ngIf="(data.chat.chat_users | whereLess : 'last_read' : data.message.id)?.length" class="mt-3 pb-0" mat-subheader>Verzonden aan</div>
                <ng-container *ngFor="let chatUser of data.chat.chat_users | whereLess : 'last_read' : data.message.id">
                    <mat-list-item>
                        <div matListItemTitle>
                            {{chatUser.user.name}}
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
    </div>
</div>
