<div class="items" [class.active]="childActive">
    <ng-container *ngIf="agendaView">
        <virtual-scroller #virtualScroll (vsEnd)="scroll($event)" [items]="groupedEvents" class="agenda-view item-list"
                          [bufferAmount]="50">

            <div class="agenda-day" *ngFor="let eventGroup of virtualScroll.viewPortItems">
                <div class="date">
                    {{eventGroup.date | date:'EEEE d MMMM yyyy'}}
                </div>
                <div class="agenda-item" *ngFor="let event of eventGroup.events"
                     routerLink="/{{Routenames.events}}/{{event.slug}}">
                    <time [style.background]="event.color">
                        <ng-container
                                *ngIf="event.all_day && (event.start_date | date:'yMd') != (event.end_date | date:'yMd')">
                            Hele dag
                        </ng-container>
                        <ng-container
                                *ngIf="event.all_day && (event.start_date | date:'yMd') == (event.end_date | date:'yMd')">
                            Hele dag
                        </ng-container>
                        <ng-container *ngIf="!event.all_day">
                            <ng-container *ngIf="(event.start_date | date:'yyMd') == (event.end_date | date:'yyMd')">
                                {{event.start_date | date:'H:mm'}}
                                -
                                {{event.end_date | date:'H:mm'}}
                            </ng-container>
                            <ng-container *ngIf="(event.start_date | date:'yyMd') != (event.end_date | date:'yyMd')">
                                {{event.start_date | date:'H:mm'}}
                                -
                                {{event.end_date | date:'H:mm'}}
                            </ng-container>
                        </ng-container>
                    </time>
                    <div class="agenda-info">
                        <div class="agenda-title">
                            {{event.title}}
                        </div>
                        <div class="agenda-classrooms">
                            <ng-container
                                    *ngFor="let classroom of event.groupedClassrooms; let last = last; let first = first">
                                {{first ? ' Groep: ' : ''}} {{classroom}}{{last ? '' : ','}}
                            </ng-container>
                            <i class="fa-duotone fa-chalkboard-user" *ngIf="event.access_level === 'EMPLOYEE'"></i>
                            <i class="fa-duotone fa-volleyball" *ngIf="event.register_before"></i>
                        </div>
                    </div>
                </div>

            </div>
            <div class="item-spacer"></div>
            <div class="title">
                <h2>
                    {{history ? 'Verleden' : 'Kalender'}}
                </h2>
                <mat-form-field
                        *ngIf="LocalStorage.school && LocalStorage.school.is_direct && LocalStorage.school.type === 'subsite'">
                    <mat-label>Groepen</mat-label>
                    <mat-select [formControl]="fcFilter" multiple>
                        <mat-option value="all">Toon alle groepen</mat-option>
                        <mat-option value="own">Toon eigen groepen</mat-option>
                        <mat-option [value]="classroom.id" *ngFor="let classroom of classrooms">
                            Groep {{classroom.classroom_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </virtual-scroller>
    </ng-container>


    <ng-container *ngIf="!agendaView">
        <virtual-scroller #virtualScroll (vsEnd)="scroll($event)" [items]="events" class="item-list">
            <div *ngFor="let event of virtualScroll.viewPortItems" class="item"
                 routerLink="/{{Routenames.events}}/{{event.slug}}">
                <app-codalt-image [path]="event.id + '/' + event.image"
                                  [thumb]="event.image_thumb"
                                  class="detail__image"
                                  secureType="event">
                </app-codalt-image>
                <div [class.alert]="event.alert"
                     [class.employee]="event.access_level === 'EMPLOYEE'"
                     [class.offer]="event.register_before"
                     class="detail__block">
                    <h3 [class.unread]="!event.read">{{event.title}}</h3>
                    <div class="details">
                        <time>
                            <ng-container
                                    *ngIf="event.all_day && (event.start_date | date:'yMd') != (event.end_date | date:'yMd')">
                                {{event.start_date | date:'EE d MMM yyyy'}} -
                                {{event.end_date | date:'EE d MMM yyyy'}}
                            </ng-container>
                            <ng-container
                                    *ngIf="event.all_day && (event.start_date | date:'yMd') == (event.end_date | date:'yMd')">
                                {{event.start_date | date:'EE d MMM yyyy'}}
                            </ng-container>
                            <ng-container *ngIf="!event.all_day">
                                <ng-container
                                        *ngIf="(event.start_date | date:'yyMd') == (event.end_date | date:'yyMd')">
                                    {{event.start_date | date:'EE d MMM yyyy, HH:mm'}} -
                                    {{event.end_date | date:'HH:mm'}}
                                </ng-container>
                                <ng-container
                                        *ngIf="(event.start_date | date:'yyMd') != (event.end_date | date:'yyMd')">
                                    {{event.start_date | date:'EE d MMM yyyy HH:mm'}} -
                                    {{event.end_date | date:'EE d MMM yyyy HH:mm'}}
                                </ng-container>
                            </ng-container>
                        </time>
                        <div>{{event.user?.name}}
                            <ng-container
                                    *ngFor="let classroom of event.groupedClassrooms; let last = last; let first = first">
                                {{first ? ' | ' : ''}} {{classroom}}{{last ? '' : ','}}
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-spacer"></div>
            <div class="title">
                <h2>
                    {{history ? 'Verleden' : 'Kalender'}}
                </h2>
                <mat-form-field
                        *ngIf="LocalStorage.school && LocalStorage.school.is_direct && LocalStorage.school.type === 'subsite'">
                    <mat-label>Groepen</mat-label>
                    <mat-select [formControl]="fcFilter" multiple>
                        <mat-option value="all">Toon alle groepen</mat-option>
                        <mat-option value="own">Toon eigen groepen</mat-option>
                        <mat-option [value]="classroom.id" *ngFor="let classroom of classrooms">
                            Groep {{classroom.classroom_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </virtual-scroller>
    </ng-container>

    <div class="floating-buttons">
        <button *ngIf="AuthorisationService.hasFeature('createEvent')" color="primary" mat-fab
                routerLink="/{{Routenames.events}}/nieuw/bewerken">
            <mat-icon class="fas fa-plus"></mat-icon>
        </button>
        <button color="primary"
                mat-fab
                matTooltip="{{history ? 'Toekomstige items' : 'Items uit het verleden'}}"
                matTooltipPosition="before"
                routerLink="/{{Routenames.events}}/{{history ? '' : 'verleden'}}">
            <mat-icon [class.fa-clock-o]="history" [class.fa-history]="!history" class="fas"></mat-icon>
        </button>
    </div>
    <div *ngIf="events.length === 0 && !loading" class="not-found">
        Er zijn nog geen kalenderitems geplaatst
    </div>
</div>
<div class="router-outlet" [class.active]="childActive">
    <router-outlet (activate)="active(true)"
                   (deactivate)="childClosed($event);">
    </router-outlet>
</div>
