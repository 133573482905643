import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'isJson'
})
export class IsJsonPipe implements PipeTransform {

    transform(value: string): string {
        if (value?.substring(0, 1) === '{' && value?.substring(value.length - 1) === '}') {
            try {
                const val = JSON.parse(value);
                return val.filetype ?? val?.type;
            } catch (error) {
                return null;
            }
        }
        return null;
    }

}
