import {Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Settings} from '../settings.class';
import {LocalStorage} from '../storage.class';

@Component({
    selector: 'app-codalt-image',
    templateUrl: './codalt-image.component.html',
    styleUrls: ['./codalt-image.component.scss']
})
export class CodaltImageComponent implements OnInit, OnChanges, OnDestroy {

    image: string;
    loading = false;
    loaded = false;
    @Input() thumb: string;
    @Input() icon: string;
    @Input() iconBackground: string;
    @Input() private fullUrl: string;
    @Input() private path: string;
    @Input() secureType = 'article';
    private wasInView = false;
    private loadStarted = false;
    private interval;

    constructor(private elemRef: ElementRef, private ngZone: NgZone) {
        this.interval = setInterval(() => {
            this.checkImg();
        }, 150);
    }

    ngOnInit() {
        setTimeout(() => {
            this.checkImg();
        });
    }

    setImage() {
        if (this.wasInView && !this.loadStarted) {
            this.loadStarted = true;


            const imageObj = new Image();
            imageObj.src = this.getImagePath();
            this.ngZone.run(() => {
                this.image = this.getImagePath();
            });

            if (imageObj.complete) {
                this.loaded = true;
                this.loading = true;
                this.ngZone.run(() => {
                    this.loaded = true;
                    this.loading = false;
                });
            } else {
                this.loading = true;
            }
            imageObj.onload = () => {
                this.loaded = true;
                this.loading = true;
                this.ngZone.run(() => {
                    this.loaded = true;
                    this.loading = false;
                });
            };
            clearInterval(this.interval);
        }
    }

    getImagePath() {
        if (this.fullUrl) {
            return this.fullUrl;
        } else if (this.path && this.path.substr(-4) !== 'null') {
            return Settings.API_ENDPOINT +
                'file/thumb?secure=' + this.secureType +
                '&path=/' + this.path +
                '&school=' + LocalStorage?.school?.id +
                '&access_token=' + LocalStorage.getUserToken();
        }
        return null;
    }


    ngOnChanges() {
        this.loaded = false;
        this.loadStarted = false;
        this.wasInView = false;
        setTimeout(() => {
            this.checkImg();
        });
    }

    isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return rect.top <= (window.innerHeight || document.documentElement.clientHeight) && (rect.top > -100 || rect.bottom > -100);
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    private checkImg() {
        if (this.isInViewport(this.elemRef.nativeElement)) {
            this.wasInView = true;
            this.setImage();
        }
    }
}
