import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'safeBgUrl'
})
export class SafeBgUrlPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {
    }

    transform(url) {
        if (url) {
            return this.domSanitizer.bypassSecurityTrustStyle('url("' + url + '")');
        }
        return null;
    }
}