import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'textHref'
})
export class TextHrefPipe implements PipeTransform {

    transform(value: any, transform = true): string {
        if (transform && (typeof value === 'string' || value instanceof String)) {
            value = value.replace(
                /(http(s)?:\/\/)?((www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}([-a-zA-Z0-9@:%_\+.~#?&//=]*))/g,
                function (matchedFull, matchedProtocol, _x, cleanUrl) {
                    return `<a target="_blank" href="${matchedProtocol ? '' : 'https://'}${matchedFull}">${cleanUrl}</a>`;
                }
            );
        }
        return value;
    }

}
