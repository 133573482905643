import {Component, OnInit} from '@angular/core';
import {ArticleService} from '../services/article.service';
import {Article} from '../classes/article.class';
import {CodaltComponent} from '../codalt.component';
import {LocalStorage} from '../storage.class';

import {ActivatedRoute} from '@angular/router';
import {School} from '../classes/school.class';
import {UntypedFormControl} from '@angular/forms';
import {ClassroomService} from '../services/classroom/classroom.service';
import {Classroom} from '../classes/classroom.class';
import {debounceTime, pairwise, startWith} from 'rxjs/operators';
import {IPageInfo} from '@iharbeck/ngx-virtual-scroller';

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent extends CodaltComponent implements OnInit {

    unreadOtherSchool = new Map<School, number>();

    fcFilter = new UntypedFormControl();
    classrooms: Classroom[];

    childActive = false;

    school: School;
    articles: Article[] = [];
    loading = true;
    future = false;
    private limit = 9;
    private skip = 0;
    private allLoaded = false;

    constructor(private articleService: ArticleService,
                private classroomService: ClassroomService,
                private route: ActivatedRoute) {
        super();
    }

    active(active) {
        setTimeout(() => {
            this.childActive = active;
        });
    }

    toSchool(school: School) {
        LocalStorage.school = school;
    }

    ngOnInit(): void {
        if (!LocalStorage.showSchoolWideArticles && !LocalStorage.showAllClassroomsArticles && !LocalStorage.showClassroomsArticles?.length) {
            this.fcFilter.setValue(['own']);
        } else if (LocalStorage.showAllClassroomsArticles) {
            this.fcFilter.setValue(['all']);
        } else if (LocalStorage.showSchoolWideArticles) {
            this.fcFilter.setValue(['school']);
        } else {
            this.fcFilter.setValue(LocalStorage.showClassroomsArticles);
        }

        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            if (LocalStorage.school.id !== this.school?.id) {
                this.skip = 0;
                this.getArticles();
            }
            this.school = LocalStorage.school;
            this.getclassrooms();
        }));
        if (LocalStorage.school) {
            this.skip = 0;
            this.getArticles();
            this.school = LocalStorage.school;
        }
        this.subscriptions.add(LocalStorage.bottomMenuReload.subscribe(() => {
            this.getArticles();
        }));

        this.getclassrooms();

        this.subscriptions.add(this.fcFilter.valueChanges.pipe(debounceTime(200)).subscribe(filters => {
            LocalStorage.showAllClassroomsArticles = !!filters?.find(f => f === 'all');
            LocalStorage.showSchoolWideArticles = !!filters?.find(f => f === 'school');
            LocalStorage.showClassroomsArticles = filters ? filters.filter(v => ['all', 'own', 'school'].indexOf(v) === -1) : [];
            this.getArticles();
        }));

        this.subscriptions.add(this.fcFilter.valueChanges.pipe(
            startWith(this.fcFilter.value),
            pairwise())
            .subscribe(([prev, next]: [any[], any[]]) => {
                if (prev.indexOf('all') === -1 && next.indexOf('all') !== -1) {
                    this.fcFilter.setValue(['all']);
                    this.skip = 0;
                }
                if (prev.indexOf('own') === -1 && next.indexOf('own') !== -1) {
                    this.fcFilter.setValue(['own']);
                    this.skip = 0;
                }
                if (prev.indexOf('school') === -1 && next.indexOf('school') !== -1) {
                    this.fcFilter.setValue(['school']);
                    this.skip = 0;
                }
                this.classrooms?.forEach(classroom => {
                    if (prev.indexOf(classroom.id) === -1 && next.indexOf(classroom.id) !== -1) {
                        const vals = this.fcFilter.value?.filter(v => ['all', 'own', 'school'].indexOf(v) === -1);
                        this.fcFilter.setValue(vals);
                        this.skip = 0;
                    }
                });
                if (!next.length) {
                    this.fcFilter.setValue(['own']);
                }
            }));
    }

    private getclassrooms() {
        this.subscriptions.add(this.articleService.unreadArticleCount().subscribe(unread => {
            this.unreadOtherSchool.clear();
            unread.data.forEach(unread => {
                const school = LocalStorage.schools.find(s => s.id === unread.school_id);
                if (unread.unread && LocalStorage.school?.id !== unread.school_id) {
                    this.unreadOtherSchool.set(school, unread.unread);
                }
            });
        }));
        if (LocalStorage.school && LocalStorage.school.is_direct && LocalStorage.school.type !== 'master') {
            this.subscriptions.add(this.classroomService.getClassrooms(true, true).subscribe(classrooms => {
                this.classrooms = classrooms.data;
                if (!LocalStorage.showClassroomsArticles?.filter(c => this.classrooms.map(d => d.id).indexOf(c) !== -1).length) {
                    if (!this.fcFilter.value.filter(v => ['all', 'own', 'school'].indexOf(v) !== -1)?.length) {
                        this.fcFilter.setValue(['own']);
                    }
                }
            }));
        } else {
            this.classrooms = [];
            [].concat.apply([], LocalStorage.user.students.map(s => s.classrooms)).forEach(classroom => {
                if (!this.classrooms.find(c => c.id === classroom.id)) {
                    this.classrooms.push(classroom);
                }
            });
            this.classrooms.sort((a, b) => {
                if (a.classroom_name < b.classroom_name) {
                    return -1;
                }
                if (a.classroom_name > b.classroom_name) {
                    return 1;
                }
                return 0;
            });
        }
    }

    childClosed(event) {
        this.childActive = false;
        if (event?.reserve || event?.deleting) {
            this.skip = 0;
            this.getArticles();
        } else if (event?.form) {
            const article = this.articles.find(a => a.id === event?.article?.id);
            if (article) {
                Object.assign(article, event?.article);
            }
        } else {
            const article = this.articles.find(a => a.id === event?.article?.id);
            if (article) {
                article.read = true;
            }
        }
    }

    changeShowAllClassrooms() {
        this.skip = 0;
        this.getArticles();
    }

    scroll(article: IPageInfo) {
        if (!this.loading && !this.allLoaded) {
            if (article.endIndex > (this.articles.length) - 3) {
                this.skip += this.limit;
                this.getArticles();
            }
        }
    }

    private getArticles() {
        this.loading = true;
        const future = this.route.snapshot.url[1]?.path === 'toekomst';
        if (this.future !== future) {
            this.skip = 0;
        }
        this.future = future;
        this.articleService.getList(
            this.limit,
            this.skip,
            this.future,
            LocalStorage.showAllClassroomsArticles,
            LocalStorage.showClassroomsArticles ?? [],
            '',
            LocalStorage.showSchoolWideArticles).subscribe(articles => {
            this.allLoaded = articles.data.length < this.limit;
            if (JSON.stringify(this.articles.slice(this.skip, this.limit)) !== JSON.stringify(articles.data)) {
                this.articles = (this.skip === 0 ? [] : this.articles).concat(articles.data);
            }
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }
}
