import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {ArticleEditComponent} from './articles/article-edit/article-edit.component';
import {EventEditComponent} from './events/event-edit/event-edit.component';

@Injectable()
export class CanDeactivateForm<T extends ArticleEditComponent | EventEditComponent> {

    constructor() {
    }

    canDeactivate(
        component: T,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.form) {
            return component.openChangesBackActionCheck();
        }
        return true;
    }
}
