<div class="container white">
    <div class="d-flex align-items-center justify-content-between mb-3">
        <h2 class="mb-0">Betaalverzoeken</h2>
        <div class="d-flex">
            <button class="button--primary" (click)="editPayment()">
                Toevoegen
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th (click)="sortList('name')">
                        Naam
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="name"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('price')">
                        Prijs
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="email"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('price_lower')">
                        Lager
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="price_lower"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('price_higher')">
                        Hoger
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="price_higher"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('updated_at')">
                        Betaald voor
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="pay_before"></app-sorting-icon>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let payment of list" style="cursor:pointer;">
                    <td (click)="editPayment(payment.id)">{{payment.name}}</td>
                    <td (click)="editPayment(payment.id)">{{payment.price | currency : 'EUR'}}</td>
                    <td (click)="editPayment(payment.id)">{{payment.price_lower ? 'Ja' : 'Nee'}}</td>
                    <td (click)="editPayment(payment.id)">{{payment.price_higher ? 'Ja' : 'Nee'}}</td>
                    <td (click)="editPayment(payment.id)">
                        {{payment.pay_before | date:'d MMM yyyy HH:mm:ss'}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
