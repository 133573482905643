import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorage} from './storage.class';
import {Routenames} from './route-names.enum';

@Injectable()
export class IsAuthenticated {
    constructor(private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!LocalStorage.user) {
            this.router.navigate([Routenames.login]);
        }
        return true;
    }
}
