import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, cordova } from '@ionic-native/core';
import { Observable } from 'rxjs';
var FirebaseX = /** @class */function (_super) {
  __extends(FirebaseX, _super);
  function FirebaseX() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  FirebaseX.prototype.getToken = function () {
    return cordova(this, "getToken", {}, arguments);
  };
  FirebaseX.prototype.getId = function () {
    return cordova(this, "getId", {}, arguments);
  };
  FirebaseX.prototype.getCurrentUser = function () {
    return cordova(this, "getCurrentUser", {}, arguments);
  };
  FirebaseX.prototype.reloadCurrentUser = function () {
    return cordova(this, "reloadCurrentUser", {}, arguments);
  };
  FirebaseX.prototype.onTokenRefresh = function () {
    return cordova(this, "onTokenRefresh", {
      "observable": true
    }, arguments);
  };
  FirebaseX.prototype.getAPNSToken = function () {
    return cordova(this, "getAPNSToken", {}, arguments);
  };
  FirebaseX.prototype.onApnsTokenReceived = function () {
    return cordova(this, "onApnsTokenReceived", {
      "observable": true
    }, arguments);
  };
  FirebaseX.prototype.onMessageReceived = function () {
    return cordova(this, "onMessageReceived", {
      "observable": true
    }, arguments);
  };
  FirebaseX.prototype.grantPermission = function () {
    return cordova(this, "grantPermission", {
      "platforms": ["iOS"]
    }, arguments);
  };
  FirebaseX.prototype.hasPermission = function () {
    return cordova(this, "hasPermission", {}, arguments);
  };
  FirebaseX.prototype.unregister = function () {
    return cordova(this, "unregister", {}, arguments);
  };
  FirebaseX.prototype.setBadgeNumber = function (badgeNumber) {
    return cordova(this, "setBadgeNumber", {}, arguments);
  };
  FirebaseX.prototype.getBadgeNumber = function () {
    return cordova(this, "getBadgeNumber", {}, arguments);
  };
  FirebaseX.prototype.clearAllNotifications = function () {
    return cordova(this, "clearAllNotifications", {
      "platforms": ["Android"]
    }, arguments);
  };
  FirebaseX.prototype.subscribe = function (topic) {
    return cordova(this, "subscribe", {}, arguments);
  };
  FirebaseX.prototype.unsubscribe = function (topic) {
    return cordova(this, "unsubscribe", {}, arguments);
  };
  FirebaseX.prototype.isAutoInitEnabled = function () {
    return cordova(this, "isAutoInitEnabled", {}, arguments);
  };
  FirebaseX.prototype.setAutoInitEnabled = function (enabled) {
    return cordova(this, "setAutoInitEnabled", {}, arguments);
  };
  FirebaseX.prototype.createChannel = function (channelOptions) {
    return cordova(this, "createChannel", {}, arguments);
  };
  FirebaseX.prototype.setDefaultChannel = function (channelOptions) {
    return cordova(this, "setDefaultChannel", {}, arguments);
  };
  FirebaseX.prototype.deleteChannel = function (channelID) {
    return cordova(this, "deleteChannel", {}, arguments);
  };
  FirebaseX.prototype.listChannels = function () {
    return cordova(this, "listChannels", {}, arguments);
  };
  FirebaseX.prototype.setAnalyticsCollectionEnabled = function (enabled) {
    return cordova(this, "setAnalyticsCollectionEnabled", {}, arguments);
  };
  FirebaseX.prototype.setCrashlyticsCollectionEnabled = function (enabled) {
    return cordova(this, "setCrashlyticsCollectionEnabled", {}, arguments);
  };
  FirebaseX.prototype.setPerformanceCollectionEnabled = function (enabled) {
    return cordova(this, "setPerformanceCollectionEnabled", {}, arguments);
  };
  FirebaseX.prototype.logEvent = function (type, data) {
    return cordova(this, "logEvent", {}, arguments);
  };
  FirebaseX.prototype.setScreenName = function (name) {
    return cordova(this, "setScreenName", {}, arguments);
  };
  FirebaseX.prototype.setUserId = function (userId) {
    return cordova(this, "setUserId", {}, arguments);
  };
  FirebaseX.prototype.setUserProperty = function (name, value) {
    return cordova(this, "setUserProperty", {}, arguments);
  };
  FirebaseX.prototype.setCrashlyticsUserId = function (userId) {
    return cordova(this, "setCrashlyticsUserId", {}, arguments);
  };
  FirebaseX.prototype.sendCrash = function () {
    return cordova(this, "sendCrash", {}, arguments);
  };
  FirebaseX.prototype.logMessage = function (message) {
    return cordova(this, "logMessage", {}, arguments);
  };
  FirebaseX.prototype.logError = function (error, stackTrace) {
    return cordova(this, "logError", {}, arguments);
  };
  FirebaseX.prototype.verifyPhoneNumber = function (phoneNumber, timeOutDuration, fakeVerificationCode) {
    return cordova(this, "verifyPhoneNumber", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  FirebaseX.prototype.setLanguageCode = function (lang) {
    return cordova(this, "setLanguageCode", {}, arguments);
  };
  FirebaseX.prototype.signInWithCredential = function (credential) {
    return cordova(this, "signInWithCredential", {}, arguments);
  };
  FirebaseX.prototype.createUserWithEmailAndPassword = function (email, password) {
    return cordova(this, "createUserWithEmailAndPassword", {}, arguments);
  };
  FirebaseX.prototype.signInUserWithEmailAndPassword = function (email, password) {
    return cordova(this, "signInUserWithEmailAndPassword", {}, arguments);
  };
  FirebaseX.prototype.signInUserWithCustomToken = function (customToken) {
    return cordova(this, "signInUserWithCustomToken", {}, arguments);
  };
  FirebaseX.prototype.signInUserAnonymously = function () {
    return cordova(this, "signInUserAnonymously", {}, arguments);
  };
  FirebaseX.prototype.authenticateUserWithGoogle = function (clientId) {
    return cordova(this, "authenticateUserWithGoogle", {}, arguments);
  };
  FirebaseX.prototype.authenticateUserWithApple = function (locale) {
    return cordova(this, "authenticateUserWithApple", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  FirebaseX.prototype.linkUserWithCredential = function (credential, success, error) {
    return cordova(this, "linkUserWithCredential", {}, arguments);
  };
  FirebaseX.prototype.reauthenticateWithCredential = function (credential, success, error) {
    return cordova(this, "reauthenticateWithCredential", {}, arguments);
  };
  FirebaseX.prototype.isUserSignedIn = function () {
    return cordova(this, "isUserSignedIn", {}, arguments);
  };
  FirebaseX.prototype.signOutUser = function () {
    return cordova(this, "signOutUser", {}, arguments);
  };
  FirebaseX.prototype.updateUserProfile = function (profile) {
    return cordova(this, "updateUserProfile", {}, arguments);
  };
  FirebaseX.prototype.updateUserEmail = function (email) {
    return cordova(this, "updateUserEmail", {}, arguments);
  };
  FirebaseX.prototype.sendUserEmailVerification = function () {
    return cordova(this, "sendUserEmailVerification", {}, arguments);
  };
  FirebaseX.prototype.updateUserPassword = function (password) {
    return cordova(this, "updateUserPassword", {}, arguments);
  };
  FirebaseX.prototype.sendUserPasswordResetEmail = function (email) {
    return cordova(this, "sendUserPasswordResetEmail", {}, arguments);
  };
  FirebaseX.prototype.deleteUser = function () {
    return cordova(this, "deleteUser", {}, arguments);
  };
  FirebaseX.prototype.registerAuthStateChangeListener = function (fn) {
    return cordova(this, "registerAuthStateChangeListener", {}, arguments);
  };
  FirebaseX.prototype.fetch = function (cacheExpirationSeconds) {
    return cordova(this, "fetch", {}, arguments);
  };
  FirebaseX.prototype.activateFetched = function () {
    return cordova(this, "activateFetched", {}, arguments);
  };
  FirebaseX.prototype.getValue = function (key) {
    return cordova(this, "getValue", {}, arguments);
  };
  FirebaseX.prototype.getByteArray = function (key) {
    return cordova(this, "getByteArray", {}, arguments);
  };
  FirebaseX.prototype.getInfo = function () {
    return cordova(this, "getInfo", {}, arguments);
  };
  FirebaseX.prototype.setConfigSettings = function (settings) {
    return cordova(this, "setConfigSettings", {}, arguments);
  };
  FirebaseX.prototype.setDefaults = function (settings) {
    return cordova(this, "setDefaults", {}, arguments);
  };
  FirebaseX.prototype.startTrace = function (name) {
    return cordova(this, "startTrace", {}, arguments);
  };
  FirebaseX.prototype.incrementCounter = function (name) {
    return cordova(this, "incrementCounter", {}, arguments);
  };
  FirebaseX.prototype.stopTrace = function (name) {
    return cordova(this, "stopTrace", {}, arguments);
  };
  FirebaseX.prototype.addDocumentToFirestoreCollection = function (document, collection, success, error) {
    return cordova(this, "addDocumentToFirestoreCollection", {}, arguments);
  };
  FirebaseX.prototype.setDocumentInFirestoreCollection = function (documentId, document, collection, success, error) {
    return cordova(this, "setDocumentInFirestoreCollection", {}, arguments);
  };
  FirebaseX.prototype.updateDocumentInFirestoreCollection = function (documentId, document, collection, success, error) {
    return cordova(this, "updateDocumentInFirestoreCollection", {}, arguments);
  };
  FirebaseX.prototype.deleteDocumentFromFirestoreCollection = function (documentId, collection, success, error) {
    return cordova(this, "deleteDocumentFromFirestoreCollection", {}, arguments);
  };
  FirebaseX.prototype.fetchDocumentInFirestoreCollection = function (documentId, collection, success, error) {
    return cordova(this, "fetchDocumentInFirestoreCollection", {}, arguments);
  };
  FirebaseX.prototype.fetchFirestoreCollection = function (collection, success, error) {
    return cordova(this, "fetchFirestoreCollection", {}, arguments);
  };
  FirebaseX.pluginName = "FirebaseX";
  FirebaseX.plugin = "cordova-plugin-firebasex";
  FirebaseX.pluginRef = "FirebasePlugin";
  FirebaseX.repo = "https://github.com/dpa99c/cordova-plugin-firebasex";
  FirebaseX.platforms = ["Android", "iOS"];
  FirebaseX.decorators = [{
    type: Injectable
  }];
  return FirebaseX;
}(IonicNativePlugin);
export { FirebaseX };
