<div *ngIf="form" class="container white">
    <h1>Gebruiker beheren</h1>
    <div class="row justify-content-center">
        <div class="col col-md-12">
            <form [formGroup]="form">
                <div *ngIf="moreRoles" class="alert alert-warning">
                    Deze gebruiker heeft rollen die jij niet hebt. Daarom kun je hem niet aanpassen.
                </div>
                <div *ngIf="moreSchools" class="alert alert-warning">
                    Deze gebruiker heeft scholen die jij niet hebt. Daarom kun je hem niet aanpassen.
                </div>
                <mat-form-field class="w-100" id="name">
                    <input formControlName="name"
                           matInput
                           name="name"
                           placeholder="Naam"
                           type="text">
                    <mat-error [control]="form.get('name')"></mat-error>
                </mat-form-field>
                <mat-form-field class="w-100" id="email">
                    <input formControlName="email"
                           matInput
                           name="email"
                           placeholder="E-mailadres"
                           type="email">
                    <mat-error [control]="form.get('email')"></mat-error>
                </mat-form-field>
                <ng-select [closeOnSelect]="false"
                           [hideSelected]="true"
                           [items]="roles"
                           [multiple]="true"
                           bindLabel="name"
                           bindValue="id"
                           formControlName="roleIds"
                           name="roleIds"
                           placeholder="Gebruikersrollen"></ng-select>
                <ng-select [closeOnSelect]="false"
                           [hideSelected]="true"
                           [items]="schools"
                           [multiple]="true"
                           bindLabel="name"
                           bindValue="id"
                           formControlName="schoolIds"
                           name="schoolIds"
                           placeholder="Scholen"></ng-select>
            </form>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-12 col">
            <div class="d-flex justify-content-between">
                <button mat-button routerLink="/gebruikers">Terug</button>

                <button (click)="save()" [disabled]="form.invalid || saving || form.disabled" color="primary" id="btn-save"
                        mat-raised-button>
                    Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
                </button>

                <ng-container *ngIf="!LocalStorage.parentUser && AuthorisationService.hasFeature('loginAsUser') && user?.id">
                    <button (click)="loginAs()" mat-raised-button>Inloggen als {{user.name}}</button>
                </ng-container>

            </div>
        </div>
    </div>
    <app-user-auth-log [user]="user"></app-user-auth-log>
</div>
