import {Component, Input, OnInit} from '@angular/core';
import {Field} from '../classes/field.class';
import {Utils} from '../utils.class';

import {LinkEditDialogComponent} from '../link-edit/link-edit-dialog.component';
import {CustobType} from '../classes/custob-type.class';
import {CustobTypeService} from '../services/custob-type/custob-type.service';
import {CustobService} from '../services/custob/custob.service';
import {SeoOptionsDialogComponent} from '../page-edit/seo-options-dialog/seo-options-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Settings} from '../settings.class';
import {LocalStorage} from '../storage.class';

@Component({
    selector: 'app-fields-edit',
    templateUrl: './fields-edit.component.html',
    styleUrls: ['./fields-edit.component.scss']
})
export class FieldsEditComponent implements OnInit {

    @Input('fields') fields: Field[];
    @Input('values') values: Object;
    Utils = Utils;
    Settings = Settings;
    Storage = LocalStorage;
    custobTypes: CustobType[];
    custobs = {};

    constructor(private custobTypeService: CustobTypeService,
                private custobService: CustobService,
                public dialog: MatDialog) {
    }

    setval(values, id, event) {
        values[id] = null;
        setTimeout(() => {
            values[id] = event;
        });
    }

    ngOnInit() {
        if (!this.values) {
            this.values = {};
        }
    }

    seoOptionsDialog(identifier?: string) {
        let dialogRef = this.dialog.open(SeoOptionsDialogComponent, {
            width: '600px',
            data: {containerObject: this.values, key: identifier}
        });

        dialogRef.afterClosed().subscribe();
    }

    editLink(values, fieldId) {
        let dialogData = {value: values[fieldId], target: values[fieldId + 'target']};
        let dialogRef = this.dialog.open(LinkEditDialogComponent, {
            width: '320px',
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                values[fieldId] = dialogData.value;
                values[fieldId + 'target'] = dialogData.target;
            }
        });
    }

    fileUploaded(data, isImage = true) {
        if (!Array.isArray(data.file)) {
            if (isImage) {
                this.values[data.fileType] = data.file.pathname;
            } else {
                this.values[data.fileType] = data.file;
            }

        } else if (Array.isArray(data.file)) {
            if (!Array.isArray(this.values[data.fileType])) {
                this.values[data.fileType] = [];
            }
            if (isImage) {
                data.file.forEach(file => {
                    this.values[data.fileType].push(file.pathname);
                });
            } else {
                data.file.forEach(file => {
                    this.values[data.fileType].push(file);
                });
            }
        }
    }

    getCustobTypes() {
        if (this.custobTypes) {
            return this.custobTypes;
        }
        this.custobTypes = [];
        /*this.custobTypeService.getAll().then((custobTypes) => {
            this.custobTypes = this.custobTypes.concat(custobTypes);
        }); */
        return this.custobTypes;
    }

    getCustobTypeNameById(id) {
        let custobTypes = this.getCustobTypes();
        let custobTypeName = '';
        custobTypes.forEach(custobType => {
            if (custobType.id === id)
                custobTypeName = custobType.name;
        });
        return custobTypeName;
    }

    getCustobs(custobTypeId: number) {
        if (!this.custobs.hasOwnProperty(custobTypeId)) {
            this.custobs[custobTypeId] = [];

            this.custobService.getAll(custobTypeId).then((custobs) => {
                this.custobs[custobTypeId] = custobs;
            });
        }
        return this.custobs[custobTypeId];
    }
}
