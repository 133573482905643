import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {UserService} from '../services/user/user.service';
import {PermissionService} from '../services/permission.service';
import {PermissionQuestion} from '../classes/permission-question';

@Component({
    selector: 'app-my-privacy',
    templateUrl: './my-privacy.component.html',
    styleUrls: ['./my-privacy.component.scss']
})
export class MyPrivacyComponent extends CodaltComponent implements OnInit {

    today = new Date();

    questions: PermissionQuestion[];

    constructor(private userService: UserService,
                private permissionService: PermissionService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.permissionService.getPermissionQuestionsForStudents().subscribe(questions => {
            this.questions = questions.data;
        }));
    }

}
