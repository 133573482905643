<section class="chat" [class.active]="childActive">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-24 col-sm-20 col-md-16">
                <div class="row">
                    <div class="col-auto" *ngIf="!isCordova">
                        <h1 class="chat__title mt-3">Chat {{archive ? 'archief' : ''}}</h1>
                    </div>
                    <mat-form-field class="col mt-2 search-input" *ngIf="!loading">
                        <mat-label>Zoeken...</mat-label>
                        <input [formControl]="fcSearch" matInput type="text">
                    </mat-form-field>
                </div>
                <div *ngIf="unreadOtherSchoolChats?.size" class="chat__block">
                    <div class="chat-item--group">
                        <b>Ongelezen berichten
                            in {{unreadOtherSchoolChats.size > 1 ? 'andere scholen' : 'andere school'}}</b>
                    </div>
                    <div *ngFor="let unread of unreadOtherSchoolChats | keyvalue" class="chat-item--group curpoint"
                         (click)="toSchool(unread.key)">
                        {{unread.key?.name}} ({{unread.value?.length}})
                    </div>
                </div>
                <div *ngIf="(chats | chatsSearch : fcSearch.value)?.length < 1" class="chat__block">
                    <div class="chat-item--group" *ngIf="loading">
                        <b>Chats worden geladen...</b>
                    </div>
                    <div class="chat-item--group" *ngIf="fcSearch.value?.length > 0">
                        <b>Er zijn geen chats gevonden voor "{{fcSearch.value}}".</b>
                    </div>
                </div>
                <div class="chat__block">
                    <a *ngFor="let chat of (chats | chatsSearch : fcSearch.value)"
                       (press)="selectStart(chat)"
                       (tap)="tapSelect(chat)"
                       [class.selected]="selectedItems && selectedItems.has(chat.id)"
                       class="chat-item--group">
                        <div class="chat-item__image-width">
                            <div class="chat-item__image-wrapper">
                                @if (chat.school_type === 'offer') {
                                    <div class="chat-item__image">
                                        <i class="fa-duotone fa-volleyball"></i>
                                    </div>
                                } @else {
                                    <app-codalt-image *ngIf="chat.chat_users?.length === 2"
                                                      [fullUrl]="LocalStorage.school?.fullUrl + (chat.chat_users | notById:LocalStorage.user.id:'user_id')?.user?.profile_image"
                                                      [thumb]="(chat.chat_users | notById:LocalStorage.user.id:'user_id')?.user?.profile_image"
                                                      class="chat-item__image"
                                                      icon="fas fa-user-friends"
                                                      iconBackground="#f2f4f6">
                                    </app-codalt-image>
                                    <div *ngIf="chat.chat_users.length > 2" class="chat-item__image">
                                        <i class="fas fa-users"></i>
                                    </div>
                                }
                            </div>
                        </div>
                        <div [class.unread]="chat.lastMessage && chat?.lastMessage?.id > (chat.chat_users | byId:LocalStorage.user.id:'user_id')?.last_read"
                             class="chat-item__wrapper">
                            <h2 class="chat-item__title">
                                {{chat.name}}
                                <small class="global-chat float-right"
                                       matTooltip="De chat is Kindwijs breed"
                                       (click)="$event.preventDefault()"
                                       *ngIf="!chat.school_id">
                                    Kindwijs
                                </small>
                                <small class="global-chat float-right"
                                       matTooltip="De chat is Kindwijs breed"
                                       (click)="$event.preventDefault()"
                                       *ngIf="chat.school_type === 'offer'">
                                    Naschools aanbod
                                </small>
                                <i *ngIf="chat.only_admin_messages" class="float-right far fa-lock only-admin"></i>
                                <i *ngIf="(chat.chat_users | byId:LocalStorage.user.id:'user_id')?.mute" class="fa-duotone fa-bell-slash float-right"></i>
                            </h2>
                            <div class="chat-item__content-wrapper">
                                <div class="chat-item__content">
                                    <h3 class="chat-item__subtitle">
                                        <ng-container *ngIf="chat && chat.chat_classrooms?.length < 1">
                                            <ng-container *ngFor="let chatUser of chat.chat_users; let last = last">
                                                {{chatUser.user_id === LocalStorage.user.id ? 'Jij' : chatUser.user?.name}}{{last ? '' : ', '}}
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="chat && chat.chat_classrooms?.length > 0">
                                            Leerkrachten en ouders uit:
                                            <ng-container
                                                *ngFor="let classroom of chat.chat_classrooms;  let last = last;">
                                                {{classroom.classroom.classroom_name}}{{last ? '' : ', '}}
                                            </ng-container>
                                        </ng-container>
                                    </h3>
                                    <p class="chat-item__message overflow-ellipsis">
                                        <i *ngIf="!chat.lastMessage">Nog geen berichten in deze chat</i>
                                        <strong *ngIf="chat.lastMessage">{{chat.lastMessage?.user?.name}}: </strong>
                                        <ng-container *ngIf="!(chat.lastMessage?.message | isBase64) && !(chat.lastMessage?.message | isJson)">
                                            {{chat.lastMessage?.message}}
                                        </ng-container>
                                        <i *ngIf="(chat.lastMessage?.message | isJson)"
                                           class="fas {{fileIconMap.get((chat.lastMessage?.message | isJson))}}"></i>
                                        <i *ngIf="(chat.lastMessage?.message | isBase64)"
                                           class="fas fa-image"></i>
                                    </p>
                                </div>
                                <ng-container *ngIf="!Utils.isToday(chat?.lastMessage?.updated_at)">
                                    <time class="chat-item__time d-none d-sm-block">
                                        {{chat?.lastMessage?.updated_at | date:'d MMM yyyy H:mm'}}
                                    </time>
                                    <time class="chat-item__time d-block d-sm-none">
                                        {{chat?.lastMessage?.updated_at | date:'d MMM H:mm'}}
                                    </time>
                                </ng-container>
                                <ng-container *ngIf="Utils.isToday(chat?.lastMessage?.updated_at)">
                                    <time class="chat-item__time">vandaag {{chat?.lastMessage?.updated_at | date:'H:mm'}}</time>
                                </ng-container>
                            </div>
                        </div>
                    </a>
                </div>
                <div *ngIf="!archive && !loading" class="chat__block curpoint" matRipple routerLink="/{{Routenames.chatArchive}}">
                    <div class="chat-item--group">
                        <i class="fa-light fa-box-archive mr-2"></i>
                        <b>Gearchiveerde chats</b>
                    </div>
                </div>
                <div *ngIf="archive && !loading" class="chat__block curpoint" matRipple routerLink="/{{Routenames.chat}}">
                    <div class="chat-item--group">
                        <i class="fa-light fa-messaging mr-2"></i>
                        <b>Terug naar actuele chats</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [class.hide]="hideEditButtons && !this.selectedItems.size"
         class="floating-buttons leave-color">
        <button (click)="newChat()" color="primary" mat-fab *ngIf="!archive">
            <mat-icon class="fas fa-plus"></mat-icon>
        </button>
        <button matTooltip="Archiveren" (click)="archiveChats()" color="primary" mat-fab *ngIf="this.selectedItems.size">
            <mat-icon *ngIf="!archive" class="fa-light fa-inbox-in"></mat-icon>
            <mat-icon *ngIf="archive" class="fa-light fa-inbox-out"></mat-icon>
        </button>
    </div>
</section>
<div class="router-outlet" [class.active]="childActive">
    <router-outlet (activate)="active(true)"
                   (deactivate)="active(false);">
    </router-outlet>
</div>
