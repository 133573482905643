<h1 mat-dialog-title>SEO opties</h1>
<div mat-dialog-content>
    <div>
        <br>
    </div>
    <mat-accordion>
        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Algemeen
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field class="w100">
                <textarea [(ngModel)]="seo.pagetitle" cdkTextareaAutosize matInput placeholder="Pagina titel" type="text"></textarea>
            </mat-form-field>
            <mat-form-field class="w100">
                <textarea [(ngModel)]="seo.description" cdkTextareaAutosize matInput placeholder="Beschrijving" type="text"></textarea>
            </mat-form-field>
            <mat-form-field class="w100">
                <textarea [(ngModel)]="seo.keywords" cdkTextareaAutosize matInput placeholder="Keywoorden" type="text"></textarea>
            </mat-form-field>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Open graph
                </mat-panel-title>
                <mat-panel-description>
                    OG SEO data
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field class="w100">
                <input [(ngModel)]="seo.og_title" matInput placeholder="Titel"
                       type="text">
            </mat-form-field>
            <mat-form-field class="w100">
                <mat-select [(ngModel)]="seo.og_type" placeholder="Type">
                    <mat-option value="website">
                        Website
                    </mat-option>
                    <mat-option value="article">
                        Artikel
                    </mat-option>
                    <mat-option value="book">
                        Boek
                    </mat-option>
                    <mat-option value="profile">
                        Profiel
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w100">
                <textarea [(ngModel)]="seo.og_description" cdkTextareaAutosize matInput
                          placeholder="Beschrijving" type="text"></textarea>
            </mat-form-field>
            <div class="images-overview">
                <div [style.background-image]="seo.og_image | safeBgUrl" class="image">
                    <div (click)="seo.og_image=null" *ngIf="seo.og_image" class="delete">
                        <i class="fa fa-2x fa-trash"></i>
                    </div>
                    <div *ngIf="!seo.og_image" class="noimg">
                        <i class="fa fa-image fa-2x"></i>
                    </div>
                    <file-manager (callback)="fileUploaded($event)" fileType="og_image"></file-manager>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Twitter
                </mat-panel-title>
                <mat-panel-description>
                    Twitter SEO data
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field class="w100">
                <input [(ngModel)]="seo.twitter_title" matInput placeholder="Titel"
                       type="text">
            </mat-form-field>
            <mat-form-field class="w100">
                <mat-select [(ngModel)]="seo.twitter_card" placeholder="Card">
                    <mat-option value="summary">
                        Samenvatting (summary)
                    </mat-option>
                    <mat-option value="summary_large_image">
                        Samenvatting met Afbeelding (summary_large_image)
                    </mat-option>
                    <mat-option value="app">
                        App (app)
                    </mat-option>
                    <mat-option value="player">
                        Player (player)
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w100">
                <textarea [(ngModel)]="seo.twitter_description" cdkTextareaAutosize matInput
                          placeholder="Beschrijving" type="text"></textarea>
            </mat-form-field>
            <div class="images-overview">
                <div [style.background-image]="seo.twitter_image | safeBgUrl" class="image">
                    <div (click)="seo.twitter_image=null" *ngIf="seo.twitter_image" class="delete">
                        <i class="fa fa-2x fa-trash"></i>
                    </div>
                    <div *ngIf="!seo.twitter_image" class="noimg">
                        <i class="fa fa-image fa-2x"></i>
                    </div>
                    <file-manager (callback)="fileUploaded($event)" fileType="twitter_image"></file-manager>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div>
        <br>
    </div>
</div>
<div mat-dialog-actions>
    <button (click)="onNoClick()" mat-button>Annuleren</button>
    <div class="spacer"></div>
    <button [mat-dialog-close]="true" cdkFocusInitial color="primary" mat-raised-button>Opslaan</button>
</div>