import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {PaymentService} from '../../services/payment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {PaymentRequest} from '../../classes/payment-request';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {Classroom} from '../../classes/classroom.class';
import {LocalStorage} from '../../storage.class';
import {map, startWith} from 'rxjs/operators';
import {Routenames} from '../../route-names.enum';
import {Utils} from '../../utils.class';
import {formatDate} from '@angular/common';
import {validTime} from '../../validators/valid-time.validator';
import {handleTimeValueChange} from '../../utils/handle-time-value-change';
import {PaymentDefault} from '../../classes/payment-default';
import {PaymentDefaultService} from '../../services/payment-default.service';
import {requiredConditionalFn} from '../../validators/required-conditional-fn.validator';
import {Transaction} from '../../classes/transaction';
import {Student} from '../../classes/student.class';

@Component({
    selector: 'app-payment-edit',
    templateUrl: './payment-edit.component.html',
    styleUrls: ['./payment-edit.component.scss']
})
export class PaymentEditComponent extends CodaltComponent implements OnInit {

    mainSchool = false;
    paymentRequest: PaymentRequest;
    fc: {
        id: UntypedFormControl,
        name: UntypedFormControl,
        price: UntypedFormControl,
        price_higher: UntypedFormControl,
        price_lower: UntypedFormControl,
        classrooms: UntypedFormControl,
        for_students: UntypedFormControl,
        payment_default: UntypedFormControl,
        pay_before: UntypedFormControl,
        pay_before_time: UntypedFormControl,
        individual: UntypedFormControl,
        schedule_date: UntypedFormControl,
        schedule_date_time: UntypedFormControl,
    };
    form: UntypedFormGroup;

    students: Student[];
    classrooms: Classroom[];
    classroomGroups: string[] = [];
    initSelectedClassrooms: (string | number)[] = [];
    initSelectedStudents: number[] = [];

    saving = false;
    defaultPayments: PaymentDefault[];

    transactions: Transaction[];

    notPaidStudents: Student[];

    constructor(private paymentService: PaymentService,
                private paymentDefaultService: PaymentDefaultService,
                private classroomService: ClassroomService,
                private route: ActivatedRoute,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school) {
                this.mainSchool = LocalStorage.school.type === 'master';
                this.getPaymentRequest(params['id']);
            }
        }));
        this.subscriptions.add(this.paymentDefaultService.getRequestOptions().subscribe(defaults => {
            this.defaultPayments = defaults.data;
        }));
        this.subscriptions.add(this.paymentService.getStudents().subscribe(students => {
            this.students = students.data;
        }));
    }

    save() {
        this.saving = true;
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.paymentService.savePaymentRequest(this.form.getRawValue()).subscribe(data => {
                this.form.markAsPristine();
                this.saving = false;
                this.router.navigate([Routenames.payments]);
            }, () => {
                this.saving = false;
            });
        } else {
            this.saving = false;
        }
    }

    private getPaymentRequest(id) {

        const classrooms$ = this.classroomService.getClassrooms();
        if (id === Routenames.newPart) {
            this.subscriptions.add(classrooms$.pipe(map(c => c.data))
                .subscribe(classrooms => this.setClassrooms(classrooms)));
            this.paymentRequest = new PaymentRequest();
            this.createForm(this.paymentRequest);
        } else {
            const payment$ = this.paymentService.getPaymentRequest(id);
            this.subscriptions.add(combineLatest([classrooms$, payment$])
                .subscribe(([classrooms, request]) => {
                    this.setClassrooms(classrooms.data);
                    this.paymentRequest = request.data;
                    this.subscriptions.add(this.paymentService.notPaidStudents(this.paymentRequest.id).subscribe(students => {
                        this.notPaidStudents = students.data;
                    }));
                    this.createForm(this.paymentRequest);
                }));
        }
    }

    private setClassrooms(classrooms: Classroom[]) {
        this.classrooms = classrooms;
        if (this.classrooms.length > 3) {
            this.classroomGroups = [...new Set(this.classrooms.filter(p => !!p.groupment).map(p => p.groupment))];
        }
    }

    private createForm(paymentRequest: PaymentRequest) {
        let classrooms = this.appendWithGroupments(paymentRequest.classrooms.map(c => c.id));
        classrooms = classrooms.length === 0 ? [null] : classrooms;
        this.initSelectedClassrooms = classrooms;
        this.initSelectedStudents = paymentRequest.for_students?.map(s => s.id);
        const fcClassrooms = new UntypedFormControl(classrooms);
        const fcPayBeforeDate = new UntypedFormControl(paymentRequest.pay_before ? paymentRequest.pay_before : null);
        const fcPayBeforeTime = new UntypedFormControl(
            paymentRequest.pay_before ? formatDate(paymentRequest.pay_before, 'HH:mm', 'nl') : null,
            {
                validators: [validTime(fcPayBeforeDate), requiredConditionalFn(() => {
                    return !!fcPayBeforeDate.value;
                })], updateOn: 'blur'
            }
        );

        const fcScheduleDate = new UntypedFormControl(paymentRequest.schedule_date ? paymentRequest.schedule_date : null);
        const fcScheduleTime = new UntypedFormControl(
            paymentRequest.schedule_date ? formatDate(paymentRequest.schedule_date, 'HH:mm', 'nl') : null,
            {
                validators: [validTime(fcScheduleDate), requiredConditionalFn(() => {
                    return !!fcScheduleDate.value;
                })], updateOn: 'blur'
            }
        );

        this.fc = {
            id: new UntypedFormControl(paymentRequest.id),
            name: new UntypedFormControl(paymentRequest.name, Validators.required),
            price: new UntypedFormControl(paymentRequest.price, [Validators.required, Validators.min(1), Validators.max(500)]),
            price_higher: new UntypedFormControl(paymentRequest.price_higher),
            price_lower: new UntypedFormControl(paymentRequest.price_lower),
            classrooms: fcClassrooms,
            for_students: new UntypedFormControl(paymentRequest.for_students?.map(s => s.id)),
            payment_default: new UntypedFormControl(paymentRequest.payment_default || 'no'),
            pay_before: fcPayBeforeDate,
            pay_before_time: fcPayBeforeTime,
            schedule_date: fcScheduleDate,
            schedule_date_time: fcScheduleTime,
            individual: new UntypedFormControl(paymentRequest?.for_students?.length ? 'students' : 'classrooms')
        };
        if (this.paymentRequest?.id) {
            this.fc.price.disable();
            if (this.paymentRequest.price_higher) {
                this.fc.price_higher.disable();
            }
            if (this.paymentRequest.price_lower) {
                this.fc.price_lower.disable();
            }
            if (this.paymentRequest.payment_default) {
                this.fc.payment_default.disable();
            }
        }
        let paymentDefaultPrevValue = this.paymentRequest?.payment_default;
        this.subscriptions.add(this.fc.payment_default.valueChanges.subscribe(value => {

            const defaultPayment = this.defaultPayments.find(d => d.id === value);
            if (value && value !== 'no' && value !== paymentDefaultPrevValue) {
                this.fc.name.setValue(defaultPayment.name);
                this.fc.price.setValue(defaultPayment.price);
                this.fc.price_higher.setValue(defaultPayment.price_higher);
                this.fc.price_lower.setValue(defaultPayment.price_lower);

            } else {

            }
            paymentDefaultPrevValue = value;
        }));
        this.subscriptions.add(fcPayBeforeTime.valueChanges.subscribe(
            value => handleTimeValueChange(value, fcPayBeforeTime)
        ));
        this.subscriptions.add(fcScheduleTime.valueChanges.subscribe(
            value => handleTimeValueChange(value, fcScheduleTime)
        ));
        this.subscriptions.add(fcClassrooms.valueChanges.subscribe(newClassrooms => {
            const selectedNew = JSON.stringify(newClassrooms);
            const prevClassrooms = this.form.value.classrooms;
            if (prevClassrooms.indexOf(null) === -1 && newClassrooms.indexOf(null) !== -1 && newClassrooms.length > 1) {
                newClassrooms = [null];
                fcClassrooms.setValue(newClassrooms);
            }
            if (this.paymentRequest?.id && prevClassrooms.indexOf(null) !== -1 && newClassrooms.indexOf(null) === -1) {
                newClassrooms = this.initSelectedClassrooms;
            }
            if (newClassrooms.length > 1 && newClassrooms.indexOf(null) !== -1) {
                newClassrooms = newClassrooms.filter(a => a !== null);
            }

            // GROUPMENT => Handle new groups
            newClassrooms.forEach(nc => {
                if (('' + nc).substr(0, 6) === 'group_') {
                    if (prevClassrooms.indexOf(nc) === -1) {
                        newClassrooms.push(
                            ...this.classrooms
                                .filter(c => newClassrooms.indexOf(c.id) === -1)
                                .filter(c => c.groupment === nc.substr(6)).map(c => c.id)
                        );
                    }
                }
            });

            // GROUPMENT => Handle removed groups
            prevClassrooms.forEach(pc => {
                if (('' + pc).substr(0, 6) === 'group_' &&
                    newClassrooms.indexOf(pc) === -1 &&
                    this.initSelectedClassrooms.indexOf(pc) === -1) {
                    const toBeRemovedClassrooms = this.classrooms
                        .filter(c => c.groupment === pc.substr(6) && this.initSelectedClassrooms.indexOf(c.id) === -1).map(c => c.id);
                    const canBeRemovedClassrooms = newClassrooms.filter(nc => toBeRemovedClassrooms.indexOf(nc) !== -1);
                    if (toBeRemovedClassrooms.length === canBeRemovedClassrooms.length) {
                        newClassrooms = newClassrooms.filter(nc => toBeRemovedClassrooms.indexOf(nc) === -1);
                    }
                }
            });

            // GROUPMENT => Check whether full group is checked
            newClassrooms = this.appendWithGroupments(newClassrooms);

            if (selectedNew !== JSON.stringify(newClassrooms)) {
                fcClassrooms.setValue(newClassrooms);
            }
        }));
        this.form = new UntypedFormGroup(this.fc);
        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            const datetimes = [
                {date: fcPayBeforeDate, time: fcPayBeforeTime},
                {date: fcScheduleDate, time: fcScheduleTime}
            ];
            datetimes.forEach(controls => {
                if (controls.time.value && controls.date.value) {
                    const time = controls.time.value.split(':');
                    if (time.length === 2) {
                        const date = new Date(controls.date.value);
                        date.setHours(time[0]);
                        date.setMinutes(time[1]);
                        if (date.toString() !== (new Date(controls.date.value)).toString()) {
                            controls.date.setValue(date);
                        }
                    }
                }
            });

        }));
        if (this.paymentRequest?.id) {
            this.subscriptions.add(this.paymentService.getPaymentRequestTransactions(this.paymentRequest.id).subscribe(transactions => {
                this.transactions = transactions.data;
            }));
        }
    }

    private appendWithGroupments(classrooms: (string | number)[]) {
        this.classroomGroups.forEach(clg => {
            const allGroupedClassrooms: (string | number)[] = this.classrooms.filter(c => c.groupment === clg).map(c => c.id);
            const selectedGroupedClassrooms = classrooms.filter(nc => allGroupedClassrooms.indexOf(nc) !== -1);
            if (allGroupedClassrooms.length === selectedGroupedClassrooms.length) {
                if (classrooms.filter(p => ('' + p).substr(6) === clg).length === 0) {
                    classrooms.push('group_' + clg);
                }
            } else {
                classrooms = classrooms.filter(a => ('' + a).substr(6) !== clg);
            }
        });
        return classrooms;
    }


}
