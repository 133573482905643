<div class="container white">
    <h1>Structure {{structure?.name}} aanpassen</h1>
    <ng-container *ngIf="structure">
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label class="col" for="structure-name">Naam</label>
                    <div class="col">
                        <input [(ngModel)]="structure.name" class="form-control" id="structure-name" type="text"/>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col" for="structure-identifier">Identifier</label>
                    <div class="col">
                        <input [(ngModel)]="structure.identifier" [disabled]="structure.id" class="form-control" id="structure-identifier"
                               placeholder="Wordt automatisch gegenereerd indien leeg"
                               type="text"/>
                    </div>
                </div>
            </div>
            <div class="col col-xs-offset-1">
                Te gebruiken bij <br>
                <label>
                    <input [(ngModel)]="structure.use_at_pages" [value]="true" type="checkbox"/>
                    Pagina's
                </label> <br>
                <ng-container *ngFor="let custobType of custobTypes">
                    <label>
                        <input [(ngModel)]="structure.useAtCustobTypeIds[custobType.id]" [value]="true"
                               type="checkbox"/>
                        {{custobType.name}}
                    </label> <br>
                </ng-container>
                <br><br>
                <b>Preview afbeelding</b>
                <file-manager (callback)="structure.image = $event.file.pathname"
                              (loading)="structure.image = null"
                              [directUpload]="true"
                              icon="fas fa-upload"></file-manager>
                <br>
                <img class="example" src="{{LocalStorage.school?.fullUrl + structure.image}}">

            </div>
        </div>

        <div class="clearfix"></div>

        <div class="col">
            <h2>Velden</h2>

            <div class="form-horizontal">
                <app-fields [custobTypes]="custobTypes" [fields]="structure.fields"></app-fields>
            </div>
        </div>

    </ng-container>
    <div class="clearfix"></div>

    <div (click)="save();" class="btn btn-default">Opslaan</div>
</div>
<br><br><br><br>