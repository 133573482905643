@if (type === 'json-video' && video) {
    <app-video class="video" (click)="openImage.emit(message)" [onlyThumb]="true" [video]="video"></app-video>
} @else if (type === 'json-image') {
    <app-codalt-image (click)="openImage.emit(message)"
                      class="image curpoint"
                      [path]="message.chat_id + '/' + item?.path"
                      [thumb]="item?.thumb"
                      secureType="chat">
    </app-codalt-image>
} @else if (type === 'json-document') {
    <div class="document" (click)="openDocument()">
        <div class="icon">
            <i class="fa-duotone {{fileIconMap.get(item.filetype)}}"></i>
        </div>
        <div class="info">
            <div class="filename">
                {{item.path}}
            </div>
            <div class="size">
                {{item.filetype}} &middot; {{(item.size / (1024 * 1024) | number : '0.2-2')}} MB
            </div>
        </div>
    </div>
} @else if (type === 'text') {
    <div class="text" [innerHTML]="message.message | textHref:usersMap?.get(message.user_id)?.direct_school_count > 0"></div>
} @else if (type === 'base64') {
    <div (click)="openImage.emit(message)" [style.background-image]="message.message | safeBgUrl" class="image"></div>
}
