import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {Router} from '@angular/router';
import {CodaltComponent} from '../../codalt.component';
import {LocalStorage} from '../../storage.class';
import {LoggerService} from '../../services/logger.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends CodaltComponent implements OnInit {

    constructor(private authService: AuthenticationService,
                private loggerService: LoggerService,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.loggerService.logError(`Logout u:${LocalStorage.user?.id} s:${LocalStorage.school?.id}  ss:${LocalStorage.schools?.length} slug: ${localStorage.getItem('selectedschool')} `);
        this.authService.logout().subscribe(() => {
            this.router.navigateByUrl(this.Routenames.login);
        }, () => {
            this.router.navigateByUrl(this.Routenames.login);
        });
    }

}
