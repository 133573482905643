<section class="detail">
    <div *ngIf="discussion" class="container item-edit">
        <div class="col  pt-3 bg-white">
            <div>
                <h2 class="news__title">{{discussion.name}} {{LocalStorage.school?.name}}</h2>
                <p [innerHTML]="discussion.description"></p>
            </div>
            <p *ngIf="subscriptionEnded" class="font-italic">
                Je kunt niet meer inschrijven of je inschrijving aanpassen. Inschrijven was mogelijk
                tot {{discussion.subscription_end | date:'EEEE d MMMM yyyy, H:mm'}}.
                <br>
                Neem contact op met de school als je de inschrijving nog wilt aanpassen.
            </p>
            <p *ngIf="!discussion.subscription_open" class="font-italic">
                Je kunt nog niet inschrijven voor deze oudergesprekken. Inschrijven is mogelijk vanaf
                <span *ngIf="discussion.classrooms_to_go.length > 2">
            {{discussion.pre_subscription_start | date:'EEEE d MMMM yyyy, H:mm'}}.
        </span>
                <span *ngIf="discussion.classrooms_to_go.length <= 2">
            {{discussion.subscription_start | date:'EEEE d MMMM yyyy, H:mm'}}.
        </span>
            </p>
            <div *ngIf="discussion.subscription_open" class="row">
                <div *ngFor="let student of students" class="col-24 col-md-12 offset-md-6 mb-4">
                    <div class="row">
                        <div class="col-24 col-sm">
                            <h3>{{student.name}}</h3>
                        </div>
                        <div class="col-24 col-sm text-sm-right mb-3">
                            Leerlingnummer: {{student.number}}
                        </div>
                    </div>
                    <div class="mb-3">
                        <h4 *ngIf="studentTimes.get(student.id).length" class="mb-2">Geplande oudergesprekken</h4>
                        <div *ngFor="let time of studentTimes.get(student.id)" class="mb-2">
                            <span class="font-weight-bolder">{{time.user?.name}}</span>
                            - {{time.datetime | date:'EE d MMM yyyy HH:mm'}}
                            - Groep {{(student.classrooms | byId:time.classroom_id)?.classroom_name}}
                            <button mat-raised-button color="primary" (click)="unsubscribe(time, student)"
                                    *ngIf="time.user?.id === LocalStorage.user.id && !subscriptionEnded">
                                Uitschrijven
                            </button>
                            <br>
                        </div>
                    </div>
                    <ng-container *ngIf="!subscriptionEnded">
                        <div *ngFor="let classroom of student.classrooms" class="mb-3">

                            <ng-container
                                    *ngIf="!(discussion.classrooms_to_go | byId:classroom.id) && !(classroom.school_year | containsText : '(SUB)')">
                                <h4>Oudergesprek groep {{classroom.classroom_name}}</h4>
                                <i class="text-bolder">
                                    Er vinden geen oudergesprekken voor {{classroom.classroom_name}} plaats.
                                </i>
                            </ng-container>

                            <ng-container *ngIf="(discussion.classrooms_to_go | byId:classroom.id)">
                                <h4>Oudergesprek groep {{classroom.classroom_name}}</h4>
                                <ng-select (change)="save($event, student)"
                                           [clearable]="false"
                                           [items]="studentAvailableTimes.get(student.id + '_' + classroom.id)"
                                           [disabled]="!discussion.maySubscribe"
                                           [ngModel]="selectValue.get(student.id + '_' + classroom.id + '_' + LocalStorage.user.id)"
                                           [searchFn]="discussionTimeSearch"
                                           notFoundText="Geen tijden gevonden"
                                           placeholder="Tijdstip">
                                    <ng-template let-item="item" ng-label-tmp ng-option-tmp>
                                        <span>
                                            {{ item.datetime  | date:'HH:mm - EEEE d MMMM yyyy' }}
                                        </span>
                                    </ng-template>
                                </ng-select>
                            </ng-container>
                            <ng-container
                                    *ngIf="selectValue.get(student.id + '_' + classroom.id + '_' + LocalStorage.user.id)">
                                <mat-checkbox
                                        (change)="save(selectValue.get(student.id + '_' + classroom.id + '_' + LocalStorage.user.id), student)"
                                        [(ngModel)]="selectValue.get(student.id + '_' + classroom.id + '_' + LocalStorage.user.id).private">
                                    Afspraak verbergen voor andere ouders/verzorgers
                                </mat-checkbox>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>

<div *ngIf="LocalStorage.school.is_direct && discussion" [class.hide]="hideEditButtons" class="floating-buttons">
    <ng-container *ngIf="AuthorisationService.hasFeature('createDiscussion')">
        <button (click)="delete()" color="primary" mat-fab>
            <mat-icon class="fas fa-trash"></mat-icon>
        </button>
        <button color="primary" mat-fab routerLink="/{{Routenames.discussionsPlanner}}/edit/{{discussion.id}}">
            <mat-icon class="fas fa-pencil-alt"></mat-icon>
        </button>
    </ng-container>
    <button color="primary" mat-fab routerLink="/{{Routenames.discussionsPlanner}}/detail/{{discussion.id}}">
        <mat-icon class="fas fa-calendar-alt"></mat-icon>
    </button>
</div>


