<div class="image-viewer-container">
    <div (click)="dialogRef.close()" class="close-viewer">
        <i class="fas fa-times"></i>
    </div>
    <div class="download" (click)="download($event)" *ngIf="isCordova">
        <i class="fas fa-download"></i>
    </div>
    <div class="swiper-container">
        <swiper #swiper initialSlide="{{currentIndex}}}" direction="horizontal" class="swiper-wrapper"
                (slideChange)="onSlideChange($event)" [navigation]="true">
            <ng-template swiperSlide *ngFor="let img of images.images; let i = index;">
                <div class="image swiper-slide">
                    <div class="image-box">
                        <pinch-zoom [autoHeight]="true" [minScale]="0.5" [limit-zoom]="5" [draggable]="true"
                                    *ngIf="img.path"
                                    overflow="visible"
                                    #pinchZoom>
                            <img
                                    [src]="(Settings.API_ENDPOINT+'file/thumb?'+secure+'path=/'+(img.event_id || img.article_id || img.job_id || img.chat_id)+'/'+img.path+'&school='+LocalStorage?.school?.id+'&access_token='+LocalStorage.getUserToken())"
                                    class="img" alt="image"/>
                        </pinch-zoom>
                        <pinch-zoom [autoHeight]="true" [minScale]="0.5" [draggable]="true"
                                    *ngIf="!img.path"
                                    overflow="visible" #pinchZoom>
                            <img [src]="img.base64" class="img" alt="image"/>
                        </pinch-zoom>
                    </div>
                    <div class="text">

                    </div>
                </div>
            </ng-template>

            <ng-template swiperSlide *ngFor="let videoO of images.videos; let i = index;">
                <div class="image swiper-slide">
                    <div class="image-box">
                        <app-video [video]="videoO" class="h-100 w-100" #video></app-video>
                    </div>
                    <div class="text">

                    </div>
                </div>
            </ng-template>
        </swiper>


    </div>
</div>
