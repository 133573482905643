import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StructureService} from '../services/structure/structure.service';

@Component({
    selector: 'app-structure',
    templateUrl: './structure.component.html',
    styleUrls: ['./structure.component.css']
})
export class StructureComponent implements OnInit {

    structures: Array<Object>;

    constructor(
        private structureService: StructureService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.structureService.getAll(9999).subscribe((structures) => {
            this.structures = structures;
        });
    }

    editStructure(structureId) {
        this.router.navigate([`/structures/${structureId}`]);

    }

}
