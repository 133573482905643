<div class="container white">
    <div class="d-flex align-items-center justify-content-between mb-3">
        <h2 class="mb-0">Gebruikers</h2>
        <button *ngIf="AuthorisationService.hasFeature('userList')" class="button--primary" color="primary" routerLink="/{{Routenames.users}}">
            Overige gebruikers
        </button>
        <button *ngIf="AuthorisationService.hasFeature('authorisationFeatureToRole')" class="button--primary ml-3" color="primary" routerLink="/auth/roles">
            Autorisatie
        </button>
    </div>
    <div *ngIf="userManagement">
        <mat-radio-group [(ngModel)]="onlythisschool">
            <mat-radio-button [value]="false">Toon {{usercount_all}} gebruikers uit alle scholen</mat-radio-button>
            <br>
            <mat-radio-button [value]="true">Toon {{usercount_selected}} gebruikers uit {{LocalStorage?.school?.name}}</mat-radio-button>
        </mat-radio-group>
    </div>

    <div>
        <mat-form-field class="w-100">
            <mat-label>Zoeken</mat-label>
            <input [(ngModel)]="query" matInput placeholder="Zoek op naam of email" type="text">
        </mat-form-field>
    </div>

    <div class="form-group">

    </div>

    <div class="row">
        <div class="col">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th (click)="sortList('name')">
                        Naam
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="name"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('email')">
                        Email
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="email"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('schools_count')">
                        Werkzaam bij
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="schools_count"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('classrooms_count')">
                        Docent voor
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="classrooms_count"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('transactionAmount')">
                        Saldo
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="transactionAmount"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('students_count')">
                        Verzorger van
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="students_count"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('last_login')" width="180">
                        Laatst actief
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="last_login"></app-sorting-icon>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of list | search:'name,email':query" style="cursor:pointer;">
                    <td (click)="editUser($event, user.id)">{{user.name}}</td>
                    <td (click)="editUser($event, user.id)">{{user.email}}</td>
                    <td (click)="editUser($event, user.id)">
            <span [hidden]="user.schools_count == 0">
              {{user.schools_count}}
                <span [hidden]="user.schools_count == 1">
                    scholen
                </span>
                <span [hidden]="user.schools_count != 1">
                      school
                </span>
            </span>
                    </td>
                    <td (click)="editUser($event, user.id)">
                        <span [hidden]="user.classrooms_count == 0">{{user.classrooms_count}} klas<span [hidden]="user.classrooms_count == 1">sen</span></span>
                    </td>
                    <td>
                        {{user.transactionAmount | currency : 'EUR'}}
                    </td>
                    <td (click)="editUser($event, user.id)">
                        <span>{{user.students}}</span>
                    </td>
                    <td (click)="editUser($event, user.id)">
                        {{user.last_login | date:'d MMM yyyy HH:mm:ss'}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
