<div class="container mt-3">
    <div class="row justify-content-center ">
        <div class="col-md-12 payment p-4">
            <div>
                <h2 class="news__title">Opwaarderen</h2>
            </div>
            <div *ngIf="form">
                <p>
                    U kunt hier het saldo op uw account opwaarderen. Dit wordt niet gedeeld tussen ouder(s) en/of verzorger(s).
                    Niet gebruikt saldo wordt vanaf 5,- euro terugbetaald als uw kind van school gaat.
                    Uw saldo mag maximaal &euro; {{Settings.MAX_BALANCE | number:'0.2-2'}} zijn.
                    Voor het opwaarderen worden &euro; {{Settings.PRICE_MOLLIE | number:'0.2-2'}} transactiekosten in rekening gebracht.
                </p>
                <p>
                    Huidigs saldo: &euro; {{LocalStorage.user?.transactionAmount | number:'0.2-2'}}
                </p>
                <div *ngIf="openPayments?.length">
                    <h4 class="mb-0">Openstaande betaalverzoeken ({{totalOpen | currency : 'EUR'}})</h4>
                    <ng-container *ngIf="changedAmount && openPayments?.length === 1">
                        <div>
                            - {{openPayments[0].name}} {{totalOpen | currency : 'EUR'}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!changedAmount">
                        <div *ngFor="let paymentRequest of openPayments">
                            - {{paymentRequest.name}} {{paymentRequest.priceToPay | currency : 'EUR'}}
                        </div>
                    </ng-container>
                </div>
                <ng-container *ngIf="calcMax() > 0">
                    <div class="row mt-3">
                        <div class="col">
                            <mat-form-field class="w-100">
                                <mat-label>Kies uw bedrag</mat-label>
                                <input [formControl]="fc.amount" matInput appDecimalInput decimalInput="2" [allowNegative]="false" type="text">
                                <mat-error [control]="fc.amount"></mat-error>
                                <mat-hint *ngIf="totalOpen > 0">
                                    Omdat er openstaande betaalverzoeken zijn kunt u opwaarderen met €75,00 + het openstaande bedrag.
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-auto pt-2">
                            <button mat-raised-button (click)="max()">Maximaal</button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                        <button class="button--primary" (click)="pay()" [disabled]="paying">
                            Opwaarderen
                            <mat-icon *ngIf="paying" class="fas fa-circle-notch fa-spin"></mat-icon>
                            <mat-icon *ngIf="!paying" class="fab fa-ideal ml-2"></mat-icon>
                        </button>
                    </div>
                </ng-container>
                <div class="mt-3 font-weight-bold" *ngIf="calcMax() <= 0">
                    Het maximum saldo is € 75,00. U kunt op dit moment niet opwaarderen.
                </div>
            </div>
        </div>
    </div>
</div>
