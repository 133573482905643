import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-choose-file-source',
    templateUrl: './choose-file-source.component.html',
    styleUrls: ['./choose-file-source.component.scss']
})
export class ChooseFileSourceComponent {
    constructor(public bottomSheetRef: MatBottomSheetRef<ChooseFileSourceComponent>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public options: {remove: boolean, rotate: boolean, video?: boolean, document?: boolean}) {
    }

    openLink(type): void {
        if (type === 'document' && Utils.isIOS()) {
            (document.querySelector('#uploadInput') as HTMLInputElement).click();
        }
        this.bottomSheetRef.dismiss(type);
    }
}
