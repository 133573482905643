<ng-container *ngIf="pageData?.length" class="Pasdfas">
    <div [(dragulaModel)]='pageData' [dragula]='dragBagName' class="page-tree">
        <div *ngFor="let node of pageData;let i = index"
             [class.ischilds]="isChilds"
             class="pageitem">
            <div>
                <div class="faholder status">
                    <i *ngIf="node.accessibility == 0" class="fa fa-eye fa-lg" title="Pagina is zichtbaar op de website"></i>
                    <i *ngIf="node.accessibility == 1" class="fa fa-ban fa-lg" title="Pagina is NIET zichtbaar op de website"></i>
                    <i *ngIf="node.accessibility == 2" class="fa fa-eye-slash fa-lg" title="Pagina wordt niet weergeven in het menu"></i>
                </div>
                <div class="name {{dragClass}}">{{ node.name }}</div>
                <div>
                    <div (click)="editPage(node.id)" class="faholder edit">
                        <i class="fas fa-pencil-alt fa-lg color-grey" title="Pagina aanpassen"></i>
                    </div>
                    <div (click)="addPage(node.id)" class="faholder">
                        <i class="fa fa-plus fa-lg color-grey" title="Sub-pagina aanmaken"></i>
                    </div>
                    <div (click)="duplicate(node.id)" class="faholder duplicate">
                        <i class="fa fa-files-o fa-lg color-grey" title="Pagina dupliceren"></i>
                    </div>
                    <div (click)="history(node.id)" class="faholder history">
                        <i class="fa fa-history fa-lg color-grey" title="Bewerkingsgeschiedenis"></i>
                    </div>
                    <div (click)="removePage(node.id, i, pageData)" class="faholder delete">
                        <i class="fa fa-trash fa-lg color-grey" title="Pagina verwijderen"></i>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>

            <app-page-tree (pageDataChanged)="node.children=$event;orderChanged()" [isChilds]="true" [pageData]="node.children"></app-page-tree>
        </div>
    </div>
</ng-container>
