<ng-container *ngIf="typeObj && typeObj.survey">
    <div *ngIf="closed">
        <div [innerHTML]="typeObj.survey.end_description"></div>
    </div>
    <h1 class="detail__title mt-3">Vragen</h1>
    <div *ngIf="typeObj.survey.answer && !changing">
        <p>
            Je hebt deze vragenlijst beantwoord op {{typeObj.survey.answer.created_at | date:'d MMMM yyyy, H:mm'}} uur.
            Hieronder zie je jouw antwoorden.
        </p>
        <div *ngFor="let question of typeObj.survey.questions">
            <h3 class="mt-2">{{question.question}}</h3>
            Mijn antwoord:
            <ng-container *ngIf="question.multiple">
            <span *ngFor="let selectedOption of (typeObj.survey.answer.answers[question.hash] | selectedOptions); let last = last;">
                {{selectedOption}}{{last ? '' : ', '}}
            </span>
            </ng-container>
            <ng-container *ngIf="!question.multiple">
                {{typeObj.survey.answer.answers[question.hash]}}
                <div *ngIf="question.type === 'boolean-quantity' && typeObj.survey.answer.answers[question.hash] == 'Ja'">
                    {{question.quantityQuestion}}:  {{typeObj.survey.answer.answers[question.hash + '-quantity']}}
                </div>
            </ng-container>
        </div>
        <button *ngIf="!closed" mat-raised-button color="secondary" (click)="changing=true" class="mt-3">
            Antwoorden aanpassen
        </button>
        <button *ngIf="!closed" class="ml-2" mat-raised-button color="primary" (click)="clearAnswers()">
            Antwoorden wissen
        </button>
    </div>

    <div *ngIf="form && changing && !closed" [formGroup]="form" (submit)="submit()">
        <ng-container *ngFor="let question of typeObj.survey.questions">
            <h3 class="mt-2">{{question.question}}</h3>
            <div *ngIf="question.type === 'open'">
                <mat-form-field class="w-100">
                    <input matInput type="text" [name]="question.hash" [formControlName]="question.hash"/>
                </mat-form-field>
            </div>
            <div class="font-italic mb-3" *ngIf="question.disabledAnswer">
                Grijze opties zijn niet meer beschikbaar omdat het aantal van {{question.limitCount}} bereikt is.
            </div>
            <div class="font-italic mb-3" *ngIf="question.type === 'boolean' && question.limit && !question.disabledAnswer">
                Er is {{question.count}} van {{question.limitCount}} benodigd gevuld.
            </div>
            <mat-radio-group [formControlName]="question.hash" *ngIf="question.type === 'boolean-quantity'">
                <mat-radio-button value="Ja" class="d-block" [disabled]="question.disabledAnswer">
                    Ja
                    <ng-container *ngIf="question.disabledAnswer">
                        (Niet meer beschikbaar omdat het maximum aantal is bereikt)
                    </ng-container>
                </mat-radio-button>
                <mat-radio-button value="Nee" class="d-block mb-1">Nee</mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="question.type === 'boolean-quantity' && form.get(question.hash)?.value === 'Ja'">
                <mat-form-field class="w-100 mb-1">
                    <mat-label>{{question.quantityQuestion}}</mat-label>
                    <input matInput type="text" appDecimalInput decimalInput="0"
                           [name]="question.hash"
                           formControlName="{{question.hash}}-quantity"/>
                    <mat-error [control]="form.get(question.hash + '-quantity')"></mat-error>
                    <mat-hint *ngIf="question.limitCount">Er is {{question.count}} van {{question.limitCount}} benodigd gevuld.</mat-hint>
                </mat-form-field>
            </ng-container>
            <mat-radio-group [formControlName]="question.hash" *ngIf="question.type === 'boolean'">
                <mat-radio-button value="Ja" class="d-block" [disabled]="question.disabledAnswer">Ja</mat-radio-button>
                <mat-radio-button value="Nee" class="d-block">Nee</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="question.type === 'options'">
                <div class="font-italic mb-3" *ngIf="question.disabledAnswers?.length > 0">
                    Grijze opties zijn gekozen door iemand anders en niet meer beschikbaar.
                </div>
                <mat-radio-group [formControlName]="question.hash" *ngIf="!question.multiple">
                    <mat-radio-button *ngFor="let option of question.options"
                                      [value]="option"
                                      class="d-block"
                                      [disabled]="question.disabledAnswers && question.disabledAnswers.indexOf(option) !== -1">
                        {{option}}
                    </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="question.multiple" [formGroupName]="question.hash">
                    <div *ngFor="let option of question.options">
                        <mat-checkbox [formControlName]="option"
                                      [disabled]="question.disabledAnswers && question.disabledAnswers.indexOf(option) !== -1">
                            {{option}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <mat-error [control]="fc[question.hash]" *ngIf="form.dirty"></mat-error>

        </ng-container>
        <button mat-flat-button color="primary" (click)="submit()" [disabled]="submitting" class="mt-2">
            {{submitting ? 'Versturen...' : 'Antwoorden versturen'}}
        </button>
    </div>
    <div *ngIf="typeObj.survey.end_date && !closed" class="mt-2">
        <small>
            Deze enquête sluit {{typeObj.survey.end_date | date:'d MMM yyyy H:mm' }}
        </small>
    </div>
    <div *ngIf="typeObj.survey.end_date && closed" class="mt-2">
        <small>
            Deze enquête is gesloten op {{typeObj.survey.end_date | date:'d MMMM yyyy H:mm' }}
        </small>
    </div>
</ng-container>
