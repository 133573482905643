<div class="container white">
    <h2>{{student?.name}}</h2>

    <table class="table table-striped">
        <tr>
            <th colspan="2">
                Leerling
            </th>
        </tr>
        <tr>
            <td>Naam</td>
            <td>{{student?.name}}</td>
        </tr>
        <tr>
            <td>Leerlingnummer</td>
            <td>{{student?.number}}</td>
        </tr>
        <tr>
            <td>Aangemaakt op</td>
            <td>
                {{student?.created_at | date:'d-MM-yyyy HH:mm'}}
            </td>
        </tr>
        <tr>
            <td>Laatst gewijzigd</td>
            <td>
                {{student?.updated_at | date:'d-MM-yyyy HH:mm'}}
            </td>
        </tr>
        <tr *ngIf="student?.users?.length < 1">
            <td>
                Ouders/verzorgers
            </td>
            <td>
                Deze leerling heeft geen verzorgers
            </td>
        </tr>
        <ng-container *ngFor="let user of student?.users">
            <tr>
                <td>
                    {{user.pivot.type}}
                </td>
                <td>
                    <b><a routerLink="/{{Routenames.parnassysUsers}}/{{user.id}}">{{user.name}}</a></b>
                    <div class="d-flex">
                        <div class="mr-1">
                            <b>Adres:</b>
                        </div>
                        <div>
                            {{user.street}} {{user.housenumber}}<br>
                            {{user.zipcode}} {{user.town}}
                        </div>
                    </div>
                    <div *ngIf="user.phone">
                        <ng-container *ngIf="!isCordova">
                            <b>Telefoon: </b><a class="curpoint" (click)="phoneService.call(user.phone, user.name)">{{user.phone}}</a>
                        </ng-container>
                        <button *ngIf="isCordova" class="mt-1 mb-1 button--primary"
                                (click)="phoneService.call(user.phone, user.name)">{{user.phone}}
                        </button>
                    </div>
                    <div *ngIf="user.phonework">
                        <ng-container *ngIf="!isCordova">
                            <b>Telefoonwerk: </b><a class="curpoint" (click)="phoneService.call(user.phonework, user.name)">{{user.phonework}}</a>
                        </ng-container>
                        <button *ngIf="isCordova" class="mt-1 mb-1 button--primary"
                                (click)="phoneService.call(user.phonework, user.name)">{{user.phonework}}
                        </button>
                    </div>
                    <div>
                        <ng-container *ngIf="!isCordova">
                            <b>Email: </b> <a href="mailto:{{user.email}}">{{user.email}}</a>
                        </ng-container>
                        <a *ngIf="isCordova" class="mt-1 mb-1 button--primary"
                           href="mailto:{{user.email}}">{{user.email}}</a>
                    </div>
                </td>
            </tr>
        </ng-container>


        <ng-container *ngIf="student?.data?.emergencyNumber">
            <tr *ngFor="let number of student.data.emergencyNumber; let i = index">
                <td>
                    Noodnummer {{i + 1}}
                    <ng-container *ngIf="isCordova"> - {{number.naam}}</ng-container>
                </td>
                <td *ngIf="!isCordova">
                    {{number.naam}}<br>
                    <a class="curpoint" (click)="phoneService.call(number.telefoonnummer, number.naam)">{{number.telefoonnummer}}</a> <br>
                    {{number.omschrijving}}
                </td>
                <td *ngIf="isCordova">
                    <button class="mt-1 mb-1 button--primary" (click)="phoneService.call(number.telefoonnummer, number.naam)">{{number.telefoonnummer}}</button>
                </td>
            </tr>
        </ng-container>
        <tr>
            <td>Groepen</td>
            <td>
                <i [hidden]="student?.classrooms?.length > 0">Deze leerling is niet gekoppeld aan een klas</i>
                <div *ngFor="let classroom of student?.classrooms">
                    <b>
                        <a routerLink="/{{Routenames.parnassysClassrooms}}/{{classroom.id}}">
                            {{classroom.classroom_name}} ({{classroom.school.name}}, {{classroom.school_year}})
                        </a>
                    </b>
                </div>
            </td>
        </tr>
        <tr *ngIf="student?.permissions">
            <td>Toestemmingen & Privacy</td>
            <td>
                <div *ngFor="let permission of student.permissions">
                    <b>
                        {{permission.permission_question.name}} <i (click)="info(permission.permission_question)"
                                                                   class="fas fa-info-circle curpoint"></i>
                    </b><br>
                    {{permission.answer}}:
                    <i>{{(permission.permission_question.options | byId : permission.answer : 'name')?.intro}}</i>
                </div>
            </td>
        </tr>
        <tr>
        </tr>
    </table>
    <br><br>
    <div class="d-flex justify-content-between align-items-center">
        <a routerLink="/{{Routenames.parnassysStudent}}">&laquo; Terug naar alle leerlingen</a>
        <button class="button--primary" [routerLink]="['/',Routenames.absence, {student: student?.id}]">
            Absenties
        </button>
    </div>
    <br><br>
</div>
