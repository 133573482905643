import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Classroom} from '../../classes/classroom.class';

@Injectable()
export class ClassroomService {


    constructor(public apiService: ApiService) {
    }

    getClassrooms(onlyselectedschool: boolean = true, allClassrooms = false) {
        return this.apiService.getCall$<Classroom[]>(`classrooms`, {
            onlyselectedschool,
            allClassrooms
        });
    }

    getClassroom(id: number) {
        return this.apiService.getCall$<Classroom>(`classrooms/${id}`);
    }

    getCount() {
        return this.apiService.getCall$<{ classroomcount_all: number, classroomcount_selected: number }>(`classrooms/count`);
    }

    saveClassroomGroupments(classrooms) {
        return this.apiService.postCall$<Classroom>(`classrooms`, classrooms);
    }


}
