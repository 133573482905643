import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-time',
    templateUrl: './time.component.html',
    styleUrls: ['./time.component.css']
})
export class TimeComponent implements OnInit {

    public time = null;
    @Output()
    public timeChange = new EventEmitter();
    @Input('time')
    public dateTime: Date;

    constructor() {
    }

    ngOnInit() {
        if (this.dateTime != null) {
            this.dateTime = new Date(this.dateTime);

            this.time = formatDate(new Date(this.dateTime), 'HH:mm', 'nl');
        }

        this.change();
    }

    correctHour(hour) {
        return hour <= 23 && hour >= 0;
    }

    correctMinute(minute) {
        return minute <= 59 && minute >= 0;
    }

    pad(num) {
        let s = '00' + num;

        return s.substr(s.length - 2);
    }

    public changeDate(event = null) {
        if (this.dateTime == null) {
            this.dateTime = new Date();
        }

        this.dateTime.setFullYear(event.value.year());
        this.dateTime.setDate(event.value.date());
        this.dateTime.setMonth(event.value.month());

        this.timeChange.emit(this.dateTime);
    }

    change(event = null) {

        let newValue = this.time || '';

        if (event) {
            event.preventDefault();
            newValue = event.target.value;
        }

        let minutes = '-1';
        let hours = '-1';

        let splitted = newValue.trim().split(/[^0-9]/);
        if (splitted.length === 2) {
            minutes = splitted[1];
            hours = splitted[0];
        }
        if (!this.correctHour(hours) || !this.correctMinute(minutes)) {

            newValue = newValue.replace(/[^0-9]/g, '');

            hours = newValue.substr(0, 2);
            minutes = newValue.substr(2);
            if (!this.correctHour(hours)) {
                hours = newValue.substr(0, 1);
                minutes = newValue.substr(1);
            }
            if (!this.correctHour(hours)) {
                hours = '0';
                minutes = newValue;
            }
            if (!this.correctMinute(minutes)) {
                minutes = '0';
            }
        }

        let newTime = this.pad(hours) + ':' + this.pad(minutes);


        if (newTime !== this.time) {
            if (this.dateTime == null) {
                this.dateTime = new Date();
            }

            this.time = newTime;

            this.dateTime.setHours(+hours);
            this.dateTime.setMinutes(+minutes);

            this.timeChange.emit(this.dateTime);
        }

        return false;

    }

    clear() {
        this.dateTime = null;
        this.time = "";
        this.timeChange.emit(this.dateTime);
    }
}
