import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-phone-dialog',
    templateUrl: './phone-dialog.component.html',
    styleUrls: ['./phone-dialog.component.scss']
})
export class PhoneDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<PhoneDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            phoneNumber: string,
            name: string
        }) {
    }

}
