export class Absence {
    id: number;
    student_id: number;
    created_id: number;
    reason_id: number;
    duration: string;
    start_date: Date;
    end_date: Date;
    comment: string;
    approved: boolean;
    approved_at: Date;
    approved_id: number;
    approved_comment: string;
    taken_date: Date;
    created_at: Date;
    updated_at: Date;

    student_name: string;
    reason_name: string;
}
