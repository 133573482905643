<div [class.copied]="urlCopiedRecent" [class.pastcopied]="urlCopied" class="url-share">
    <h4>Kindwijs kalender URL</h4>
    <p>Dit is een persoonlijke URL. Deel deze niet met derden.</p>
    <div (click)="copyUrlShare()" class="d-flex inpholder">
        <div class="inputs">
            <div class="animated"><span>app.kindwijs.org</span>/ical/{{LocalStorage.user.calendarUrl}}</div>
            <div class="animated animate"><span>app.kindwijs.org</span>/ical/{{LocalStorage.user.calendarUrl}}</div>
        </div>
        <div class="button button--primary">{{urlCopiedRecent ? 'Gekopieerd!' : 'Kopieer URL'}}</div>
    </div>
    <small class="mt-3 d-block">
        Wordt de rest van het schooljaar automatisch bijgewerkt.<br>
        Wijzigingen worden binnen 24 uur zichtbaar in je agenda.
    </small>
</div>
