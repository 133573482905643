<div class="container mt-3">
    <div class="row justify-content-center ">
        <div class="col-md-12 absence p-4" *ngIf="form">
            <div>
                <h2 class="news__title">Absentie registreren</h2>
            </div>
            <div [formGroup]="form">
                <div class="select">
                    <ng-select [items]="students"
                               appearance="fill"
                               dropdownPosition="bottom"
                               bindLabel="name"
                               bindValue="id"
                               formControlName="student_id"
                               placeholder="Leerling">
                    </ng-select>
                    <mat-error [control]="form.get('student_id')"></mat-error>
                </div>
                <div class="row">
                    <mat-form-field class="col">
                        <mat-label>Reden</mat-label>
                        <mat-select formControlName="reason_id">
                            <mat-option [value]="reason.id" *ngFor="let reason of reasons">
                                <div class="ucfirst">{{reason.name}}</div>
                            </mat-option>
                        </mat-select>
                        <mat-error [control]="form.get('reason_id')"></mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field (click)="picker.open()" class="col">
                        <mat-label>Datum</mat-label>
                        <input [min]="currentDate" [matDatepicker]="picker" formControlName="start_date" matInput>
                        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error [control]="form.get('start_date')"></mat-error>
                    </mat-form-field>
                    <mat-form-field class="col">
                        <mat-label>Tijdsduur</mat-label>
                        <mat-select formControlName="duration">
                            <mat-option [value]="duration" *ngFor="let duration of durations">
                                <div class="ucfirst">{{duration}}</div>
                            </mat-option>
                        </mat-select>
                        <mat-error [control]="form.get('duration')"></mat-error>
                    </mat-form-field>
                    <mat-form-field (click)="pickerE.open()" class="col" *ngIf="form.get('duration')?.value === durationWithEndDate">
                        <mat-label>Einddatum</mat-label>
                        <input [min]="form.get('start_date').value" [matDatepicker]="pickerE" formControlName="end_date" matInput>
                        <mat-datepicker-toggle [for]="pickerE" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #pickerE></mat-datepicker>
                        <mat-error [control]="form.get('end_date')"></mat-error>
                        <mat-hint title="Leeg laten als einddatum nog niet bekend">Leeg laten als einddatum nog niet bekend</mat-hint>
                    </mat-form-field>
                </div>
                <mat-form-field class="w-100" *ngIf="showDurationComment">
                    <input formControlName="duration_comment" matInput placeholder="Beschrijf de tijdsperiode"
                           type="text">
                    <mat-label>Beschrijf de tijdsperiode</mat-label>
                    <mat-error [control]="form.get('duration_comment')"></mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Opmerking</mat-label>
                    <textarea formControlName="comment"
                              cdkTextareaAutosize
                              [cdkAutosizeMinRows]="2"
                              matInput type="text"
                              autocomplete="false"></textarea>
                    <mat-error [control]="form.get('comment')"></mat-error>
                </mat-form-field>
                <div *ngIf="teacher && form.get('id')?.value" class="row">
                    <mat-form-field class="col">
                        <mat-label>Opmerking goed/afkeuren</mat-label>
                        <textarea formControlName="approved_comment"
                                  cdkTextareaAutosize
                                  [cdkAutosizeMinRows]="2"
                                  matInput type="text"
                                  autocomplete="false"></textarea>
                        <mat-error [control]="form.get('approved_comment')"></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <p *ngIf="form.get('approved_at').value">
                    <b>{{form.get('approved').value ? 'Goedgekeurd' : 'Afgekeurd'}} op: {{form.get('approved_at').value | date : 'EEEE d MMMM yyyy'}}</b>
                </p>
                <p *ngIf="form.get('taken_date')?.value && form.get('approved_at')?.value && !form.get('approved')?.value">
                    <b>Absentie toch opgenomen</b>
                </p>
                <div class="d-flex justify-content-between">
                    <button *ngIf="!form.get('id')?.value" class="button--primary" (click)="save()">Indienen</button>
                    <ng-container *ngIf="!form.get('approved_at')?.value">
                        <button *ngIf="teacher && form.get('id')?.value" class="button--secondary" (click)="disapprove()">Afkeuren</button>
                        <button *ngIf="teacher && form.get('id')?.value" class="button--primary" (click)="approve()">Goedkeuren</button>
                    </ng-container>
                    <button *ngIf="!teacher && form.get('id')?.value && !form.get('end_date').value" class="button--primary" (click)="reportPresence()">Aanwezig melden</button>
                    <button class="button--secondary"
                            (click)="forced()"
                            *ngIf="teacher && !form.get('taken_date')?.value && form.get('approved_at')?.value && form.get('approved')?.value === false && !form.get('taken_at')?.value">
                        Absentie toch opgenomen
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
