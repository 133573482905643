import {Component, Input, OnInit} from '@angular/core';
import {Field} from '../classes/field.class';
import {Utils} from '../utils.class';
import {CustobType} from '../classes/custob-type.class';

@Component({
    selector: 'app-field-list',
    templateUrl: './field-list.component.html',
    styleUrls: ['field-list.component.scss']
})
export class FieldListComponent implements OnInit {

    @Input('field') field: Field;
    @Input() custobTypes: CustobType[];
    Utils = Utils;

    ngOnInit(): void {
        if (typeof this.field['children'] == 'undefined') {
            this.field['children'] = [];
        }
    }


}