import {Component, HostListener, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import SwiperCore, {
    A11y,
    Autoplay,
    Controller,
    Navigation,
    Pagination,
    Scrollbar,
    Thumbs,
    Virtual,
    Zoom,
} from 'swiper';
import {ImageViewerData} from './image-viewer-data';
import {CodaltComponent} from '../codalt.component';

import {VideoComponent} from '../video/video.component';
import {Image} from '../classes/image.class';
import {PinchZoomComponent} from '@meddv/ngx-pinch-zoom';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';

declare var cordova;

SwiperCore.use([
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller
]);

@Component({
    selector: 'app-image-viewer-dialog',
    templateUrl: './image-viewer-dialog.component.html',
    styleUrls: ['./image-viewer-dialog.component.scss']
})
export class ImageViewerDialogComponent extends CodaltComponent implements OnInit {

    @ViewChildren('pinchZoom') pinchZooms: QueryList<PinchZoomComponent>;

    @ViewChildren('video') videos: QueryList<VideoComponent>;

    secure = '';

    currentIndex;


    constructor(
        public dialogRef: DialogRef<ImageViewerDialogComponent>,
        @Inject(DIALOG_DATA) public images: ImageViewerData) {
        super();
        this.currentIndex = images.viewIndex;
        if (images.secure) {
            this.secure = `secure=${images.secure}&`;
        }
    }

    ngOnInit(): void {

        setTimeout(() => {
            this.videoAutoplay(this.images.viewIndex);
        }, 750);
    }

    onSlideChange(event) {
        const index = event[0].activeIndex;
        this.currentIndex = index;
        this.pinchZooms.forEach(pz => {
            if (pz.isZoomedIn) {
                pz.toggleZoom();
            }
        });
        this.videoAutoplay(index);
    }

    download(event) {
        event.stopPropagation();
        if (this.images.images[this.currentIndex]) {
            if ((this.images.images[this.currentIndex] as any)?.base64) {
                const image = (this.images.images[this.currentIndex] as any)?.base64;
                const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
                    const byteCharacters = atob(b64Data);
                    const byteArrays = [];

                    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                        const slice = byteCharacters.slice(offset, offset + sliceSize);

                        const byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }

                        const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }

                    const blob = new Blob(byteArrays, {type: contentType});
                    return blob;
                };
                const splitted = (image).split(',');
                const type = splitted[0].replace('data:', '').replace(';base64', '');
                const extension = type.split('/')[1];
                const fileName = (this.images.images[this.currentIndex] as any)?.name + '.' + extension;
                cordova.plugins.saveDialog.saveFile(b64toBlob((image).split(',')[1] as string, type), fileName).then(uri => {
                    console.log('The file has been successfully saved to', uri);
                }).catch(reason => {
                    console.warn(reason);
                });
            } else {
                const image = this.images.images[this.currentIndex] as Image;
                fetch(`${this.Settings.API_ENDPOINT}file/thumb?${this.secure}path=/${(image.event_id || image.article_id || image.job_id || image.chat_id)}/${image.path}&school=${this.LocalStorage?.school?.id}&access_token=${this.LocalStorage.getUserToken()}`).then(response => {
                    response.blob().then(blob => {
                        if (image['name']) {
                            const splitted = image.path.split('.');
                            const extension = splitted[splitted.length - 1];
                            image['name'] += '.' + extension;
                        }
                        cordova.plugins.saveDialog.saveFile(blob, image['name'] ?? image.path).then(uri => {
                            console.log('The file has been successfully saved to', uri);
                        }).catch(reason => {
                            console.warn(reason);
                        });
                    });
                });
            }
        }
        if (this.images.videos[(this.currentIndex - (this.images.images.length))]) {
            const video = this.images.videos[(this.currentIndex - (this.images.images.length))];
            fetch(`${this.Settings.API_ENDPOINT}file/thumb?${this.secure}path=/${(video.event_id || video.article_id || video.job_id || video.chat_id)}/${video.path}&school=${this.LocalStorage?.school?.id}&access_token=${this.LocalStorage.getUserToken()}`).then(response => {
                response.blob().then(blob => {
                    if (video['name']) {
                        const splitted = video.path.split('.');
                        const extension = splitted[splitted.length - 1];
                        video['name'] += '.' + extension;
                    }
                    cordova.plugins.saveDialog.saveFile(blob, video['name'] ?? video.path).then(uri => {
                        console.log('The file has been successfully saved to', uri);
                    }).catch(reason => {
                        console.warn(reason);
                    });
                });
            });
        }
    }

    private videoAutoplay(cIndex: number) {
        const video = this.videos.find(v => v.video === this.images.videos[cIndex - this.images.images?.length]);
        this.videos.forEach(vid => {
            vid.stop();
        });
        if (video) {
            video.play();
        }
    }

    @HostListener('click', ['$event'])
    public click(event) {
        if (event.target.localName !== 'img' && event.target.localName !== 'video'
            && event.srcElement.className.indexOf('swiper-button') === -1
            && event.srcElement.className.indexOf('pz-zoom-button') === -1) {
            this.dialogRef.close();
        }
    }
}

