<div class="container white">
    <ng-container *ngIf="LocalStorage.user?.students?.length && question">


        <div *ngIf="form" class="row justify-content-center">
            <div class="col">
                <h3>{{question.name}}</h3>
                <div [innerHTML]="question.description | nl2br" class="pb-4"></div>
                <mat-accordion multi>
                    <mat-expansion-panel *ngFor="let level of question.options">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{level.name}}
                            </mat-panel-title>
                            <mat-panel-description>
                                {{level.intro}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div [innerHTML]="level.description | nl2br">

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div *ngIf="!isNew">
                    <h4 class="mt-4">Uw voorkeuren</h4>
                    <p>
                        Voor al uw kinderen zijn de privacyvoorkeuren opgegeven.
                    </p>
                </div>
                <div *ngIf="isNew">
                    <h4 class="mt-4">Wilt u de voorkeuren per kind aangeven?</h4>
                    <p>
                        <mat-radio-group [formControl]="form.controls.allSame">
                            <mat-radio-button [value]="true">Voor al mijn kinderen aangeven</mat-radio-button>
                            <mat-radio-button [value]="false">Per kind aangeven</mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="form.controls.allSame.touched" [control]="form.controls.allSame"></mat-error>
                    </p>
                    <ng-container *ngIf="form.controls.allSame.value">
                        <h4 class="mt-3">Welk niveau kiest u?</h4>
                        <p>
                            <mat-radio-group [formControl]="form.controls.allSamePreference">
                                <mat-radio-button *ngFor="let level of question.options"
                                                  [value]="level.name">
                                    {{level.name}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </p>
                    </ng-container>
                </div>
                <div>
                    <ng-container *ngIf="form.controls.allSame.value === false">
                        <ng-container *ngFor="let studentPref of form.controls.students.controls">
                            <h4 class="mt-3 mb-1">
                                {{isNew ? 'Welk niveau kiest u voor ' : 'Gekozen voorkeur voor '}}
                                {{(LocalStorage.user.students | byId : studentPref.controls.studentId?.value)?.name}}
                                {{isNew ? '?' : ''}}
                            </h4>
                            <h6 (click)="showHistory(studentPref.controls.studentId?.value)"
                                *ngIf="!isNew && ((LocalStorage.user.students | byId : studentPref.controls.studentId?.value)?.permissions | byId : question.id : 'permission_question_id')">
                                Aangegeven op
                                {{((LocalStorage.user.students | byId : studentPref.controls.studentId?.value)?.permissions | byId : question.id : 'permission_question_id')?.created_at | date:'EEEE d MMMM yyyy HH:mm'}}
                                uur door
                                {{((LocalStorage.user.students | byId : studentPref.controls.studentId?.value)?.permissions | byId : question.id : 'permission_question_id')?.user.name}}
                                <i class="fas fa-history"></i>
                            </h6>
                            <p>
                                <mat-radio-group [formControl]="studentPref.controls.preference">
                                    <mat-radio-button *ngFor="let level of question.options"
                                                      [value]="level.name">
                                        {{level.name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="studentPref.controls.preference.touched" [control]="studentPref.controls.preference"></mat-error>
                            </p>
                        </ng-container>
                    </ng-container>

                    <div class="mb-3" *ngIf="!form.disabled">
                        <mat-checkbox value="true" [formControl]="form.controls.iKnow">
                            Ik heb kennis genomen van de verschillende niveaus en begrijp de verschillen
                        </mat-checkbox>
                        <mat-error *ngIf="form.controls.iKnow.touched" [control]="form.controls.iKnow"></mat-error>
                    </div>

                    <button (click)="save()" [disabled]="saving"
                            *ngIf="!form.disabled"
                            class="button--primary mb-3"
                            color="primary">
                        {{isNew ? 'Voorkeuren opslaan' : 'Mijn voorkeuren aanpassen'}}
                        <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
                    </button>
                </div>

                <p class="mt-3">
                    Wilt u meer weten over hoe Kindwijs omgaat met de privacy van u en uw kinderen?
                    <a href="https://kindwijs.org/over-ons/privacy" target="_BLANK">Lees dan de Privacyverklaring op onze website.</a>
                </p>
                <button routerLink="/{{Routenames.myPrivacy}}"
                        class="button--secondary mt-3">
                    Terug naar alle toestemmingen
                </button>
            </div>
        </div>
    </ng-container>
</div>
