import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {CodaltComponent} from '../codalt.component';
import {Article} from '../classes/article.class';
import {ArticleReply} from '../classes/article-reply';
import {ArticleReplyLike} from '../classes/article-reply-like';
import {ReplyService} from '../services/reply.service';
import {LocalStorage} from '../storage.class';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-replies',
    templateUrl: './replies.component.html',
    styleUrls: ['./replies.component.scss']
})
export class RepliesComponent extends CodaltComponent implements OnChanges {

    viewRepliesCount = 10;

    @Input()
    title = '';

    @Input() article: Article;

    replies: ArticleReply[];

    fcReply = new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(550)
    ]);

    voted = new Map<number, ArticleReplyLike>();

    sending = false;

    mayNotLike = true;

    hasNotReplied = false;

    constructor(protected replyService: ReplyService,
                private confirmDialog: ConfirmDialogService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.article) {
            this.getReplies();
        }
    }

    private getReplies() {
        this.subscriptions.add(this.replyService.getArticleReplies(this.article.id).subscribe(replies => {
            this.replies = replies.data.replies;
            this.mayNotLike = replies.data.mayNotLike;
            this.hasNotReplied = !this.replies.find(r => r.user_id === LocalStorage.user.id);
            replies.data.userLikes.forEach(ul => {
                this.voted.set(ul.reply_id, ul);
            });
        }));
    }

    delete(reply: ArticleReply) {
        this.confirmDialog.confirm('Verwijderen',
            `Weet je zeker dat je de reactie van ${reply.user_name} wilt verwijderen?`, 'Ja', 'Nee').then(() => {
            this.replyService.deleteArticleReply(reply.id).subscribe(result => {
                this.replies.splice(this.replies.indexOf(reply), 1);
            }, error => {
                this.confirmDialog.confirm('Fout bij verwijderen',
                    error.message, 'Sluiten', null).then(() => {
                });
            });
        }, () => {
        });
    }

    sendEnter(event, parentReply?: ArticleReply) {
        if (event.ctrlKey && event.key === 'Enter') {
            this.sendReply(parentReply);
        }
    }

    sendReply(parentReply?: ArticleReply) {
        if (this.fcReply.valid && !this.sending) {
            this.sending = true;
            this.subscriptions.add(this.replyService.sendArticleReply(
                LocalStorage.user.id,
                this.article.id,
                this.fcReply.value,
                parentReply?.id
            ).subscribe(reply => {
                if (parentReply) {
                    parentReply.sub_replies = [...(parentReply.sub_replies ?? []), reply.data];
                    parentReply.viewRepliesCount = parentReply.sub_replies.length;
                } else {
                    this.replies = [...this.replies, reply.data];
                    this.viewRepliesCount = this.replies.length;
                }
                this.hasNotReplied = false;
                this.fcReply.reset();
                this.sending = false;
            }, () => {
                this.sending = false;
            }));
        }
    }

    vote(reply: ArticleReply, positive: boolean) {
        this.subscriptions.add(this.replyService.voteArticleReply(reply.id, positive).subscribe(result => {
            reply.likes = result.data.reply.likes;
            this.voted.set(result.data.like.reply_id, result.data.like);
        }));
    }
}
