import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {PermissionService} from '../services/permission.service';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PermissionQuestion, PermissionQuestionOption} from '../classes/permission-question';
import {Utils} from '../utils.class';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {ClassroomService} from '../services/classroom/classroom.service';
import {combineLatest} from 'rxjs';
import {Classroom} from '../classes/classroom.class';
import {ActivatedRoute, Router} from '@angular/router';
import {Routenames} from '../route-names.enum';

@Component({
    selector: 'app-permission-questions-edit',
    templateUrl: './permission-questions-edit.component.html',
    styleUrls: ['./permission-questions-edit.component.scss']
})
export class PermissionQuestionsEditComponent extends CodaltComponent implements OnInit {

    currentDate = new Date();
    form: UntypedFormGroup;
    saving = false;
    classrooms: Classroom[];
    classroomGroups: string[] = [];

    question: PermissionQuestion;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private confirmDialogService: ConfirmDialogService,
                private classroomService: ClassroomService,
                private permissionService: PermissionService) {
        super();
    }

    ngOnInit(): void {
        this.route.params.subscribe((params: { id: number }) => {
            const classrooms$ = this.classroomService.getClassrooms();
            const questions$ = this.permissionService.getPermissionQuestion(+params.id);

            this.subscriptions.add(combineLatest([classrooms$, questions$])
                .subscribe(([classrooms, question]) => {
                    this.classrooms = classrooms.data;
                    if (this.classrooms.length > 3) {
                        this.classroomGroups = [...new Set(this.classrooms.filter(p => !!p.groupment).map(p => p.groupment))];
                    }
                    this.question = question.data;
                    this.createForm(this.question);
                }));
        });
    }

    createForm(question?: PermissionQuestion) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        let classrooms: (string | number)[] = question?.classrooms?.length ? question?.classrooms?.map(c => c?.id) : [null];

        classrooms = this.appendWithGroupments(classrooms);

        const options = new UntypedFormArray([]);
        const fg = new UntypedFormGroup({
            id: new UntypedFormControl(question?.id),
            name: new UntypedFormControl(question?.name, [Validators.required, Validators.maxLength(255)]),
            description: new UntypedFormControl(question?.description, Validators.required),
            start_date: new UntypedFormControl(question?.start_date || tomorrow, Validators.required),
            deadline_date: new UntypedFormControl(question?.deadline_date ?? null),
            end_date: new UntypedFormControl(question?.end_date ?? null),
            classrooms: new UntypedFormControl(classrooms),
            show_news: new UntypedFormControl(question?.show_news),
            options
        });
        question?.options?.forEach(option => {
            this.addOption(fg.get('options') as any, option);
        });
        this.form = fg;

        if ((question?.start_date && this.currentDate > new Date(question.start_date))) {
            fg.disable();
            fg.get('deadline_date').enable();
            fg.get('end_date').enable();
        }
        this.subscriptions.add(fg.get('classrooms').valueChanges.subscribe(newClassrooms => {
            const selectedNew = JSON.stringify(newClassrooms);
            const prevClassrooms = fg.value.classrooms;
            if (prevClassrooms?.indexOf(null) === -1 && newClassrooms.indexOf(null) !== -1 && newClassrooms.length > 1) {
                newClassrooms = [];
                fg.get('classrooms').setValue(newClassrooms);
            }
            if (newClassrooms.length > 1 && newClassrooms.indexOf(null) !== -1) {
                newClassrooms = newClassrooms.filter(a => a !== null);
            }
            // GROUPMENT => Handle new groups
            newClassrooms.forEach(nc => {
                if (('' + nc).substr(0, 6) === 'group_') {
                    if (prevClassrooms.indexOf(nc) === -1) {
                        newClassrooms.push(
                            ...this.classrooms
                                .filter(c => newClassrooms.indexOf(c.id) === -1)
                                .filter(c => c.groupment === nc.substr(6)).map(c => c.id)
                        );
                    }
                }
            });
            // GROUPMENT => Handle removed groups
            prevClassrooms?.forEach(pc => {
                if (('' + pc).substr(0, 6) === 'group_' && newClassrooms.indexOf(pc) === -1) {
                    const toBeRemovedClassrooms = this.classrooms.filter(c => c.groupment === pc.substr(6)).map(c => c.id);
                    const canBeRemovedClassrooms = newClassrooms.filter(nc => toBeRemovedClassrooms.indexOf(nc) !== -1);
                    if (toBeRemovedClassrooms.length === canBeRemovedClassrooms.length) {
                        newClassrooms = newClassrooms.filter(nc => toBeRemovedClassrooms.indexOf(nc) === -1);
                    }
                }
            });
            // GROUPMENT => Check whether full group is checked
            newClassrooms = this.appendWithGroupments(newClassrooms);
            if (selectedNew !== JSON.stringify(newClassrooms)) {
                fg.get('classrooms').setValue(newClassrooms);
            }
        }));
    }


    private appendWithGroupments(classrooms: (string | number)[]) {
        this.classroomGroups.forEach(clg => {
            const allGroupedClassrooms: (string | number)[] = this.classrooms.filter(c => c.groupment === clg).map(c => c.id);
            const selectedGroupedClassrooms = classrooms.filter(nc => allGroupedClassrooms.indexOf(nc) !== -1);
            if (allGroupedClassrooms.length === selectedGroupedClassrooms.length) {
                if (classrooms.filter(p => ('' + p).substr(6) === clg).length === 0) {
                    classrooms.push('group_' + clg);
                }
            } else {
                classrooms = classrooms.filter(a => ('' + a).substr(6) !== clg);
            }
        });
        return classrooms;
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            Object.assign(this.question, this.form.getRawValue());
            this.subscriptions.add(this.permissionService.savePermissionQuestion([this.question]).subscribe((questions) => {
                this.form.markAsPristine();
                this.router.navigateByUrl(`/${Routenames.permissions}`);
            }));
        }
    }

    removeOption(fg, optionIndex: number) {
        fg.removeAt(optionIndex);
    }

    reorderOptions(fg: AbstractControl, event: CdkDragDrop<string[]>) {
        const options = fg.get('options').value;
        const movedOption = options[event.previousIndex];
        options.splice(event.previousIndex, 1);
        options.splice(event.currentIndex, 0, movedOption);
    }

    addOption(fg, option?: PermissionQuestionOption) {
        fg.push(new UntypedFormGroup({
            name: new UntypedFormControl(option?.name),
            intro: new UntypedFormControl(option?.intro),
            shortIntro: new UntypedFormControl(option?.shortIntro),
            description: new UntypedFormControl(option?.description)
        }));
        event.preventDefault();
    }

}
