<div class="container mt-2 item-edit">
    <div class="row justify-content-center">
        <div class="col-md-12 remain p-4">
            <div>
                <h2 class="news__title">Overblijven registreren</h2>
                <h4>
                    {{today | date:'EEEE d MMMM yyyy'}}
                </h4>
            </div>
            <div *ngIf="form && !defaultPayments?.length">
                <a routerLink="/{{Routenames.defaultPayments}}">Maak eerst een standaardbetaling aan van het type overblijven.</a>
            </div>
            <div *ngIf="form && defaultPayments?.length">
                <form [formGroup]="form">
                    <mat-form-field (click)="picker.open()"
                                    appearance="fill"
                                    class="w-100"
                                    *ngIf="AuthorisationService.hasFeature('remainChangeHistory')">
                        <mat-label>Datum</mat-label>
                        <input [matDatepicker]="picker"
                               [formControl]="fc.date"
                               [matDatepickerFilter]="filterDates"
                               matInput>
                        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Type</mat-label>
                        <mat-select [formControl]="fc.defaultPaymentId">
                            <mat-option *ngFor="let defaultPayment of defaultPayments" [value]="defaultPayment.id">
                                {{defaultPayment.name}} {{defaultPayment.price | currency : 'EUR'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="fc.defaultPaymentId?.value">
                        <mat-form-field class="w-100">
                            <mat-label>Zoeken...</mat-label>
                            <input matInput [formControl]="searchControl">
                        </mat-form-field>
                        <mat-selection-list [formControl]="fc.studentIdsTemp" (selectionChange)="studentSelectionChange($event)">
                            <mat-list-option *ngFor="let student of students | search:'name':searchControl.value"
                                             [value]="student.id">
                                {{student.name}}
                            </mat-list-option>
                        </mat-selection-list>
                    </ng-container>
                </form>
            </div>

        </div>
    </div>
    <div class="floating-buttons">
        <button (click)="save()" [disabled]="saving" color="primary" mat-fab>
            <mat-icon [class.fa-spin]="saving" class="fas fa-utensils"></mat-icon>
        </button>
    </div>
</div>
