import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../../services/payment.service';
import {Routenames} from '../../../route-names.enum';
import {ApiErrorResponse} from '../../../services/api/api.service';
import {Transaction} from '../../../classes/transaction';
import {CordovaService} from '../../../services/cordova.service';

@Component({
    selector: 'app-payment-check',
    templateUrl: './payment-check.component.html',
    styleUrls: ['./payment-check.component.scss']
})
export class PaymentCheckComponent extends CodaltComponent implements OnInit {

    public error: ApiErrorResponse;
    public transaction: Transaction;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private cordovaService: CordovaService,
                private paymentService: PaymentService) {
        super();
    }

    ngOnInit(): void {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigateByUrl(`/${Routenames.transactionsOverview}`);
        });

        this.subscriptions.add(this.route.params.subscribe(params => {
            this.paymentService.checkPayment(params['id']).subscribe(() => {
                if (params['eventId']) {
                    const route = [Routenames.afterSchoolOffer, Routenames.pay, params['eventId']];
                    if (params['amount']) {
                        route.push({amount: +params['amount']});
                    }
                    this.router.navigate(route);
                } else if (params['paymentRequestId']) {
                    const route = [Routenames.paymentRequests, Routenames.pay, params['paymentRequestId']];
                    if (params['amount']) {
                        route.push({amount: +params['amount']});
                    }
                    this.router.navigate(route);
                } else {
                    this.paymentService.getOwnPaymentRequests().subscribe(payments => {
                        if (payments.data.length < 1) {
                            this.router.navigate([Routenames.transactionsOverview]);
                        } else if (payments.data.length === 1) {
                            const payment = payments.data[0];
                            this.router.navigate([Routenames.paymentRequests, Routenames.pay, payment.id]);
                        } else {
                            this.router.navigate([Routenames.paymentRequests]);
                        }
                    });
                }
            }, error => {
                this.error = error;
                this.transaction = error.data;
            });
        }));
    }

}
