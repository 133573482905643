<div class="d-flex justify-content-between position-sticky" [style.top.px]="0">
    <button class="button" (click)="getData()">Log herladen</button>
    <button class="button" *ngIf="authorisationLog" (click)="scrollTo('authlog')">Authentication log</button>
    <button class="button" *ngIf="authorisationLog" (click)="scrollTo('authorlog')">Authorisation log</button>
    <button class="button" *ngIf="pushLog" (click)="scrollTo('pushlog')">Firebase / push log</button>
    <button class="button" *ngIf="appLog" (click)="scrollTo('applog')">App log</button>
</div>

<h2 id="authlog">Authentication log</h2>
<table class="table table-striped">
    <thead>
    <tr>
        <th (click)="sortList('type')" class="type">
            Type
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="type"></app-sorting-icon>
        </th>
        <th (click)="sortList('ip')" class="ip">
            IP
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="ip"></app-sorting-icon>
        </th>
        <th (click)="sortList('browser')" class="browser">
            Browser
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="browser"></app-sorting-icon>
        </th>
        <th (click)="sortList('created_at')" class="date">
            Datum
            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="created_at"></app-sorting-icon>
        </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let log of list">
        <tr>
            <td>
                {{log.type}}
            </td>
            <td>
                {{log.ip}}
            </td>
            <td>
                {{log.browser}}
            </td>
            <td>
                {{log.created_at | date:'d MMM yyyy HH:mm:ss'}}
            </td>
        </tr>
        <tr *ngIf="log.data">
            <td colspan="4">
                <i>{{log.data}}</i>
            </td>
        </tr>
    </ng-container>
    </tbody>
</table>

<ng-container *ngIf="authorisationLog">
    <h2 id="authorlog">Authorisation log</h2>
    <table class="table table-striped">
        <thead>
        <tr>
            <th class="type">
                Browser
            </th>
            <th class="ip">
                IP
            </th>
            <th class="browser">
                Method / URL
            </th>
            <th class="browser">
                Succes
            </th>
            <th class="date">
                Datum
            </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let log of authorisationLog">
            <tr>
                <td [matTooltip]="log.browser">
                    {{log.browser?.substr(0, 40)}}
                </td>
                <td>
                    {{log.ip}}
                </td>
                <td>
                    {{log.method}} {{log.url}}
                </td>
                <td>
                    {{log.success}}
                </td>
                <td>
                    {{log.created_at | date:'d MMM yyyy HH:mm:ss'}}
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</ng-container>

<ng-container *ngIf="pushLog">
    <h2 id="pushlog">Push log</h2>
    <table class="table table-striped">
        <thead>
        <tr>
            <th class="type">
                Type
            </th>
            <th class="ip">
                Token
            </th>
            <th class="browser">
                Titel
            </th>
            <th class="date">
                Datum
            </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let log of pushLog">
            <tr>
                <td>
                    {{log.type}}
                </td>
                <td [matTooltip]="log.token">
                    {{log.token?.substring(0, 15)}}
                </td>
                <td>
                    <a routerLink="/{{Routenames.events}}/{{log.item_id}}" *ngIf="log.type == 'kalender'">
                        {{log.event_title}}
                    </a>
                    <a routerLink="/{{Routenames.articles}}/{{log.item_id}}" *ngIf="log.type == 'nieuws'">
                        {{log.article_title}}
                    </a>
                    - {{log.school_name}}
                </td>
                <td>
                    {{log.created_at | date:'d MMM yyyy HH:mm:ss'}}
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</ng-container>

<ng-container *ngIf="appLog">
    <h2 id="applog">App log</h2>
    <table class="table table-striped">
        <thead>
        <tr>
            <th class="type">
                Browser
            </th>
            <th class="ip">
                IP
            </th>
            <th>
                Token
            </th>
            <th class="version">
                Version
            </th>
            <th class="date">
                Datum
            </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let log of appLog">
            <tr>
                <td [matTooltip]="log.browser">
                    {{log.browser?.substr(0, 40)}}
                </td>
                <td>
                    {{log.ip}}
                </td>
                <td matTooltip="Dubbelklik voor kopie" (dblclick)="navigator.clipboard.writeText(log.token)">
                    {{log.token?.substring(0, 20)}}
                </td>
                <td title="{{log.app_version?.version}}, {{log.app_version?.commit}}, {{log.app_version?.datetime| date:'d MMM yyyy HH:mm:ss'}}">
                    {{log.app_version?.version}} - {{log.app_version?.datetime| date:'d MMM yyyy HH:mm'}}
                </td>
                <td>
                    {{log.created_at | date:'d MMM yyyy HH:mm:ss'}}
                </td>
            </tr>
            <tr>
                <td colspan="5" [innerHTML]="log.exception | nl2br">
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</ng-container>
