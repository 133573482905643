import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-edit-button',
    templateUrl: './edit-button.component.html',
    styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent {

    @Input() tooltip = null;
    @Input() destinationUrl = null;
    @Input() iconClass = 'fa-pencil-alt';
    @Input() index = 1;
    @Input() right = 15;
    @Input() pending = false;

    constructor() {
    }

}
