import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {FileItem} from '../../file-manager/file-selection-dialog/FileItem';

@Injectable()
export class FileService {

    static fileIconMap = new Map<string, string>([
        ['document', 'fa-file'],
        ['image', 'fa-file-image'],
        ['video', 'fa-films'],
        ['bmp', 'fa-file-image'],
        ['gif', 'fa-file-image'],
        ['jpg', 'fa-file-image'],
        ['png', 'fa-file-image'],
        ['image/png', 'fa-file-image'],
        ['image/jpeg', 'fa-file-image'],
        ['ods', 'fa-file-excel'],
        ['xls', 'fa-file-excel'],
        ['xlsx', 'fa-file-excel'],
        ['doc', 'fa-file-word'],
        ['docx', 'fa-file-word'],
        ['zip', 'fa-file-archive'],
        ['', 'fa-file'],
        [null, 'fa-file'],
        ['undefined', 'fa-file'],
        ['pdf', 'fa-file-pdf'],
        ['application/pdf', 'fa-file-pdf'],
        ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'fa-file-excel'],
        ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'fa-file-word'],
        ['application/x-zip-compressed', 'fa-file-archive']
    ]);

    constructor(private apiService: ApiService) {
    }

    read(dir?: string) {
        return this.apiService.getCall$<FileItem[]>(`file/read`, {dir});
    }

    createFolder(name: string, path?: string) {
        return this.apiService.postCall$(`file/create-folder`, {name, path});
    }

    delete(path) {
        return this.apiService.deleteCall$('file', {path});
    }

    rotateImage(path?: string, root?: boolean, secure?: string) {
        if (path.substr(0, 7) === '/media/') {
            path = path.substr(6);
        }
        if (root) {
            return this.apiService.getCall$<FileItem>(`file/rotate-image`, {path, root, secure});
        } else {
            return this.apiService.getCall$<FileItem>(`file/rotate-image`, {path, secure});
        }
    }
}

export enum FileType {
    video = 'video', image = 'image', document = 'document'
}
