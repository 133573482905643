<div class="container white">
    <h1>{{classroom?.classroom_name}}</h1>

    <table class="table table-striped">
        <tr>
            <th colspan="2">
                Klas
            </th>
        </tr>
        <tr>
            <td width="300">Klasnaam</td>
            <td>{{classroom?.classroom_name}}</td>
        </tr>
        <tr>
            <td>Groepering</td>
            <td>{{classroom?.groupment}}</td>
        </tr>
        <tr>
            <td>Aangemaakt op</td>
            <td>
                {{classroom?.created_at | date:'d-MM-yyyy HH:mm'}}
            </td>
        </tr>
        <tr>
            <td>Laatst gewijzigd</td>
            <td>
                {{classroom?.updated_at | date:'d-MM-yyyy HH:mm'}}
            </td>
        </tr>
        <tr>
            <td>Leraren</td>
            <td>
                <i [hidden]="classroom?.users?.length > 0">Er zijn geen leraren gekoppeld aan deze klas</i>
                <div *ngFor="let user of classroom?.users">
                    <b><a routerLink="/{{Routenames.parnassysUsers}}/{{user.id}}">{{user.name}}</a></b><br>
                </div>
            </td>
        </tr>
        <tr>
            <td>Leerlingen</td>
            <td>
                <i [hidden]="classroom?.students?.length > 0">Er zitten geen leerlingen in deze klas</i>
                <div *ngFor="let student of classroom?.students">
                    <b><a routerLink="/{{Routenames.parnassysStudent}}/{{student.id}}">{{student.name}}</a></b><br>
                </div>
            </td>
        </tr>
    </table>

    <a routerLink="/{{Routenames.parnassysClassrooms}}">&laquo; Terug naar alle klassen</a>
    <br><br>
</div>
