<ng-container *ngIf="hasStructures">
    <div class="container-fluid space-for-sidemodal">
        <div class="row">
            <div class="container">

                <div class="txtcenter">
                    <button (click)="addStructure(0)" class="add-button" color="primary" mat-button>
                        Element invoegen
                    </button>
                </div>

                <div #blocksContainer
                     [(dragulaModel)]="elements"
                     [class.dragging]="dragging"
                     class="structures"
                     dragula="structures">

                    <div *ngFor="let element of elements;let containerIndex = index;"
                         [attr.container]="element.id"
                         [ngClass]="{'new':element.objecttype==null}"
                         class="page-container">
                        <div class="name">- {{element.name}} -</div>

                        <div *ngIf="!element.objecttype" class="new">
                            Nieuw element
                        </div>


                        <div class="content">
                            <div class="title">
                                <div (click)="removeStructure(null, containerIndex)" class="extended curpoint">
                                    <i class="fa fa-trash fa-lg"></i>
                                </div>
                                <div (click)="forceSelectElement(element)" class="extended curpoint">
                                    <i class="fa fa-pencil-alt fa-lg"></i>
                                </div>
                            </div>
                            <div [class.downlighted]="editingStructure && editingStructure.id != element.id">
                                <app-element-preview
                                        *ngIf="element.objecttype"
                                        [content]="values"
                                        [elementHolderLastChanged]="elementHolderLastChanged"
                                        [elementHolder]="elementHolder"
                                        [struct]="element">
                                </app-element-preview>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                        <div class="txtcenter">
                            <button (click)="addStructure(containerIndex+1)" class="add-button" color="primary"
                                    mat-button>
                                Element invoegen
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <div *ngIf="dragging"
         [style.right.px]="Settings.sidemodalWidth + 35"
         class="infosave">
        <b>Sortering afgerond?</b><br>
        Gebruik de opslaan knop om verder te gaan met het bewerken van de pagina.
    </div>
    <app-edit-button (click)="create(editingStructure);stopSort()"
                     *ngIf="dragging"
                     [right]="Settings.sidemodalWidth + 35"
                     iconClass="fa fa-save"
                     tooltip="Opslaan"></app-edit-button>
    <app-edit-button (click)="startSort()"
                     *ngIf="!dragging"
                     [right]="Settings.sidemodalWidth + 35"
                     iconClass="fa fa-sort"
                     tooltip="Verander sortering"></app-edit-button>
    <!--<div (resizeEnd)="onResizeEnd($event)"
         [@sideModelContent]="editingStructure?.name"
         [enableGhostResize]="true"
         [resizeEdges]="{ left: true }"
         [style.width.px]="Settings.sidemodalWidth"
         class="sidemodal"
         mwlResizable >-->
    <div
            [@sideModelContent]="editingStructure?.name"
            [style.width.px]="Settings.sidemodalWidth"
            class="sidemodal"
    >
        <mat-toolbar color="primary">
            <ng-container *ngIf="!editingStructure">
                Scroll om een element te bewerken
            </ng-container>
            <ng-container *ngIf="editingStructure">
                {{editingStructure.name}} bewerken
            </ng-container>
        </mat-toolbar>
        <div class="tab">
            <div *ngIf="editingStructure && editingStructure.objecttype && structures && getStructureByIdentifier(editingStructure.objecttype) && values[editingStructure.id]">
                <mat-form-field class="w100">
                    <input [(ngModel)]="editingStructure.name" matInput placeholder="Naam" required type="text">
                </mat-form-field>
                <app-fields-edit [fields]="getStructureByIdentifier(editingStructure.objecttype).fields"
                                 [values]="values[editingStructure.id]"></app-fields-edit>
            </div>
        </div>

        <div *ngIf="editingStructure" class="actionbuttons">
            <button (click)="cancelChanges()" [disabled]="disableCancelChanges()" class="add-button" color="warn"
                    mat-button type="submit">annuleren
            </button>
            <div class="spacer"></div>
            <button (click)="create(editingStructure)" class="add-button" color="primary" mat-button type="submit">
                publiceren
            </button>
        </div>
    </div>
</ng-container>
