import {Component, OnInit} from '@angular/core';
import {FormService} from '../../services/form/form.service';
import {ActivatedRoute} from '@angular/router';
import {CodaltComponent} from '../../codalt.component';
import {combineLatest} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {startWith} from 'rxjs/operators';
import {Form} from '../../classes/form.class';

@Component({
    selector: 'app-form-history-detail',
    templateUrl: './form-history-detail.component.html',
    styleUrls: ['./form-history-detail.component.scss']
})
export class FormHistoryDetailComponent extends CodaltComponent implements OnInit {

    item: Form;
    formFields = [];
    formData = {};
    LocalStorage = LocalStorage;

    constructor(private route: ActivatedRoute,
                private formService: FormService) {
        super();
    }

    ngOnInit() {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school && !this.item) {
                this.formService.getFormsHistoryItem(params['id']).subscribe(item => {
                    this.item = item.data;
                    this.formData = this.item.data;
                    for (var prop in this.formData['saveData']) {
                        // skip loop if the property is from prototype
                        if (!this.formData['saveData'].hasOwnProperty(prop)) continue;

                        this.formFields.push(prop);
                    }
                });
            }
        }));
    }

}
