<div class="container white">
    <h2>Groepen</h2>

    <div>
        <mat-radio-group [(ngModel)]="onlythisschool">
            <mat-radio-button [value]="false">
                Toon {{classroomcount_all}} groepen uit alle scholen
            </mat-radio-button>
            <br>
            <mat-radio-button [value]="true">
                Toon {{classroomcount_selected}} groepen uit {{LocalStorage?.school?.name}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="row">
        <div class="col">
            <table class="table table-striped table-fixed">
                <thead>
                <tr>
                    <th>Naam</th>
                    <th>
                        Groepering
                        <small (click)="autoGroupment()"
                               class="curpoint float-right"
                               matTooltip="Vult alleen groepen zonder ingestelde groepering">
                            Automatisch instellen
                        </small>
                    </th>
                    <th>
                        Kleur
                    </th>
                    <th>School</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let classroom of classrooms">
                    <td (click)="editClassroom(classroom.id)" class="curpoint">{{classroom.classroom_name}}</td>
                    <td>
                        <ng-select (change)="saveGroupment()"
                                   [(ngModel)]="classroom.groupment"
                                   [addTag]="addTag"
                                   notFoundText="Je hebt nog geen groepen gemaakt"
                                   [items]="uniqueGroupments"
                                   [loading]="saving || saved"
                                   addTagText="Groepering toevoegen"
                                   bindValue="value">
                            <ng-template ng-loadingspinner-tmp>
                                <i *ngIf="saving" class="fas fa-spinner fa-spin"></i>
                                <i *ngIf="saved && !saving" class="fas fa-check"></i>
                            </ng-template>
                        </ng-select>
                    </td>
                    <td>
                        <ng-select (change)="saveColor()" class="color-picker"
                                   [(ngModel)]="classroom.color"
                                   [searchable]="false"
                                   [items]="colors"
                                   [clearable]="false"
                                   [loading]="savingColor || savedColor"
                                   addTagText="Groepering toevoegen"
                                   bindValue="value">
                            <ng-template ng-loadingspinner-tmp>
                                <i *ngIf="savingColor" class="fas fa-spinner fa-spin"></i>
                                <i *ngIf="savedColor && !savingColor" class="fas fa-check"></i>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <div class="color" [style.background]="item"></div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <div class="color" [style.background]="item"></div>
                            </ng-template>
                        </ng-select>
                    </td>
                    <td>{{classroom.school.name}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
