<div class="container mt-3">
    <div class="row justify-content-center">
        <div class="col-24 col-lg-18 col-xl-16">
            <div class="container bg-white mt-2 item-edit">
                <div>
                    <h2 class="news__title">Standaardbetaling {{paymentDefault?.id ? 'wijzigen' : 'toevoegen'}}</h2>
                </div>
                <div *ngIf="form">
                    <form [formGroup]="form">
                        <div class="row">
                            <mat-form-field class="col-auto flex-grow-1 mt-3">
                                <mat-label>Titel</mat-label>
                                <input [formControl]="fc.name" matInput type="text">
                                <mat-error [control]="fc.name"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-auto flex-grow-1">
                                <mat-label>Prijs</mat-label>
                                <input [formControl]="fc.price"
                                       appDecimalInput
                                       [allowNegative]="false"
                                       decimalInput="2" matInput type="text">
                                <mat-error [control]="fc.price"></mat-error>
                            </mat-form-field>
                            <div class="col-auto align-items-center d-flex" *ngIf="!fc.low_permissions.value">
                                <mat-checkbox [formControl]="fc.price_lower" class="mr-2">
                                    Mag lager zijn
                                </mat-checkbox>
                                <mat-checkbox [formControl]="fc.price_higher">
                                    Mag hoger zijn
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto align-items-center">
                                <mat-checkbox [formControl]="fc.low_permissions" class="mr-2">
                                    Overblijven
                                </mat-checkbox>
                            </div>
                        </div>

                    </form>
                </div>
                <div class="floating-buttons">
                    <button (click)="save()" [disabled]="saving" color="primary" mat-fab>
                        <mat-icon *ngIf="!saving" class="fas fa-save"></mat-icon>
                        <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
                    </button>
                </div>
            </div>
            <div class="container bg-white mt-2 item-edit" *ngIf="transactions">
                <div>
                    <h2 class="news__title">Betalingen</h2>
                </div>
                <div class="transactions">
                    <div class="transaction" *ngFor="let transaction of transactions">
                        <div class="icon">
                            <mat-icon mat-list-icon>euro</mat-icon>
                        </div>
                        <div class="info">
                            <div>
                                Betaling voor <b class="curpoint" routerLink="/{{Routenames.parnassysStudent}}/{{transaction.payed_for?.id}}">{{transaction.payed_for?.name}}</b>
                                door <b class="curpoint" routerLink="/{{Routenames.parnassys}}/{{Routenames.users}}/{{transaction.payed_by?.id}}">{{transaction.payed_by?.name}}</b>
                            </div>
                            <div class="date">{{transaction.updated_at | date}}</div>
                        </div>
                        <div class="amount">
                            <i class="fas fa-coins mr-3"
                               matTooltip="Contant betaald"
                               *ngIf="transaction.cash"></i>
                            <span [class.add]="transaction.amount > 0">
                            {{-transaction.amount | currency : 'EUR'}}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
