<ng-container *ngFor="let field of fields; let ii = index;">
    <div class="field">
        <div class="row">
            <div class="col">
                <button (click)="Utils.removePage(field, fields)" class="btn btn-danger flright" type="button">
                    <i aria-hidden="true" class="fa fa-trash"></i>
                </button>
                <button (click)="Utils.downPage(field, fields)" [hidden]="Utils.isLastInList(field, fields)"
                        class="btn btn-default flright">
                    <i aria-hidden="true" class="fa fa-arrow-down"></i>
                </button>
                <button (click)="Utils.upPage(field, fields)" [hidden]="Utils.isFirstInList(field, fields)"
                        class="btn btn-default flright">
                    <i aria-hidden="true" class="fa fa-arrow-up"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="w100">
                    <mat-select [(value)]="field.type">
                        <mat-option *ngFor="let fieldType of fieldTypes" [value]="fieldType.value">
                            {{fieldType.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


            </div>
        </div>
        <div class="row">
            <div class="col">
                <div *ngIf="field.type=='custob'" class="form-group">
                    <label class="col" for="field-{{ii}}-type_custob_type">Custom type</label>
                    <div class="col">
                        <select [(ngModel)]="field.type_custob_type" class="form-control"
                                id="field-{{ii}}-type_custob_type" name="field">
                            <option value="">Geen</option>
                            <option *ngFor="let custobType of custobTypes"
                                    [value]="custobType.id">{{custobType.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">

                        <mat-form-field class="w100">
                            <input [(ngModel)]="field.name" id="field-{{ii}}-name" matInput placeholder="Naam"
                                   type="text"/>
                        </mat-form-field>

                    </div>
                    <div class="col">
                        <mat-form-field class="w100">
                            <input [(ngModel)]="field.id" id="field-{{ii}}-identifier" matInput placeholder="Identifier"
                                   type="text"/>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngIf="noDefaultValuesTypes.indexOf(field.type) === -1" class="col">
                <mat-form-field class="w100">
                    <input [(ngModel)]="field.default" matInput placeholder="Standaard waarde" type="text"/>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div *ngIf="field.type == 'fieldlist'" class="col">
                <app-field-list [custobTypes]="custobTypes" [field]="field"></app-field-list>
            </div>
        </div>

        <div *ngIf="field.type == 'structure-form'" class="row">
            <div class="col">
                <app-form [structcontentParent]="field" structcontentParentIdentifier="form"></app-form>
            </div>
        </div>
        <ng-container
                *ngIf="field.type == 'options-radio' || field.type == 'options-dropdown' || field.type == 'options-checkbox'">
            <div class="row">
                <div class="col">
                    <div>Opties</div>
                    <mat-checkbox [(ngModel)]="field.customvalue">Aangepaste waarde</mat-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <ng-container *ngFor="let option of field.options; let i = index">

                        <div class="row">
                            <div class="col" style="line-height: 34px;">
                                <label for="fieldName">Optie {{i + 1}}</label>
                            </div>
                            <div class="col">
                                <div *ngIf="field.type == 'options-radio' || field.type == 'options-dropdown'"
                                     class="radio">
                                    <label>
                                        <input (change)="handleRadioButtonChange(option, field.options)"
                                               [checked]="option.selected" [value]="1"
                                               name="{{field.id}}"
                                               type="radio">
                                        Standaard geselecteerd
                                    </label>
                                </div>
                                <div *ngIf="field.type == 'options-checkbox'" class="checkbox">
                                    <label>
                                        <input (change)="handleCheckboxChange(option, $event)"
                                               [checked]="option.selected"
                                               name="{{field.id}}"
                                               type="checkbox">
                                        Standaard geselecteerd
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <button (click)="Utils.removePage(option, field.options)" class="btn btn-danger flright"
                                        type="button">
                                    <i aria-hidden="true" class="fa fa-trash"></i>
                                </button>
                                <button (click)="Utils.downPage(option, field.options)"
                                        [hidden]="Utils.isLastInList(option, field.options)"
                                        class="btn btn-default flright">
                                    <i aria-hidden="true" class="fa fa-arrow-down"></i>
                                </button>
                                <button (click)="Utils.upPage(option, field.options)"
                                        [hidden]="Utils.isFirstInList(option, field.options)"
                                        class="btn btn-default flright">
                                    <i aria-hidden="true" class="fa fa-arrow-up"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div [class.col]="!field.customvalue"
                                         [class.col]="field.customvalue">
                                        <mat-form-field class="w100">
                                            <input [(ngModel)]="option.name" matInput placeholder="Optie {{i+1}}"
                                                   required type="text"/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field *ngIf="field.customvalue" class="w100">
                                            <input [(ngModel)]="option.value" matInput
                                                   placeholder="Optie {{i+1}} waarde" required type="text"/>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button (click)="addOption(field)" class="procent100 btn btn-primary" type="button">
                        Optie toevoegen
                    </button>
                </div>
            </div>
        </ng-container>

        <div *ngIf="field.type === 'infotext'" class="row">
            <div class="col">
                <input [(ngModel)]="field.title" id="field-{{ii}}-title" matInput placeholder="Titel" type="text"/>
                <simple-tiny [content]="field"
                             [elementId]="field.id"
                             propertyName="text"
                             ngDefaultControl
                ></simple-tiny>
                <br>
            </div>
        </div>
    </div>

</ng-container>
<div class="row">
    <div class="col txtcenter">
        <button (click)="addField()" class="add-button" mat-button>Veld toevoegen</button>
        <br>
    </div>
</div>
