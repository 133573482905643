import {Component, Input, OnInit} from '@angular/core';
import {Article} from '../classes/article.class';
import {Event} from '../classes/event.class';
import {Job} from '../classes/job.class';
import {EventarticlejobService} from '../services/eventarticlejob.service';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ArticleService} from '../services/article.service';
import {JobService} from '../services/job.service';
import {EventService} from '../services/event.service';
import {SurveyService} from '../services/survey.service';
import {Routenames} from '../route-names.enum';
import {CodaltComponent} from '../codalt.component';
import {Utils} from '../utils.class';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {requiredConditionalFn} from '../validators/required-conditional-fn.validator';

@Component({
    selector: 'app-survey-answer',
    templateUrl: './survey-answer.component.html',
    styleUrls: ['./survey-answer.component.scss']
})
export class SurveyAnswerComponent extends CodaltComponent implements OnInit {

    closed = false;

    typeArticle = false;
    typeEvent = false;
    typeJob = false;
    typeName = '';
    submitting = false;
    changing = false;
    @Input() typeObj: Article | Event | Job;
    typeService: EventarticlejobService;
    faQuestions = new UntypedFormArray([], Validators.required);
    fc = {};
    form: UntypedFormGroup;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private articleService: ArticleService,
                private jobService: JobService,
                private eventService: EventService,
                private surveyService: SurveyService,
                private confirmDialogService: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        const firstPart = this.route.snapshot.url[0].path;
        this.typeName = firstPart;
        if (firstPart === Routenames.articles) {
            this.typeArticle = true;
            this.typeService = this.articleService;
        } else if (firstPart === Routenames.events) {
            this.typeEvent = true;
            this.typeService = this.eventService;
        } else if (firstPart === Routenames.jobs) {
            this.typeJob = true;
            this.typeService = this.jobService;
        }

        if (this.typeObj.survey) {
            if (this.typeObj.survey.end_date) {
                this.closed = new Date().getTime() > new Date(this.typeObj.survey.end_date).getTime();
            }
            this.typeObj.survey.questions.forEach(q => {
                const answer = this.typeObj.survey.answer?.answers[q.hash];
                if (q.multiple && q.type === 'options') {
                    this.fc[q.hash] = new UntypedFormGroup(q.options.reduce(function (map, option) {
                        map[option] = new UntypedFormControl(answer ? answer[option] : null);
                        return map;
                    }, {}), requiredConditionalFn(() => !!q.required));
                } else {
                    this.fc[q.hash] = new UntypedFormControl(answer, requiredConditionalFn(() => !!q.required));

                }
                if (q.type === 'boolean-quantity') {
                    if (q.disabledAnswer) {
                        this.fc[q.hash].setValue('Nee');
                    }
                    this.fc[`${q.hash}-quantity`] = new UntypedFormControl(
                        this.typeObj.survey.answer?.answers[`${q.hash}-quantity`], requiredConditionalFn(() => this.form?.get(q.hash)?.value === 'Ja')
                    );
                }
            });
            this.form = new UntypedFormGroup(this.fc);
            if (!this.typeObj.survey.answer) {
                this.changing = true;
            }
            this.uncheckDisabled();
        }
    }

    clearAnswers() {
        this.confirmDialogService.confirm(
            'Antwoorden verwijderen?',
            'Weet je zeker dat je de antwoorden op deze enquête wilt verwijderen?',
            'Verwijderen', 'Behouden').then(() => {
            this.surveyService.deleteAnswers(this.typeObj.survey.id).subscribe(() => {
                this.typeObj.survey.answer = null;
                this.changing = true;
                this.form.reset();
            });
        }, () => {
        });
    }

    submit() {
        Utils.triggerValidation(this.form);
        this.form.markAsDirty();
        if (this.form.valid) {
            this.submitting = true;
            this.subscriptions.add(this.surveyService.submit(this.typeObj.survey.id, this.form.value).subscribe((data) => {
                this.submitting = false;
                this.changing = false;
                this.typeObj.survey.answer = data.data;
            }, (error) => {
                Object.assign(this.typeObj.survey, error.data);
                this.uncheckDisabled();
                this.confirmDialogService.confirm(
                    'Gekozen optie is niet meer beschikbaar',
                    'De door jou gekozen optie bij de vraag ' + error.message +
                    ' is niet meer beschikbaar ' +
                    'omdat iemand in de tussentijd voor deze optie heeft gekozen. ' +
                    'Kies een andere optie.',
                    'Sluiten', null).then(() => {

                }, () => {
                });
                this.submitting = false;
            }));
        }
    }

    uncheckDisabled() {
        this.typeObj.survey.questions.filter(q => !!q.unique && q.type === 'options').forEach(question => {
            const fc = this.form.get(question.hash);
            const myAnswer = fc.value;
            if (myAnswer) {
                if (typeof myAnswer === 'object') {
                    Object.keys(myAnswer).forEach(myA => {
                        if (question.disabledAnswers?.indexOf(myA) !== -1) {
                            myAnswer[myA] = null;
                            fc.setValue(myAnswer);
                        }
                    });
                } else {
                    if (question.disabledAnswers?.indexOf(myAnswer) !== -1) {
                        fc.setValue(null);
                    }
                }
            }
        });
    }
}
