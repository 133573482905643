<section *ngIf="event" class="detail">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-24 col-sm-20 col-md-16">
                <app-codalt-image [path]="event.id + '/' + event.image"
                                  [thumb]="event.image_thumb"
                                  class="detail__image"
                                  secureType="event">
                </app-codalt-image>
                <div class="detail__block">
                    <h1 class="detail__title">
                        {{event.title}}
                    </h1>
                    <div class="detail__details">
                        <div class="detail__author">
                            {{event.user.name}}
                            <ng-container *ngFor="let cl of event.groupedClassrooms; let last = last; let first = first;">
                                {{first ? ' | ' : ''}} {{cl}}{{last ? '' : ','}}
                            </ng-container>
                            @if (event.editable) {
                                <ng-container *ngFor="let cl of event.schools; let last = last; let first = first;">
                                    {{first ? ' | ' : ''}} {{cl.name}}{{last ? '' : ','}}
                                </ng-container>
                            }
                        </div>
                        <time *ngFor="let range of event.dateranges" class="detail__date" datetime="2020-04-22" pubdate="pubdate">
                            <ng-container *ngIf="range.all_day && (range.start_date | date:'yMd') != (range.end_date | date:'yMd')">
                                {{range.start_date | date:'EEEE d MMMM yyyy'}} -
                                {{range.end_date | date:'EEEE d MMMM yyyy'}}
                            </ng-container>
                            <ng-container *ngIf="range.all_day && (range.start_date | date:'yMd') == (range.end_date | date:'yMd')">
                                {{range.start_date | date:'EEEE d MMMM yyyy'}}
                            </ng-container>
                            <ng-container *ngIf="!range.all_day">
                                <ng-container *ngIf="(range.start_date | date:'yyMd') == (range.end_date | date:'yyMd')">
                                    {{range.start_date | date:'EEEE d MMMM yyyy, HH:mm'}} -
                                    {{range.end_date | date:'HH:mm'}}
                                </ng-container>
                                <ng-container *ngIf="(range.start_date | date:'yyMd') != (range.end_date | date:'yyMd')">
                                    {{range.start_date | date:'EEEE d MMMM yyyy HH:mm'}} -
                                    {{range.end_date | date:'EEEE d MMMM yyyy HH:mm'}}
                                </ng-container>
                            </ng-container>
                        </time>
                    </div>
                    <div class="content">
                        <p [innerHTML]="event.content"></p>
                    </div>

                    <div class="item-images">
                        <app-codalt-image (click)="openImage(image)"
                                          *ngFor="let image of event.images"
                                          [path]="event.id + '/' + image.path"
                                          [thumb]="image.thumb"
                                          secureType="event">
                        </app-codalt-image>
                    </div>
                    <div class="item-documents" *ngIf="event.documents?.length">
                        <b>Bijlage: </b>
                        <table>
                            <tr (click)="openDocument(document)" *ngFor="let document of event.documents">
                                <td>
                                    <i class="fas fa-file-pdf-o"></i>
                                </td>
                                <td>
                                    {{document.path}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <app-survey-answer [typeObj]="event"></app-survey-answer>
                </div>
            </div>
            <div *ngIf="event.readBy" class="col-24 col-sm-20 col-md-16 col-lg-8 mt-4 mt-lg-0">
                <div class="detail__block readby">
                    <h3 class="mb-2">Gelezen ({{event.readBy.length || '0'}})</h3>
                    <div *ngFor="let user of event.readBy">
                        {{user.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="event.school?.type === 'offer' && LocalStorage?.school?.type === 'offer'">
            <div class="col-24 col-sm-20 col-md-16">
                <mat-tab-group [selectedIndex]="eventStarted ? 0 : 1">
                    <mat-tab label="Aanwezigheid">
                        <div class="detail__block">
                            <div class="content">
                                <app-event-presence [event]="event"></app-event-presence>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Inschrijvingen">
                        <div class="detail__block">
                            <div class="content">
                                <table class="table table-striped table-last-col">
                                    <tr>
                                        <th>
                                            Naam
                                        </th>
                                        <th>
                                            Telefoonnummer
                                        </th>
                                        <th>
                                            Opmerking
                                        </th>
                                        <th>

                                        </th>
                                    </tr>

                                    @for (subscription of event.all_subscriptions; track subscription.id) {
                                        <tr [class.deleted]="subscription.deleted_at">
                                            <td  matTooltip="Uitgeschreven" [matTooltipDisabled]="!subscription.deleted_at" matTooltipPosition="before">
                                                <i class="fa-duotone fa-money-bill-1" matTooltip="Terugbetaald" *ngIf="subscription.refund_transaction_id"></i> {{subscription.student.name}}
                                            </td>
                                            <td>
                                                <a class="curpoint" (click)="phoneService.call(subscription.phone)">{{subscription.phone}}</a>
                                            </td>
                                            <td [innerHTML]="subscription.comment | nl2br">

                                            </td>
                                            <td>
                                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                                    <mat-icon class="fa-solid fa-ellipsis-vertical"></mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item (click)="unsubscribe(subscription)" [disabled]="subscription.deleted_at">Uitschrijven</button>
                                                    <button mat-menu-item (click)="unsubscribeWithRefund(subscription)" [disabled]="subscription.refund_transaction_id || subscription.deleted_at">Uitschrijven en Terugbetalen</button>
                                                    <button mat-menu-item (click)="refund(subscription)" [disabled]="subscription.refund_transaction_id">{{subscription.deleted_at ? 'Terugbetalen' : 'Alleen terugbetalen'}}
                                                    </button>
                                                </mat-menu>
                                            </td>
                                        </tr>
                                    }
                                    @if (!event.all_subscriptions?.length) {
                                        <tr>
                                            <td colspan="4">
                                                Er zijn nog geen inschrijvingen op dit aanbod
                                            </td>
                                        </tr>
                                    }
                                </table>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div class="row justify-content-center mt-5" *ngIf="event?.school?.type === 'offer' && LocalStorage?.school?.type !== 'offer'">
            <div class="col-24 col-sm-20 col-md-16">

                <app-event-subscribe [event]="event"></app-event-subscribe>

            </div>
        </div>
    </div>

    <div *ngIf="event.editable && LocalStorage.school.id === event.school_id" class="floating-buttons">
        <button color="primary"
                class="drop-up-menu-trigger"
                mat-fab
                [matMenuTriggerFor]="aboveMenu"
                (menuOpened)="dropUpOpen = true"
                (menuClosed)="dropUpOpen = false">
            <mat-icon class="fas fa-chevron-up" [class.fa-rotate-180]="dropUpOpen"></mat-icon>
        </button>
        <mat-menu #aboveMenu="matMenu" yPosition="above" class="drop-up-menu">
            <button (click)="delete()" color="primary" mat-fab>
                <mat-icon class="fas fa-trash"></mat-icon>
            </button>
            <button color="primary" mat-fab routerLink="/{{Routenames.events}}/{{event.slug}}/vragenlijst-antwoorden"
                    matTooltip="Poll / vragenlijst voor dit artikel">
                <mat-icon class="fas fa-question"></mat-icon>
            </button>
            <button color="primary" mat-fab routerLink="/{{Routenames.events}}/{{event.slug}}/kopie">
                <mat-icon class="fas fa-copy"></mat-icon>
            </button>
            <button color="primary" mat-fab routerLink="/{{Routenames.events}}/{{event.slug}}/bewerken">
                <mat-icon class="fas fa-pencil-alt"></mat-icon>
            </button>
        </mat-menu>
    </div>

    <div *ngIf="event.editable && LocalStorage.school.id !== event.school_id" class="floating-buttons">
        <button color="primary"
                class="drop-up-menu-trigger"
                (click)="switchToSchool()"
                matTooltipPosition="before"
                matTooltip="Open de school waar dit item in geplaatst is"
                mat-fab>
            <mat-icon class="fas fa-school"></mat-icon>
        </button>
    </div>
</section>
