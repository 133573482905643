import {Pipe, PipeTransform} from '@angular/core';
import {Permission} from '../classes/permission';

@Pipe({
    name: 'filterPermissionsPipe'
})
export class FilterPermissionsPipePipe implements PipeTransform {

    transform(permissions: Permission[], classroomId?: number, answer?: string, inClassroomIds?: number[]): Permission[] {
        let result = permissions;
        if (classroomId) {
            result = result.filter(p => !!p.student.classrooms.find(c => c.id === classroomId));
        }
        if (answer) {
            result = result.filter(p => p.answer === answer);
        }
        if (inClassroomIds?.filter(c => c !== null)?.length) {
            result = result.filter(p => {
                return p.student.classrooms.filter(c => inClassroomIds.includes(c.id) && c.id === classroomId)?.length > 0;
            });
        }
        return result;
    }

}
