import {Injectable} from '@angular/core';
import {ApiService} from "./api/api.service";

declare let FB: any;

@Injectable()
export class FacebookService {

    name: string;
    url: string;
    clientId = '1072439079801266';

    constructor(public apiService: ApiService) {
        this.name = 'facebook';
        this.url = 'https://connect.facebook.net/en_US/sdk.js';

        this.initialize();
    }

    hasFacebook() {
        return this.apiService.getCall(`facebook`);
    }

    sendAuth(socials: Object) {
        return this.apiService.postCall(`facebook`, socials);
    }

    loadScript(onload: any): void {
        if (document.getElementById(this.name)) {
            return;
        }
        const signInJS = document.createElement('script');
        signInJS.async = true;
        signInJS.src = this.url;
        signInJS.onload = onload;
        document.head.appendChild(signInJS);
    }

    initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.loadScript(() => {
                FB.init({
                    appId: this.clientId,
                    autoLogAppEvents: true,
                    cookie: true,
                    xfbml: true,
                    version: 'v6.0'
                });
            });
        });
    }

    drawUser(response: any): any {
        return response;
    }

    signIn(): Promise<any> {
        return new Promise((resolve, reject) => {
            FB.login((response: any) => {
                if (response.authResponse) {
                    const accessToken = FB.getAuthResponse()['accessToken'];
                    FB.api('/me?fields=name,email,picture,accounts', (res: any) => {
                        resolve(this.drawUser(Object.assign({}, {token: accessToken}, res)));
                    });
                }
            }, {scope: 'email,public_profile,manage_pages,publish_pages'});
        });
    }

    signOut(): Promise<void> {
        return new Promise((resolve, reject) => {
            FB.logout((response: any) => {
                resolve();
            });
        });
    }


}
