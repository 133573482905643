<div class="container bg-white mt-2 item-edit" *ngIf="question">
    <div>
        <h2 class="news__title">Toestemmingen</h2>
        <h4 class="text-capitalize"></h4>
    </div>
    <div *ngIf="form">
        <form>
            <div>
                <div class="row  question" *ngIf="form" [formGroup]="form">
                    <div class="col-1 move-question">
                        <div>Vraag {{question.order + 1}}</div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <h3>Vraag {{question.order + 1}} - {{form.get('name').value}}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <mat-form-field (click)="pickerS.open()" class="col">
                                <mat-label>Begindatum</mat-label>
                                <input [min]="currentDate" [matDatepicker]="pickerS" formControlName="start_date" [max]="form.get('end_date')?.value" matInput>
                                <mat-datepicker-toggle [for]="pickerS" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #pickerS></mat-datepicker>
                                <mat-error [control]="form.get('start_date')"></mat-error>
                            </mat-form-field>
                            <mat-form-field (click)="pickerU.open()" class="col">
                                <mat-label>Uiterste invuldatum</mat-label>
                                <input [min]="form.get('start_date')?.value" [matDatepicker]="pickerU" formControlName="deadline_date" [max]="form.get('deadline_date')?.value" matInput>
                                <mat-datepicker-toggle [for]="pickerU" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #pickerU></mat-datepicker>
                                <mat-error [control]="form.get('deadline_date')"></mat-error>
                                <mat-hint>
                                    Leeg laten om deze vraag altijd invulbaar te houden
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field (click)="pickerE.open()" class="col">
                                <mat-label>Einddatum</mat-label>
                                <input [min]="form.get('start_date')?.value" [matDatepicker]="pickerE" formControlName="end_date"  matInput>
                                <mat-datepicker-toggle [for]="pickerE" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #pickerE></mat-datepicker>
                                <mat-error [control]="form.get('end_date')"></mat-error>
                                <mat-hint>
                                    Leeg laten om deze vraag altijd beschikbaar te houden
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col">
                                <mat-label>Vraag - Waar dient toestemming voor te worden gegeven?</mat-label>
                                <input formControlName="name" matInput type="text" autocomplete="false">
                                <mat-error [control]="form.get('name')"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col">
                                <mat-label>Beschrijving - Omschrijf waarvoor deze toestemming gegeven moet worden</mat-label>
                                <textarea formControlName="description" cdkTextareaAutosize [cdkAutosizeMinRows]="2" matInput type="text" autocomplete="false"></textarea>
                                <mat-error [control]="form.get('description')"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-checkbox formControlName="show_news" class="mb-2">
                                    Tonen bij nieuws bewerken
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row" *ngIf="classrooms?.length">
                            <div class="col">
                                <b>Voor groepen: </b>
                                <mat-selection-list *ngIf="classrooms" class="classroom-list" formControlName="classrooms">
                                    <mat-list-option *ngIf="AuthorisationService.hasFeature('publicArticles')" [value]="null" checkboxPosition="before">
                                        Schoolbreed
                                    </mat-list-option>
                                    <mat-list-option *ngFor="let classroomGroup of classroomGroups" [value]="'group_' + classroomGroup" checkboxPosition="before">
                                        {{classroomGroup}}
                                    </mat-list-option>
                                    <mat-list-option *ngFor="let classroom of classrooms" [value]="classroom.id" checkboxPosition="before">
                                        {{classroom.classroom_name}}
                                    </mat-list-option>
                                </mat-selection-list>
                                <mat-error [control]="form.get('classrooms')"></mat-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-24 col-md">
                                <h3>Keuzeopties vraag {{question.order + 1}}</h3>
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th class="text-center" width="50">Sortering</th>
                                        <th>Optie</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody (cdkDropListDropped)="reorderOptions(form, $event)" cdkDropList>
                                    <tr *ngFor="let option of form.get('options')['controls']; index as oi" cdkDrag [formGroup]="option">
                                        <td cdkDragHandle class="text-center curmove">
                                            <i class="fa fa-arrows-alt"></i>
                                        </td>
                                        <td>
                                            <mat-form-field class="col">
                                                <mat-label>Naam - Kort maar omschrijvend voor deze optie</mat-label>
                                                <input formControlName="name" matInput type="text" autocomplete="false">
                                                <mat-error [control]="option.get('name')"></mat-error>
                                            </mat-form-field>
                                            <mat-form-field class="col">
                                                <mat-label>Intro</mat-label>
                                                <input formControlName="intro" matInput type="text" autocomplete="false">
                                                <mat-error [control]="option.get('intro')"></mat-error>
                                            </mat-form-field>
                                            <mat-form-field class="col">
                                                <mat-label>Uitgebreide beschrijving van optie</mat-label>
                                                <textarea formControlName="description" matInput type="text" autocomplete="false"></textarea>
                                                <mat-error [control]="option.get('description')"></mat-error>
                                            </mat-form-field>
                                        </td>
                                        <td class="text-center curpoint" (click)="removeOption(form.get('options'), oi)" *ngIf="!form.get('options').disabled">
                                            <i class="fa fa-trash"></i>
                                        </td>
                                        <td class="text-center" *ngIf="form.get('options').disabled"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" class="text-center curpoint" (click)="addOption(form.get('options'))">
                                            Optie toevoegen
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </div>
    <div class="floating-buttons">
        <button (click)="save()" [disabled]="saving" color="primary" mat-fab>
            <mat-icon *ngIf="!saving" class="fas fa-save"></mat-icon>
            <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
        </button>
    </div>
</div>
