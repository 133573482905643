import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {UserService} from '../../services/user/user.service';
import {DiscussionService} from '../../services/discussion.service';
import {combineLatest, Subscription} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {startWith} from 'rxjs/operators';
import {Discussion} from '../../classes/discussion.class';
import {ActivatedRoute, Router} from '@angular/router';
import {Discussiontime} from '../../classes/discussiontime.class';
import {Student} from '../../classes/student.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-discussion-subscribe',
    templateUrl: './discussion-subscribe.component.html',
    styleUrls: ['./discussion-subscribe.component.scss']
})
export class DiscussionSubscribeComponent extends CodaltComponent implements OnInit {

    subscriptionEnded = false;
    discussion: Discussion;
    discussionId: number;
    loading = false;
    subscriptions = new Subscription();
    studentTimes = new Map<number, Discussiontime[]>();
    studentClassroomTimes = new Map<string, Discussiontime[]>();
    studentAvailableTimes = new Map<string, Discussiontime[]>();
    selectValue = new Map<string, Discussiontime>();
    students: Student[] = [];

    constructor(private studentService: UserService,
                private route: ActivatedRoute,
                private router: Router,
                private confirmDialogService: ConfirmDialogService,
                private discussionService: DiscussionService) {
        super();
    }

    ngOnInit() {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (!this.loading && LocalStorage.school && (!this.discussion || (this.discussion && this.discussion.id != params['id']))) {
                this.loading = true;
                this.discussionId = +params['id'];
                this.getDiscussion();
            } else if (this.discussion) {
                LocalStorage.updateSelectedSchoolIfWrong(this.discussion.school_id);
            }
        }));
    }

    delete() {
        this.confirmDialogService.confirm('Verwijderen',
            'Wil je deze ouderavond verwijderen?',
            'Verwijderen', 'Behouden').then(() => {
            this.discussionService.delete(this.discussion.id).subscribe(() => {
                this.router.navigate([this.Routenames.discussions]);
            });
        });
    }

    unsubscribe(discussiontime: Discussiontime, student: Student) {
        this.confirmDialogService.confirm('Uitschrijven ouderavond',
            'U bent nu ingeschreven voor de ouderavond van ' + student.name + ' op '
            + formatDate(discussiontime.datetime, 'EEEE d MMMM yyyy H:mm', 'nl') + '. ' +
            'Wilt u zich hiervoor uitschrijven?',
            'Uitschrijven', 'Annuleren').then(() => {
            discussiontime.user_id = null;
            discussiontime.student_id = null;
            this.discussionService.updateTime(discussiontime).subscribe(result => {
                this.getDiscussion();
            }, error => {
                this.confirmDialogService.confirm('Inschrijven mislukt', error.message, 'Oke', null);
                this.getDiscussion();
            });
        }, () => {

        });
    };

    save(discussiontime: Discussiontime, student: Student) {
        const confirm = () => {
            this.confirmDialogService.confirm('Inschrijving ouderavond',
                'U bent nu ingeschreven voor de ouderavond van ' + student.name + ' op '
                + formatDate(discussiontime.datetime, 'EEEE d MMMM yyyy H:mm', 'nl') + '. ' +
                'Deze afspraak wordt ' + (discussiontime.private ? 'niet' : 'wel') + ' getoond aan andere verzorgers van ' + student.name + '.',
                'Oke', null);
            this.getDiscussion();
        };

        const apply = (current?: Discussiontime) => {
            discussiontime.student_id = student.id;
            discussiontime.user_id = LocalStorage.user.id;
            this.discussionService.updateTime(discussiontime).subscribe(result => {
                if (current) {
                    current.user_id = null;
                    current.student_id = null;
                    this.discussionService.updateTime(current).subscribe(result => {
                        confirm();
                    }, error => {
                        this.confirmDialogService.confirm('Uitschrijven mislukt', error.message, 'Oke', null);
                        this.getDiscussion();
                    });
                } else {
                    confirm();
                }
            }, error => {
                this.confirmDialogService.confirm('Inschrijven mislukt', error.message, 'Oke', null);
                this.getDiscussion();
            });
        };

        const currents = this.discussion.discussiontimes.filter(dt =>
            dt.student_id === student.id &&
            dt.classroom_id === discussiontime.classroom_id &&
            dt.user_id === LocalStorage.user.id);
        if (currents && currents.length > 0 && currents[0].datetime.toString() !== discussiontime.datetime.toString()) {
            const current = currents[0];
            this.confirmDialogService.confirm('Inschrijving aanpassen',
                'Je bent nu ingeschreven op ' + formatDate(current.datetime, 'd MMM H:mm', 'nl') + '.',
                'Wijzigen naar ' + formatDate(discussiontime.datetime, 'd MMM H:mm', 'nl'),
                formatDate(current.datetime, 'd MMM HH:mm', 'nl') + ' behouden')
                .then(() => apply(current), () => {
                });
        } else {
            apply();
        }
    }

    discussionTimeSearch(term: string, item: Discussiontime) {
        const dateString = formatDate(item.datetime, 'EEEE d MMMM yyyy HH:mm', 'nl').toLowerCase();
        term = term.toLocaleLowerCase();
        return dateString.indexOf(term) !== -1;
    }

    private getDiscussion() {
        this.discussionService.get(this.discussionId).subscribe(response => {
            this.discussion = response.data;
            this.subscriptionEnded = new Date(this.discussion.subscription_end) < new Date();
            LocalStorage.updateSelectedSchoolIfWrong(this.discussion.school_id);

            this.students = LocalStorage.user?.students.filter(s => s.classrooms.find(c => c.school_id === this.discussion.school_id));

            const lockedThisUser = this.discussion.discussiontimes.filter(
                p => p.user_id === LocalStorage.user.id
            ).map(p => new Date(p.datetime).getTime());

            LocalStorage.user.students.forEach(student => {
                this.studentTimes.set(
                    student.id,
                    this.discussion.discussiontimes.filter(discussiontime =>
                        discussiontime.student_id === student.id));

                student.classrooms?.forEach(classroom => {

                    this.studentClassroomTimes.set(
                        student.id + '_' + classroom.id,
                        this.discussion.discussiontimes.filter(discussiontime =>
                            discussiontime.student_id === student.id &&
                            discussiontime.classroom_id === classroom.id));

                    this.selectValue.set(
                        student.id + '_' + classroom.id + '_' + LocalStorage.user.id,
                        this.discussion.discussiontimes.find(discussiontime =>
                            discussiontime.student_id === student.id &&
                            discussiontime.classroom_id === classroom.id &&
                            discussiontime.user_id === LocalStorage.user.id));

                    this.studentAvailableTimes.set(
                        student.id + '_' + classroom.id,
                        this.discussion.discussiontimes.filter(discussiontime =>
                            discussiontime.student_id === null &&
                            discussiontime.classroom_id === classroom.id &&
                            lockedThisUser.indexOf(new Date(discussiontime.datetime).getTime()) === -1 &&
                            !discussiontime.unavailable
                        )
                    );
                });
            });
        });
    }
}
