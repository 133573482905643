import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LoginFormState} from '../login-form-state.enum';
import {Utils} from '../../utils.class';
import {CodaltComponent} from '../../codalt.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends CodaltComponent implements OnInit {

    LoginFormState = LoginFormState;
    subscriptions = new Subscription();

    loginFormState: LoginFormState = LoginFormState.FillIn;
    errorMessage = null;

    loginForm = new UntypedFormGroup({
        email: new UntypedFormControl(AuthenticationService.filledInEmail, [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.required])
    });

    constructor(private authService: AuthenticationService,
                private router: Router) {
        super();
    }

    ngOnInit() {
        this.subscriptions.add(this.loginForm.get('email').valueChanges.subscribe((val: string) => {
            if (val.indexOf(' ') !== -1) {
                this.loginForm.get('email').setValue(val.replace(' ', ''));
            }
        }));
    }

    ngOnDestroy() {
        AuthenticationService.filledInEmail = this.loginForm.get('email').value;
        this.subscriptions.unsubscribe();
    }

    login() {
        Utils.triggerValidation(this.loginForm);
        if (this.loginForm.valid) {
            this.errorMessage = null;
            this.loginFormState = LoginFormState.Login;
            this.authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(
                authResponse => {
                    this.router.navigate(['']);
                }, failReason => {
                    this.errorMessage = failReason.message;
                    if (!this.errorMessage) {
                        this.errorMessage = 'Kon de server niet bereiken';
                    }
                    this.loginFormState = LoginFormState.FillIn;
                });
        }
    }

    forgotPassword() {
        this.router.navigate(['wachtwoord-vergeten']);
        return false;
    }

}
