import {User} from './user.class';
import {Video} from './video.class';

export class ChatMessage {
    id: number;
    message: string;
    user_id: number;
    chat_id: number;
    user: User;
    updated_at: Date;
    created_at: Date;
    deleted_at: Date;

    video: Video;
}
