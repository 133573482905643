<div class="container white">
    <h2>Gebruikers</h2>
    <div class="row">
        <div class="col-18">
            <table class="table table-hover table-add-row table-last-col">
                <thead>
                <tr>
                    <th (click)="sortList('name')">
                        Naam
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="name"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('email')">
                        E-mailadres
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="email"></app-sorting-icon>
                    </th>
                    <th>
                        Rollen
                    </th>
                    <th>

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of list"
                    [class.curpoint]="AuthorisationService.hasFeature(editFeatureName, user)"
                    routerLink="/gebruikers/{{user.id}}">
                    <td>
                        {{user.name}}
                    </td>
                    <td>
                        {{user.email}}
                    </td>
                    <td>
                        <span *ngFor="let role of user.roles; let last = last">
                            {{role.name}}{{last ? '' : ', '}}
                        </span>
                    </td>
                    <td>
                        <button (click)="deleteUser($event, user)" color="warn" mat-icon-button><mat-icon
                            class="fa fa-trash"></mat-icon>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <button *ngIf="AuthorisationService.hasFeature(editFeatureName)"
                                class="w-100" color="primary" mat-button routerLink="/gebruikers/new">
                            Een gebruiker toevoegen <i class="fas fa-plus"></i>
                        </button>
                    </td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row ">
        <div class="col-12">
            <div class="d-flex">
                <button class="button mr-2" routerLink="/{{Routenames.parnassysUsers}}">Terug</button>
                <button *ngIf="AuthorisationService.hasFeature(authFeatureName)" color="primary" class="button--primary" routerLink="/auth/roles">
                    Autorisatie
                </button>
            </div>
        </div>
    </div>
</div>


