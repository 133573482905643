import {Component, Input, OnInit} from '@angular/core';
import {LocalStorage} from '../storage.class';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
    selector: 'app-url-share',
    templateUrl: './url-share.component.html',
    styleUrls: ['./url-share.component.scss']
})
export class UrlShareComponent implements OnInit {

    @Input()
    urlFriendlyCalendarUrl = '';

    urlCopied = false;
    urlCopiedRecent = false;
    LocalStorage = LocalStorage;

    constructor(private clipboard: Clipboard) {
    }

    ngOnInit(): void {
    }

    copyUrlShare() {
        if (this.clipboard.copy('https://app.kindwijs.org/ical/' + LocalStorage.user.calendarUrl)) {
            this.urlCopiedRecent = this.urlCopied = true;
            setTimeout(() => {
                this.urlCopiedRecent = false;
            }, 5000);
        }
    }
}
