import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Structure} from '../../classes/structure.class';
import {LocalStorage} from '../../storage.class';
import {StructureService} from '../../services/structure/structure.service';
import {Settings} from '../../settings.class';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-element-create',
    templateUrl: './element-create.component.html',
    styleUrls: ['./element-create.component.scss']
})
export class ElementCreateComponent implements OnInit {

    @ViewChild('editModal', {static: true}) editModal: ModalDirective;
    @Output() savePage = new EventEmitter<Structure>();

    element: Structure = {
        id: 'test',
        name: 'example',
        type: 'type',
        objecttype: '', children: []
    } as Structure;
    public settings = Settings;
    origExtended: Structure;
    childList: Array<Object>;
    content: Object;
    storage = LocalStorage;
    @Input() structures: Structure[];
    structcontent: {};
    LocalStorage = LocalStorage;

    constructor(private structureService: StructureService,
                public dialogRef: MatDialogRef<ElementCreateComponent>,
                @Inject(MAT_DIALOG_DATA) {content, childList, element, structures}) {
        this.element = element;
        this.origExtended = JSON.parse(JSON.stringify(element));
        this.childList = childList;
        this.content = content;
        this.structures = structures;

        if (!this.content[this.element.id])
            this.content[this.element.id] = {settings: {}};

        if (!this.content[this.element.id]['settings'] || this.content[this.element.id]['settings'] instanceof Array)
            this.content[this.element.id]['settings'] = {};

        this.structcontent = this.content[this.element.id]['settings'];
    }

    ngOnInit() {
        if (!this.structures)
            this.structureService.getAll(9999).subscribe((structures) => {
                this.structures = structures;
            });
    }

    save() {
        this.element.classes = this.element.custom_classes;
        Object.assign(this.origExtended, this.element);
        this.dialogRef.close(this.element);
    }

    listClick(structure: Structure) {
        this.element.objecttype = structure.identifier;
        this.genIdentifier(structure.identifier, structure.name);
        this.save();
    }

    cancel() {
        this.dialogRef.close();

        if (this.childList)
            this.childList.push(this.element);
    }


    getExtendedIdNum(val) {

        let id = 1;
        let idname = `${val}-${id}`;
        let found = true;

        while (found) {
            for (const key in this.content) {
                if (key == idname) {
                    id++;
                    idname = `${val}-${id}`;
                    found = true;
                }
            }
            found = false;
        }

        return id;

    }

    private genIdentifier(identifier, name) {
        const num = this.getExtendedIdNum(identifier);

        const oldContent = this.content[this.element.id];
        delete this.content[this.element.id];

        this.element.name = `${name} ${num}`;
        this.element.id = `${identifier}-${num}`;

        if (!this.content[this.element.id])
            this.content[this.element.id] = oldContent;

        this.structcontent = this.content[this.element.id]['settings'];
    }

}
