import {Pipe, PipeTransform} from '@angular/core';
import {Chat} from '../classes/chat';

@Pipe({
    name: 'chatsSearch'
})
export class ChatsSearchPipe implements PipeTransform {
    public transform(value: Chat[], term: string) {
        if (!term) return value;
        if (!value || !(value.length > 0)) {
            return value;
        }
        return (value || []).filter((item) => {
            const strings = [item.name, ...item.chat_users.map(cu => cu.user?.name), ...item.chat_classrooms.map(cc => cc?.classroom?.classroom_name), item.lastMessage?.message];
            return strings.some(string => ('' + string ?? '').toLowerCase().indexOf(term.toLowerCase()) !== -1);
        });
    }
}
