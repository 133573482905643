import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {PasswordRequestValidationState} from './validation-state.enum';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {CodaltComponent} from '../../codalt.component';
import {Routenames} from '../../route-names.enum';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-restore-password',
    templateUrl: './restore-password.component.html',
    styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent extends CodaltComponent implements OnInit {

    public ValidationState = PasswordRequestValidationState;
    form: UntypedFormGroup;
    public message;
    public validToken: PasswordRequestValidationState = PasswordRequestValidationState.Validating;
    public loading = false;
    private token: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private authService: AuthenticationService) {
        super();
    }

    get password() {
        return this.form.get('password');
    }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            password: new UntypedFormControl('',
                [
                    Validators.required,
                    Validators.minLength(7),
                    Validators.pattern(/^(?=.*[!@#$&*0-9A-Z].*[!@#$&*0-9A-Z]).*$/)
                ]),
            passwordconf: new UntypedFormControl('', [
                Validators.required,
                this.matchFieldValidator('password')
            ])
        });
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.authService.logout().subscribe();
            this.token = params['token'];
            this.authService.verifyResetToken(this.token).then(() => {
                this.validToken = PasswordRequestValidationState.Valid;
            }, () => {
                this.validToken = PasswordRequestValidationState.Invalid;
            });
        }));
    }

    matchFieldValidator(matchField: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (this.form) {
                const toValue = this.form.controls[matchField].value;
                const equal = toValue === control.value;
                return equal ? null : {'matchField': true};
            }
            return null;
        };
    }

    setNewPassword() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.loading = true;
            this.authService.restorePassword(this.token, this.form.value.password).subscribe(() => {
                this.loading = false;
                this.router.navigate([Routenames.home], {replaceUrl: true});
            }, error => {
                this.loading = false;
                if (error) {
                    this.message = error.message;
                }
            });
        }
    }

}
