import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user/user.service';
import {LocalStorage} from '../storage.class';
import {User} from '../classes/user.class';
import {SortingComponent} from '../sorting.component';
import {AuthorisationService} from '../services/auth/authorisation.service';

@Component({
    selector: 'users-component',
    templateUrl: './users.component.html'
})
export class UsersComponent extends SortingComponent<User> implements OnInit {

    userManagement = false;
    users: User[];
    LocalStorage = LocalStorage;
    usercount_all: number;
    usercount_selected: number;
    query;
    onlythisschoolBool = true;

    constructor(private userService: UserService, private router: Router) {
        super();
    }

    get onlythisschool() {
        return this.onlythisschoolBool;
    }

    set onlythisschool(onlythisschool: boolean) {
        this.onlythisschoolBool = onlythisschool;
        this.getUsers();
    }

    ngOnInit() {
        this.userManagement = AuthorisationService.hasFeature('userManagement');
        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.getUsers();
            this.getUsersCount();
        }));

        if (LocalStorage.school) {
            this.getUsers();
            this.getUsersCount();
        }
    }

    getUsers() {
        this.userService.getParnassysUsers(this.onlythisschool).subscribe(users => {
            this.list = users.data;
        });
    }

    getUsersCount() {
        this.userService.getCount().subscribe(data => {
            this.usercount_selected = data.data.usercount_selected;
            this.usercount_all = data.data.usercount_all;
        });
    }

    editUser(event: PointerEvent, userId) {
        if (event.ctrlKey) {
            window.open(`/${this.Routenames.parnassysUsers}/${userId}`);
        } else {
            this.router.navigate([this.Routenames.parnassysUsers, userId]);
        }

    }
}
