import {Component, OnDestroy, OnInit} from '@angular/core';
import {SortingComponent} from '../sorting.component';
import {AuthenticationLog} from '../classes/authenticationlog.class';
import {AuthenticationService} from '../services/auth/authentication.service';

@Component({
    selector: 'app-auth-log',
    templateUrl: './auth-log.component.html',
    styleUrls: ['./auth-log.component.scss']
})
export class AuthLogComponent extends SortingComponent<AuthenticationLog> implements OnInit, OnDestroy {

    private authLogInterval;

    constructor(private authenticationService: AuthenticationService) {
        super();
    }

    ngOnInit() {
        this.getAuthLog();
        this.authLogInterval = setInterval(() => {
            this.getAuthLog();
        }, 25000);
    }

    getAuthLog() {
        this.authenticationService.fullLog().subscribe(log => {
            this.list = log.data;
            this.currentSort = 'created_at';
            this.desc = true;
        });
    }

    ngOnDestroy() {
        clearInterval(this.authLogInterval);
    }

}
