import {Component, OnInit} from '@angular/core';
import {SortingComponent} from '../../../sorting.component';
import {paymentSchoolOverview, PaymentService} from '../../../services/payment.service';

@Component({
    selector: 'app-payment-schools',
    templateUrl: './payment-schools.component.html',
    styleUrls: ['./payment-schools.component.scss']
})
export class PaymentSchoolsComponent extends SortingComponent<paymentSchoolOverview> implements OnInit {

    total: number;

    months: Date[] = [];

    selectedMonth = new Date();

    constructor(private paymentService: PaymentService) {
        super();
    }

    ngOnInit() {
        const fromDate = new Date();
        fromDate.setFullYear(2021);
        fromDate.setMonth(4);
        while (fromDate.getTime() < new Date().getTime()) {
            this.months.push(new Date(fromDate.setMonth(fromDate.getMonth() + 1)));
        }
        this.selectedMonth = this.months[this.months.length - 1];
        this.getData();
    }

    getData() {
        this.paymentService.schoolsOverview(this.selectedMonth).subscribe(data => {
            this.list = data.data.totals;
            this.list.forEach(total => {
                const overview = data.data.overview.find(p => p.school_id === total.school_id);
                total.saldo = +total?.amount_in - +total?.amount_out;
                total.cash = overview?.cash;
                total.amount_out = overview?.amount_out;
            });

            this.total = +data.data.saldo.amount_in - +data.data.saldo.amount_out;
        });
    }

}
