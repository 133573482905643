<div class="field-list">
    <div class="row">
        <div class="col">
            <mat-form-field class="w100">
                <input [(ngModel)]="field.minrepeat" matInput placeholder="Minimaal herhalen" type="text"/>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field class="w100">
                <input [(ngModel)]="field.maxrepeat" matInput placeholder="Maximaal herhalen" type="text"/>
            </mat-form-field>
        </div>
    </div>

    <app-fields [custobTypes]="custobTypes" [fields]="field.children"></app-fields>
</div>