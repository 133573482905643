import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Settings} from '../../settings.class';
import {LocalStorage} from '../../storage.class';
import {catchError, map} from 'rxjs/operators';
import {Routenames} from '../../route-names.enum';

export interface ApiResponse<T> {
    success: boolean;
    message: string;
    data: T;
}

export interface ErrorData {
    error: string;
    errormessage: string;
    data: any;
}

export class ApiErrorResponse implements ApiResponse<ErrorData> {
    data: ErrorData;
    message: string;
    success: boolean;
}

@Injectable()
export class ApiService {

    response: Subject<Object> = new Subject();
    response$: Observable<Object>;


    constructor(private http: HttpClient, private router: Router) {
        this.response$ = this.response.asObservable();
    }

    /**
     * @deprecated Use getCall$
     */
    public getCall<T>(apiUrl: string, params = {}): Promise<T> {

        params = this.buildURLParams(params);

        return this.http.get(`${Settings.API_ENDPOINT}${apiUrl}`, {params})
            .toPromise()
            .then(response => {
                this.response.next(response);
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public getCall$<T>(apiUrl: string, params = {}): Observable<ApiResponse<T>> {

        params = this.buildURLParams(params);

        return this.http.get(
            `${Settings.API_ENDPOINT}${apiUrl}`, {params})
            .pipe(map(response => {
                this.response.next(response);
                return response as ApiResponse<T>;
            }), catchError(error => {
                return throwError(this.onError(error));
            }));
    }

    /**
     * @deprecated Use postCall$
     */
    public postCall<T>(apiUrl: string, params = {}): Promise<T> {

        return this.http.post(`${Settings.API_ENDPOINT}${apiUrl}`, params)
            .toPromise()
            .then(response => {
                this.response.next(response);
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public postCall$<T>(apiUrl: string, params = {}): Observable<ApiResponse<T>> {

        return this.http.post(`${Settings.API_ENDPOINT}${apiUrl}`, params)
            .pipe(map(response => {
                this.response.next(response);
                return response as ApiResponse<T>;
            }), catchError(error => {
                return throwError(this.onError(error));
            }));
    }

    public patchCall$<T>(apiUrl: string, params = {}): Observable<ApiResponse<T>> {

        return this.http.patch(`${Settings.API_ENDPOINT}${apiUrl}`, params)
            .pipe(map(response => {
                this.response.next(response);
                return response as ApiResponse<T>;
            }), catchError(error => {
                return throwError(this.onError(error));
            }));
    }

    /**
     * @deprecated Use patchCall$
     */
    public patchCall<T>(apiUrl: string, params = {}): Promise<T> {

        return this.http.patch(`${Settings.API_ENDPOINT}${apiUrl}`, params)
            .toPromise()
            .then(response => {
                this.response.next(response);
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public putCall$<T>(apiUrl: string, params = {}): Observable<ApiResponse<T>> {

        return this.http.put(`${Settings.API_ENDPOINT}${apiUrl}`, params)
            .pipe(map(response => {
                this.response.next(response);
                return response as ApiResponse<T>;
            }), catchError(error => {
                return throwError(this.onError(error));
            }));
    }

    /**
     * @deprecated Use putCall$
     */
    public putCall<T>(apiUrl: string, params = {}): Promise<T> {

        return this.http.put(`${Settings.API_ENDPOINT}${apiUrl}`, params)
            .toPromise()
            .then(response => {
                this.response.next(response);
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    /**
     * @deprecated Use deleteCall$
     */
    public deleteCall<T>(apiUrl: string, params = {}): Promise<T> {
        params = this.buildURLParams(params);

        return this.http.delete(`${Settings.API_ENDPOINT}${apiUrl}`, {params})
            .toPromise()
            .then(response => {
                this.response.next(response);
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public deleteCall$<T>(apiUrl: string, params = {}): Observable<ApiResponse<T>> {
        params = this.buildURLParams(params);

        return this.http.delete(
            `${Settings.API_ENDPOINT}${apiUrl}`, {params})
            .pipe(map(response => {
                    this.response.next(response);
                    return response as ApiResponse<T>;
                }), catchError(error => {
                return throwError(this.onError(error));
            }));
    }

    // get call with blob as return format
    public getBlobCall(apiUrl: string, params = {}): Observable<any> {
        params = this.buildURLParams(params);
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + LocalStorage.getUserToken());
        headers = headers.append('Accept', '*');

        return this.http.get(`${Settings.API_ENDPOINT}${apiUrl}`, {params, responseType: 'blob', headers});
    }

    /**
     * Make a request for one file
     * @param apiUrl
     * @param file
     * @param params
     */
    public makeFileRequest$<T>(apiUrl: string, file: File, params = {}): Observable<ApiResponse<T>> {
        return new Observable(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            params['school'] = LocalStorage.school ? LocalStorage.school.id : null;
            formData.append('upload', file, file.name);
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    formData.append(key, params[key]);
                }
            }

            xhr.onreadystatechange = () => {
                console.log(xhr.status);
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(this.onError(xhr.response));
                        observer.complete();
                    }
                }
            };

            xhr.open('POST', `${Settings.API_ENDPOINT}${apiUrl}`, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + LocalStorage.getUserToken());
            xhr.send(formData);
        });
    }

    private buildURLParams(data: Object): HttpParams {
        let params = new HttpParams();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                params = params.set(key, data[key]);
            }
        }
        return params;
    }

    /**
     * @deprecated Use onError
     * @param error
     */
    private handleError(error: any): Promise<any> {

        console.log('An error occured', error);

        const response = {
            success: false,
            message: error,
            data: {}
        } as ApiResponse<any>;

        if (error.status === 0) {
            response.data['errormessage'] = 'No response from server';
        } else {
            response.data['errormessage'] = error.message;
        }

        if (error.status === 401) { // Unauthorized
            const firstPart = this.router.url.split('/')[1];
            if (Settings.unauthorizedAllowedUrls.indexOf(firstPart) === -1) {
                console.log('API Service error, not unauthorized allowed, redirect to login');
                this.router.navigate(['/']);
            }

        }

        return Promise.reject(response);
    }

    private onError(error: any): ApiErrorResponse {
        const err = new ApiErrorResponse();
        if (!!error.error) {
            if (!!error?.error?.data) {
                err.data = error.error.data;
            }
            if (!!error?.error?.message) {
                err.message = error.error.message;
            }
        } else {
            if (typeof error === 'string') {
                try {
                    error = JSON.parse(error);
                } catch (e) {

                }
                err.data = error;
            } else {
                err.data = new class implements ErrorData {
                    data = error;
                    error: string;
                    errormessage: string;
                };
            }
            err.message = 'Some server error';

        }
        console.error(err);
        err.success = false;

        if (error.status === 401) { // Unauthorized
            this.router.navigateByUrl(Routenames.logout);
        } else if (error.status === 403) {
            const firstPart = this.router.url.split('/')[1];
            if (Settings.unauthorizedAllowedUrls.indexOf(firstPart) === -1) {
                console.log('API Service error, not unauthorized allowed, redirect to login');

                this.router.navigate([Routenames.home]);
            }
        }

        return err;
    }

}
