import {User} from "./user.class";
import {Classroom} from "./classroom.class";
import {Permission} from './permission';

export class Student {
    id: number;
    name: string;
    number: string;
    deleted_at: string;
    created_at: string;
    updated_at: string;
    users: User[];
    classrooms: Classroom[];
    privacy_preference: PrivacyPreference;
    privacy_history: PrivacyHistory[];
    permissions: Permission[];
    data: {
        emergencyNumber: {
            naam: string,
            telefoonnummer: string,
            omschrijving: string
        }[];
    };

    classroomIds?: number[];
}

export enum PrivacyPreference {
    public = 'Openbaar', semiPublic = 'Semi-openbaar', closed = 'Besloten'
}

export class PrivacyHistory {
    id: number;
    student_id: string;
    privacy_user_id: string;
    user: User;
    privacy_preference: string;
    created_at: Date;
    updated_at: Date;
}
