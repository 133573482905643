import {Component, OnInit} from '@angular/core';
import {DiscussionService} from '../services/discussion.service';
import {LocalStorage} from '../storage.class';
import {CodaltComponent} from '../codalt.component';
import {Discussion} from '../classes/discussion.class';
import {IPageInfo} from '@iharbeck/ngx-virtual-scroller';
import {debounceTime, startWith} from 'rxjs/operators';

@Component({
    selector: 'app-discussions',
    templateUrl: './discussions.component.html',
    styleUrls: ['./discussions.component.scss']
})
export class DiscussionsComponent extends CodaltComponent implements OnInit {

    discussions: Discussion[] = [];
    loading = true;
    private limit = 9;
    private skip = 0;
    private allLoaded = false;

    constructor(private discussionService: DiscussionService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(LocalStorage.schoolChange.pipe(startWith(LocalStorage.school), debounceTime(50)).subscribe(() => {
            this.discussions = [];
            this.skip = 0;
            this.getDiscussions();
        }));

    }

    changeShowAllClassrooms() {
        this.skip = 0;
        this.getDiscussions();
    }

    scroll(discussion: IPageInfo) {
        if (!this.loading && !this.allLoaded) {
            if (discussion.endIndex > (this.discussions.length) - 3) {
                this.skip += this.limit;
                this.getDiscussions();
            }
        }
    }

    private getDiscussions() {
        this.loading = true;
        const skip = this.skip;
        this.discussionService.getList(this.limit, skip).subscribe(discussions => {
            if (skip === 0) {
                this.discussions = [];
            }
            this.allLoaded = discussions.data.length < this.limit;
            this.discussions = this.discussions.concat(discussions.data);
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }
}
