import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
};
if (!!window['cordova']) {
    document.addEventListener('deviceready', () => {
        bootstrap();
    });
} else {
    bootstrap();
}

setTimeout(() => {
    const reloadButton = document.getElementById("reload");
    if (reloadButton) {
        reloadButton.classList.add('show');
    }
}, 1200);
