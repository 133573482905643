import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export class Message {
    constructor(public text: string, public type: string) {

    }
}


@Injectable()
export class MessageService {

    private _messageSource = new BehaviorSubject<Message>(null);
    message$ = this._messageSource.asObservable();

    public constructor() {

    }

    public newMessage(text: string, type: string) {
        this._messageSource.next(new Message(text, type));
    }
}
