<div class="login-container">
    <div class="login">
        <div class="info">
            <ng-container *ngIf="requestState === RequestRestorePasswordState.SendedEmail">
                <h2 class="mb-2">
                    Er is een e-mail verstuurd naar <u>{{form.value.email}}</u>
                </h2>
                Via de link in deze e-mail kun je een nieuw wachtwoord instellen.
                <br><br>
                <h2 class="mb-2">E-mail niet ontvangen?</h2>
                Controleer of deze in spam terecht is gekomen en voeg
                <a href="mailto:app@kindwijs.org">app&#64;kindwijs.org</a> toe aan de lijst met veilige afzenders.
                <br><br>
                <button class="big white" mat-button routerLink="/{{Routenames.login}}/wachtwoord">Terug naar het inlogscherm</button>
            </ng-container>
            <ng-container *ngIf="requestState === RequestRestorePasswordState.SendEmailFail">
                Het is niet gelukt om een e-mail te versturen. Probeer het nog een keer, want het ligt niet aan jou.
            </ng-container>
        </div>
        <ng-container
            *ngIf="requestState === RequestRestorePasswordState.FillInEmail || requestState === RequestRestorePasswordState.SendingEmail">
            <form [formGroup]="form" [style.fontSize.px]="19">
                <div class="pb-2">
                    <h2>Wachtwoord vergeten</h2>
                    <div>Vul hier je e-mailadres in om je wachtwoord te resetten.</div>
                </div>
                <mat-form-field id="email">
                    <mat-label>E-mailadres</mat-label>
                    <input formControlName="email" matInput name="email"
                           placeholder="Vul hier je e-mailadres in"
                           type="text">
                    <mat-error [control]="form.get('email')"></mat-error>
                </mat-form-field>
                <div class="pt-2">
                    <button (click)="requestPasswordEmail()"
                            [disabled]="requestState === RequestRestorePasswordState.SendingEmail"
                            class="loading w-100 big dark"
                            color="primary"
                            id="btn-request-password"
                            mat-flat-button>
                        <ng-container *ngIf="requestState === RequestRestorePasswordState.FillInEmail">
                            Wachtwoord opvragen
                        </ng-container>
                        <ng-container *ngIf="requestState === RequestRestorePasswordState.SendingEmail">
                            Link wordt verstuurd...
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </ng-container>
                    </button>
                </div>
            </form>
        </ng-container>
    </div>
</div>
