import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Video} from '../classes/video.class';

@Injectable({
    providedIn: 'root'
})
export class VideoService {

    constructor(private apiService: ApiService) {
    }

    getChatVideo(id: number) {
        return this.apiService.getCall$<Video>(`video/${id}`);
    }

}
