import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {PagesComponent} from './pages/pages.component';
import {PageHistoryComponent} from './page-history/page-history.component';
import {PageEditComponent} from './page-edit/page-edit.component';
import {StructureComponent} from './structure/structure.component';
import {StructureEditComponent} from './structure-edit/structure-edit.component';
import {Routenames} from './route-names.enum';
import {LoginComponent} from './auth/login/login.component';
import {IsNotAuthenticated} from './is-not-authenticated';
import {LinkLoginComponent} from './auth/link-login/link-login.component';
import {LogoutComponent} from './auth/logout/logout.component';
import {RequestRestorePasswordComponent} from './auth/request-restore-password/request-restore-password.component';
import {RestorePasswordComponent} from './auth/restore-password/restore-password.component';
import {UsersOverviewComponent} from './users/users-overview/users-overview.component';
import {IsAuthenticated} from './is-authenticated';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {AuthRolesComponent} from './auth/auth-roles/auth-roles.component';
import {UserDetailComponent} from './users/user-detail.component';
import {UsersComponent} from './users/users.component';
import {StudentDetailComponent} from './students/student-detail.component';
import {StudentsComponent} from './students/students.component';
import {ClassroomDetailComponent} from './classrooms/classroom-detail.component';
import {ClassroomsComponent} from './classrooms/classrooms.component';
import {AuthLogComponent} from './auth-log/auth-log.component';
import {SchoolSettingsComponent} from './school-settings/school-settings.component';
import {ProfileComponent} from './profile/profile.component';
import {InfoComponent} from './info/info.component';
import {ArticlesComponent} from './articles/articles.component';
import {ArticleEditComponent} from './articles/article-edit/article-edit.component';
import {EventsComponent} from './events/events.component';
import {EventEditComponent} from './events/event-edit/event-edit.component';
import {EventDetailComponent} from './events/event-detail/event-detail.component';
import {ArticleDetailComponent} from './articles/article-detail/article-detail.component';
import {FormHistoryComponent} from './form/form-history/form-history.component';
import {FormHistoryDetailComponent} from './form/form-history-detail/form-history-detail.component';
import {JobsComponent} from './jobs/jobs.component';
import {JobEditComponent} from './jobs/job-edit/job-edit.component';
import {CanDeactivateForm} from './can-deactivate-form';
import {ChatComponent} from './chat/chat.component';
import {ChatMessagingComponent} from './chat/chat-messaging/chat-messaging.component';
import {DiscussionsComponent} from './discussions/discussions.component';
import {DiscussionEditComponent} from './discussions/discussion-edit/discussion-edit.component';
import {DiscussionDetailComponent} from './discussions/discussion-detail/discussion-detail.component';
import {DiscussionSubscribeComponent} from './discussions/discussion-subscribe/discussion-subscribe.component';
import {MyPrivacyComponent} from './my-privacy/my-privacy.component';
import {SurveyEditComponent} from './survey-edit/survey-edit.component';
import {SurveyAnswersComponent} from './survey-answers/survey-answers.component';
import {TransactionOverviewComponent} from './profile/transaction-overview/transaction-overview.component';
import {AddCreditComponent} from './profile/transaction-overview/add-credit/add-credit.component';
import {PaymentCheckComponent} from './profile/transaction-overview/payment-check/payment-check.component';
import {PaymentsComponent} from './payments/payments.component';
import {PaymentEditComponent} from './payments/payment-edit/payment-edit.component';
import {DefaultPaymentsComponent} from './payments/default-payments/default-payments.component';
import {PayPaymentRequestComponent} from './payments/pay-payment-request/pay-payment-request.component';
import {AddCashCreditComponent} from './payments/add-cash-credit/add-cash-credit.component';
import {DefaultPaymentEditComponent} from './payments/default-payments/default-payment-edit/default-payment-edit.component';
import {RemainPaymentsComponent} from './payments/remain-payments/remain-payments.component';
import {PaymentsOverviewComponent} from './payments/payments-overview/payments-overview.component';
import {CanDeactivateChat} from './can-deactivate-chat';
import {PermissionQuestionsEditComponent} from './permission-questions-edit/permission-questions-edit.component';
import {PrivacyQuestionComponent} from './my-privacy/privacy-question/privacy-question.component';
import {AbsenceRegisterComponent} from './absence-register/absence-register.component';
import {AbsencesComponent} from './absences/absences.component';
import {PermissionQuestionsComponent} from './permission-questions/permission-questions.component';
import {AbsenceCountsComponent} from './absence-counts/absence-counts.component';
import {PaymentRequestsComponent} from './payments/payment-requests/payment-requests.component';
import {DiscussionsOverviewComponent} from './discussions/payment-requests/discussions-overview.component';
import {PermissionStatsComponent} from './permission-questions/permission-stats/permission-stats.component';
import {PayAfterSchoolOfferComponent} from './payments/pay-afterschool-offer/pay-after-school-offer.component';

const routes: Routes = [
    {path: '', component: HomeComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.login + '/wachtwoord', component: LoginComponent, canActivate: [IsNotAuthenticated]},
    {path: Routenames.login + '/:token', component: LinkLoginComponent},
    {path: Routenames.logout, component: LogoutComponent},
    {path: Routenames.login, component: LinkLoginComponent, canActivate: [IsNotAuthenticated]},
    {path: Routenames.lostpw, component: RequestRestorePasswordComponent, canActivate: [IsNotAuthenticated]},
    {path: Routenames.lostpw + '/:token', component: RestorePasswordComponent},

    {path: Routenames.authLog, component: AuthLogComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.users, component: UsersOverviewComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.users + '/:userId', component: UserEditComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.authRoles, component: AuthRolesComponent, canActivate: [IsAuthenticated]},

    {path: 'pagina-management', component: PagesComponent, canActivate: [IsAuthenticated]},
    {path: 'pagina-historie/:id', component: PageHistoryComponent, canActivate: [IsAuthenticated]},
    {path: 'pagina-aanpassen', component: PageEditComponent, canActivate: [IsAuthenticated]},
    {path: 'pagina-aanpassen/:id/:parent', component: PageEditComponent, canActivate: [IsAuthenticated]},
    {path: 'pagina-aanpassen/:id', component: PageEditComponent, canActivate: [IsAuthenticated]},

    {path: 'structures', component: StructureComponent, canActivate: [IsAuthenticated]},
    {path: 'structures/:id', component: StructureEditComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.parnassys, redirectTo: Routenames.home, pathMatch: 'full'},
    {path: Routenames.parnassysUsers + '/:id', component: UserDetailComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.parnassysUsers + '/:id/transactions', component: TransactionOverviewComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.parnassysUsers + '/:id/:action', component: UserDetailComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.parnassysUsers, component: UsersComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.schoolSettings, component: SchoolSettingsComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.parnassysStudent + '/:id', component: StudentDetailComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.parnassysStudent, component: StudentsComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.parnassysClassrooms + '/:id', component: ClassroomDetailComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.parnassysClassrooms, component: ClassroomsComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.profile, component: ProfileComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.info, component: InfoComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.articles + '/toekomst', component: ArticlesComponent, canActivate: [IsAuthenticated]},
    {
        path: Routenames.articles, component: ArticlesComponent, children: [
            {path: ':id', component: ArticleDetailComponent},
            {path: ':id/bewerken', component: ArticleEditComponent, canDeactivate: [CanDeactivateForm]},
            {path: ':id/kopie', component: ArticleEditComponent, canDeactivate: [CanDeactivateForm]}
        ]
    },
    {path: Routenames.articles + '/:id/vragenlijst-bewerken', component: SurveyEditComponent, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateForm]},
    {path: Routenames.articles + '/:id/vragenlijst-antwoorden', component: SurveyAnswersComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.events + '/verleden', component: EventsComponent, canActivate: [IsAuthenticated]},
    {
        path: Routenames.events, component: EventsComponent, canActivate: [IsAuthenticated], children: [
            {path: ':id', component: EventDetailComponent, canActivate: [IsAuthenticated]},
            {path: ':id/bewerken', component: EventEditComponent, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateForm]},
            {path: ':id/kopie', component: EventEditComponent, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateForm]}
        ]
    },
    {path: Routenames.events + '/:id/vragenlijst-bewerken', component: SurveyEditComponent, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateForm]},
    {path: Routenames.events + '/:id/vragenlijst-antwoorden', component: SurveyAnswersComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.formHistory, component: FormHistoryComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.formHistory + '/:id', component: FormHistoryDetailComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.jobs, component: JobsComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.jobs + '/:id', component: JobEditComponent, canActivate: [IsAuthenticated]},
    {
        path: Routenames.chatArchive, component: ChatComponent, data: {archive: true}, children: [
            {path: ':id', component: ChatMessagingComponent, data: {archive: true}, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateChat]}
        ],
        canActivate: [IsAuthenticated]
    },
    {
        path: Routenames.chat, component: ChatComponent, children: [
            {path: ':id', component: ChatMessagingComponent, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateChat]}
        ],
        canActivate: [IsAuthenticated]
    },

    {path: Routenames.discussions, component: DiscussionsOverviewComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.discussionsPlanner, component: DiscussionsComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.discussionsPlanner + '/edit/:id', component: DiscussionEditComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.discussionsPlanner + '/detail/:id', component: DiscussionDetailComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.discussions + '/:id', component: DiscussionSubscribeComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.myPrivacy, component: MyPrivacyComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.myPrivacy + '/:id', component: PrivacyQuestionComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.transactionsOverview, component: TransactionOverviewComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.transactionTopUp + '/' + Routenames.cash, component: AddCashCreditComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.transactionTopUp, component: AddCreditComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.transactionTopUpCheck, component: PaymentCheckComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.payments, component: PaymentsComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.payments + '/' + Routenames.editPart + '/:id', component: PaymentEditComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.defaultPayments, component: DefaultPaymentsComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.defaultPayments + '/' + Routenames.editPart + '/:id', component: DefaultPaymentEditComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.paymentRequests, component: PaymentRequestsComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.paymentRequests + '/' + Routenames.pay + '/:id', component: PayPaymentRequestComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.afterSchoolOffer + '/' + Routenames.pay + '/:id', component: PayAfterSchoolOfferComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.lowPermissionPayments, component: RemainPaymentsComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.payments + '/' + Routenames.overview, component: PaymentsOverviewComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.permissions, component: PermissionQuestionsComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.permissions + '/:id', component: PermissionQuestionsEditComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.permissionStats + '/:id', component: PermissionStatsComponent, canActivate: [IsAuthenticated]},

    {path: Routenames.absence, component: AbsencesComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.absenceRegister, component: AbsenceRegisterComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.absenceRegister + '/:id', component: AbsenceRegisterComponent, canActivate: [IsAuthenticated]},
    {path: Routenames.absenceCounts, component: AbsenceCountsComponent, canActivate: [IsAuthenticated]}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule],
    providers: [IsAuthenticated, IsNotAuthenticated, CanDeactivateForm, CanDeactivateChat]
})
export class AppRoutingModule {
}
