<div class="container">
    <div class="news">
        <h2 class="news__title">Geschiedenis ingevulde formulieren</h2>
        <div class="news-home">

            <div *ngFor="let item of items" class="item" routerLink="/{{Routenames.formHistory}}/{{item.id}}">
                <h3 class="item__title">
                    {{item.page?.name || '?'}}
                    -
                    {{item.data?.name || '?'}}
                </h3>
                <div class="item__details">
                    {{item.updated_at | date:'d MMMM yyyy HH:mm:ss'}}
                </div>
            </div>
        </div>
    </div>


    <div class="text-center">
        <button (click)="loadMore()" *ngIf="showMore" class="add-button" color="primary" mat-button>Laad meer</button>
    </div>
</div>

