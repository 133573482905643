import {CodaltComponent} from './codalt.component';

export class SortingComponent<T> extends CodaltComponent {
    list: T[];
    currentSort = null;
    desc = false;


    sortList(sortingOn) {
        let sortList = this.list;
        this.list = [];

        if (sortingOn === this.currentSort) {
            sortList = sortList.reverse();
            this.desc = !this.desc;
        } else {
            this.desc = false;
            sortList = sortList.sort((a, b) => {

                const nonUppercaseTypes = ['boolean', 'number'];
                if (nonUppercaseTypes.indexOf(typeof (a[sortingOn])) !== -1) {
                    if (a[sortingOn] < b[sortingOn]) {
                        return -1;
                    }
                    if (a[sortingOn] > b[sortingOn]) {
                        return 1;
                    }
                } else {
                    if (!a[sortingOn]) {
                        return -1;
                    }
                    if (!b[sortingOn]) {
                        return 1;
                    }
                    if (a[sortingOn].toUpperCase() < b[sortingOn].toUpperCase()) {
                        return -1;
                    }
                    if (a[sortingOn].toUpperCase() > b[sortingOn].toUpperCase()) {
                        return 1;
                    }
                }
                return 0;
            });
        }

        this.list = sortList;

        this.currentSort = sortingOn;
    }


}
