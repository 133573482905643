import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {School} from '../../classes/school.class';

export class Message {
    constructor(public text: string, public type: string) {

    }
}

@Injectable()
export class SchoolService {

    public constructor(private apiService: ApiService) {

    }

    allSchools() {
        return this.apiService.getCall$<School[]>('schools/all');
    }

    getSchools() {
        return this.apiService.getCall$<School[]>('schools');
    }

    save(data: any) {
        return this.apiService.postCall$<School>('schools', data);
    }
}
