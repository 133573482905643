import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Chat} from '../../classes/chat';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {ChatService} from '../../services/chat.service';
import {CodaltComponent} from '../../codalt.component';
import {UntypedFormGroup} from '@angular/forms';
import {ChatUser} from '../../classes/chat-user';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {NewChatDialogComponent} from '../new-chat-dialog/new-chat-dialog.component';
import {Routenames} from '../../route-names.enum';

@Component({
    selector: 'app-chat-settings-dialog',
    templateUrl: './chat-settings-dialog.component.html',
    styleUrls: ['./chat-settings-dialog.component.scss']
})
export class ChatSettingsDialogComponent extends CodaltComponent implements OnInit {

    form: UntypedFormGroup;
    chatUser: ChatUser;
    chatAdmin = false;

    constructor(public dialogRef: MatDialogRef<ChatSettingsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public chat: Chat,
                private classroomService: ClassroomService,
                private confirmDialog: ConfirmDialogService,
                private breakpointObserver: BreakpointObserver,
                private dialog: MatDialog,
                private router: Router,
                private chatService: ChatService) {
        super();
    }

    ngOnInit(): void {
        this.checkIsChatAdmin();
    }


    private checkIsChatAdmin() {
        this.chatUser = this.chat?.chat_users.find(u => u.user_id === this.LocalStorage.user.id);
        this.chatAdmin = this.chatUser?.admin;
    }

    onlyAdminMessages(event, toggleClick?: boolean) {
        event.stopPropagation();
        this.chat.only_admin_messages = !this.chat.only_admin_messages;
        this.chatService.onlyAdminMessages(this.chat).subscribe(() => {

        });
    }

    muteChat(event, toggleClick?: boolean) {
        event.stopPropagation();
        if (!toggleClick) {
            this.chatUser.mute = !this.chatUser.mute;
        }
        this.chatService.muteChat(this.chat).subscribe(() => {
        });
    }

    leaveChat() {
        if (this.chatAdmin && this.chat.chat_users.filter(u => u.admin)?.length < 2 && this.chat.chat_users?.length > 1) {
            this.confirmDialog.confirm(
                'Er moet minimaal 1 beheerder overblijven',
                'Je kunt deze chat niet verlaten omdat er minimaal 1 beheerder over moet blijven. Maak een andere gebruiker beheerder voordat u de chat verlaat.',
                'Sluiten',
                null
            ).then(() => {

            }, () => {

            });
        } else {
            this.confirmDialog.confirm(
                'Verlaat deze Chat',
                'Weet je zeker dat je deze chat wilt verlaten?',
                'Verlaten',
                'Blijven'
            ).then(() => {
                this.chatService.leaveChat(this.chat).subscribe(() => {
                    this.router.navigateByUrl(this.Routenames.chat);
                    this.dialogRef.close();
                });
            }, () => {

            });
        }
    }

    archiveChats() {
        let extraInfo = '';
        if (this.chatAdmin) {
            const archive = this.chat.deleted_at ? 'dearchiveren' : 'archiveren';
            extraInfo = `<p><br><i>Je bent geen beheerder van deze chat <br>Deze kun je niet ${archive}</i></p>`;
        }
        if (this.chat.deleted_at) {
            this.confirmDialog.confirm(
                'Chat dearchiveren',
                `Weet je zeker dat je deze chats terug wilt plaatsen vanuit het archief?<br>${extraInfo}`,
                'Terugplaatsen',
                'Annuleren'
            ).then(() => {
                this.chatService.deArchive([this.chat.id]).subscribe(() => {
                    this.router.navigateByUrl(`/${Routenames.chat}/${this.chat.id}`);
                    this.dialogRef.close();
                });
            }, () => {

            });
        } else {
            this.confirmDialog.confirm(
                'Chat archiveren',
                `Weet je zeker dat je deze chat wilt archiveren?<br>${extraInfo}`,
                'Archiveren',
                'Annuleren'
            ).then(() => {
                this.chatService.archive([this.chat.id]).subscribe(() => {
                    setTimeout(() => {
                        this.router.navigateByUrl(`/${Routenames.chatArchive}/${this.chat.id}`);
                        this.dialogRef.close();
                    }, 400);
                });
            }, () => {

            });
        }
    }

    makeAdmin(chatUser) {
        if (this.chatAdmin) {
            if (!chatUser.admin || this.chat.chat_users.filter(u => u.admin).length > 1) {
                this.confirmDialog.confirm(
                    chatUser.admin ? 'Beheerder' : 'Beheerder maken',
                    `Weet je zeker dat je de gebruiker ${chatUser.user.name} ${chatUser.admin ? 'als beheerder wilt verwijderen?' : 'beheerder wilt maken?'}`,
                    chatUser.admin ? 'Verwijderen als beheerder' : 'Beheerder maken',
                    'Annuleren'
                ).then(() => {
                    this.chatService.makeUserAdmin(chatUser).subscribe(data => {
                        Object.assign(chatUser, data.data);
                        this.checkIsChatAdmin();
                    });
                }, () => {

                });
            } else {
                this.confirmDialog.confirm(
                    'Beheerder',
                    `Je kunt de laatste beheerder niet verwijderen!`,
                    'Oké',
                    null
                ).then(() => {

                }, () => {

                });
            }
        }
    }

    editChat() {
        const isMobile = this.breakpointObserver.isMatched('(max-width: 450px)');
        let dialogRef = this.dialog.open(NewChatDialogComponent, {
            maxHeight: '98vh',
            minHeight: isMobile ? '100%' : '500px',
            height: isMobile ? '100%' : '700px',
            minWidth: isMobile ? '100%' : '400px',
            width: isMobile ? '100%' : '400px',
            panelClass: 'chat-dialog',
            data: this.chat
        });
    }

}
