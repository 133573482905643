<div class="container bg-white mt-2 item-edit" *ngIf="question">
    <div>
        <h2 class="news__title">{{question.name}}
            <button (click)="info(question)" mat-icon-button color="primary">
                <mat-icon class="fas fa-info-circle"></mat-icon>
            </button></h2>
        <h4 class="text-capitalize"></h4>
    </div>
    <div>
        <div *ngFor="let level of question.options" class="pb-2">
            <div class="mb-2">
                <b>{{level.name}}</b>, <i>{{level.intro}}</i>
            </div>
            <div class="ml-3" *ngFor="let classroom of classrooms">
                <ng-container *ngIf="(question.permissions | filterPermissionsPipe : classroom.id : level.name).length">
                    <b *ngIf="classrooms">{{classroom.classroom_name}}</b>: -
                    <ng-container
                        *ngFor="let permission of ((question.permissions | filterPermissionsPipe : classroom.id : level.name) | orderBy : 'student.name'); let last = last">
                        {{permission?.student?.name}}{{last ? '' : ','}}
                    </ng-container>
                </ng-container>
            </div>
        </div>

    </div>
</div>
