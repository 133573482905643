<div class="container white">
    <h3>Toestemmingen & privacy</h3>
    <mat-card class="mb-3" *ngFor="let question of questions">
        <mat-card-content>
            <h4>{{question.name}}</h4>
            <div [innerHTML]="question.description | nl2br"></div>
            <div *ngIf="question._answers?.length" class="font-italic mt-3">
                Voorkeuren opgegeven voor:
                <span *ngFor="let answer of question._answers; let last = last">
                    {{answer.student.name}}{{!last ? ', ' : ''}}
                </span>
            </div>
            <div>
                <button routerLink="/{{Routenames.myPrivacy}}/{{question.id}}" class="button--primary mt-3">
                    <ng-container *ngIf="!question.deadline_date || question.deadline_date >= today">
                        Voorkeuren {{question._answers?.length === LocalStorage.user?.students?.length ? 'aanpassen' : 'opgeven'}}
                    </ng-container>
                    <ng-container *ngIf="question.deadline_date && question.deadline_date < today">
                        Voorkeuren bekijken
                    </ng-container>
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
