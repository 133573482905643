<div class="container white">
    <ng-container *ngIf="LocalStorage.user">
        <div *ngIf="form" class="row justify-content-center">
            <div class="col-24 pb-3 pb-sm-0 col-sm-8">
                <div (click)="fileManager.chooseSource()"
                     class="profile-image">
                    <app-codalt-image *ngIf="profileImage" [fullUrl]="profileImage"
                                      [thumb]="LocalStorage.user.profile_image_thumb">
                    </app-codalt-image>
                    <div class="content">
                        <div class="align-self-center text-center">
                            <i *ngIf="loading" class="fa fa-spinner fa-spin fa-6x"></i>
                            <div [hidden]="loading">
                                <file-manager #fileManager (callback)="fileUploaded($event, 'profile_image')"
                                              (loading)="loading = true;"
                                              (selected)="loading = false"
                                              [class.text-white]="form.controls['profile_image'].value"
                                              [currentFile]="form.controls['profile_image'].value"
                                              [directUpload]="true"
                                              [remove]="true"
                                              icon="fas fa-image"></file-manager>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-24 col-lg">
                        <h2>{{LocalStorage.user.name}}</h2>
                        <h3>{{LocalStorage.user.email}}</h3>
                    </div>
                    <div class="col-24 col-lg d-flex align-items-lg-end flex-column">
                        <button class="button mb-3" routerLink="/{{Routenames.logout}}">Uitloggen</button>
                        <button class="button--primary mb-3" routerLink="/{{Routenames.myPrivacy}}" *ngIf="LocalStorage.user?.students?.length">
                            Toestemmingen & Privacy
                        </button>
                    </div>
                </div>
                <div class="alert alert-info mt-3">
                    Kloppen je gegevens niet? Neem contact op met school. Zij kunnen deze aanpassen in Parnassys, het leerlingvolgsysteem.
                </div>
                <div class="mb-4" *ngIf="AuthorisationService.hasFeature('transactions')">
                    <h3>
                        Kosten
                    </h3>
                    <p class="mb-1">Huidige saldo: {{LocalStorage.user.transactionAmount | currency : 'EUR'}}</p>
                    <button class="button" routerLink="/{{Routenames.transactionsOverview}}">Bekijk transacties</button>
                </div>

                <ng-container *ngIf="schoolForms && (LocalStorage.user.schools | filter : 'subsite' : 'type')?.length > 0">
                    <h3>Functie per school</h3>
                    <p>Voer hieronder de functies in die je op een specifieke school vervult. Deze wordt samen met je profielfoto getoond op de website bij
                        "onze medewerkers". Wil je niet getoond worden? Laat de functies dan leeg.</p>
                    <form [formGroup]="form">
                        <div *ngFor="let school of LocalStorage.user.schools | filter : 'subsite' : 'type'" [formGroup]="schoolForms.get(school.id)">
                            <b>{{school.name}}</b><br>
                            <div class="row">
                                <div class="col-24 col-md-12">
                                    <mat-form-field class="w-100">
                                        <mat-label>Naam eerste functie</mat-label>
                                        <input formControlName="function_one" matInput type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-24 col-md-12">

                                    <mat-form-field class="w-100">
                                        <mat-label>Naam tweede functie</mat-label>
                                        <input formControlName="function_two" matInput type="text">
                                    </mat-form-field>

                                </div>
                            </div>
                        </div>
                        <button (click)="save()" [disabled]="form.invalid || saving" class="button--primary mt-2 mb-3" color="primary">
                            Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
                        </button>
                        <div class="pt-2">
                            <h3>Chat instellingen</h3>
                            <mat-checkbox formControlName="chat_only_worktimes" (change)="save()">
                                Alleen notificaties tijdens werktijd
                            </mat-checkbox>
                            <br>
                            <small>Alleen notificaties op werkdagen tussen 8:00 en 17:00 uur</small>
                        </div>
                        <div *ngIf="form.get('chat_only_worktimes').value" class="pl-4 pt-2 pb-2">
                            <div class="font-weight-bold mb-1">Mijn werkdagen:</div>
                            <mat-checkbox formControlName="workday_0" class="w-100" (change)="save()">
                                Zondag
                            </mat-checkbox>
                            <mat-checkbox formControlName="workday_1" class="w-100" (change)="save()">
                                Maandag
                            </mat-checkbox>
                            <mat-checkbox formControlName="workday_2" class="w-100" (change)="save()">
                                Dinsdag
                            </mat-checkbox>
                            <mat-checkbox formControlName="workday_3" class="w-100" (change)="save()">
                                Woensdag
                            </mat-checkbox>
                            <mat-checkbox formControlName="workday_4" class="w-100" (change)="save()">
                                Donderdag
                            </mat-checkbox>
                            <mat-checkbox formControlName="workday_5" class="w-100" (change)="save()">
                                Vrijdag
                            </mat-checkbox>
                            <mat-checkbox formControlName="workday_6" class="w-100" (change)="save()">
                                Zaterdag
                            </mat-checkbox>
                        </div>


                    </form>
                </ng-container>

                <h3>
                    Agenda weergave
                </h3>
                <mat-checkbox [(ngModel)]="LocalStorage.agendaListView" class="w-100">
                    Toon agenda als lijstweergave
                </mat-checkbox>

                <h3>Persoonlijke agenda koppelen</h3>
                <p>
                    De app biedt de mogelijkheid om de afspraken voor heel Kindwijs automatisch in
                    je persoonlijke agenda te plaatsen. Volg de instructies hieronder.
                </p>

                <div class="row">
                    <div class="col">
                        <h5 class="mb-1">Google Calendar en Android</h5>
                        <p>
                            Klik op de link om direct te koppelen.<br>
                            <a href="https://www.google.com/calendar/render?cid=http://app.kindwijs.org/ical/{{LocalStorage.user.calendarUrl}}">
                                <i class="fas fa-external-link-alt"></i> Koppelen aan Google Calendar
                            </a>
                        </p>
                    </div>
                    <div class="col">
                        <h5 class="mb-1">Outlook Web App / Office365</h5>
                        <p>
                            Klik op de link om direct te koppelen.<br>
                            <a href="https://outlook.live.com/calendar/0/addcalendar?url=https://app.kindwijs.org/ical/{{LocalStorage.user.calendarUrl}}&name={{urlFriendlyCalendarUrl}}">
                                <i class="fas fa-external-link-alt"></i> Koppelen aan Outlook Web App
                            </a>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h5 class="mb-1">Outlook op Windows</h5>
                        <p>
                            Open Outlook op je computer. Klik onder 'Start' op 'Agenda openen' > 'Van Internet'.
                            Plak onderstaande URL.
                        </p>
                    </div>
                    <div class="col">
                        <h5 class="mb-1">Apple iPhone of iPad</h5>
                        <p>
                            Tik in Instellingen op 'Agenda' > 'Accounts' > 'Voeg account toe' > 'Andere'.
                            Tik op 'Voeg agenda-abonnement toe'. Plak onderstaande URL.
                        </p>
                    </div>
                </div>

                <app-url-share [urlFriendlyCalendarUrl]="urlFriendlyCalendarUrl"></app-url-share>

            </div>
        </div>
    </ng-container>
</div>
