<div class="container">
    <ng-container *ngIf="!pageData.length && emptyPageData">
        <h2 class="page-title">Pagina's aan het laden...</h2>
    </ng-container>

    <ng-container *ngIf="!emptyPageData">
        <h2 class="page-title">Deze school heeft nog geen pagina's</h2>
    </ng-container>

    <ng-container *ngIf="pageData.length">
        <div class="d-none d-md-block">
            <h2 class="page-title">Alle pagina's</h2>
            <app-page-tree (pageDataChanged)="pageData=$event;saveOrder()" [pageData]="pageData"></app-page-tree>
        </div>
        <div class="d-block d-md-none alert alert-danger">
            Het is niet mogelijk om op een mobiel apparaat de pagina's te beheren.
        </div>

    </ng-container>
    <br><br>
</div>
<app-edit-button destinationUrl="/pagina-aanpassen" iconClass="fa fa-plus" tooltip="Toevoegen"></app-edit-button>
