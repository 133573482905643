import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Structure} from '../classes/structure.class';
import {StructureService} from '../services/structure/structure.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Settings} from '../settings.class';
import {ElementCreateComponent} from './element-create/element-create.component';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {DragulaOptions, DragulaService} from 'ng2-dragula';
import {CustobType} from '../classes/custob-type.class';
import {ResizeEvent} from 'angular-resizable-element';
import {LocalStorage} from '../storage.class';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {MatDialog} from '@angular/material/dialog';

export function checkChangeSideModalContent(fromState: string, toState: string, element: any, params: {
    [key: string]: any
}): boolean {
    // notice that `element` and `params` are also available here
    return toState !== fromState && fromState !== 'void';
}

@Component({
    selector: 'app-elements',
    templateUrl: 'elements.component.html',
    styleUrls: ['elements.scss'],
    animations: [trigger('sideModelContent', [
        transition(checkChangeSideModalContent, [
            animate('0.35s', keyframes([
                style({backgroundColor: 'transparent', offset: 0}),
                style({backgroundColor: '#a7a7a7', offset: 0.25}),
                style({backgroundColor: 'transparent', offset: 1})
            ]))
        ])
    ])]
})
export class ElementsComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild('structureSettingsModal') public structureSettingsModal: ElementCreateComponent;

    @Input() customType: CustobType = null;
    @Input() elements: Structure[];
    @Input() values: Object;
    @Input() elementHolder: string;
    @Input() elementHolderLastChanged = null;

    @Output() saveChanges = new EventEmitter<Structure[]>();
    @Output() doScroll = new EventEmitter<number>();
    @Output() showSideBar = new EventEmitter<boolean>();
    @Output() isDragging = new EventEmitter<boolean>();

    hasStructures = false;
    dragging = false;
    storage = LocalStorage;
    type: string;
    Settings = Settings;
    editingStructure: Structure;
    initialEditingStructure: Structure;
    structures: Structure[];
    availableParentPages: Array<Object>;
    accesibilityOptions = [
        {
            value: 0,
            text: 'Online',
            icon: 'fa-eye'
        },
        {
            value: 1,
            text: 'Offline',
            icon: 'fa-ban'
        },
        {
            value: 2,
            text: 'Onzichtbaar',
            icon: 'fa-eye-slash'
        }
    ];
    confirmDialogOpen = false;
    private sub: any;
    // We need to access the displayed structures to calculate which is displayed based on scrollPosition
    @ViewChild('blocksContainer')
    private blocksContainer: ElementRef;

    constructor(public structureService: StructureService,
                private route: ActivatedRoute,
                private router: Router,
                private matDialog: MatDialog,
                private confirmDialogService: ConfirmDialogService,
                private dragulaService: DragulaService,
                private elRef: ElementRef) {
    }


    onResizeEnd(event: ResizeEvent): void {
        Settings.sidemodalWidth = Settings.sidemodalWidth - +event.edges.left;
    }

    ngOnInit() {

        this.structureService.getAll().subscribe(structures => {
            // Todo use at pages???
            if (this.customType) {
                this.structures = structures.filter(p => {
                    return p.useAtCustobTypeIds[this.customType.id] === true;
                });
            } else {
                this.structures = structures.filter(p => p.use_at_pages === true);
            }
            if (this.structures.length > 0) {
                this.showSideBar.emit(true);
                this.hasStructures = true;
            } else {
                this.showSideBar.emit(false);
                this.hasStructures = false;
            }
        });

        this.dragulaService.createGroup('structures', {
            moves: (el, container, handle) => {
                return this.dragging;
            }
        } as DragulaOptions);

        this.dragulaService.dragend("structures").subscribe((value) => {
            //(value[1] as HTMLElement).style.height = '';
            console.log(this.elements);
        });
    }

    ngOnChanges() {

        if (this.elements && this.elements.length == 1) {
            this.editingStructure = this.elements[0];
        }
    }

    ngOnDestroy(): void {
        this.dragulaService.destroy('structures');
    }

    forceSelectElement(element) {
        const children = Array.from(this.blocksContainer?.nativeElement.children) as HTMLElement[];
        const editNativeElement = children.filter(c => c.getAttribute('container') === element.id)[0];
        this.doScroll.emit(editNativeElement.offsetTop);
        this.editStructureFields(element);
    }

    scroll(event) {
        const currentScrollPosition = event.srcElement.scrollTop + 300;
        const clientHeight = event.srcElement.clientHeight;
        const scrollHeight = event.srcElement.scrollHeight;

        const children = Array.from(this.blocksContainer?.nativeElement.children);

        if ((clientHeight + currentScrollPosition - 300) === scrollHeight) {
            const el = children[children.length - 1] as HTMLElement;

            const currentVisibleStructure = this.elements.filter(
                s => s.id === el.getAttribute('container'))[0];
            if (currentVisibleStructure) {
                this.editStructureFields(currentVisibleStructure);
            }
        } else {

            children.forEach((el: HTMLElement) => {
                if (currentScrollPosition > el.offsetTop && currentScrollPosition <= (el.offsetTop + el.offsetHeight)) {
                    const currentVisibleStructure = this.elements.filter(
                        s => s.id === el.getAttribute('container'))[0];
                    if (currentVisibleStructure) {
                        this.editStructureFields(currentVisibleStructure);
                    }
                }
            });
        }
    }


    startSort() {
        this.dragging = true;
        this.isDragging.emit(this.dragging);
        this.doScroll.emit(this.elRef.nativeElement.offsetTop);

    }

    stopSort() {
        this.dragging = false;
        this.isDragging.emit(this.dragging);
        this.doScroll.emit(this.elRef.nativeElement.offsetTop);
    }

    create(structure: Structure = null) {
        console.log('saving some page!!!!');
        let isAddingNew = false;
        if (structure != null && structure.insertIndex >= 0) {
            if (!this.elements) {
                this.elements = [];
            }
            this.elements.splice(structure.insertIndex, 0, structure);
            delete structure.insertIndex;
            isAddingNew = true;
            this.editingStructure = structure;
        }

        this.saveChanges.emit(this.elements);
        if (structure != null) {
            this.initialEditingStructure = (JSON.parse((JSON.stringify(this.values[structure.id]))));
        }
        setTimeout(() => {
            if (structure != null) {
                //this.initialEditingStructure = (JSON.parse((JSON.stringify(this.values[structure.id]))));

                const children = Array.from(this.blocksContainer?.nativeElement.children);
                let toEdit = null;
                children.forEach((el: HTMLElement) => {
                    if (el.getAttribute('container') === structure.id) {
                        toEdit = el;
                    }
                });
                //if(!isAddingNew) {
                let scrollPos = 0;
                if (toEdit) {
                    scrollPos = toEdit.offsetTop;
                }
                this.doScroll.emit(scrollPos);
                // }
            }
        }, 250);

    }

    addStructure(insertIndex: number = null) {
        console.log(insertIndex);
        const structure = {id: null, name: '', children: []} as Structure;
        if (insertIndex < 0 && this.elements) {
            insertIndex = this.elements.length;
        } else if (!this.elements) {
            console.log('no elems, set index to 0');
            insertIndex = 0;
        }

        structure.insertIndex = insertIndex;

        const dialogRef = this.matDialog.open(ElementCreateComponent, {
            disableClose: true,
            width: '850px',
            height: '99vh',
            data: {
                structures: this.structures,
                content: this.values,
                childList: null,
                element: structure
            }
        });

        const subs = dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.create(data);
            }
            subs.unsubscribe();
        });
    }

    removeStructure(parentStructure: Structure, childIndex: number) {

        const childList = parentStructure ? parentStructure.children : this.elements;

        childList.splice(childIndex, 1);
        this.saveChanges.emit();
    }

    getStructureByIdentifier(identifier: string) {
        return this.structures.filter(p => p.identifier === identifier)[0];
    }

    editStructureFields(structure: Structure) {
        if (this.editingStructure && structure.id === this.editingStructure.id) {
            return;
        }

        if (!this.values[structure.id]) {
            this.values[structure.id] = {};
        }
        if (this.values[structure.id]['settings'] instanceof Array) {
            this.values[structure.id]['settings'] = {};
        }

        if (this.editingStructure && this.initialEditingStructure &&
            JSON.stringify(this.initialEditingStructure) !== JSON.stringify(this.values[this.editingStructure.id])) {
            if (!this.confirmDialogOpen) {
                this.confirmDialogOpen = true;
                this.confirmDialogService.confirm('Wijzigingen',
                    `Je hebt het element ${this.editingStructure.name} aangepast. Wijzigingen publiceren?`)
                    .then(() => {
                        this.create();
                        this.initialEditingStructure = (JSON.parse((JSON.stringify(this.values[structure.id]))));
                        this.editingStructure = structure;
                        this.confirmDialogOpen = false;
                    }, () => {
                        this.values[this.editingStructure.id] = this.initialEditingStructure;
                        this.initialEditingStructure = (JSON.parse((JSON.stringify(this.values[structure.id]))));
                        this.editingStructure = structure;
                        this.confirmDialogOpen = false;
                    });
            }

        } else {
            this.initialEditingStructure = JSON.parse((JSON.stringify(this.values[structure.id])));
            this.editingStructure = structure;
        }

    }

    cancelChanges() {
        this.values[this.editingStructure.id] = JSON.parse(JSON.stringify(this.initialEditingStructure));
    }

    disableCancelChanges() {
        return JSON.stringify(this.values[this.editingStructure.id]) == JSON.stringify(this.initialEditingStructure);
    }

}
