import {Component, OnInit} from '@angular/core';
import {AbsenceService} from '../services/absence.service';
import {SortingComponent} from '../sorting.component';
import {Absence} from '../classes/absence';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorage} from '../storage.class';
import {Student} from '../classes/student.class';
import {StudentService} from '../services/student/student.service';
import {UntypedFormControl} from '@angular/forms';
import {ClassroomService} from '../services/classroom/classroom.service';
import {Classroom} from '../classes/classroom.class';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-absences',
    templateUrl: './absences.component.html',
    styleUrls: ['./absences.component.scss']
})
export class AbsencesComponent extends SortingComponent<Absence> implements OnInit {

    fcClassroomIds = new UntypedFormControl();
    fcStudentIds = new UntypedFormControl();
    fcDone = new UntypedFormControl();
    fcUnauthorized = new UntypedFormControl();

    students: Student[];
    classrooms: Classroom[];

    teacher = false;

    constructor(private studentService: StudentService,
                private classroomService: ClassroomService,
                private absenceService: AbsenceService,
                private confirmDialog: ConfirmDialogService,
                private route: ActivatedRoute,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.fcClassroomIds.valueChanges.subscribe(() => {
            this.getData();
        }));
        this.subscriptions.add(this.fcStudentIds.valueChanges.subscribe(() => {
            this.getData();
        }));
        this.subscriptions.add(this.fcDone.valueChanges.subscribe(() => {
            this.getData();
        }));
        this.subscriptions.add(this.fcUnauthorized.valueChanges.subscribe(() => {
            this.getData();
        }));
        this.subscriptions.add(this.route.params.subscribe((params: { student: number }) => {
            if (params.student) {
                this.fcStudentIds.setValue([+params.student]);
            }
        }));

        this.init();

        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.init();
        }));


    }

    private init() {
        this.getData();

        this.teacher = !!LocalStorage.user.schools?.find(s => s.id === LocalStorage.school.id);

        this.students = null;
        this.classrooms = null;
        if (this.teacher) {
            this.subscriptions.add(this.studentService.getAll(true).subscribe(students => {
                this.students = students.data;
            }));
            this.subscriptions.add(this.classroomService.getClassrooms(true, true).subscribe(classrooms => {
                this.classrooms = classrooms.data;
            }));
        }
    }

    private getData() {
        this.subscriptions.add(this.absenceService.getAbsences(this.fcStudentIds.value, this.fcClassroomIds.value, this.fcDone.value, this.fcUnauthorized.value).subscribe(absences => {
            this.list = absences.data;
            this.desc = true;
            this.currentSort = 'start_date';
        }));
    }

    delete(id) {
        this.confirmDialog.confirm('Verwijderen',
            `Weet je zeker dat je deze absentie wilt verwijderen?`, 'Verwijderen', 'Annuleren').then(() => {
            this.absenceService.deleteAbsence(id).subscribe(() => {
                this.getData();
            });
        }, () => {
        });
    }

    editAbsence(absenceId) {
        this.router.navigate([this.Routenames.absenceRegister, absenceId]);
    }
}
