
<div class="d-flex justify-content-between pr-4">
    <h2 mat-dialog-title>
        Element toevoegen

    </h2>
    <button mat-icon-button (click)="cancel()">
        <mat-icon  class="fa fa-times curpoint"></mat-icon>
    </button>
</div>
<div class="dialog-container">
    <div class="dialog-content">
        <ng-container *ngFor="let structure of structures">
            <div (click)="listClick(structure);" class="item">
                <img *ngIf="structure.image" class="example" src="{{LocalStorage.school?.fullUrl + structure.image}}">
                <ng-container *ngIf="!structure.image">
                    {{structure.name}}
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
