import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'byValue'
})
export class ByValuePipe implements PipeTransform {

    transform<T>(value: any[], id: any): T {
        return value.find(p => p === id);
    }

}
