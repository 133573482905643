import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user/user.service';
import {SchoolService} from '../services/school/school.service';
import {User} from '../classes/user.class';
import {LocalStorage} from '../storage.class';
import {Subscription} from 'rxjs';
import {Role} from '../classes/role.class';
import {AuthorisationService} from '../services/auth/authorisation.service';
import {CodaltComponent} from '../codalt.component';
import {AuthenticationService} from '../services/auth/authentication.service';
import {Routenames} from '../route-names.enum';
import {PhoneService} from '../services/phone-service/phone.service';


@Component({
    selector: 'user-detail-component',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent extends CodaltComponent implements OnInit {

    AuthorisationService = AuthorisationService;
    user: User;
    userId: number;
    subscriptions = new Subscription();
    LocalStorage = LocalStorage;
    schools: any;
    roles: Role[];
    currentRoles: number[];
    masterUserId: number;
    moreRoles = false;
    moreSchools = false;
    kindwijs = false;
    afterSchoolOffer = false;
    hasKindwijs = false;
    hasAfterSchoolOffer = false;
    otherUsers: User[];

    userManagement = false;

    constructor(private userService: UserService,
                private authorisationService: AuthorisationService,
                private authenticationService: AuthenticationService,
                private route: ActivatedRoute,
                private router: Router,
                public phoneService: PhoneService,
                private schoolService: SchoolService) {
        super();
    }

    loginAs() {
        this.subscriptions.add(this.authenticationService.loginAsUser(this.userId).subscribe(token => {
            this.authenticationService.switchToProfile(token.data).subscribe(() => {
                this.router.navigate([Routenames.profile]);
            });
        }));
    }

    ngOnInit() {
        this.userManagement = AuthorisationService.hasFeature('userManagement');
        if (this.userManagement) {
            this.subscriptions.add(this.authorisationService.getRoles().subscribe(roles => this.roles = roles));
        }
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.userId = +params['id'];
            if (LocalStorage.school) {
                this.getUser(this.userId);
            }
        }));
        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.getUser(this.userId);
        }));
        this.hasKindwijs = !!LocalStorage.user.anySchools.find(p => p.type === 'master');
        this.hasAfterSchoolOffer = !!LocalStorage.user.anySchools.find(p => p.type === 'offer');
        if (!this.user?.direct_schools?.length && this.userManagement) {
            this.subscriptions.add(this.userService.getParnassysUsers(false, true).subscribe(users => {
                this.otherUsers = users.data.filter(u => u.schools_count > 0);
            }));
        }
    }

    getUser(userId: number) {
        this.subscriptions.add(this.userService.getParnassysUser(userId).subscribe(user => {
            this.user = user.data;
            this.currentRoles = this.user.roles.map(r => r.id);
            this.getSchools();
            this.kindwijs = !!this.user.direct_schools.find(p => p.type === 'master');
            this.afterSchoolOffer = !!this.user.direct_schools.find(p => p.type === 'offer');
            this.user.roles.forEach(role => {
                if (!LocalStorage.user.roles.find(p => p.id === role.id)) {
                    this.moreRoles = true;
                }
            });
            this.user.direct_schools.forEach(school => {
                if (!LocalStorage.user.anySchools.find(p => p.is_direct && +p.id === +school.id)) {
                    this.moreSchools = true;
                }
            });
        }));
    }

    masterUserChange() {
        this.userService.saveUser(this.user).subscribe(user => {
            this.user = user.data;
        });
    }

    rolesChanged() {
        this.user['roleIds'] = this.currentRoles;
        this.user['kindwijs'] = this.kindwijs;
        this.user['offer'] = this.afterSchoolOffer;
        this.userService.saveUser(this.user).subscribe(user => {
            this.user = user.data;
        });
    }

    public getSchools() {
        this.subscriptions.add(this.schoolService.getSchools().subscribe(schools => {
            if (this.userId) {
                schools.data.map(school => {
                    school.checked = typeof this.user.direct_schools.filter(x => x.id == school.id)[0] != 'undefined';
                });
            }
            this.schools = schools;
        }));
    }
}
