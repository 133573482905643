<div *ngIf="user" class="container white">
    <h2>{{user.name}}</h2>
    <table class="table table-striped">
        <tr>
            <th colspan="2">
                Gebruiker
            </th>
        </tr>
        <tr>
            <td width="300">Naam</td>
            <td>{{user?.name}}</td>
        </tr>
        <tr>
            <td>E-mailadres</td>
            <td><a href="mailto:{{user?.email}}">{{user?.email}}</a></td>
        </tr>
        <tr *ngIf="AuthorisationService.hasFeature('userTransactions')">
            <td>Saldo</td>
            <td>
                {{user?.transactionAmount | currency : 'EUR'}}
                <button class="ml-3 button" routerLink="/{{Routenames.parnassysUsers}}/{{user.id}}/transactions">
                    Transacties
                </button>
            </td>
        </tr>
        <tr>
            <td>Aangemaakt op</td>
            <td>
                {{user?.created_at | date:'d-MM-yyyy HH:mm'}}
                <span [hidden]="!user?.viaparnassys"> (via parnassys)</span>
            </td>
        </tr>
        <tr>
            <td>Laatst gewijzigd</td>
            <td>
                {{user?.updated_at | date:'d-MM-yyyy HH:mm'}}
            </td>
        </tr>

        <tr>
            <td>Telefoonnummer</td>
            <td>
                <a class="curpoint" (click)="phoneService.call(user?.phone, user.name)">{{user?.phone}}</a>
            </td>
        </tr>
        <tr>
            <td>Telefoonnummer werk</td>
            <td>
                <a class="curpoint" (click)="phoneService.call(user?.phonework, user.name)">{{user?.phonework}}</a>
            </td>
        </tr>
        <tr>
            <td>
                Adres
            </td>
            <td>
                {{user.street}} {{user.housenumber}}<br>
                {{user.zipcode}} {{user.town}}
            </td>
        </tr>
        <tr>
            <td>Leerlingen (waarvan verzorger)</td>
            <td>
                <i [hidden]="user?.students?.length > 0">Deze gebruiker is geen verzorger van leerlingen</i>
                <div *ngFor="let student of user?.students" class="students">
                    <b><a routerLink="/{{Routenames.parnassysStudent}}/{{student.id}}">{{student.name}}</a>
                    </b><br>
                    Leerlingnummer: {{student.number}}<br> Verzorgersoort: {{student?.pivot?.type}}<br><br>
                </div>
            </td>
        </tr>
        <tr>
            <td>Groepen (waarvan docent)</td>
            <td>
                <i [hidden]="user?.classrooms?.length > 0">Deze gebruiker is geen docent van een klas</i>
                <div *ngFor="let classroom of user?.classrooms">
                    <b><a routerLink="/{{Routenames.parnassysClassrooms}}/{{classroom.id}}">{{classroom.classroom_name}}
                        ({{classroom.school.name}}, {{classroom.school_year}})</a></b>
                </div>
            </td>
        </tr>
        <tr>
            <td>Werkzaam bij scholen</td>
            <td>
                <i [hidden]="user?.direct_schools?.length > 0">Deze gebruiker is niet werkzaam bij een school</i>
                <div *ngFor="let school of user?.direct_schools">
                    <b>{{school.name}}</b> ({{school.slug}})
                </div>
                <mat-checkbox (change)="rolesChanged()" *ngIf="hasAfterSchoolOffer" [(ngModel)]="afterSchoolOffer" [disabled]="afterSchoolOffer && user?.direct_schools?.length === 1">
                    Naschools aanbod
                </mat-checkbox>
                <mat-checkbox (change)="rolesChanged()" *ngIf="hasKindwijs" [(ngModel)]="kindwijs" [disabled]="kindwijs && user?.direct_schools?.length === 1">
                    Kindwijs
                </mat-checkbox>
                <i *ngIf="hasKindwijs && kindwijs && user?.direct_schools?.length === 1"><br>Een gebruiker moet aan minimaal 1 school gekoppeld zijn</i>

            </td>
        </tr>
        <tr *ngIf="userManagement">
            <td>
                Rechten
            </td>
            <td>
                <ng-select (change)="rolesChanged()"
                           [(ngModel)]="currentRoles"
                           [closeOnSelect]="false"
                           [disabled]="moreRoles"
                           [hideSelected]="true"
                           [items]="roles"
                           [multiple]="true"
                           bindLabel="name"
                           bindValue="id"
                           name="roleIds"
                           placeholder="Gebruikersrollen"></ng-select>
                <div *ngIf="moreRoles" class="alert alert-warning">
                    Deze gebruiker heeft rollen die jij niet hebt. Daarom kun je geen rollen toewijzen.
                </div>
            </td>
        </tr>
        <tr *ngIf="!user?.direct_schools?.length && userManagement">
            <td>
                Ouderaccount
            </td>
            <td>
                <ng-select (change)="masterUserChange()"
                           [(ngModel)]="user.master_id"
                           [clearable]="true"
                           [closeOnSelect]="true"
                           [hideSelected]="true"
                           [items]="otherUsers"
                           [multiple]="false"
                           [virtualScroll]="true"
                           bindLabel="email"
                           bindValue="id"
                           name="masterUserId"
                           placeholder="Selecteer een overname account"></ng-select>
                <i>Als je een Kindwijs account instelt worden de kinderen van dit account aan het gekozen Kindwijs account gekoppeld. Let op: dit gebeurt de eerstvolgende nacht.
                    Dit ouderaccount is hierdoor niet meer bruikbaar.</i>
            </td>
        </tr>
        <tr *ngIf="!LocalStorage.parentUser && AuthorisationService.hasFeature('loginAsUser')">
            <td>Inloggen</td>
            <td>
                <div *ngIf="LocalStorage.user.id === user.id">Je kunt jezelf niet overnemen</div>
                <div *ngIf="moreRoles">Je kunt deze gebruiker niet overnemen omdat hij/zij meer rollen heeft</div>
                <div *ngIf="moreSchools">Je kunt deze gebruiker niet overnemen omdat hij/zij toegang heeft tot scholen waar je zelf geen toegang toe hebt</div>
                <button class="button" (click)="loginAs()" *ngIf="!moreRoles && !moreSchools && LocalStorage.user.id !== user.id">Inloggen
                    als {{user.name}}</button>
            </td>
        </tr>
        <tr *ngIf="!user.viaparnassys && userManagement">
            <td>Aanpassen</td>
            <td>
                <button class="button" routerLink="/{{Routenames.users}}/{{user.id}}">Gebruiker aanpassen</button>
            </td>
        </tr>
    </table>
    <app-user-auth-log class="d-none d-md-block" *ngIf="userManagement" [user]="user"></app-user-auth-log>

    <br><br>
    <a routerLink="/{{Routenames.parnassysUsers}}">&laquo; Terug naar alle gebruikers</a>
    <br><br>
</div>
