import {Component, OnInit} from '@angular/core';
import {PaymentService} from '../../../services/payment.service';
import {CodaltComponent} from '../../../codalt.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LocalStorage} from '../../../storage.class';
import {Settings} from '../../../settings.class';
import {PaymentRequest} from '../../../classes/payment-request';
import {Utils} from '../../../utils.class';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-add-credit',
    templateUrl: './add-credit.component.html',
    styleUrls: ['./add-credit.component.scss']
})
export class AddCreditComponent extends CodaltComponent implements OnInit {

    changedAmount = false;
    openPayments: PaymentRequest[];
    totalOpen: number;
    paying = false;
    form: UntypedFormGroup;
    fc: {
        amount: UntypedFormControl
    };

    constructor(private route: ActivatedRoute,
                private paymentService: PaymentService) {
        super();
    }

    max() {
        this.fc.amount.setValue(Math.round(this.calcMax() * 100) / 100);
    }

    calcMax() {
        return (Settings.MAX_BALANCE - LocalStorage.user.transactionAmount) + (+this.route.snapshot.params['extra'] || this.totalOpen);
    }

    ngOnInit(): void {
        this.paymentService.getOwnPaymentRequests().subscribe(payments => {
            this.openPayments = payments.data;
            this.totalOpen = this.openPayments.map(p => {
                if (p.id === +this.route.snapshot.params['paymentRequestId']) {
                    return +this.route.snapshot.params['extra'];
                }
                return p.priceToPay;
            }).reduce((a, c) => {
                return a + c;
            }, 0);
            this.totalOpen = Math.ceil(this.totalOpen * 100) / 100;
            let min = 0.01;
            if (this.route.snapshot.params['extra']) {
                min = +this.route.snapshot.params['extra'];
            }
            if (this.route.snapshot.params['paymentRequestId'] && this.route.snapshot.params['extra']) {
                const pr = this.openPayments.find(p => p.id === +this.route.snapshot.params['paymentRequestId']);
                if (pr.price_lower) {
                    min = 0.01;
                }
            }
            this.fc = {
                amount: new UntypedFormControl(this.totalOpen - LocalStorage.user.transactionAmount, [
                    Validators.required,
                    Validators.min((min - LocalStorage.user.transactionAmount) || 0.01),
                    Validators.max(Math.round(this.calcMax() * 100) / 100)
                ])
            };
            this.form = new UntypedFormGroup(this.fc);
            if (this.route.snapshot.params['extra']) {
                this.fc.amount.setValue(+this.route.snapshot.params['extra'] - LocalStorage.user?.transactionAmount);

                this.changedAmount = true;
            }
        });
    }

    pay() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.paying = true;
            this.paymentService.createPayment(
                this.fc.amount.value,
                +this.route.snapshot.params['extra'] || null,
                +this.route.snapshot.params['paymentRequestId'] || null,
                +this.route.snapshot.params['eventId'] || null).subscribe(paymentData => {
                window.location.href = paymentData.data.url;
            }, () => {
                this.paying = false;
            });
        }
    }

}
