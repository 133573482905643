import {Routenames} from './route-names.enum';
import {Utils} from './utils.class';
import {LocalStorage} from './storage.class';
import {Settings} from './settings.class';
import {AuthorisationService} from './services/auth/authorisation.service';
import {Component, HostListener, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

declare const cordova;

@Component({template: ''})
export class CodaltComponent implements OnDestroy {

    dropUpOpen = false;

    Routenames = Routenames;
    Utils = Utils;
    LocalStorage = LocalStorage;
    Settings = Settings;
    AuthorisationService = AuthorisationService;
    isCordova = typeof cordova !== 'undefined';

    hideEditButtons = false;
    protected subscriptions = new Subscription();
    private hideTimeout;

    constructor() {
        if (this.isCordova) {
            this.hideTimeout = setTimeout(() => {
                this.hideEditButtons = true;
            }, 1800);
        }
    }

    @HostListener('body:click', ['$event'])
    public onClick() {
        if (this.isCordova) {
            this.hideEditButtons = false;
            if (this.hideTimeout) {
                clearTimeout(this.hideTimeout);
            }
            this.hideTimeout = setTimeout(() => {
                this.hideEditButtons = true;
            }, 2000);
        }
    }

    @HostListener('touchmove', ['$event'])
    public touchMove() {
        if (this.isCordova) {
            this.hideEditButtons = true;
        }
    }


    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
