<form *ngIf="form" [formGroup]="form" class="container bg-white item-edit">
    <div *ngIf="discussion" class="row">
        <div class="col-auto no-outline pr-0 curpoint" routerLink="/{{Routenames.discussions}}/{{discussion?.id}}">
            <i class="fas fa-2x fa-angle-left"></i>
        </div>
        <div class="col">
            <h2 class="news__title">Oudergesprekkenplanner {{LocalStorage.school?.name}}</h2>
        </div>
    </div>
    <div *ngIf="faDiscussiontimesGroups">

        <b>Avonden en tijden</b><br>
        <div *ngIf="discussion.times?.length > 0">
            <b>Huidige data:</b><br>
            <div *ngFor="let time of discussion.times" [class.line-through]="time.deleted" class="my-2 user-select-none">
                <span class="mr-2">
                    {{time.start_date | date:'EEE d MMM yyyy HH:mm'}} - {{time.end_date | date:'HH:mm'}}
                </span>
                <i (click)="time.deleted=true" *ngIf="!time.deleted" class="fa fa-trash curpoint"></i>
                <i (click)="time.deleted=false" *ngIf="time.deleted" class="fa fa-undo curpoint"></i>
            </div>
        </div>
        <div *ngFor="let fgDateRange of faDiscussiontimesGroups.controls; index as i" [formGroup]="fgDateRange" class="row align-items-center daterange">
            <div class="col-md col-2 curpoint order-md-last text-right text-md-left">
                <i (click)="removeRange(i)" class="fa fa-trash curpoint"></i>
            </div>
            <div class="col-md col-12">
                <mat-form-field (click)="pickerStart.open()" class="w-100">
                    <mat-label>Datum</mat-label>
                    <input [matDatepicker]="pickerStart" [min]="getFirstStartTime()" formControlName="start_date" matInput>
                    <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart></mat-datepicker>
                    <mat-error [control]="fgDateRange.get('start_date')"></mat-error>
                </mat-form-field>
            </div>
            <div class="col-md  col-10">
                <mat-form-field class="w-100 pl-md-2">
                    <mat-label>Starttijd</mat-label>
                    <input formControlName="start_time" matInput type="text">
                    <mat-error [control]="fgDateRange.get('start_time')"></mat-error>
                </mat-form-field>
            </div>
            <div class="col-md col-10">
                <mat-form-field class="w-100 pl-md-2">
                    <mat-label>Eindtijd</mat-label>
                    <input formControlName="end_time" matInput type="text">
                    <mat-error [control]="fgDateRange.get('end_time')"></mat-error>
                </mat-form-field>
            </div>

            <mat-form-field class="col-md-auto col-14">
                <mat-label>Minuten per gesprek incl. wisseling</mat-label>
                <input formControlName="interval" matInput type="text">
                <mat-error [control]="fgDateRange.get('interval')"></mat-error>
            </mat-form-field>


        </div>
        <button (click)="addDiscussiontimesGroup()" class="mt-3" mat-button mat-raised-button>Datum toevoegen</button>
        <br><br>
        <b *ngIf="discussion.discussiontimes?.length > 0 && discussiontimesGroups?.length > 0">Extra tijden</b><br>

        <div *ngFor="let distimeGroup of discussiontimesGroups">
            <b>{{distimeGroup.start_date | date:'EEEE d MMMM yyyy HH:mm'}}
                - {{distimeGroup.end_date | date:'HH:mm'}}
                ({{distimeGroup.times?.length}} gesprekken)
            </b><br>
            <span *ngFor="let time of distimeGroup.times; let i = index">
                    {{time | date:'HH:mm'}}{{i == distimeGroup.times.length - 1 ? '' : ','}}
                </span>
        </div>
        <div class="mt-3">
            <b>Selecteer groepen</b>
            <button (click)="allClassrooms()" class="ml-3" mat-button>(De)selecteer alle groepen</button>
            <mat-selection-list *ngIf="classrooms" class="classroom-list" formControlName="classrooms">
                <mat-list-option *ngFor="let classroom of classrooms" [value]="classroom.id" checkboxPosition="before">
                    {{classroom.classroom_name}}
                </mat-list-option>
            </mat-selection-list>
            <mat-error *ngIf="form.get('classrooms').touched" [control]="form.get('classrooms')"></mat-error>
        </div>
        <div>
            <mat-form-field class="mt-3 w-100">
                <mat-label>Naam</mat-label>
                <input formControlName="name" matInput type="text">
                <mat-error [control]="form.get('name')"></mat-error>
            </mat-form-field>
        </div>
        <div>
            <b>Omschrijving</b>
            <simple-tiny [content]="form.get('description')"
                         elementId="description"
                         ngDefaultControl
                         propertyName="value"
            ></simple-tiny>
            <mat-error [control]="form.get('description')"></mat-error>
        </div>
        <div class="row">
            <div class="col-24 mt-3 mb-2">
                <b>Eenmalig inschrijven per leerling</b><br>
                <small><i>Sta maximaal 1 gesprek toe per leerling. Dit zorgt ervoor dat b.v. gescheiden ouders geen extra gesprek kunnen plannen.</i></small><br>
                <mat-checkbox formControlName="limit_registrations">Inschrijvingen beperken</mat-checkbox>
            </div>
            <div class="col-24 mt-1 mb-2">
                <b>Inschrijvingen door ouders</b><br>
                <small><i>Ouders met minimaal het hieronder opgegeven aantal kinderen kunnen inschrijven tijdens de voorinschrijvingen</i></small><br>
                <mat-form-field class="mt-1">
                    <mat-label>Aantal kinderen voorinschrijving</mat-label>
                    <input appDecimalInput decimalInput="0" formControlName="pre_child_count" matInput>
                    <mat-error [control]="form.get('pre_child_count')"></mat-error>
                </mat-form-field>
            </div>
            <div class="col-24 col-md col-date">
                <mat-form-field (click)="pickerPreSubs.open()" class="w-50">
                    <mat-label>Start voorinschrijvingen</mat-label>
                    <input [matDatepicker]="pickerPreSubs" formControlName="pre_subscription_start" matInput>
                    <mat-datepicker-toggle [for]="pickerPreSubs" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #pickerPreSubs></mat-datepicker>
                    <mat-error [control]="form.get('pre_subscription_start')"></mat-error>
                </mat-form-field>
                <mat-form-field class="w-50 pl-2">
                    <mat-label>Tijd</mat-label>
                    <input formControlName="pre_subscription_start_time" matInput type="text">
                    <mat-error [control]="form.get('pre_subscription_start_time')"></mat-error>
                </mat-form-field>
            </div>

            <div class="col-24 col-md col-date">
                <mat-form-field (click)="pickerSubs.open()" class="w-50">
                    <mat-label>Start inschrijvingen</mat-label>
                    <input [matDatepicker]="pickerSubs" formControlName="subscription_start" matInput>
                    <mat-datepicker-toggle [for]="pickerSubs" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #pickerSubs></mat-datepicker>
                    <mat-error [control]="form.get('subscription_start')"></mat-error>
                </mat-form-field>
                <mat-form-field class="w-50 pl-2">
                    <mat-label>Tijd</mat-label>
                    <input formControlName="subscription_start_time" matInput type="text">
                    <mat-error [control]="form.get('subscription_start_time')"></mat-error>
                </mat-form-field>
            </div>
            <div class="col-24 col-md col-date">
                <mat-form-field (click)="pickerEndSubs.open()" class="w-50">
                    <mat-label>Einde inschrijvingen</mat-label>
                    <input [matDatepicker]="pickerEndSubs" formControlName="subscription_end" matInput>
                    <mat-datepicker-toggle [for]="pickerEndSubs" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndSubs></mat-datepicker>
                    <mat-error [control]="form.get('subscription_end')"></mat-error>
                </mat-form-field>
                <mat-form-field class="w-50 pl-2">
                    <mat-label>Tijd</mat-label>
                    <input formControlName="subscription_end_time" matInput type="text">
                    <mat-error [control]="form.get('subscription_end_time')"></mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="floating-buttons">
        <button (click)="save()" [disabled]="saving" color="primary" mat-fab>
            <mat-icon *ngIf="!saving" class="fas fa-save"></mat-icon>
            <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
        </button>
    </div>
</form>
