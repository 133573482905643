<ng-container *ngIf="video">
    <div class="processing" *ngIf="!video.processed">
        Bezig met verwerken, dit kan enkele minuten duren.
    </div>
    <video *ngIf="!onlyThumb" [poster]="thumbPath" [src]="videoPath" controls playsinline webkit-playsinline id="video-{{video.id}}">
    </video>

    <i class="fas fa-play" *ngIf="onlyThumb"></i>
    <div class="overlay" *ngIf="onlyThumb"></div>
    <app-codalt-image class="mb-0"
                      [secureType]="secureType"
                      *ngIf="onlyThumb" [path]="(video.article_id || video.chat_id)  + '/' + video.thumb">
    </app-codalt-image>
</ng-container>
