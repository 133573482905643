<div class="container white">
    <div *ngIf="LocalStorage.school">
        <h2>{{LocalStorage.school.name}}</h2>
        <div class="row">
            <div class="col-24 col-sm-12 mb-5">
                <h3>
                    Algemene informatie
                    <i *ngIf="AuthorisationService.hasFeature('schoolSave')" class="fas fa-pencil-alt" routerLink="/{{Routenames.schoolSettings}}"></i>
                </h3>

                <div *ngFor="let address of LocalStorage.school.addresses">
                    <div class="row">
                        <div class="col-auto"><i class="m-2 fas fa-phone"></i></div>
                        <div class="col p-2">
                            <a href="tel:{{address.phone}}" target="_BLANK">{{address.phone}}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto"><i class="m-2 fas fa-envelope"></i></div>
                        <div class="col p-2">
                            <a href="mailto:{{address.email}}" target="_BLANK">{{address.email}}</a>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-auto"><i class="m-2 fas fa-user-secret"></i></div>
                    <div class="col p-2">
                        <a href="{{LocalStorage.school.fullUrl}}{{LocalStorage.school.privacy_url}}" target="_BLANK">Lees onze privacyverklaring</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto"><i class="m-2 fas fa-link"></i></div>
                    <div class="col p-2">
                        <a href="{{LocalStorage.school.fullUrl}}" target="_BLANK">Bezoek onze website</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto"><i class="m-2 fas fa-info"></i></div>
                    <div class="col p-2">
                        App versie: {{version?.datetime | date:'d MMM yyyy HH:mm:ss'}}
                    </div>
                </div>
                <img class="logo" src="./assets/logo/{{LocalStorage.school.slug}}.svg"/>

            </div>
            <div class="col-24 col-sm-12">
                <div class="mb-5">
                    <h3>Adresgegevens {{LocalStorage.school.prefix}} {{LocalStorage.school.name}}</h3>
                    <div *ngFor="let address of LocalStorage.school.addresses">
                        <h4>Bezoekadres</h4>
                        {{address.street}}<br>
                        {{address.zipcode}} {{address.city}}
                        <ng-container *ngIf="address.mailbox">
                            <h4 class="mt-3">Postadres</h4>
                            {{address.mailbox}}<br>
                            {{address.mailbox_zipcode}} {{address.mailbox_city}}
                        </ng-container>
                    </div>
                </div>
                <div class="mb-5">
                    <h3>Social media</h3>
                    <div *ngIf="LocalStorage.school.facebook_url" class="row">
                        <div class="col-auto"><i class="m-2 fab fa-facebook-f"></i></div>
                        <div class="col p-2">
                            <a href="{{LocalStorage.school.facebook_url}}" target="_BLANK">{{LocalStorage.school.facebook_url}}</a>
                        </div>
                    </div>
                    <div *ngIf="LocalStorage.school.instagram_url" class="row">
                        <div class="col-auto"><i class="m-2 fab fa-instagram"></i></div>
                        <div class="col p-2">
                            <a href="{{LocalStorage.school.instagram_url}}" target="_BLANK">{{LocalStorage.school.instagram_url}}</a>
                        </div>
                    </div>
                    <div *ngIf="LocalStorage.school.linkedin_url" class="row">
                        <div class="col-auto"><i class="m-2 fab fa-linkedin"></i></div>
                        <div class="col p-2">
                            <a href="{{LocalStorage.school.linkedin_url}}" target="_BLANK">{{LocalStorage.school.linkedin_url}}</a>
                        </div>
                    </div>
                    <div *ngIf="LocalStorage.school.twitter_url" class="row">
                        <div class="col-auto"><i class="m-2 fab fa-twitter"></i></div>
                        <div class="col p-2">
                            <a href="{{LocalStorage.school.twitter_url}}" target="_BLANK">{{LocalStorage.school.twitter_url}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div *ngIf="LocalStorage.school.type !== 'master' && LocalStorage.mainSchool" class="mb-5">
                    <h3>{{LocalStorage.mainSchool.prefix}} {{LocalStorage.mainSchool.name}}</h3>
                    Onze basisschool is onderdeel van {{LocalStorage.mainSchool.prefix}} {{LocalStorage.mainSchool.name}}.
                    Hieronder vindt u de contactgegevens van {{LocalStorage.mainSchool.prefix}} {{LocalStorage.mainSchool.name}}.
                    <div *ngFor="let address of LocalStorage.mainSchool.addresses" class="pt-3">
                        <div class="row">
                            <div class="col-auto"><i class="m-2 fas fa-phone"></i></div>
                            <div class="col p-2">
                                <a href="tel:{{address.phone}}" target="_BLANK">{{address.phone}}</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto"><i class="m-2 fas fa-envelope"></i></div>
                            <div class="col p-2">
                                <a href="mailto:{{address.email}}" target="_BLANK">{{address.email}}</a>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let address of LocalStorage.mainSchool.addresses" class="row">
                        <div class="col-24 col-sm-6">

                            <h4 class="mt-3">Bezoekadres</h4>
                            {{address.street}}<br>
                            {{address.zipcode}} {{address.city}}
                        </div>
                        <div *ngIf="address.mailbox" class="col-24 col-sm-6">
                            <h4 class="mt-3">Postadres</h4>
                            {{address.mailbox}}<br>
                            {{address.mailbox_zipcode}} {{address.mailbox_city}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
