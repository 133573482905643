import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {RequestRestorePasswordState} from './request-restore-password-state.enum';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CodaltComponent} from '../../codalt.component';
import {Utils} from '../../utils.class';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-request-restore-password',
    templateUrl: './request-restore-password.component.html',
    styleUrls: ['./request-restore-password.component.scss']
})
export class RequestRestorePasswordComponent extends CodaltComponent implements OnInit {

    public RequestRestorePasswordState = RequestRestorePasswordState;

    subscriptions = new Subscription();

    form = new UntypedFormGroup({
        email: new UntypedFormControl(AuthenticationService.filledInEmail, [Validators.required, Validators.email])
    });
    public requestState: RequestRestorePasswordState = RequestRestorePasswordState.FillInEmail;

    constructor(private authService: AuthenticationService) {
        super();
    }

    ngOnInit() {
        this.subscriptions.add(this.form.get('email').valueChanges.subscribe((val: string) => {
            if (val.indexOf(' ') !== -1) {
                this.form.get('email').setValue(val.replace(' ', ''));
            }
        }));
    }

    ngOnDestroy() {
        AuthenticationService.filledInEmail = this.form.get('email').value;
        this.subscriptions.unsubscribe();
    }

    requestPasswordEmail() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.requestState = RequestRestorePasswordState.SendingEmail;
            this.authService.requestRestorePassword(this.form.value.email).then((response) => {
                this.requestState = RequestRestorePasswordState.SendedEmail;
            }, () => {
                this.requestState = RequestRestorePasswordState.SendEmailFail;
            });
        }
    }

}
