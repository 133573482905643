import {Component, OnDestroy, OnInit} from '@angular/core';
import {Article} from '../classes/article.class';
import {Event} from '../classes/event.class';
import {ArticleService} from '../services/article.service';
import {CodaltComponent} from '../codalt.component';
import {LocalStorage} from '../storage.class';
import {EventService} from '../services/event.service';
import {CordovaService} from '../services/cordova.service';
import {Router} from '@angular/router';
import {DiscussionService} from '../services/discussion.service';
import {Discussion} from '../classes/discussion.class';
import {PaymentService} from '../services/payment.service';
import {PaymentRequest} from '../classes/payment-request';
import {debounceTime, startWith} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {AbsenceService} from '../services/absence.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {Routenames} from '../route-names.enum';
import {LoggerService} from '../services/logger.service';
import {PermissionService} from '../services/permission.service';
import {AfterSchoolService} from '../services/after-school.service';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends CodaltComponent implements OnInit, OnDestroy {

    fcSearch = new UntypedFormControl('');

    payments: PaymentRequest[] = [];
    articles: Article[] = [];
    events: Event[] = [];
    afterSchoolEvents: Event[];
    discussions: Discussion[] = [];
    private loadedSchoolSlug: string;
    viewState = {
        permissions: false,
        remain: false,
        saldo: false,
        students: false,
        discussionsPlanner: false,
        paymentRequests: false,
        discussions: false,
        discussionsDanger: false,
        absence: false,
        absenceHighlight: false,
        absenceDanger: false,
        permissionHighlight: false,
        permissionDanger: false
    };

    constructor(private articleService: ArticleService,
                private absenceService: AbsenceService,
                private cordovaService: CordovaService,
                private router: Router,
                private discussionService: DiscussionService,
                private paymentService: PaymentService,
                private permissionService: PermissionService,
                private http: HttpClient,
                private breakpointObserver: BreakpointObserver,
                private dialogService: ConfirmDialogService,
                private loggerService: LoggerService,
                private eventService: EventService,
                private afterSchoolService: AfterSchoolService) {
        super();

    }

    ngOnInit() {
        this.http.get('assets/version.json').subscribe(versionInfo => {
            LocalStorage.version = versionInfo as { datetime: string };
            this.subscriptions.add(LocalStorage.schoolChange.pipe(startWith(null), debounceTime(25)).subscribe(() => {
                if (LocalStorage.school) {
                    this.getData();
                }
            }));
        });
        this.subscriptions.add(LocalStorage.bottomMenuReload.subscribe(() => {
            this.loadedSchoolSlug = null;
            this.getData();
        }));
        this.subscriptions.add(this.fcSearch.valueChanges.pipe(debounceTime(200)).subscribe(search => {
            this.getArticles();
            this.getEvents();
            this.getAfterSchoolEvents();
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigateByUrl('/');
        });
    }

    private getData() {
        if (!this.loadedSchoolSlug || this.loadedSchoolSlug !== LocalStorage.school.slug) {
            this.loadedSchoolSlug = LocalStorage.school.slug;
            this.getEvents();
            this.getArticles();
            this.getAfterSchoolEvents();
            if (LocalStorage.school?.type !== 'offer') {
                this.subscriptions.add(this.discussionService.getHome().subscribe(result => {
                    this.discussions = result.data;
                    this.viewState.discussionsDanger = this.discussions.filter(d => d.subscription_open && d.classrooms_to_go?.length > 0 && d.own_times.length === 0)?.length > 0;
                }));
                this.subscriptions.add(this.paymentService.getOwnPaymentRequests().subscribe(payments => {
                    this.payments = payments.data;
                }));
            }

            this.viewState.remain = this.AuthorisationService.hasFeature('paymentsDefaultsRemain') && LocalStorage.school?.type !== 'master';

            if (LocalStorage.school?.type !== 'offer') {
                this.viewState.absenceDanger = false;
                this.viewState.absenceHighlight = false;
                this.subscriptions.add(this.absenceService.getAbsenceHomeStats().subscribe(stats => {
                    this.viewState.absenceDanger = stats.data.notApprovedLastWeek > 0;
                    this.viewState.absenceHighlight = stats.data.future > 0;
                }));
            }


            this.viewState.students = this.AuthorisationService.hasFeature('studentlist');
            this.viewState.discussionsPlanner = this.AuthorisationService.hasFeature('studentlist');

            const hasAnySchoolStudent = LocalStorage.user.students?.length > 0;
            this.viewState.discussions = hasAnySchoolStudent;
            this.viewState.absence = (hasAnySchoolStudent || LocalStorage.user.schools?.filter(s => s.type !== 'master').length > 0) && LocalStorage.school?.absences;
            this.viewState.paymentRequests = hasAnySchoolStudent;
            this.viewState.permissions = hasAnySchoolStudent;
            this.viewState.saldo = hasAnySchoolStudent || LocalStorage.user.transactionAmount !== 0; // Hide when no saldo and no students

            const today = new Date();
            this.viewState.permissionDanger = false;
            this.viewState.permissionHighlight = false;
            if (LocalStorage.school?.type !== 'offer') {
                this.subscriptions.add(this.permissionService.getPermissionQuestionsForStudents().subscribe(questions => {
                    questions.data.forEach(question => {
                        if (!question.deadline_date || question.deadline_date >= today) {
                            if (question._answers?.length !== LocalStorage.user?.students?.length) {
                                this.viewState.permissionDanger = true;
                                this.viewState.permissionHighlight = true;
                            }
                        }
                    });
                }));
            }
        }
    }

    private getArticles() {
        if (LocalStorage.school?.type !== 'offer') {
            this.subscriptions.add(this.articleService
                                       .getList(5, 0, false, true, [], this.fcSearch.value)
                                       .subscribe(articles => {
                                               this.articles = articles.data;
                                           },
                                           () => {
                                               this.dialogService.confirm(
                                                   'Laden mislukt',
                                                   'Het is niet gelukt om de app te laden. Controleer je internetverbinding.',
                                                   'Sluiten',
                                                   'Uitloggen').then(() => {
                                               }, () => {
                                                   this.loggerService.logError(`Force logout u:${LocalStorage.user?.id} s:${LocalStorage.school?.id}  ss:${LocalStorage.schools?.length} slug: ${localStorage.getItem('selectedschool')} `);
                                                   this.router.navigate([Routenames.logout]);
                                               });
                                           }));
        }
    }

    private getEvents() {
        this.subscriptions.add(this.eventService
            .getList(5, 0, false, true, [], this.fcSearch.value, true)
            .subscribe(events => {
                this.events = events.data;
            }));
    }

    private getAfterSchoolEvents() {
        const mobile = this.breakpointObserver.isMatched('(max-width: 575px)');
        this.subscriptions.add(this.afterSchoolService
                                   .getList((mobile ? 3 : 5), 0, this.fcSearch.value)
                                   .subscribe(events => {
                                       this.afterSchoolEvents = events.data;
                                   }));
    }
}
