import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ConfirmDialogService {

    private prevBackFunction: Function;

    constructor(public dialog: MatDialog) {
    }

    confirm(title, text, confirm = 'Oké', cancel = 'Annuleren', maxWidth = '400px'): Promise<void> {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog',
            maxWidth,
            disableClose: true,
            closeOnNavigation: true,
            data: {
                title: title,
                text: text,
                confirm: confirm,
                cancel: cancel
            }
        });

        return new Promise((resolve, reject) => {
            const subs = dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    resolve();
                } else {
                    reject();
                }
                subs.unsubscribe();
            });
        });
    }
}
