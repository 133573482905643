<h1 mat-dialog-title class="title pr-1 pt-1">
    <div>
        {{data.name}}
    </div>
    <button mat-icon-button mat-dialog-close>
        <mat-icon class="fas fa-close"></mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div class="news-home">
        <div class="news-home">
            <a class="item" href="tel:{{data.phoneNumber}}">
                <h3 class="item__title">Herkenbaar bellen</h3>
                <div class="item__details">
                    {{data.phoneNumber}}
                </div>
            </a>
            <a class="item" href="tel:%2331%23{{data.phoneNumber}}">
                <h3 class="item__title">Anoniem bellen</h3>
                <div class="item__details">
                    {{data.phoneNumber}}
                </div>
            </a>
        </div>
    </div>
</div>
