<div class="container mt-3" *ngIf="event">
    <div class="row justify-content-center ">
        <div class="col-md-12 payment p-4">
            <div>
                <h2 class="news__title">Betalen voor deelname aan {{event?.title}}</h2>
            </div>
            <div>
                <div>
                    <b>Bedrag: </b>{{priceToPay | currency : 'EUR'}}
                    <small *ngIf="priceToPay > event.price">
                        ({{subscriptionsToPay.length}} x {{event.price | currency : 'EUR'}})
                    </small>
                </div>
                <div>
                    <b>Voor leerlingen: </b>
                    <ng-container *ngFor="let subscription of subscriptionsToPay; let first = first;">
                        {{first ? '' : ', '}}{{subscription.student.name}}
                    </ng-container>
                </div>
                <div class="mt-4" *ngIf="currentSaldo < priceToPay">
                    Uw saldo is te laag om dit verzoek te betalen. Waardeer eerst uw saldo op.
                </div>
                <div class="d-flex justify-content-between mt-4">
                    <div class="amount" [class.low-saldo]="currentSaldo < priceToPay">
                        Huidig saldo: &euro; {{currentSaldo | number: '1.2-2'}}
                    </div>
                    <button class="button--primary" (click)="pay()" [disabled]="form.invalid">
                        {{(fc.price.value * subscriptionsToPay.length) > currentSaldo ? 'Opwaarderen en betalen' : 'Betalen'}}
                        <mat-icon *ngIf="paying" class="fas fa-circle-notch fa-spin"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
