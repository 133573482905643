<section *ngIf="item" class="detail">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-24 col-sm-20 col-md-16">
                <div class="detail__block">
                    <h1 class="detail__title">
                        Ingevuld formulier op {{item.page?.name || ''}}
                    </h1>
                    <div class="detail__details">
                        <div class="detail__author">
                            {{item.updated_at | date:'d MMMM yyyy HH:mm:ss'}}
                        </div>
                    </div>

                    <div *ngIf="formFields">
                        <div class="text-bold">
                            <p></p>
                        </div>
                        <div class="text-bold">
                            <p>Formulier gegevens: </p>
                        </div>
                        <div *ngFor="let field of formFields">
                            <strong>{{field}}</strong>
                            <ng-container *ngIf="formData['saveData'][field]['file'] != undefined">
                                <a class="d-block mt-1 mb-2"
                                   href="/api/form/attachment/?file={{formData['saveData'][field]['file']}}&filename={{formData['saveData'][field]['filename']}}&access_token={{LocalStorage.getUserToken()}}"
                                   target="_blank">
                                    {{formData['saveData'][field]['filename']}}
                                </a>
                                <span *ngIf="!formData['saveData'][field]['filename']">Geen bijlage toegevoegd</span>
                            </ng-container>
                            <ng-container *ngIf="formData['saveData'][field]['file'] == undefined">
                                <pre>{{formData['saveData'][field]}}</pre>
                            </ng-container>
                        </div>
                    </div>
                    <br><br>
                    <a routerLink="/{{Routenames.formHistory}}">&laquo; Alle ingevulde formulieren</a>
                </div>
            </div>
        </div>
    </div>
</section>
