import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {combineLatest, of} from 'rxjs';
import {ArticleService} from '../../services/article.service';
import {Article} from '../../classes/article.class';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {Classroom} from '../../classes/classroom.class';
import {LocalStorage} from '../../storage.class';
import {startWith} from 'rxjs/operators';
import {DateNLFormatAdapter} from '../../date-adapters/date-nlformat-adapter';
import {DateAdapter} from '@angular/material/core';
import {formatDate, Location} from '@angular/common';
import {Image} from '../../classes/image.class';
import {Routenames} from '../../route-names.enum';
import {Utils} from '../../utils.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {validTime} from '../../validators/valid-time.validator';
import {handleTimeValueChange} from '../../utils/handle-time-value-change';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Document} from '../../classes/document.class';
import {Video} from '../../classes/video.class';
import {PrivacyPreference} from '../../classes/student.class';
import {PermissionService} from '../../services/permission.service';
import {PermissionQuestion} from '../../classes/permission-question';
import {DomSanitizer} from '@angular/platform-browser';
import {FileType} from '../../services/file/file.service';
import {SchoolService} from '../../services/school/school.service';
import {School} from '../../classes/school.class';

@Component({
    selector: 'app-article-edit',
    templateUrl: './article-edit.component.html',
    styleUrls: ['./article-edit.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: DateNLFormatAdapter}
    ]
})
export class ArticleEditComponent extends CodaltComponent implements OnInit, OnDestroy {

    permissions: PermissionQuestion[];

    PrivacyPreference = PrivacyPreference;

    masterSchool = false;
    reserve = false;
    saving = false;
    form: UntypedFormGroup;
    classrooms: Classroom[];
    classroomGroups: string[] = [];
    article: Article;

    schools: School[];

    uploadingVideos = 0;

    private videoPrepInterval;

    constructor(private articleService: ArticleService,
                private classroomService: ClassroomService,
                private schoolService: SchoolService,
                private permissionService: PermissionService,
                private location: Location,
                private router: Router,
                private dialog: ConfirmDialogService,
                private confirmDialog: ConfirmDialogService,
                private domSanitizer: DomSanitizer,
                private route: ActivatedRoute,
                private cdref: ChangeDetectorRef) {
        super();
    }

    info(question: PermissionQuestion) {
        let content = `${question.description?.replace('\n', '<br>')}<br><br>`;
        question.options.forEach(level => {
            content += `<b>${level.name}</b> <i>${level.intro}</i><br>`;
            content += `<p>${level.description?.replace('\n', '<br>')}</p>`;
        });

        this.confirmDialog.confirm(question.name,
            content, 'Sluiten', null, '600px').then(() => {
        });
    }

    reorderImages(event: CdkDragDrop<Image[]>) {
        const movedImage = this.article.images[event.previousIndex];
        this.article.images.splice(event.previousIndex, 1);
        this.article.images.splice(event.currentIndex, 0, movedImage);
        this.articleService.sortImages(this.article).subscribe(() => {
        });
    }

    reorderVideos(event: CdkDragDrop<Video[]>) {
        const movedVideo = this.article.videos[event.previousIndex];
        this.article.videos.splice(event.previousIndex, 1);
        this.article.videos.splice(event.currentIndex, 0, movedVideo);
        this.articleService.sortVideos(this.article).subscribe(() => {
        });
    }

    reorderDocuments(event: CdkDragDrop<Document[]>) {
        const movedImage = this.article.documents[event.previousIndex];
        this.article.documents.splice(event.previousIndex, 1);
        this.article.documents.splice(event.currentIndex, 0, movedImage);
        this.articleService.sortDocuments(this.article).subscribe(() => {
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school) {
                this.masterSchool = LocalStorage.school.type === 'master';
                this.getArticle(params['id']);
            }
        }));
    }

    ngOnDestroy() {
        clearInterval(this.videoPrepInterval);
        super.ngOnDestroy();
    }

    public openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.form.dirty) {
                this.dialog.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen opslaan of verwerpen?`,
                    'Wijzigingen opslaan',
                    'Wijzigingen verwerpen').then(() => {
                    this.save();
                    resolve(false);
                }, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            if (['PUBLIC', 'EMPLOYEE'].indexOf(this.form.get('access_level').value) !== -1) {
                this.form.get('classrooms')?.setValue([]);
            }
            this.saving = true;
            this.articleService.save(this.form.value).subscribe(article => {
                Object.assign(this.article, article.data);
                this.form.markAsPristine();
                this.saving = false;
                if (new Date(this.form.value['publish_date']).getTime() > new Date().getTime()) {
                    this.router.navigateByUrl(`${Routenames.articles}/toekomst`);
                } else {
                    this.router.navigateByUrl(Routenames.articles);
                }

            }, error => {
                this.dialog.confirm(
                    'Fout bij het opslaan',
                    `Reden: ${error.message}`,
                    'Sluiten',
                    null)
                    .then(() => {

                    }, () => {

                    });
                this.saving = false;
            });
        }
    }

    uploadedVideos(files) {
        this.uploadingVideos--;
        this.articleService.get(this.article.slug).subscribe(article => {
            this.article.videos = article.data.videos;
        });

        this.videoPrepInterval = setInterval(() => {
            if (!this.article.videos.find(v => !v.processed)) {
                clearInterval(this.videoPrepInterval);
            } else {
                setTimeout(() => {
                    this.articleService.get(this.article.slug).subscribe(article => {
                        this.article.videos = article.data.videos;
                    });
                });

            }
        }, 5000);
    }


    uploadVideos(files?: File[]) {
        this.uploadingVideos++;
    }

    uploadedImages(files) {
        if (!this.article.images) {
            this.article.images = [];
        }
        files.file.forEach(file => {
            this.articleService.addImage(this.article.id, file.filename).subscribe(img => {
                const loading = this.article.images.find(p => !p.id);
                if (loading) {
                    img.data.thumb = loading.thumb;
                    Object.assign(loading, img.data);
                } else {
                    this.article.images.push(img.data);
                }
            });
        });
    }

    uploadImages(files?: File[]) {
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (!this.article.images) {
                    this.article.images = [];
                }
                this.article.images.push({
                    thumb: reader.result.toString()
                } as Image);
            };
        });
    }

    uploadedDocuments(files) {
        if (!this.article.documents) {
            this.article.documents = [];
        }
        files.file.forEach(file => {
            this.articleService.addDocument(this.article.id, file.filename).subscribe(img => {
                const loading = this.article.documents.find(p => !p.id);
                if (loading) {
                    Object.assign(loading, img.data);
                } else {
                    this.article.documents.push(img.data);
                }
            });
        });
    }

    deleteImage(image: Image) {
        this.articleService.rmImage(image.id).subscribe(() => {
            const index = this.article.images.indexOf(image);
            this.article.images.splice(index, 1);
        });
    }

    deleteVideo(video: Video) {
        this.articleService.rmVideo(video.id).subscribe(() => {
            const index = this.article.videos.indexOf(video);
            this.article.videos.splice(index, 1);
        });
    }

    deleteDocument(document: Document) {
        this.articleService.rmDocument(document.id).subscribe(() => {
            const index = this.article.documents.indexOf(document);
            this.article.documents.splice(index, 1);
        });
    }

    uploadedMainImage(file) {
        this.form.get('image').setValue(file ? file.file.filename : null);
    }

    uploadMainImage(files) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.article.image_thumb = reader.result.toString();
        };
        this.form.get('image').setValue(null);
    }

    private getPersmissions() {
        if (this.form.get('classrooms')) {
            this.permissionService.getPermissionWithStudents(this.form.get('classrooms').value, true).subscribe(permissions => {
                this.permissions = permissions.data;
            });
        }
    }

    private getArticle(slug) {
        this.reserve = slug === 'nieuw';
        const schools$ = this.masterSchool ? this.schoolService.allSchools() : of({data: []});
        const classrooms$ = this.classroomService.getClassrooms();
        const article$ = this.reserve ? this.articleService.reserve() : this.articleService.get(slug, this.route.snapshot.url[1].path === 'kopie');
        combineLatest([classrooms$, article$, schools$])
            .subscribe(([classrooms, article, schools]) => {
                this.classrooms = classrooms.data;
                if (this.classrooms.length > 3) {
                    this.classroomGroups = [...new Set(this.classrooms.filter(p => !!p.groupment).map(p => p.groupment))];
                }
                if (!AuthorisationService.hasFeature('publicArticles') && this.classrooms.length < 1 && !this.masterSchool) {
                    this.dialog.confirm(
                        'Je kunt geen artikel aanmaken',
                        `Omdat je in parnassys niet bent gekoppeld aan klassen
                    en je geen rechten hebt om publieke nieuwsberichten te maken`,
                        'Terug naar overzicht',
                        null).then(() => {
                        this.router.navigateByUrl(Routenames.articles);
                    });
                }
                this.article = article.data;
                this.generateForm(this.article);
                this.schools = schools.data.filter(s => s.type === 'subsite');
                this.cdref.detectChanges();

            }, error => {
                this.dialog.confirm('Fout', error.message, 'Terug naar overzicht', null).then(() => {
                    this.router.navigateByUrl(Routenames.articles);
                });
            });
    }

    private generateForm(article: Article) {
        let classrooms: (string | number)[] = article.classrooms ? article.classrooms.map(c => c.id) : [];
        let images = article.images ? article.images.map(img => img.id) : [];

        const directClassroomsForThisArticle = (LocalStorage.user.classrooms ?? []).filter(c => this.classrooms.map(d => d.id).indexOf(c.id) > -1).map(c => c.id);

        const permittedToAllClassrooms = directClassroomsForThisArticle.length === this.classrooms.length;

        if (!this.reserve || (AuthorisationService.hasFeature('publicArticles') && directClassroomsForThisArticle.length === 0)) {
            classrooms = classrooms.length === 0 ? [null] : classrooms;
        } else if (directClassroomsForThisArticle.length > 0 && !permittedToAllClassrooms) {
            classrooms = classrooms.length === 0 ? [...directClassroomsForThisArticle] : classrooms;
        } else {
            classrooms = classrooms.length === 0 && this.classrooms.length === 1 ? [this.classrooms[0].id] : classrooms;
        }
        classrooms = this.appendWithGroupments(classrooms);

        const fcClassrooms = new UntypedFormControl(classrooms, Validators.required);
        const fcAccessLevel = new UntypedFormControl(article.access_level ? article.access_level : 'LOGGEDIN');
        if (!article.publish_date) {
            article.publish_date = new Date();
            if (article.publish_date.getUTCDay() > 5) {
                article.publish_date = Utils.getNextWorkDay(article.publish_date);
                article.publish_date.setHours(8);
            } else if (article.publish_date.getHours() >= 17) {
                article.publish_date.setUTCDate(article.publish_date.getUTCDate() + 1);
                article.publish_date = Utils.getNextWorkDay(article.publish_date);
                article.publish_date.setHours(8);
            } else {
                if (article.publish_date.getMinutes() > 44) {
                    article.publish_date.setHours(article.publish_date.getHours() + 2);
                } else {
                    article.publish_date.setHours(article.publish_date.getHours() + 1);
                }
            }

            article.publish_date.setMinutes(0);
        }
        const fcPublishTime = new UntypedFormControl(
            article.publish_date ? formatDate(article.publish_date, 'HH:mm', 'nl') : null,
            {validators: [validTime(), Validators.required], updateOn: 'blur'}
        );
        const fcPublishDate = new UntypedFormControl(article.publish_date ? article.publish_date : new Date());
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(article.id),
            title: new UntypedFormControl(article.title, Validators.required),
            alert: new UntypedFormControl(article.alert || false),
            content: new UntypedFormControl(article.content),
            publish_date: fcPublishDate,
            publish_time: fcPublishTime,
            images: new UntypedFormControl(images),
            access_level: fcAccessLevel,
            image: new UntypedFormControl(article.image),
            replies: new UntypedFormControl(article.replies),
            schools: new UntypedFormControl(article.schools?.map(s => s.id) ?? []),
            schoolWide: new UntypedFormControl(!article?.classrooms?.length && AuthorisationService.hasFeature('publicArticles'))
        });
        if (!this.masterSchool) {
            if (this.reserve) {
                if (LocalStorage.showClassroomsArticles?.length) {
                    if (AuthorisationService.hasFeature('AllclassroomsList')) {
                        fcClassrooms.setValue(LocalStorage.showClassroomsArticles);
                    } else {
                        const ownClassrooms = LocalStorage.user?.classrooms?.map(c => c.id);
                        fcClassrooms.setValue(LocalStorage.showClassroomsArticles.filter(c => ownClassrooms.includes(c)));
                    }
                }
                if (!LocalStorage.showSchoolWideArticles && !LocalStorage.showAllClassroomsArticles && !LocalStorage.showClassroomsArticles?.length) {
                    fcClassrooms.setValue(LocalStorage.user?.classrooms?.map(c => c.id));
                }
                if (LocalStorage.showAllClassroomsArticles) {
                    fcClassrooms.setValue(this.classrooms.map(c => c.id));
                }
            }
            if (fcClassrooms.value?.filter(v => v !== null)?.length) {
                this.form.get('schoolWide').setValue(false);
            }
            this.form.addControl('classrooms', fcClassrooms);
        }
        this.subscriptions.add(fcPublishTime.valueChanges.subscribe(
            value => handleTimeValueChange(value, fcPublishTime)
        ));
        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            if (fcPublishTime.value && fcPublishDate.value) {
                const time = fcPublishTime.value.split(':');
                if (time.length === 2) {
                    const date = new Date(fcPublishDate.value);
                    date.setHours(time[0]);
                    date.setMinutes(time[1]);
                    if (date.toString() !== (new Date(fcPublishDate.value)).toString()) {
                        fcPublishDate.setValue(date);
                    }
                }
            }
        }));
        this.getPersmissions();

        this.subscriptions.add(this.form.get('schoolWide').valueChanges.subscribe(schoolWide => {
            if (schoolWide && this.form.get('classrooms')) {
                this.form.get('classrooms').setValue([null]);
            }
        }));

        this.subscriptions.add(fcClassrooms.valueChanges.subscribe(newClassrooms => {
            const selectedNew = JSON.stringify(newClassrooms);
            const prevClassrooms = this.form.value.classrooms;
            if (prevClassrooms.indexOf(null) === -1 && newClassrooms.indexOf(null) !== -1 && newClassrooms.length > 1) {
                newClassrooms = [];
                fcClassrooms.setValue(newClassrooms);
            }
            if (newClassrooms.length > 1 && newClassrooms.indexOf(null) !== -1) {
                newClassrooms = newClassrooms.filter(a => a !== null);
            }

            // GROUPMENT => Handle new groups
            newClassrooms.forEach(nc => {
                if (('' + nc).substr(0, 6) === 'group_') {
                    if (prevClassrooms.indexOf(nc) === -1) {
                        newClassrooms.push(
                            ...this.classrooms
                                   .filter(c => newClassrooms.indexOf(c.id) === -1)
                                   .filter(c => c.groupment === nc.substr(6)).map(c => c.id)
                        );
                    }
                }
            });

            // GROUPMENT => Handle removed groups
            prevClassrooms.forEach(pc => {
                if (('' + pc).substr(0, 6) === 'group_' && newClassrooms.indexOf(pc) === -1) {
                    const toBeRemovedClassrooms = this.classrooms.filter(c => c.groupment === pc.substr(6)).map(c => c.id);
                    const canBeRemovedClassrooms = newClassrooms.filter(nc => toBeRemovedClassrooms.indexOf(nc) !== -1);
                    if (toBeRemovedClassrooms.length === canBeRemovedClassrooms.length) {
                        newClassrooms = newClassrooms.filter(nc => toBeRemovedClassrooms.indexOf(nc) === -1);
                    }
                }
            });

            // GROUPMENT => Check whether full group is checked
            newClassrooms = this.appendWithGroupments(newClassrooms);

            if (selectedNew !== JSON.stringify(newClassrooms)) {
                fcClassrooms.setValue(newClassrooms);
            }
            if (newClassrooms.find(nc => !!nc) && fcAccessLevel.value === 'PUBLIC') {
                fcAccessLevel.setValue('LOGGEDIN');
            }
            this.getPersmissions();
        }));
        this.subscriptions.add(fcAccessLevel.valueChanges.subscribe(newAccessLevel => {
            if (['PUBLIC', 'EMPLOYEE'].indexOf(newAccessLevel) !== -1 && fcClassrooms.value[0] !== null) {
                fcClassrooms.setValue([null]);
            }
            const oldAccessLevel = this.form.value.access_level;
            if (['PUBLIC', 'EMPLOYEE'].indexOf(oldAccessLevel) !== -1 && ['PUBLIC', 'EMPLOYEE'].indexOf(newAccessLevel) === -1 && fcClassrooms.value[0] === null) {
                fcClassrooms.setValue([]);
            }
        }));
    }


    private appendWithGroupments(classrooms: (string | number)[]) {
        this.classroomGroups.forEach(clg => {
            const allGroupedClassrooms: (string | number)[] = this.classrooms.filter(c => c.groupment === clg).map(c => c.id);
            const selectedGroupedClassrooms = classrooms.filter(nc => allGroupedClassrooms.indexOf(nc) !== -1);
            if (allGroupedClassrooms.length === selectedGroupedClassrooms.length) {
                if (classrooms.filter(p => ('' + p).substr(6) === clg).length === 0) {
                    classrooms.push('group_' + clg);
                }
            } else {
                classrooms = classrooms.filter(a => ('' + a).substr(6) !== clg);
            }
        });
        return classrooms;
    }

    readonly FileTypes = FileType;
}
