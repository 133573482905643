<h1 mat-dialog-title>Link aanpassen</h1>
<div mat-dialog-content>
    <mat-radio-group [(ngModel)]="linkType" (change)="typeChanged()">
        <mat-radio-button value="page">Kies een pagina</mat-radio-button>
        <Br>
        <mat-radio-button value="text">Voer een URL in</mat-radio-button>
    </mat-radio-group>
    <mat-form-field *ngIf="linkType == 'page'" class="w100">
        <mat-select [(ngModel)]="data.value" placeholder="Link voor pagina" required>
            <mat-option *ngFor="let page of pages" [value]="page['url']">
                {{page['page']}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="linkType == 'text'" class="w100">
        <input [(ngModel)]="data.value" matInput placeholder="Link" required type="text">
    </mat-form-field>
    <mat-form-field class="w100">
        <mat-select [(ngModel)]="data.target" placeholder="Openen" required>
            <mat-option defa value="_self">
                op dezelfde pagina
            </mat-option>
            <mat-option value="_blank">
                in een nieuw venster
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="row" mat-dialog-actions>
    <div class="col">
        <button (click)="onNoClick()" cdkFocusInitial mat-button>Annuleren</button>
    </div>
    <div class="flex-fill"></div>
    <div class="col">
        <button [mat-dialog-close]="true" color="primary" mat-raised-button>Opslaan</button>
    </div>
</div>