import {CustobType} from './classes/custob-type.class';
import {Custob} from './classes/custob.class';
import {AbstractControl, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {formatDate} from '@angular/common';

export class Utils {

    static isIOS() {
        if (/iPad|iPhone|iPod/.test(navigator.platform)) {
            return true;
        } else {
            return navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.platform);
        }
    }

    public static currentSchoolYear(): string {

        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();

        if (month >= 7)
            return `${year}-${year + 1}`;

        return `${year - 1}-${year}`;
    }

    public static isToday(date: Date) {
        const testDate = new Date(date);
        const today = new Date();
        return testDate.getDate() === today.getDate() &&
            testDate.getMonth() === today.getMonth() &&
            testDate.getFullYear() === today.getFullYear();
    }

    public static getFirstText(custobTypes: CustobType[], custobTypeId: number, custob: Custob) {
        if (custobTypes) {
            const custobType = custobTypes.filter(p => p.id == custobTypeId)[0];
            let firstText = '';
            if (custobType) {
                custobType.fields.forEach(field => {
                    if (field.type == 'text' && firstText == '') {
                        firstText = custob.values[field.id];
                    }
                });
                return firstText;
            }
        }
        return '';
    }

    public static isLocalHost() {
        const hostnameparts = location.hostname.split('.');
        const tldpart = hostnameparts[hostnameparts.length - 1];
        return (tldpart == 'dev' || location.hostname === 'localhost' || location.hostname === '127.0.0.1');
    }


    public static timestamp() {
        return Date.now();
    }

    public static multi(objectName: string, object) {

        if (object != null && object != undefined) {
            if (typeof object[objectName] == 'undefined') {
                object[objectName] = [];
            }
            object[objectName].push({name: ''});
        }

    }

    public static isLastInList(item, list) {
        const index = list.indexOf(item);
        if (index == list.length - 1)
            return true;
        return false;
    }

    public static isFirstInList(item, list) {
        const index = list.indexOf(item);
        if (index == 0)
            return true;
        return false;
    }

    public static getTimeOrNull(date: Date) {
        if (date !== null && date !== undefined) {
            return (new Date(date)).getTime();
        }
        return null;
    }

    public static getDatestringOrNull(date: Date) {
        if (date !== null && date !== undefined) {
            return formatDate(new Date(date), 'd-M-y', 'nl');
        }
        return null;
    }

    public static getFcTime(date: Date) {
        if (date !== null && date !== undefined) {
            return formatDate(date, 'HH:mm', 'nl');
        }
        return null;
    }

    public static setTime(date: Date, hours: number, minutes: number) {
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    public static getNextWorkDay(today?: Date) {
        const checkDay = (date => {
            if (date.getUTCDay() === 0) {
                return new Date(date.setUTCDate(date.getUTCDate() + 1));
            }
            if (date.getUTCDay() > 5) { // if friday or saturday move to next monday
                return new Date(date.setUTCDate(date.getUTCDate() + (8 - date.getUTCDay())));
            }
            return new Date(date);
        });
        if (!today) {
            today = new Date();
            today.setUTCDate(today.getUTCDate() + 1);
        }
        today = checkDay(today);
        return checkDay(today);
    }

    public static removePage(item, list) {
        const index = list.indexOf(item);
        this.removePageOnIndex(list, index);
    }

    public static removePageOnIndex(list, index) {
        list.splice(index, 1);
    }

    public static upPage(item, list) {
        const index = list.indexOf(item);
        list.splice(index - 1, 0, list.splice(index, 1)[0]);
    }

    public static downPage(item, list) {
        const index = list.indexOf(item);
        list.splice(index + 1, 0, list.splice(index, 1)[0]);
    }

    static triggerValidation(control: AbstractControl, source: AbstractControl[] = []) {
        if (control instanceof UntypedFormGroup) {
            const group = (control as UntypedFormGroup);

            for (const field in group.controls) {
                const c = group.controls[field];

                this.triggerValidation(c, source);
            }
        } else if (control instanceof UntypedFormArray) {
            const group = (control as UntypedFormArray);

            for (const field in group.controls) {
                const c = group.controls[field];

                this.triggerValidation(c, source);
            }
        } else {
            control.markAllAsTouched();
            control.updateValueAndValidity({emitEvent: source.indexOf(control) === -1});
        }
    }

    static makeThumbBlob(callback: (file: Blob) => void, inpFile: File, size: number) {
        this.makeThumb(ctx => {
            ctx.canvas.toBlob((blobFileC) => {
                callback(blobFileC);
            }, 'image/jpeg', .7);
        }, inpFile, size);
    }

    static makeThumbBase64(callback: (file: string) => void, inpFile: File, size: number) {
        this.makeThumb(ctx => {
            if (inpFile.type === 'image/png') {
                callback(ctx.canvas.toDataURL('image/png'));
            } else {
                callback(ctx.canvas.toDataURL('image/jpeg', 0.7));
            }
        }, inpFile, size);
    }

    private static makeThumb<T>(callback: (ctx: CanvasRenderingContext2D) => void, inpFile: File, size: number) {
        const img = new Image();
        img.src = window?.URL.createObjectURL(inpFile);
        img.onload = () => {
            const factor = size / Math.max(img.naturalWidth, img.naturalHeight);
            const width = factor < 1 ? img.naturalWidth * factor : img.naturalWidth;
            const height = factor < 1 ? img.naturalHeight * factor : img.naturalHeight;
            const elem = document.createElement('canvas');
            elem.width = width;
            elem.height = height;
            const ctx = elem.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            callback(ctx);
        };
    }

    static createRange(nr) {
        const arr = [];
        for (let i = 0; i < nr; i++) {
            arr.push(i);
        }
        return arr;
    }
}
