import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CodaltComponent} from '../../../codalt.component';
import {Classroom} from '../../../classes/classroom.class';
import {Discussiontime} from '../../../classes/discussiontime.class';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgSelectComponent} from '@ng-select/ng-select';
import {Discussion} from '../../../classes/discussion.class';
import {Utils} from '../../../utils.class';
import {DiscussionService} from '../../../services/discussion.service';
import {ConfirmDialogService} from '../../../services/confirm-dialog-service/confirm-dialog.service';
import {Student} from '../../../classes/student.class';
import {User} from '../../../classes/user.class';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-discussion-plan',
    templateUrl: './discussion-plan.component.html',
    styleUrls: ['./discussion-plan.component.scss']
})
export class DiscussionPlanComponent extends CodaltComponent implements OnInit {

    classroom: Classroom;
    discussion: Discussion;
    discussiontime: Discussiontime;
    otherTimes: Discussiontime[];
    form: UntypedFormGroup;
    formControls: {
        time: UntypedFormControl,
        student: UntypedFormControl,
        user: UntypedFormControl,
        unavailable: UntypedFormControl,
        private: UntypedFormControl
    };
    otherTimesStudent: Discussiontime[];
    otherTimesUser: Discussiontime[];
    allClassrooms: Classroom[];
    selectedStudent: Student;
    sameUserStudent = false;
    sameTime = false;


    @ViewChild('users') users: NgSelectComponent;


    constructor(
        public dialogRef: MatDialogRef<DiscussionPlanComponent>,
        private discussionService: DiscussionService,
        private confirmDialogService: ConfirmDialogService,
        @Inject(MAT_DIALOG_DATA) data: {
            classroom: Classroom,
            time: Discussiontime,
            discussion: Discussion,
            classrooms: Classroom[]
        }) {
        super();

        this.classroom = data.classroom;
        this.discussiontime = data.time;
        this.discussion = data.discussion;
        this.allClassrooms = data.classrooms;

        this.otherTimes = this.discussion.discussiontimes.filter(p => p.classroom_id === this.classroom.id);

        this.formControls = {
            time: new UntypedFormControl(this.discussiontime),
            student: new UntypedFormControl(this.discussiontime.student_id),
            user: new UntypedFormControl(this.discussiontime.user_id),
            unavailable: new UntypedFormControl(this.discussiontime.unavailable),
            private: new UntypedFormControl(this.discussiontime.private)
        };
        this.form = new UntypedFormGroup(this.formControls);
        this.updateOthers();
    }

    ngOnInit() {
    }

    studentSelected(event: any) {
        if (event) {
            setTimeout(() => {
                this.users.focus();
                this.users.open();
            });
        }
        this.updateOthers();
        this.formControls.user.setValue(null);
    }

    public discussionTimeSearch(term: string, item: Discussiontime) {
        const dateString = formatDate(item.datetime, 'EEEE d MMMM yyyy HH:mm', 'nl').toLowerCase();
        term = term.toLocaleLowerCase();
        return dateString.indexOf(term) !== -1;
    }

    updateOthers() {
        this.selectedStudent = this.classroom.students.find(p => p.id === this.formControls.student.value);
        let user: User;
        const selectedTimeId = this.formControls.time.value.id;
        if (this.selectedStudent) {
            this.otherTimesStudent = this.discussion.discussiontimes.filter(p => p.student_id === this.selectedStudent.id && p.id !== selectedTimeId);
            user = this.selectedStudent.users.find(p => p.id === this.formControls.user.value);
        } else {
            this.otherTimesUser = [];
        }
        if (user) {
            this.otherTimesUser = this.discussion.discussiontimes.filter(p => p.user_id === user.id && p.id !== selectedTimeId);
        } else {
            this.otherTimesUser = [];
        }

        if (this.formControls.time.value && this.otherTimesUser) {
            this.sameUserStudent = !!this.otherTimesUser.find(p => p.student_id === this.selectedStudent.id &&
                p.id !== this.formControls.time.value.id);
            this.sameTime = !!this.otherTimesUser
                .find(p => new Date(p.datetime).getTime() === new Date(this.formControls.time.value.datetime).getTime() &&
                    p.id !== this.formControls.time.value.id);
        } else {
            this.sameUserStudent = false;
            this.sameTime = false;
        }
    }

    save() {
        const failed = (message: string) => {
            this.confirmDialogService.confirm('Opslaan mislukt', message, 'Oke', null);
        };

        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            const saveDiscussiontime = this.formControls.time.value;
            saveDiscussiontime.student_id = this.formControls.student.value;
            saveDiscussiontime.user_id = this.formControls.user.value;
            saveDiscussiontime.private = this.formControls.private.value || false;
            saveDiscussiontime.unavailable = this.formControls.unavailable.value || false;
            this.discussionService.updateTime(saveDiscussiontime).subscribe(result => {
                if (result.success) {
                    // When another Discussiontime is selected, clear previous DiscussionTime
                    if (this.discussiontime.id !== this.formControls.time.value.id) {
                        this.discussiontime.user_id = null;
                        this.discussiontime.student_id = null;
                        this.discussiontime.private = false;
                        this.discussionService.updateTime(this.discussiontime).subscribe(result => {
                            this.dialogRef.close();
                        }, () => {
                            failed(result.message);
                        });
                    } else {
                        this.dialogRef.close();
                    }
                } else {
                    failed(result.message);
                }
            }, error => {
                failed(error.message);
            });
        }
    }
}
