import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Article} from '../classes/article.class';
import {Image} from '../classes/image.class';
import {Document} from '../classes/document.class';
import {EventarticlejobService} from './eventarticlejob.service';
import {Video} from '../classes/video.class';
import {ArticleLike} from '../classes/article-like';
import {tap} from 'rxjs/operators';

@Injectable()
export class ArticleService implements EventarticlejobService {

    static reloadUnread: EventEmitter<any> = new EventEmitter();

    constructor(private apiService: ApiService) {
    }

    unreadArticleCount() {
        return this.apiService.getCall$<{school_id: number, unread: number}[]>('articles/unread-count');
    }

    like(article_id: number) {
        return this.apiService.postCall$<ArticleLike>('articles/like', {article_id});
    }

    getList(
        limit?: number,
        skip?: number,
        future?: boolean,
        showAllClassrooms?: boolean,
        classroomIds = [],
        search = '',
        schoolWide?: boolean,
        pastAndFuture?: boolean,
        skipFields = [],
        onlyCurrentSchool = false
    ) {
        future = future || false;
        showAllClassrooms = showAllClassrooms || false;
        return this.apiService.getCall$<Article[]>(`articles/${limit}/${skip}`, {
            future,
            showAllClassrooms,
            classroomIds,
            schoolWide,
            search,
            pastAndFuture,
            skipFields,
            onlyCurrentSchool
        });
    }

    get(slug: string, copy?: boolean) {
        const params = copy ? {copy} : {};
        return this.apiService.getCall$<Article>(`articles/${slug}`, params).pipe(tap(() => {
            ArticleService.reloadUnread.emit();
        }));
    }

    save(article: Article) {
        return this.apiService.postCall$<Article>('articles', article);
    }

    delete(id: number) {
        return this.apiService.deleteCall$(`articles/${id}`);
    }

    reserve() {
        return this.apiService.postCall$<Article>('articles/reserve');
    }

    addImage(id, path) {
        return this.apiService.postCall$<Image>('articles/add-image', {id, path});
    }

    addChatImage(articleId: number, path: string, chatId: number) {
        return this.apiService.postCall$('articles/add-chat-image', {articleId, path, chatId});
    }

    addChatVideo(articleId: number, videoId: number) {
        return this.apiService.postCall$('articles/add-chat-video', {articleId, videoId});
    }

    addVideo(id, path) {
        return this.apiService.postCall$<Video>('articles/add-video', {id, path});
    }

    sortImages(article: Article) {
        return this.apiService.postCall$<Image>('articles/sort-images', {
            id: article.id,
            images: article.images.map(p => p.id)
        });
    }

    sortVideos(article: Article) {
        return this.apiService.postCall$<Image>('articles/sort-videos', {
            id: article.id,
            videos: article.videos.map(p => p.id)
        });
    }

    rmImage(id) {
        return this.apiService.deleteCall$(`articles/rm-image/${id}`);
    }

    rmVideo(id) {
        return this.apiService.deleteCall$(`articles/rm-video/${id}`);
    }

    addDocument(id, path) {
        return this.apiService.postCall$<Document>('articles/add-document', {id, path});
    }

    sortDocuments(article: Article) {
        return this.apiService.postCall$<Document>('articles/sort-documents', {
            id: article.id,
            documents: article.documents.map(p => p.id)
        });
    }

    rmDocument(id) {
        return this.apiService.deleteCall$(`articles/rm-document/${id}`);
    }
}
