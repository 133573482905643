<div class="container mt-3">
    <div class="row justify-content-center ">
        <div class="col-md-12 payment p-4">
            <div>
                <h2 class="news__title">Account opwaarderen</h2>
                <p>
                    Hier kunt u accounts handmatig opwaarderen. Doe dit als een ouder contant betaald.
                    Let op: deze bedragen worden verrekend met de bedragen die de school van Kindwijs krijgt.
                </p>
            </div>
            <div>
                <ng-select [items]="users"
                           notFoundText="Geen verzorgers gevonden"
                           bindLabel="name"
                           bindValue="id"
                           [formControl]="fc.payed_by"
                           placeholder="Verzorger">
                    <ng-template ng-label-tmp let-item="item">
                        {{item.name}} {{item.transactionAmount | currency : 'EUR'}}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        {{item.name}} {{item.transactionAmount | currency : 'EUR'}}
                    </ng-template>
                </ng-select>
                <mat-form-field class="mt-3 w-100">
                    <mat-label>Kies een bedrag</mat-label>
                    <input [formControl]="fc.amount" matInput type="number">
                    <mat-error [control]="fc.amount"></mat-error>
                </mat-form-field>
                <div class="d-flex justify-content-end mt-3">
                    <button class="button--primary" (click)="pay()">
                        Opwaarderen
                        <mat-icon *ngIf="paying" class="fas fa-circle-notch fa-spin"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
