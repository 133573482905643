import {User} from './classes/user.class';
import {School} from './classes/school.class';
import {EventEmitter} from '@angular/core';

export class LocalStorage {

    static gStorageSupported = undefined;
    static nonLocalStorageData = {};
    static schoolChange: EventEmitter<any> = new EventEmitter();
    static bottomMenuReload: EventEmitter<void> = new EventEmitter();
    static version: {datetime: string};

    private static _school: School = null;

    public static get school() {
        if (!LocalStorage._schools && LocalStorage.user) {
            LocalStorage.schools = LocalStorage.user.anySchools;
        }
        return LocalStorage._school;
    }

    public static set school(selectedSchool: School) {
        if (selectedSchool) {
            localStorage.setItem('selectedschool', selectedSchool?.slug);
            LocalStorage._school = selectedSchool;
            LocalStorage.schoolChange.emit();
        }
    }

    public static get showAllClassroomsArticles() {
        return !!localStorage.getItem('showAllClassroomsArticles');
    }

    public static set showAllClassroomsArticles(show) {
        localStorage.setItem('showAllClassroomsArticles', show as any as boolean === false ? '' : 'true');
    }

    public static get showSchoolWideEvents() {
        return !!localStorage.getItem('showSchoolWideEvents');
    }

    public static set showSchoolWideEvents(show) {
        localStorage.setItem('showSchoolWideEvents', show as any as boolean === false ? '' : 'true');
    }

    public static get showSchoolWideArticles() {
        return !!localStorage.getItem('showSchoolWideArticles');
    }

    public static set showSchoolWideArticles(show) {
        localStorage.setItem('showSchoolWideArticles', show as any as boolean === false ? '' : 'true');
    }

    public static get showClassroomsArticles(): [] {
        return JSON.parse(localStorage.getItem('showClassroomsArticles'));
    }

    public static set showClassroomsArticles(classrooms) {
        localStorage.setItem('showClassroomsArticles', JSON.stringify(classrooms));
    }

    public static get showAllClassroomsEvents() {
        return !!localStorage.getItem('showAllClassroomsEvents');
    }

    public static set showAllClassroomsEvents(show) {
        localStorage.setItem('showAllClassroomsEvents', show as any as boolean === false ? '' : 'true');
    }

    public static get showClassroomsEvents(): [] {
        return JSON.parse(localStorage.getItem('showClassroomsEvents'));
    }

    public static set showClassroomsEvents(classrooms) {
        localStorage.setItem('showClassroomsEvents', JSON.stringify(classrooms));
    }




    public static get showAllClassroomsStudentList() {
        return !!localStorage.getItem('showAllClassroomsStudentList');
    }

    public static set showAllClassroomsStudentList(show) {
        localStorage.setItem('showAllClassroomsStudentList', show as any as boolean === false ? '' : 'true');
    }

    public static get showClassroomsStudentList(): [] {
        return JSON.parse(localStorage.getItem('showClassroomsStudentList'));
    }

    public static set showClassroomsStudentList(classrooms) {
        localStorage.setItem('showClassroomsStudentList', JSON.stringify(classrooms));
    }





    public static get agendaListView() {
        return !!localStorage.getItem('agendaListView');
    }

    public static set agendaListView(show) {
        localStorage.setItem('agendaListView', show as any as boolean === false ? '' : 'true');
    }

    private static _mainSchool: School = null;

    public static get mainSchool(): School {
        if (!this._mainSchool) {
            this._mainSchool = this.user?.mainSchool;
        }
        return this._mainSchool;
    }

    private static _user: User = null;

    public static get user(): User {
        if (this._user) {
            return this._user;
        } else {
            this._user = JSON.parse(localStorage.getItem('user'));
            return JSON.parse(localStorage.getItem('user'));
        }
    }

    private static _parentUser: User = null;

    public static get parentUser(): User {
        if (this._parentUser) {
            return this._parentUser;
        } else {
            this._parentUser = JSON.parse(localStorage.getItem('parent_user'));
            return JSON.parse(localStorage.getItem('parent_user'));
        }
    }

    private static _schools: School[];

    public static get schools(): Array<School> {
        if (!LocalStorage._schools && LocalStorage.user) {
            LocalStorage.schools = LocalStorage.user.anySchools;
        }
        return LocalStorage._schools;
    }

    public static set schools(schools: Array<School>) {

        if (schools?.length > 0) {
            LocalStorage._schools = schools;
            const selectedSchoolFromStorage = LocalStorage._schools.find(p => p.slug == localStorage.getItem('selectedschool'));
            if (selectedSchoolFromStorage) {
                LocalStorage._school = selectedSchoolFromStorage;
                LocalStorage.school = selectedSchoolFromStorage;
            } else {
                const selectedSchoolFirst = LocalStorage._schools[0];
                LocalStorage._school = selectedSchoolFirst;
                LocalStorage.school = selectedSchoolFirst;
            }
        }
    }

    public static get autoPayPayment() {
        return +localStorage.getItem('autoPayPayment');
    }

    public static set autoPayPayment(paymentId: number) {
        localStorage.setItem('autoPayPayment', `${paymentId}`);
    }

    public static get autoPayPaymentStudentAmount() {
        return +localStorage.getItem('autoPayPaymentStudentAmount');
    }

    public static set autoPayPaymentStudentAmount(amount: number) {
        localStorage.setItem('autoPayPaymentStudentAmount', `${amount}`);
    }

    public static setUser(user: User) {
        this._user = user;
        localStorage.setItem('user', JSON.stringify(user));
    }

    public static setUserToken(token) {
        localStorage.setItem('access_token', token);
    }

    public static setParentUser(token, user) {
        localStorage.setItem('parent_access_token', token);
        localStorage.setItem('parent_user', JSON.stringify(user));
        this._parentUser = user;
    }

    public static logoutUser() {
        this.setParentUser(null, null);
        this.setUser(null);
        localStorage.clear();
    }

    public static getUserToken() {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            return;
        }
        const tokenValidUntil = new Date(LocalStorage.getTokenDate());
        tokenValidUntil.setSeconds(tokenValidUntil.getSeconds() + (+LocalStorage.getExpireTimeToken()));
        if (tokenValidUntil <= new Date()) {
            console.error('Token expired, ignoring');
            this.setParentUser(null, null);
            this.setUser(null);
            localStorage.clear();
            return;
        }
        return accessToken;
    }

    public static getParentUserToken() {
        return localStorage.getItem('parent_access_token');
    }

    public static setExpireTimeToken(token) {
        localStorage.setItem('token_date', new Date().toISOString());
        localStorage.setItem('expire_access_token', token);
    }

    public static getTokenDate() {
        return localStorage.getItem('token_date');
    }

    public static getExpireTimeToken() {
        return localStorage.getItem('expire_access_token');
    }

    public static updateSelectedSchoolIfWrong(schoolId: any) {
        setTimeout(() => {
            if (LocalStorage.school?.id !== schoolId) {
                this.school = this.schools.find(p => p.id == schoolId);
            }
        });
    }


}
