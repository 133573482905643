import {Component, Input, OnInit} from '@angular/core';
import {SortingComponent} from '../sorting.component';
import {AuthenticationLog} from '../classes/authenticationlog.class';
import {AuthenticationService} from '../services/auth/authentication.service';
import {User} from '../classes/user.class';
import {PushLog} from '../classes/pushlog.class';
import {AuthorisationLog} from '../classes/authorisationlog.class';
import {AppLog} from '../classes/applog.class';
import {AuthorisationService} from '../services/auth/authorisation.service';

@Component({
    selector: 'app-user-auth-log',
    templateUrl: './user-auth-log.component.html',
    styleUrls: ['./user-auth-log.component.scss']
})
export class UserAuthLogComponent extends SortingComponent<AuthenticationLog> implements OnInit {

    @Input() user: User;

    private authLogInterval;

    pushLog: PushLog[];
    authorisationLog: AuthorisationLog[];
    appLog: AppLog[];

    constructor(private authenticationService: AuthenticationService) {
        super();
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.subscriptions.add(this.authenticationService.log(this.user.id).subscribe(log => {
            this.list = log.data;
        }));
        if (AuthorisationService.hasFeature('userAuthorisationLog')) {
            this.subscriptions.add(this.authenticationService.authorisationLog(this.user.id).subscribe(log => {
                this.authorisationLog = log.data;
            }));
        }
        if (AuthorisationService.hasFeature('userPushLog')) {
            this.subscriptions.add(this.authenticationService.pushLog(this.user.id).subscribe(log => {
                this.pushLog = log.data;
            }));
        }
        if (AuthorisationService.hasFeature('userAppLog')) {
            this.subscriptions.add(this.authenticationService.appLog(this.user.id).subscribe(log => {
                this.appLog = log.data;
            }));
        }
    }

    scrollTo(elementId: string){
        document.getElementsByClassName('router-outlet')[0].scrollTo({top: document.getElementById(elementId).offsetTop - 40 });
    }

    ngOnDestroy() {
        clearInterval(this.authLogInterval);
    }

    protected readonly navigator = navigator;
}
