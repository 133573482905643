import {Component, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {debounceTime, startWith} from 'rxjs/operators';
import {CodaltComponent} from '../../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Routenames} from '../../route-names.enum';
import {formatCurrency} from '@angular/common';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AfterSchoolService} from '../../services/after-school.service';
import {EventService} from '../../services/event.service';
import {Event} from '../../classes/event.class';
import {EventSubscription} from '../../classes/event-subscription';

@Component({
    selector: 'app-pay-after-school-offer',
    templateUrl: './pay-after-school-offer.component.html',
    styleUrls: ['./pay-after-school-offer.component.scss']
})
export class PayAfterSchoolOfferComponent extends CodaltComponent implements OnInit {

    paying = false;
    event: Event;
    currentSaldo = LocalStorage?.user?.transactionAmount;

    fc: {
        price: UntypedFormControl
    };
    form: UntypedFormGroup;

    subscriptionsToPay: EventSubscription[];
    priceToPay: number;

    constructor(private afterSchoolService: AfterSchoolService,
                private eventService: EventService,
                private confirmDialog: ConfirmDialogService,
                private route: ActivatedRoute,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).pipe(debounceTime(250)).subscribe(([school, params]) => {
            if (LocalStorage.school) {
                this.getAfterSchoolOffer(params['id'], params['amount'] || null);
            }
        }));
    }

    pay() {
        if ((this.fc.price.value * this.subscriptionsToPay.length) > this.currentSaldo) {
            const extra = Math.ceil((this.fc.price.value * 100) * this.subscriptionsToPay.length) / 100;
            const eventId = this.event.id;
            this.router.navigate([Routenames.transactionTopUp, {extra, eventId}]);
        } else {
            this.paying = true;
            this.afterSchoolService.pay(this.event.id, this.fc.price.value).subscribe(result => {
                this.paying = false;
                this.confirmDialog.confirm(
                    'Betaald',
                    `U heeft betaald voor deelname aan ${this.event.title}`,
                    'Sluiten', null).then(() => {
                    this.router.navigate([Routenames.events, this.event.id]);
                }, () => {
                    this.router.navigate([Routenames.events, this.event.id]);
                });
            }, error => {
                const errorData = error.data as {lowCredit: boolean, price: number, credit: number};
                if (errorData.lowCredit) {
                    this.confirmDialog.confirm(
                        'Fout',
                        `U heeft ${formatCurrency(errorData.credit, 'nl', '€')}
                     tegoed op uw account. Er is ${formatCurrency(errorData.price, 'nl', '€')} benodigd.`,
                        'Opwaarderen', 'Annuleren').then(() => {
                        this.router.navigate([Routenames.transactionTopUp]);
                    }, () => {

                    });
                }
                this.paying = false;
            });
        }
    }

    private getAfterSchoolOffer(id, amount?: number) {

        this.eventService.get(id + '-').subscribe(request => {
            this.subscriptionsToPay = request.data.subscriptions.filter(s => !s.transaction_id);
            this.priceToPay = this.subscriptionsToPay?.length * request.data.price;
            this.event = request.data;
            const validators = [Validators.required];
            validators.push(Validators.min(this.event.price));
            validators.push(Validators.max(this.event.price));

            this.fc = {
                price: new UntypedFormControl(this.event.price, validators)
            };
            this.form = new UntypedFormGroup(this.fc);
            if (amount) {
                this.fc.price.setValue(amount / this.subscriptionsToPay.length);
                this.pay();
            }
            this.subscriptions.add(this.fc.price.valueChanges.subscribe(price => {
                this.priceToPay = this.subscriptionsToPay?.length * price;
            }));
        }, () => {

        });
    }

}
