<div class="container mt-3">
    <div class="row justify-content-center ">
        <div class="col-md-12 payment p-4">
            <div>
                <h2 class="news__title">Oudergesprekken</h2>
                <p *ngIf="!discussions">
                    Aan het laden...
                </p>
                <p *ngIf="discussions?.length === 0">
                    Er zijn geen oudergesprekken gepland.
                </p>
                <div class="news-home mb-3">
                    <div *ngFor="let discussion of discussions"
                         class="item"
                         routerLink="/{{Routenames.discussions}}/{{discussion.id}}">
                        <h3 class="item__title">{{discussion.name}}</h3>
                        <div *ngIf="!discussion.subscription_open" class="item__details">
                            Inschrijven mogelijk vanaf&nbsp;
                            <span *ngIf="discussion.mayPreSubscribe">
                                     {{discussion.pre_subscription_start | date:'EEE d MMM yyyy, H:mm'}}.
                                </span>
                            <span *ngIf="!discussion.mayPreSubscribe">
                                     {{discussion.subscription_start | date:'EEE d MMM yyyy, H:mm'}}.
                                </span>
                        </div>
                        <div *ngIf="discussion.subscription_open && discussion.classrooms_to_go?.length === 0" class="item__details">
                            <ng-container *ngFor="let date of discussion.times; let first = first">
                                {{first ? '' : ' | '}} {{date.start_date | date:'EEE d MMM'}}
                            </ng-container>
                        </div>
                        <div *ngIf="discussion.subscription_open && discussion.classrooms_to_go?.length > 0" class="item__details">
                            <ng-container *ngIf="discussion.own_times.length === 0">
                                U kunt nu inschrijven voor deze ouderavond
                            </ng-container>
                            <ng-container *ngIf="discussion.own_times.length !== 0">
                                <div *ngFor="let time of discussion.own_times" class="w-100">
                                    {{time.datetime | date:'EEE d MMM H:mm'}} -
                                    {{(LocalStorage.user.students|byId:time.student_id)?.name}}
                                    ({{((LocalStorage.user.students|byId:time.student_id)?.classrooms|byId:time.classroom_id)?.classroom_name}})
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
