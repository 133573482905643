import {Component, OnInit, ViewChild} from '@angular/core';
import {combineLatest} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {Article} from '../../classes/article.class';
import {ArticleService} from '../../services/article.service';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {formatDate, Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Routenames} from '../../route-names.enum';
import {CodaltComponent} from '../../codalt.component';
import {Image} from '../../classes/image.class';
import {ImageViewerDialogComponent} from '../../image-viewer-dialog/image-viewer-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ImageViewerData} from '../../image-viewer-dialog/image-viewer-data';
import {startWith} from 'rxjs/operators';
import {CordovaService} from '../../services/cordova.service';
import {Settings} from '../../settings.class';
import {Document} from '../../classes/document.class';
import {Video} from '../../classes/video.class';

declare var cordova;

@Component({
    selector: 'app-article-detail',
    templateUrl: './article-detail.component.html',
    styleUrls: ['./article-detail.component.scss']
})
export class ArticleDetailComponent extends CodaltComponent implements OnInit {

    @ViewChild('likes') likesTemplate;

    loading = false;
    article: Article;
    deleting = false;

    ownLike = false;

    constructor(private articleService: ArticleService,
                private classroomService: ClassroomService,
                private location: Location,
                private router: Router,
                private confirmDialogService: ConfirmDialogService,
                private matDialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                private cordovaService: CordovaService,
                private route: ActivatedRoute,
                private dialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school && (!this.article || (this.article && this.article.slug != params['id']))) {
                this.getArticle(params['id']);
            }
        }));

    }

    showLikes() {
        const dialogRef = this.dialog.open(this.likesTemplate, {
            panelClass: 'confirm-dialog',
            disableClose: true,
        });
    }

    like() {
        this.articleService.like(this.article.id).subscribe(like => {
            if (like.data) {
                this.article.articleLikes.push(like.data);
            } else {
                this.article.articleLikes.splice(this.article.articleLikes.findIndex(l => l.user_id === LocalStorage.user.id), 1);
            }
            this.ownLike = !!this.article.articleLikes.find(a => a.user_id === LocalStorage.user.id);
        });
    }

    delete() {
        this.confirmDialog.confirm('Verwijderen',
            `Weet je zeker dat je dit artikel wilt verwijderen?`, 'Ja', 'Nee').then(() => {
            this.articleService.delete(this.article.id).subscribe(() => {
                this.deleting = true;
                this.router.navigateByUrl(Routenames.articles);
            });
        }, () => {
        });
    }

    openDocument(doc: Document) {
        const url = Settings.API_ENDPOINT +
            'file/file?secure=articleDocument' +
            '&path=/' + this.article.id + '/' + doc.path +
            '&school=' + LocalStorage?.school?.id +
            '&access_token=' + LocalStorage.getUserToken();
        if (typeof cordova !== 'undefined') {
            window['PreviewAnyFile'].previewPath(
                win => console.log("open status", win),
                error => console.error("open failed", error),
                url, {name: 'file.pdf'}
            );
        } else {
            window.open(url);
        }
    }

    openImage(item: Image | Video) {

        if (typeof (item as Video)?.processed === 'undefined' || (item as Video)?.processed) {
            let viewIndex = this.article.images.indexOf(item);
            if (viewIndex < 0) {
                viewIndex = this.article.videos.indexOf(item as Video) + this.article.images.length;
            }
            const dialogRef = this.matDialog.open(ImageViewerDialogComponent, {
                panelClass: 'image-viewer',
                width: '100vw',
                height: '100vh',
                maxWidth: '100vw',
                hasBackdrop: true,
                data: {
                    videos: this.article.videos?.filter(v => v.processed).map((video, index) => {
                        video['name'] = `${this.article.title}-${formatDate(this.article.publish_date, 'EEEE d MMMM yyyy HH:mm', 'nl')}-${index + 1}`.replace(/\s/g, '-').replace(/[^0-9a-z\-]/gi, '');
                        return video;
                    }),
                    images: this.article.images.map((img, index) => {
                        img['name'] = `${this.article.title}-${formatDate(this.article.publish_date, 'EEEE d MMMM yyyy HH:mm', 'nl')}-${index + 1}`.replace(/\s/g, '-').replace(/[^0-9a-z\-]/gi, '');
                        return img;
                    }),
                    secure: 'article',
                    viewIndex
                } as ImageViewerData
            });
        }
    }

    switchToSchool() {
        LocalStorage.school = LocalStorage.schools.find(p => +p.id === +this.article.school_id);
    }

    private getArticle(slug) {
        this.articleService.get(slug).subscribe((article) => {
            this.article = article.data;
            if (new Date(this.article.publish_date).getTime() > new Date().getTime()) {
                this.cordovaService.setBackbuttonAction(() => {
                    this.router.navigateByUrl(`${Routenames.articles}/toekomst`);
                });
            }
            this.location.replaceState(`${Routenames.articles}/${article.data.slug}`);
            // TODO dit doet niets aangezien in getIfAccess er controle zit op school input id...
            if (!article.data.global && LocalStorage.school?.id != this.article.school_id) {
                LocalStorage.school = LocalStorage.schools.find(p => +p.id === +this.article.school_id);
            }
            this.ownLike = !!this.article.articleLikes.find(a => a.user_id === LocalStorage.user.id);
        }, error => {
            if (!this.isCordova) {
                this.confirmDialogService.confirm('Fout', error.message, 'Terug naar overzicht', null).then(() => {
                    this.router.navigateByUrl(Routenames.articles);
                });
            } else {
                this.router.navigateByUrl(Routenames.articles);
            }
        });
    }
}
