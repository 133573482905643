import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {FileService, FileType} from '../services/file/file.service';
import {MessageService} from '../services/message/message.service';
import {MatDialog} from '@angular/material/dialog';
import {FileSelectionDialogComponent} from './file-selection-dialog/file-selection-dialog.component';
import {FileItem} from './file-selection-dialog/FileItem';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ChooseFileSourceComponent} from './choose-file-source/choose-file-source.component';
import {CordovaService} from '../services/cordova.service';
import {Platform} from '@angular/cdk/platform';
import {Utils} from '../utils.class';

declare var cordova;

@Component({
    selector: 'file-manager',
    templateUrl: './file-manager.component.html',
    styleUrls: ['file-manager.component.scss']
})
export class FileManagerComponent {

    static callback = new EventEmitter<any>();
    static loading = new EventEmitter<any>();

    isIos = false;
    dragOver = false;
    @Input() fileType: any;
    @Input() selectImage = true;
    @Input() secure = '';
    @Input() path: string;
    @Input() hide = false;
    @Input() multiple = false;
    @Input() directUpload = false;
    @Input() hideCamera = false;
    @Input() video = false;
    @Input() remove = false;
    @Input() icon: string;
    @Input() iconText: string;
    @Input() currentFile: string;
    @Input() allMedia = false;
    @Output() loading = new EventEmitter<any>();
    @Output() selected = new EventEmitter();
    @Output() callback = new EventEmitter<any>();

    @Input() reference: FileType;
    @Input() referenceItem: number;

    @ViewChild('uploadInput') uploadInput: ElementRef<HTMLInputElement>;

    directCamera = false;

    sheetDismissSubs = null;

    constructor(private fileServive: FileService,
                private messageService: MessageService,
                private matBottomSheet: MatBottomSheet,
                private cordovaService: CordovaService,
                public platform: Platform,
                private fileService: FileService,
                private matDialog: MatDialog) {
        if (typeof cordova !== 'undefined') {
            this.directCamera = true;
        }
        this.isIos = this.platform.IOS;
    }

    chooseSource(event?: any) {
        event?.stopPropagation();
        if (!this.sheetDismissSubs && this.directCamera) {
            const ref = this.matBottomSheet.open(ChooseFileSourceComponent, {
                data: {
                    rotate: !!this.currentFile,
                    remove: this.remove && !!this.currentFile,
                    currentFile: this.currentFile,
                    video: this.allMedia,
                    document: this.allMedia
                }
            });
            this.sheetDismissSubs = ref.afterDismissed().subscribe(type => {
                if (type === 'video') {
                    this.video = true;
                    this.reference = FileType.video;
                    this.openModal(null, true, false, true);
                }
                if (type === 'video-gallery') {
                    this.video = true;
                    this.reference = FileType.video;
                    this.openModal(null, true, true, true);
                }
                if (type === 'camera') {
                    this.video = false;
                    this.reference = null;
                    this.openModal(null, true, false, false);
                }
                if (type === 'gallery') {
                    this.video = false;
                    this.reference = null;
                    this.openModal(null, true, true, false);
                }
                if (type === 'document') {
                    this.video = false;
                    this.reference = FileType.document;
                    if (!Utils.isIOS()) {
                        FileManagerComponent.loading.emit('uploadDialog');
                        this.uploadInput.nativeElement.click();
                    }
                }
                if (type === 'remove') {
                    this.callback.emit(null);
                }
                if (type === 'rotate') {
                    const path = this.path ? `/${this.path}/${this.currentFile}` : this.currentFile;
                    this.fileService.rotateImage(path, (this.directUpload && !this.path), this.secure).subscribe(file => {
                        setTimeout(() => {
                            this.callback.emit(null);
                            setTimeout(() => {
                                this.callback.emit({
                                    file: {
                                        filename: file.data.basename,
                                        pathname: this.secure ? `${file.data.path}` : `/media${file.data.path}`,
                                        mime: file.data.extension
                                    },
                                    fileType: this.fileType
                                });
                            });
                        });
                    });
                }
                this.cordovaService.setBackbuttonAction(null);
                this.sheetDismissSubs.unsubscribe();
                this.sheetDismissSubs = null;
            });
            this.cordovaService.setBackbuttonAction(() => {
                ref.dismiss();
            });
        }
    }

    uploadFile(event) {
        FileManagerComponent.callback.emit('uploadDialog');
        event.stopPropagation();
        event.preventDefault();
        const files = event.srcElement.files;
        this.openModal(files);
        this.loading.emit(files);
    }

    openModal(files?: File[], directCamera?: boolean, photoLib?: boolean, video?: boolean) {
        if (directCamera && (video || this.video)) {
            this.loading.emit(files);
        }
        const identifier = `${(new Date()).getTime()}`;
        FileManagerComponent.loading.emit(identifier);
        const dialogRef = this.matDialog.open(FileSelectionDialogComponent, {
            panelClass: this.directUpload ? 'd-none' : 'file-selection-dialog',
            hasBackdrop: !this.directUpload,
            disableClose: true,
            width: '700px',
            height: '500px',
            data: {
                photoLib,
                directCamera,
                secure: this.secure,
                multiple: this.multiple,
                images: this.selectImage,
                files,
                currentPath: this.path,
                video: this.video,
                reference: this.reference,
                referenceItem: this.referenceItem
            }
        });
        const subs = dialogRef.afterClosed().subscribe((uploadedFiles: FileItem[]) => {
            if (this.uploadInput) {
                this.uploadInput.nativeElement.value = null;
            }
            if (uploadedFiles) {
                const selectedFiles = uploadedFiles.map(f => {
                    return {
                        filename: f.basename,
                        pathname: this.secure ? `${f.path}` : `/media${f.path}`,
                        mime: f.extension,
                        reference: this.reference,
                        referenceItem: this.referenceItem,
                        orginalName: f.orginalName,
                        video: f.video
                    };
                });
                const availableFiles = this.multiple ? selectedFiles : selectedFiles[0];
                const callbackData = {
                    file: availableFiles,
                    fileType: this.fileType,
                    orgFiles: files
                };
                this.callback.emit(callbackData);
                FileManagerComponent.callback.emit(identifier);
            }
            if (video) {
                this.callback.emit();
                FileManagerComponent.callback.emit(identifier);
            }
            this.selected.emit();
            FileManagerComponent.callback.emit(identifier);
            subs.unsubscribe();
        }, () => {
            FileManagerComponent.callback.emit(identifier);
        });
    }

    @HostListener('dragover', ['$event'])
    public ondragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    public ondragleave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (evt.srcElement.classList.contains('dialog-container') || evt.srcElement.classList.contains('overlay')) {
            this.dragOver = false;
        }
    }

    @HostListener('drop', ['$event'])
    public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = false;
        const files = evt.dataTransfer.files;
        this.openModal(files);
    }

    readonly FileType = FileType;
}
