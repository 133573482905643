import {Component, OnInit} from '@angular/core';
import {FormService} from '../../services/form/form.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CodaltComponent} from '../../codalt.component';
import {LocalStorage} from '../../storage.class';
import {Form} from '../../classes/form.class';

@Component({
    selector: 'app-form-history',
    templateUrl: './form-history.component.html',
    styleUrls: ['./form-history.component.scss']
})
export class FormHistoryComponent extends CodaltComponent implements OnInit {

    items: Array<Form> = new Array<Form>();
    loading = true;
    showMore = false;
    limit = 30;

    inProgress = false;

    constructor(private formService: FormService,
                public snackBar: MatSnackBar) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.items = [];
            this.loadHistory();
        }));
        if (LocalStorage.school) {
            this.loadHistory();
        }
    }

    loadMore() {
        this.inProgress = true;
        this.loading = true;
        this.loadHistory();
    }

    private loadHistory() {
        this.loading = true;
        this.formService.getFormsHistory(this.items.length, this.limit).subscribe(items => {

            this.items = this.items.concat(items.data);
            this.loading = false;
            this.showMore = items.data.length === this.limit;
            this.inProgress = false;

            if (items.message && items.message.length > 0) {
                this.snackBar.open(items.message, 'Oké', {
                    duration: 3500
                });
            }
        }, () => {
            this.snackBar.open('Fout bij het ophalen van Formulier geschiedenis', 'Oké', {
                duration: 3500
            });
        });
    }

}
