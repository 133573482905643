import {Component, OnInit} from '@angular/core';
import {PageService} from '../services/page/page.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Page} from '../classes/page.class';
import {LocalStorage} from '../storage.class';

@Component({
    selector: 'app-page-history',
    templateUrl: './page-history.component.html',
    styleUrls: ['./page-history.component.css']
})
export class PageHistoryComponent implements OnInit {

    LocalStorage = LocalStorage;
    history: Page[];
    private sub: any;
    private pageId: number;

    constructor(private pageService: PageService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.pageId = +params['id'];
            const parentId = +params['parent'];
            this.pageService.getPageHistory(this.pageId).subscribe(history => {
                this.history = history.data;
            });
        });
    }


    restore(pageHistoryId) {
        this.pageService.restoreHistory(pageHistoryId).then(data => {
            this.router.navigate(['/pagina-management']);
        });
    }
}
