<div class="container white">
    <div class="d-md-flex align-items-center justify-content-between mb-3">
        <h2 class="mb-0">Absenties</h2>
        <div class="d-flex mt-md-0 mt-3">
            <button class="button--primary mr-2" color="primary" routerLink="/{{Routenames.absenceCounts}}"
                    *ngIf="teacher">
                Aantallen
            </button>
            <button class="button--primary" color="primary" routerLink="/{{Routenames.absenceRegister}}">
                Absentie registreren
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div *ngIf="students" class="row align-items-center">
                <ng-select class="col-24 col-md" [items]="students"
                           [multiple]="true"
                           bindLabel="name"
                           bindValue="id"
                           [formControl]="fcStudentIds"
                           placeholder="Leerlingen">
                </ng-select>
                <ng-select class="col-24 col-md" [items]="classrooms"
                           [multiple]="true"
                           bindLabel="classroom_name"
                           bindValue="id"
                           [formControl]="fcClassroomIds"
                           placeholder="Groepen">
                </ng-select>
                <mat-checkbox [formControl]="fcDone" class="col-auto">Toon afgehandeld</mat-checkbox>
                <mat-checkbox [formControl]="fcUnauthorized" class="col-auto">Alleen ongeoorloofd</mat-checkbox>

            </div>

            <table class="table table-striped d-none d-md-table">
                <thead>
                <tr class="sticky-top">
                    <th (click)="sortList('student_name')">
                        Leerling
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc"
                                          property="student_name"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('reason_name')">
                        Reden
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc"
                                          property="reason_name"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('start_date')">
                        Datum
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc"
                                          property="start_date"></app-sorting-icon>
                    </th>
                    <th>
                        Tijdsduur
                    </th>
                    <th>
                        Toegekend
                    </th>
                    <th>
                        Opgenomen
                    </th>
                    <th style="width: 20px"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let absence of list" style="cursor:pointer;">
                    <td (click)="editAbsence(absence.id)">{{absence.student_name}}</td>
                    <td (click)="editAbsence(absence.id)">{{absence.reason_name}}</td>
                    <td (click)="editAbsence(absence.id)">{{absence.start_date | date:'d MMMM yyyy'}}</td>
                    <td (click)="editAbsence(absence.id)">{{absence.duration}}</td>
                    <td (click)="editAbsence(absence.id)">
                        <ng-container *ngIf="absence?.approved_at">
                            {{absence.approved ? 'Ja' : 'Nee'}}
                        </ng-container>
                    </td>
                    <td (click)="editAbsence(absence.id)">
                        <ng-container *ngIf="absence.taken_date">
                            Ja
                        </ng-container>
                        <ng-container *ngIf="!absence.taken_date && absence?.approved_at">
                            Nee
                        </ng-container>
                    </td>
                    <td (click)="delete(absence.id)" matRipple>
                        <i class="fas fa-trash"></i>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="container mt-3 d-md-none">
    <div class="row justify-content-center ">
        <div class="col-md-12 payment">
            <div class="news-home mb-3 ">
                <div *ngFor="let absence of list"
                     class="item"
                     (click)="editAbsence(absence.id)">
                    <h3 class="item__title">{{absence.student_name}}</h3>
                    <div class="item__details">
                        <time class="item__date">{{absence.start_date | date:'d MMMM yyyy'}}
                            - {{absence.duration}}</time>
                        <div class="item__author">{{absence.reason_name}}</div>
                    </div>
                    <div *ngIf="!absence.approved && absence.approved_at" class="item__alert">
                        <svg-icon src="assets/svg/alert.svg"></svg-icon>
                    </div>
                    <div class="item__employee" *ngIf="absence.approved && absence.approved_at">
                        <i class="fa-light fa-circle-check"></i>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
