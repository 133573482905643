<div class="row">
    <div class="col-lg-17 col-xl-15">
        <div *ngIf="mayNotRegisterPresence">
            Dit aanbod is nog niet gestart, er kan nog geen aanwezigheid geregistreerd worden.
        </div>
        <mat-form-field class="w-100" *ngIf="!mayNotRegisterPresence">
            <mat-label>Aanwezigheid voor datum</mat-label>
            <mat-select [formControl]="fcDaterangeId">
                @for (range of event.dateranges; track range.id) {
                    <mat-option [value]="range.id" [disabled]="todaySelectionAvailable < range.start_date">
                        <ng-container *ngIf="range.all_day && (range.start_date | date:'yMd') != (range.end_date | date:'yMd')">
                            {{range.start_date | date:'EEEE d MMMM yyyy'}} -
                            {{range.end_date | date:'EEEE d MMMM yyyy'}}
                        </ng-container>
                        <ng-container *ngIf="range.all_day && (range.start_date | date:'yMd') == (range.end_date | date:'yMd')">
                            {{range.start_date | date:'EEEE d MMMM yyyy'}}
                        </ng-container>
                        <ng-container *ngIf="!range.all_day">
                            <ng-container *ngIf="(range.start_date | date:'yyMd') == (range.end_date | date:'yyMd')">
                                {{range.start_date | date:'EEEE d MMMM yyyy, HH:mm'}} -
                                {{range.end_date | date:'HH:mm'}}
                            </ng-container>
                            <ng-container *ngIf="(range.start_date | date:'yyMd') != (range.end_date | date:'yyMd')">
                                {{range.start_date | date:'EEEE d MMMM yyyy HH:mm'}} -
                                {{range.end_date | date:'EEEE d MMMM yyyy HH:mm'}}
                            </ng-container>
                        </ng-container>
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div>
    @if (!(event.all_subscriptions ?? event.subscriptions)?.length && fcDaterangeId.value) {
        <div>
            Er zijn geen inschrijvingen op dit aanbod
        </div>
    }
    @for (subscription of (event.all_subscriptions ?? event.subscriptions); track subscription.id) {
        <div class="row mb-1" *ngIf="presenceMap.has(fcDaterangeId.value+'-'+subscription.student_id)">
            <div class="col-lg-7 col-sm-10 d-flex align-items-center">
                <div class="pt-2 pb-2">
                    {{subscription.student.name}}
                </div>
            </div>
            <div class="col-sm-14 col-lg-9">
                @if (event.all_subscriptions || presenceMap.get(fcDaterangeId.value+'-'+subscription.student_id).disabled) {
                    <mat-button-toggle-group [formControl]="presenceMap.get(fcDaterangeId.value+'-'+subscription.student_id).controls.presence"
                                             aria-label="Aanwezigheid">
                        <mat-button-toggle value="present">Aanwezig</mat-button-toggle>
                        <mat-button-toggle value="absent">Afwezig</mat-button-toggle>
                    </mat-button-toggle-group>
                } @else {
                    <mat-button-toggle-group [formControl]="presenceMap.get(fcDaterangeId.value+'-'+subscription.student_id).controls.presence"
                                             aria-label="Aanwezigheid">
                        <mat-button-toggle [value]="">Aanwezig</mat-button-toggle>
                        <mat-button-toggle value="absent">Afwezig</mat-button-toggle>
                    </mat-button-toggle-group>
                }
            </div>
            <div class="col col-auto"
                 *ngIf="userMap.has(presenceMap.get(fcDaterangeId.value+'-'+subscription.student_id).value.user_id) && presenceMap.get(fcDaterangeId.value+'-'+subscription.student_id).value.user_id !== LocalStorage.user.id">
                <small>Afwezig gemeld door {{userMap.get(presenceMap.get(fcDaterangeId.value + '-' + subscription.student_id).value.user_id)?.name}}</small>
            </div>
        </div>
    }
</div>
