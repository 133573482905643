import {Component, OnInit} from '@angular/core';
import {LocalStorage} from '../storage.class';
import {PageService} from '../services/page/page.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {School} from '../classes/school.class';
import {SchoolService} from '../services/school/school.service';
import {LinkEditDialogComponent} from '../link-edit/link-edit-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '../services/auth/authentication.service';
import {CodaltComponent} from '../codalt.component';

@Component({
    selector: 'app-school-settings',
    templateUrl: './school-settings.component.html',
    styleUrls: ['./school-settings.component.scss']
})
export class SchoolSettingsComponent extends CodaltComponent implements OnInit {

    LocalStorage = LocalStorage;
    form: UntypedFormGroup;
    pageList: { page: string, url: string }[];
    saving = false;

    constructor(private pageService: PageService,
                private schoolService: SchoolService,
                private authService: AuthenticationService,
                public dialog: MatDialog) {
        super();
    }


    editLink(fieldId) {
        let dialogData = {value: this.form.get(fieldId).value, target: this.form.get(fieldId + 'target').value};
        let dialogRef = this.dialog.open(LinkEditDialogComponent, {
            width: '320px',
            data: dialogData
        });

        this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.form.get(fieldId).setValue(dialogData.value);
                this.form.get(fieldId + 'target').setValue(dialogData.target);
            }
        }));
    }

    ngOnInit(): void {
        this.authService.refresh().subscribe(() => {
            if (LocalStorage.school) {
                this.createForm(LocalStorage.school);
            }

            this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
                this.createForm(LocalStorage.school);
            }));
        });
    }

    save() {
        this.saving = true;
        const values = this.form.value;
        values['updated_at'] = LocalStorage.school.updated_at;
        this.schoolService.save(values).subscribe(school => {
            LocalStorage.school = school.data;
            const currentSchoolIndex = LocalStorage.user.anySchools.findIndex(s => s.id === school.data.id);
            const user = LocalStorage.user;
            user.anySchools[currentSchoolIndex] = school.data;
            LocalStorage.setUser(user);
            this.saving = false;
        }, error => {
            this.saving = false;
        });
    }

    fileUploaded(data, field) {
        this.form.controls[field].setValue(data.file.pathname);
    }

    private createForm(school: School) {
        this.pageService.getList().subscribe(pages => {
            this.pageList = pages.data;
        });

        this.form = new UntypedFormGroup({
            absences: new UntypedFormControl(school.absences),
            replies: new UntypedFormControl(school.replies),
            chat_preference: new UntypedFormControl(school.chat_preference),
            facebook_url: new UntypedFormControl(school.facebook_url),
            twitter_url: new UntypedFormControl(school.twitter_url),
            linkedin_url: new UntypedFormControl(school.linkedin_url),
            instagram_url: new UntypedFormControl(school.instagram_url),
            privacy_url: new UntypedFormControl(school.privacy_url),
            badge1_title: new UntypedFormControl(school.badge1_title),
            badge1_image: new UntypedFormControl(school.badge1_image),
            badge1_url: new UntypedFormControl(school.badge1_url),
            badge1_urltarget: new UntypedFormControl(school.badge1_urltarget),
            badge2_title: new UntypedFormControl(school.badge2_title),
            badge2_image: new UntypedFormControl(school.badge2_image),
            badge2_url: new UntypedFormControl(school.badge2_url),
            badge2_urltarget: new UntypedFormControl(school.badge2_urltarget)
        });
    }
}
