<h2 class="striped-title heading-500">Reacties</h2>
<p *ngIf="replies?.length === 0">
    Er zijn nog geen reacties geplaatst bij dit nieuwsbericht.
</p>
<div class="discussion" *ngFor="let reply of replies | slice : 0 : viewRepliesCount">
    <div class="reply">
        <div class="avatar">
            <span>{{reply.user_name | slice : 0 : 1}}</span>
        </div>
        <div class="reply-header">
            <h3 class="name"><strong>{{reply.user_name}}</strong></h3>
            <div class="time">op
                <time>{{reply.created_at | date : 'd MMM yyyy HH:mm'}}</time>
            </div>
        </div>
        <div class="votes" *ngIf="reply.deletable" (click)="delete(reply)">
            <button mat-icon-button>
                <mat-icon class="fas fa-trash"></mat-icon>
            </button>
        </div>
        <div class="votes" *ngIf="false">
            <button class="btn btn-icon btn-blue-light" [disabled]="mayNotLike || reply.likes < 1 || voted.has(reply.id) || reply.user_id === LocalStorage.user?.id"
                    (click)="vote(reply, false)">
                <i class="fas fa-minus"></i>
            </button>
            <div class="count">{{reply.likes ?? 0}}</div>
            <button class="btn btn-icon btn-blue-light" [disabled]="mayNotLike || voted.has(reply.id) || reply.user_id === LocalStorage.user?.id"
                    (click)="vote(reply, true)">
                <i class="fas fa-plus"></i>
            </button>
        </div>
        <div class="content" [innerHTML]="reply.content | nl2br"></div>
    </div>


    <div class="replies">
        <div class="reply ml-5" *ngFor="let subReply of reply.sub_replies | slice : 0 : (reply.viewRepliesCount ?? 1)">
            <div class="avatar">
                <span>{{subReply.user_name | slice : 0 : 1}}</span>
            </div>
            <div class="reply-header">
                <h3 class="name"><strong>{{subReply.user_name}}</strong></h3>
                <div class="time">op
                    <time>{{subReply.created_at | date : 'd MMM yyyy HH:mm'}}</time>
                </div>
            </div>
            <div class="votes" *ngIf="false">
                <button class="btn btn-icon btn-blue-light"
                        [disabled]="mayNotLike || subReply.likes < 1 || voted.has(subReply.id) || subReply.user_id === LocalStorage.user?.id"
                        (click)="vote(subReply, false)">
                    <i class="fas fa-minus"></i>
                </button>
                <div class="count">{{subReply.likes ?? 0}}</div>
                <button class="btn btn-icon btn-blue-light" [disabled]="mayNotLike || voted.has(subReply.id) || subReply.user_id === LocalStorage.user?.id"
                        (click)="vote(subReply, true)">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
            <div class="content" [innerHTML]="subReply.content | nl2br"></div>
        </div>
    </div>
    <div (click)="reply.viewRepliesCount = (reply.viewRepliesCount ?? 1) + 6"
         *ngIf="(reply.sub_replies | slice : (reply.viewRepliesCount ?? 1))?.length && LocalStorage.user" class="more-replies">
        <div class="number"><span>{{(reply.sub_replies | slice : (reply.viewRepliesCount ?? 1))?.length}}</span></div>
        <p>Bekijk meer reacties</p>
    </div>

    <div class="react" *ngIf="false">
        <div class="input-wrapper">
            <textarea cdkTextareaAutosize
                      [cdkAutosizeMinRows]="1"
                      [cdkAutosizeMaxRows]="6"
                      (keyup)="sendEnter($event, reply)"
                      class="form-control"
                      placeholder="Beantwoorden"
                      type="text"
                      [formControl]="fcReply">
            </textarea>
            <button [disabled]="fcReply.invalid" class="submit btn" (click)="sendReply(reply)"><i class="fas fa-paper-plane"></i></button>
        </div>
        <div class="error" *ngIf="fcReply.touched && fcReply.value?.length && fcReply.invalid">
            Voer een reactie in van maximaal 550 tekens.
        </div>
    </div>
</div>

<div class="buttons" *ngIf="LocalStorage.user">
    <div *ngIf="(replies | slice : viewRepliesCount)?.length"
         class="btn btn-lowercase btn-blue-solid"
         (click)="viewRepliesCount = viewRepliesCount + 6">
        Laad meer discussies ({{(replies | slice : viewRepliesCount)?.length}})
    </div>
</div>
<h2 class="striped-title heading-600 mt-5">Plaats een reactie</h2>
<div class="discussion">
    <div class="react" *ngIf="hasNotReplied">
        <div class="input-wrapper">
                <textarea cdkTextareaAutosize
                          [cdkAutosizeMinRows]="1"
                          [cdkAutosizeMaxRows]="6"
                          (keyup)="sendEnter($event)"
                          class="form-control"
                          placeholder="Typ hier jouw reactie"
                          type="text"
                          [formControl]="fcReply">
                </textarea>
            <button class="submit btn" (click)="sendReply()"><i class="fas fa-paper-plane"></i></button>
        </div>
        <div class="error" *ngIf="fcReply.touched && fcReply.value?.length && fcReply.invalid">
            Voer een reactie in van maximaal 550 tekens.
        </div>
    </div>
    <div class="react" *ngIf="!hasNotReplied">
        Je hebt al gereageerd op dit bericht. Je mag maximaal één reactie plaatsen.
    </div>
</div>
