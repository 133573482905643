<div class="login-container">
    <div class="login">
        <form [formGroup]="loginForm" [style.fontSize.px]="19" class="login-form">
            <h1>{{loginFormState === LoginFormState.Send ? 'Gelukt!' : 'Inloggen'}}</h1>

            <ng-container *ngIf="loginFormState !== LoginFormState.Send">
                <div class="mb-2">
                    We sturen een link naar je e-mailadres.
                </div>
                <mat-form-field id="email">
                    <input formControlName="email" matInput name="email" placeholder="Vul hier je e-mailadres in"
                           type="text">
                    <mat-label>E-mailadres</mat-label>
                    <mat-error [control]="loginForm.get('email')"></mat-error>
                </mat-form-field>
            </ng-container>
            <div class="pt-2">

                <button (click)="login()" *ngIf="loginFormState !== LoginFormState.Send" [disabled]="loginFormState === LoginFormState.Login"
                        class="loading w-100 big dark" color="primary" id="btn-login"
                        mat-flat-button>
                    <ng-container *ngIf="loginFormState !== LoginFormState.Login">
                        Opvragen
                    </ng-container>
                    <ng-container *ngIf="loginFormState === LoginFormState.Login">
                        We sturen je nu een link
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </ng-container>
                </button>
                <div *ngIf="loginFormState === LoginFormState.Send" class="send mb-3">
                    <div class="mb-2">
                        <h2>
                            We hebben een link verzonden waarmee je kunt inloggen!
                        </h2>
                    </div>
                    <small>
                        Controleer of deze in spam terecht is gekomen en voeg
                        <a href="mailto:app@kindwijs.org">app&#64;kindwijs.org</a> toe aan de lijst met veilige afzenders.
                    </small>
                </div>
                <button class="w-100 mt-3" id="btn-lost-password" mat-button routerLink="/inloggen/wachtwoord">
                    Inloggen met een wachtwoord
                </button>
            </div>

        </form>
    </div>
</div>
