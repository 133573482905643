import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {PermissionQuestion} from '../classes/permission-question';
import {User} from '../classes/user.class';
import {PermissionHistory} from '../classes/permission-history';
import {map} from 'rxjs/operators';
import {LocalStorage} from '../storage.class';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    constructor(private apiService: ApiService) {
    }

    savePermissionForQuestion(answers) {
        return this.apiService.postCall$<User>('permissions/answer', answers);
    }

    getPermissionQuestionsForStudent(id?: number) {
        return this.apiService.getCall$<PermissionQuestion>('permissions/for-students', {id});
    }

    getPermissionQuestionsForStudents() {
        const today = new Date();
        return this.apiService.getCall$<PermissionQuestion[]>('permissions/for-students').pipe(map(questions => {
            questions.data.forEach(question => {
                question.deadline_date = question.deadline_date ? new Date(question.deadline_date) : null;
                question._answers = [];
                LocalStorage.user.students.forEach(student => {
                    const answer = student.permissions.find(p => p.permission_question_id === question.id);
                    if (answer) {
                        question._answers.push({
                            student,
                            answer
                        });
                    }
                });
            });
            questions.data = questions.data.filter(q => {
                return (!q.deadline_date || q.deadline_date >= today) || (q.deadline_date < today && q._answers?.length === LocalStorage.user?.students?.length);
            });
            return questions;
        }));
    }

    getPermissionQuestion(id) {
        return this.apiService.getCall$<PermissionQuestion>('permissions', {id});
    }

    getPermissionQuestions() {
        return this.apiService.getCall$<PermissionQuestion[]>('permissions');
    }

    savePermissionQuestion(questions: PermissionQuestion[]) {
        return this.apiService.postCall$<PermissionQuestion[]>('permissions', {questions});
    }

    getPermissionHistory(studentId: number, questionId: number) {
        return this.apiService.getCall$<PermissionHistory[]>(`permissions/history/${studentId}/${questionId}`);
    }

    getOnePermissionWithStudents(id: number) {
        return this.apiService.getCall$<PermissionQuestion[]>('permissions/with-students', {id});
    }

    getPermissionWithStudents(classroomIds, onlyForNews?: boolean) {
        return this.apiService.getCall$<PermissionQuestion[]>('permissions/with-students', {classroomIds, onlyForNews});
    }
}
