import {DiscussiontimesGroup} from './discussiontimes-group.class';
import {Discussiontime} from './discussiontime.class';

export class Discussion {
    id: number;
    classrooms: any[];
    school_id: number;
    name: string;
    description: string;
    pre_subscription_start: Date;
    pre_subscription_start_time: Date;
    pre_child_count: number;
    subscription_start: Date;
    subscription_start_time: Date;
    subscription_end: Date;
    subscription_end_time: Date;
    times: DiscussiontimesGroup[];
    discussiontimes: Discussiontime[];
    own_times: Discussiontime[];
    classrooms_to_go: number[];
    subscription_open: boolean;
    mayPreSubscribe: boolean;
    limit_registrations: boolean;
    maySubscribe: boolean;
}
