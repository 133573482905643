import {Component, OnInit} from '@angular/core';
import {JobService} from '../services/job.service';
import {Job} from '../classes/job.class';
import {CodaltComponent} from '../codalt.component';
import {LocalStorage} from '../storage.class';
import {IPageInfo} from '@iharbeck/ngx-virtual-scroller';

@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss']
})
export class JobsComponent extends CodaltComponent implements OnInit {

    jobs: Job[] = [];
    loading = true;
    private limit = 9;
    private skip = 0;
    private allLoaded = false;

    constructor(private jobService: JobService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.jobs = [];
            this.skip = 0;
            this.getJobs();
        }));
        if (LocalStorage.school) {
            this.getJobs();
        }
    }

    scroll(job: IPageInfo) {
        if (!this.loading && !this.allLoaded) {
            if (job.endIndex > (this.jobs.length) - 3) {
                this.skip += this.limit;
                this.getJobs();
            }
        }
    }

    private getJobs() {
        this.loading = true;
        this.jobService.getList(this.limit, this.skip).subscribe(jobs => {
            if (this.skip == 0) {
                this.jobs = [];
            }
            this.allLoaded = jobs.data.length < this.limit;
            this.jobs = this.jobs.concat(jobs.data);
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }
}
