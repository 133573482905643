<div class="container white">
    <h2 *ngIf="history" class="page-title">Bewerkingsgeschiedenis {{history[0]?.name}}</h2>
    <table class="table">
        <thead>
        <tr>
            <th>Paginanaam</th>
            <th>Datum wijziging</th>
            <th>Gewijzigd door</th>
            <th class="txtright">Gewijzigd</th>
            <th>Versie bekijken</th>
            <th>Versie terugzetten</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="!history">
            <td class="txtcenter" colspan="6">
                Aan het laden...
            </td>
        </tr>
        <tr *ngIf="history && history.length == 0">
            <td class="txtcenter" colspan="6">
                Er is geen geschiedenis van deze pagina
            </td>
        </tr>
        <tr *ngFor="let page of history">
            <td>{{page.name}}</td>
            <td>{{page.updated_at | date:'d MMM yyyy HH:mm:ss'}}</td>
            <td>{{page.user?.name}}</td>
            <td [class.light]="page.diff==0" [class.txtbold]="page.diff>10" class="txtright">{{page.diff}}%</td>
            <td>
                <a href="{{LocalStorage.school.fullUrl}}/page-history/{{page.id}}" target="_BLANK">
                    <i class="fas fa-external-link-alt"></i>
                    deze versie bekijken
                </a>
            </td>
            <td>
                <a (click)="restore(page.id)" class="curpoint">
                    <i class="fa fa-undo"></i>
                    deze versie terugzetten
                </a>
            </td>
        </tr>
        </tbody>
    </table>
</div>