import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'containsText'
})
export class ContainsTextPipe implements PipeTransform {

    transform(value: string, text: string): unknown {
        return value?.indexOf(text) !== -1;
    }

}
