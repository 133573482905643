<div class="container mt-3" *ngIf="error && transaction">
    <div class="row justify-content-center ">
        <div class="col-md-12 payment p-4">
            <div>
                <h2 class="news__title">{{error.message}}</h2>
            </div>
            <div>
                <div>
                    <b>Reden: </b>{{transaction.status}}
                </div>
                <div>
                    <b>Transactie nummer: </b> {{transaction.transaction_id}}
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <button class="button--primary" routerLink="/{{Routenames.transactionsOverview}}">
                        Transactieoverzicht
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
