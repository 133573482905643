import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {ClassroomService} from '../services/classroom/classroom.service';
import {LocalStorage} from '../storage.class';
import {Classroom} from '../classes/classroom.class';
import {CodaltComponent} from '../codalt.component';
import {Routenames} from '../route-names.enum';

@Component({
    selector: 'app-classrooms',
    templateUrl: './classrooms.component.html',
    styleUrls: ['./classrooms.component.css']
})
export class ClassroomsComponent extends CodaltComponent implements OnInit {

    colors = [
        '#AE3586', '#7E4892', '#4F4F8A',
        '#314F74', '#2F4858', '#CB422C',
        '#A76700', '#748000', '#2B8E39',
        '#009577', '#4E8074', '#C6FCED',
        '#564146', '#7E7000', '#B8A300',
        '#0071FF', '#2E8000', '#AD6700',
        '#73604C', '#0057AF', '#EA9A28',
        '#733700', '#263E4D', '#082331',
        '#00081A'
    ];

    classrooms: Classroom[];

    LocalStorage = LocalStorage;

    classroomcount_all: number;
    classroomcount_selected: number;
    onlythisschoolBool = true;
    saving = false;
    saved = false;
    uniqueGroupments = [];

    savingColor = false;
    savedColor = false;

    constructor(private classroomService: ClassroomService, private router: Router) {
        super();
    }

    get onlythisschool() {
        return this.onlythisschoolBool;
    }

    set onlythisschool(onlythisclassroom) {
        this.onlythisschoolBool = onlythisclassroom;
        this.getClassrooms();
    }

    ngOnInit() {

        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.getClassroomCount();
            this.getClassrooms();
        }));

        if (LocalStorage.school) {
            this.getClassroomCount();
            this.getClassrooms();
        }
    }

    saveColor() {
        this.classroomService.saveClassroomGroupments(this.classrooms).subscribe(() => {
            this.savingColor = false;
            this.savedColor = true;
            setTimeout(() => {
                this.savedColor = false;
            }, 1500);
        });
    }

    saveGroupment() {
        this.saving = true;
        this.classroomService.saveClassroomGroupments(this.classrooms).subscribe(() => {
            this.saving = false;
            this.saved = true;
            this.setUniqueGroupments();
            setTimeout(() => {
                this.saved = false;
            }, 1500);
        });
    }

    getClassrooms() {
        this.classroomService.getClassrooms(this.onlythisschoolBool).subscribe(classrooms => {
            this.classrooms = classrooms.data;
            this.setUniqueGroupments();
        });
    }

    getClassroomCount() {
        this.classroomService.getCount().subscribe(data => {
            this.classroomcount_selected = data.data.classroomcount_selected;
            this.classroomcount_all = data.data.classroomcount_all;
        });
    }

    editClassroom(id) {
        this.router.navigate([Routenames.parnassysClassrooms, id]);
    }

    addTag(tag: string) {
        return tag;
    }

    autoGroupment() {
        const autoGroupments = [
            {group: 'Onderbouw', values: ['1', '2']},
            {group: 'Middenbouw', values: ['3', '4', '5']},
            {group: 'Bovenbouw', values: ['6', '7', '8']}
        ];

        this.classrooms.forEach(classroom => {
            if (!classroom.groupment) {
                autoGroupments.forEach(autoGroupment => {
                    autoGroupment.values.forEach(agv => {
                        if (classroom.classroom_name.indexOf(agv) !== -1) {
                            classroom.groupment = autoGroupment.group;
                        }
                    });
                });
            }
        });
        this.setUniqueGroupments();
        this.saveGroupment();
    }

    private setUniqueGroupments() {
        this.uniqueGroupments = [...new Set(this.classrooms.filter(p => !!p.groupment).map(p => p.groupment))].map(a => {
            return {label: a, value: a};
        });
    }
}
