import {Field} from './field.class';

export class Structure {

    public name: string;

    public id: string;

    public identifier: string;

    public fields: Array<Field> = [];
    public settings: Array<Field> = [];

    public type: string;

    public objecttype: string;

    public children: Structure[] = [];

    public classes: String = '';

    public useAtCustobTypeIds = {};

    public use_at_pages = true;

    image: string;

    public custom_classes: String = '';
    public containerclass: String = '';


    public insertIndex: number = null;
}
