import {Component, HostBinding, OnInit} from '@angular/core';
import {EventService} from '../services/event.service';
import {Event} from '../classes/event.class';
import {CodaltComponent} from '../codalt.component';
import {LocalStorage} from '../storage.class';
import {ActivatedRoute} from '@angular/router';
import {School} from '../classes/school.class';
import {UntypedFormControl} from '@angular/forms';
import {Classroom} from '../classes/classroom.class';
import {debounceTime, pairwise, startWith} from 'rxjs/operators';
import {ClassroomService} from '../services/classroom/classroom.service';
import {formatDate} from '@angular/common';
import {IPageInfo} from '@iharbeck/ngx-virtual-scroller';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent extends CodaltComponent implements OnInit {

    @HostBinding('class.agenda-list-view') agendaView = LocalStorage.agendaListView;

    fcFilter = new UntypedFormControl();
    classrooms: Classroom[];

    childActive = false;

    school: School;
    events: Event[] = [];
    loading = true;
    history = false;
    private limit = 25;
    private skip = 0;
    private allLoaded = false;

    groupedEvents: {
        date: Date,
        events: Event[]
    }[] = [];

    constructor(private eventService: EventService,
                private classroomService: ClassroomService,
                private route: ActivatedRoute) {
        super();
    }

    active(active) {
        setTimeout(() => {
            this.childActive = active;
        });
    }

    ngOnInit(): void {
        if (!LocalStorage.showAllClassroomsEvents && !LocalStorage.showAllClassroomsEvents && !LocalStorage.showClassroomsEvents?.length) {
            this.fcFilter.setValue(['own']);
        } else if (LocalStorage.showAllClassroomsEvents) {
            this.fcFilter.setValue(['all']);
        } else {
            this.fcFilter.setValue(LocalStorage.showClassroomsEvents);
        }

        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            if (LocalStorage.school.id !== this.school?.id) {
                this.events = [];
                this.skip = 0;
                this.getEvents();
            }
            this.school = LocalStorage.school;
            this.getclassrooms();
        }));
        if (LocalStorage.school) {
            this.getEvents();
        }
        this.subscriptions.add(LocalStorage.bottomMenuReload.subscribe(() => {
            this.getEvents();
        }));

        this.getclassrooms();

        this.subscriptions.add(this.fcFilter.valueChanges.pipe(
            debounceTime(200),
            startWith(this.fcFilter.value),
            pairwise())
                                   .subscribe(([prev, next]: [any[], any[]]) => {
                                       if (prev.indexOf('all') === -1 && next.indexOf('all') !== -1) {
                                           this.fcFilter.setValue(['all']);
                                           this.skip = 0;
                                       }
                                       if (prev.indexOf('own') === -1 && next.indexOf('own') !== -1) {
                                           this.fcFilter.setValue(['own']);
                                           this.skip = 0;
                                       }
                                       this.classrooms.forEach(classroom => {
                                           if (prev.indexOf(classroom.id) === -1 && next.indexOf(classroom.id) !== -1) {
                                               const vals = this.fcFilter.value?.filter(v => v !== 'all' && v !== 'own');
                                               this.fcFilter.setValue(vals);
                                               this.skip = 0;
                                           }
                                       });
                                       if (!next.length) {
                                           this.fcFilter.setValue(['own']);
                                       }

                                       LocalStorage.showAllClassroomsEvents = !!next?.find(f => f === 'all');
                                       LocalStorage.showClassroomsEvents = (next ? next.filter(v => v !== 'all' && v !== 'own') : []) as [];
                                       this.getEvents();
                                   }));

    }


    private getclassrooms() {
        if (LocalStorage.school && LocalStorage.school.is_direct && LocalStorage.school.type === 'subsite') {
            this.subscriptions.add(this.classroomService.getClassrooms(true, true).subscribe(classrooms => {
                this.classrooms = classrooms.data;
                if (!LocalStorage.showClassroomsEvents?.filter(c => this.classrooms.map(d => d.id).indexOf(c) !== -1).length) {
                    if (!this.fcFilter.value.filter(v => ['all', 'own', 'school'].indexOf(v) !== -1)?.length) {
                        this.fcFilter.setValue(['own']);
                    }
                }
            }));
        } else {
            this.classrooms = [];
        }
    }

    childClosed(event) {
        this.childActive = false;
        if (event?.reserve || event?.deleting) {
            this.skip = 0;
            this.getEvents();
        } else if (event?.form) {
            this.skip = 0;
            this.getEvents();
        } else {
            const e = this.events.find(a => a.id === event?.event?.id);
            if (e) {
                e.read = true;
            }
        }
    }

    changeShowAllClassrooms() {
        this.skip = 0;
        this.getEvents();
    }

    scroll(event: IPageInfo) {
        if (!this.loading && !this.allLoaded) {
            if (event.endIndex > (this.events.length) - 3) {
                this.skip += this.limit;
                this.getEvents();
            }
        }
    }

    private getEvents() {
        this.loading = true;
        const history = typeof this.route.snapshot.url[1] !== 'undefined';
        if (this.history !== history) {
            this.skip = 0;
        }
        this.history = history;

        this.eventService.getList(
            this.limit,
            this.skip,
            this.history,
            LocalStorage.showAllClassroomsEvents,
            (LocalStorage.showClassroomsEvents ?? [])).subscribe(events => {

            if (this.skip === 0) {
                this.events = [];
                this.groupedEvents = [];
            }
            if (this.agendaView) {
                events.data.forEach(event => {
                    const loopDate = new Date(Math.max(new Date(event.start_date).getTime(), this.history ? 0 : new Date().getTime()));
                    const maxDate = new Date(event.end_date);
                    while (loopDate < maxDate) {
                        let dategroup = this.groupedEvents.find(e => formatDate(e.date, 'yMd', 'nl') === formatDate(loopDate, 'yMd', 'nl'));
                        if (!dategroup) {
                            dategroup = {
                                date: new Date(loopDate),
                                events: []
                            };
                            this.groupedEvents.push(dategroup);
                        }
                        if (!dategroup.events.find(e => e.id === event.id)) {
                            dategroup.events.push(event);
                        }
                        loopDate.setDate(loopDate.getDate() + 1);
                    }
                });
            }
            this.allLoaded = events.data.length < this.limit;
            this.events = this.events.concat(events.data);
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }
}
