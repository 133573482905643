<section *ngIf="article" class="detail">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-24 col-sm-20 col-md-16">
                <app-codalt-image [path]="article.id + '/' + article.image"
                                  [thumb]="article.image_thumb"
                                  class="detail__image">
                </app-codalt-image>
                <div class="detail__block">
                    <div class="row">
                        <div class="col">
                            <h1 class="detail__title">
                                {{article.title}}
                            </h1>

                            <div class="detail__details">
                                <div class="detail__author">
                                    {{article.user.name}}
                                    <ng-container *ngFor="let cl of article.groupedClassrooms; let last = last; let first = first;">
                                        {{first ? ' | ' : ''}} Groep {{cl}}{{last ? '' : ','}}
                                    </ng-container>
                                    <ng-container *ngIf="article.groupedClassrooms.length === 0">
                                        | {{article.school.name}}
                                    </ng-container>
                                </div>
                                <time class="detail__date" datetime="2020-04-22" pubdate="pubdate">
                                    {{article.publish_date | date:'EEEE d MMMM yyyy HH:mm'}}
                                </time>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="like d-flex">
                                <button mat-icon-button color="primary" (click)="like()"><mat-icon class="fas fa-thumbs-up" [class.no-like]="!ownLike"></mat-icon></button>
                                <button mat-icon-button color="primary" (click)="showLikes()"><div class="like-count">{{article.articleLikes?.length}}</div></button>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <p [innerHTML]="article.content | safeHtml"></p>
                    </div>

                    <div class="item-images">
                        <app-codalt-image (click)="openImage(image)"
                                          *ngFor="let image of article.images"
                                          [path]="article.id + '/' + image.path"
                                          [thumb]="image.thumb">
                        </app-codalt-image>
                        <app-video (click)="openImage(video)"
                                   [onlyThumb]="true"
                                   *ngFor="let video of article.videos"
                                   [video]="video">
                        </app-video>
                    </div>
                    <div class="item-documents" *ngIf="article.documents?.length">
                        <b>Bijlage: </b>
                        <table>
                            <tr (click)="openDocument(document)" *ngFor="let document of article.documents">
                                <td>
                                    <i class="fas fa-file-pdf-o"></i>
                                </td>
                                <td>
                                    {{document.path}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <app-survey-answer [typeObj]="article"></app-survey-answer>
                </div>
                <div class="mt-5" *ngIf="LocalStorage.school?.replies && article.replies">
                    <app-replies [article]="article"></app-replies>
                </div>
            </div>
            <div class="col-24 col-sm-20 col-md-16 col-lg-8 mt-4 mt-lg-0">
                <div *ngIf="article.readBy && !article.classrooms?.length">
                    <div class="detail__block readby">
                        <h3 class="mb-2">Gelezen ({{article.readBy.length || '0'}})</h3>
                        <div *ngFor="let user of article.readBy">
                            {{user.name}}
                        </div>
                    </div>
                </div>
                <div *ngIf="article.readByStudents && article.classrooms?.length">
                    <div class="detail__block readby">
                        <h3 class="mb-2">Gelezen ({{article.readBy.length || '0'}})</h3>
                        <h4 class="mb-2">Leerlingen</h4>
                        <table class="w-100">
                            <tr [matTooltip]="student.readByParents"
                                [matTooltipShowDelay]="0"
                                [matTooltipHideDelay]="isCordova ? 10000 : 0"
                                *ngFor="let student of article.readByStudents.students  | readByStudents : article.readByStudents.readByParent;">
                                <td class="pr-2 text-center">
                                    <i class="fas"
                                       [class.fa-times]="student.readBy?.length < 1"
                                       [class.fa-check]="student.readBy?.length === 1"
                                       [class.fa-check-double]="student.readBy?.length > 1">
                                    </i>
                                </td>
                                <td>
                                    {{student.student.name}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="article.editable && LocalStorage.school.id === article.school_id" class="floating-buttons">
        <button color="primary"
                class="drop-up-menu-trigger"
                mat-fab
                matmenu
                [matMenuTriggerFor]="aboveMenu"
                (menuOpened)="dropUpOpen = true"
                (menuClosed)="dropUpOpen = false">
            <mat-icon class="fas fa-chevron-up" [class.fa-rotate-180]="dropUpOpen"></mat-icon>
        </button>
        <mat-menu #aboveMenu="matMenu" yPosition="above" class="drop-up-menu" backdropClass="drop-up-backdrop">
            <button (click)="delete()" color="primary" mat-fab>
                <mat-icon class="fas fa-trash"></mat-icon>
            </button>
            <button color="primary" mat-fab routerLink="/{{Routenames.articles}}/{{article.slug}}/vragenlijst-antwoorden"
                    matTooltip="Poll / vragenlijst voor dit artikel">
                <mat-icon class="fas fa-question"></mat-icon>
            </button>
            <button color="primary" mat-fab routerLink="/{{Routenames.articles}}/{{article.slug}}/kopie">
                <mat-icon class="fas fa-copy"></mat-icon>
            </button>
            <button color="primary" mat-fab routerLink="/{{Routenames.articles}}/{{article.slug}}/bewerken">
                <mat-icon class="fas fa-pencil-alt"></mat-icon>
            </button>
        </mat-menu>
    </div>
    <div *ngIf="article.editable && LocalStorage.school.id !== article.school_id" class="floating-buttons">
        <button color="primary"
                class="drop-up-menu-trigger"
                (click)="switchToSchool()"
                matTooltipPosition="before"
                matTooltip="Open de school waar dit item in geplaatst is"
                mat-fab>
            <mat-icon class="fas fa-school"></mat-icon>
        </button>
    </div>
</section>

<ng-template #likes>
    <div class="likes-dialog">
        <div class="row mb-2 ">
            <h3 class="col mb-0 d-flex align-items-center">{{article.articleLikes?.length || 'Geen'}} like{{article.articleLikes?.length === 1 ? '' : 's'}}</h3>
            <div class="col-auto">
                <button mat-icon-button color="primary" mat-dialog-close>
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <table>
            <tr *ngFor="let like of article.articleLikes">
                <td><i class="fas fa-thumbs-up"></i></td>
                <td>{{like.user.name}}</td>
            </tr>
        </table>
    </div>
</ng-template>
