import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ConfirmDialogService} from "../../services/confirm-dialog-service/confirm-dialog.service";
import {Role} from '../../classes/role.class';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {Routenames} from '../../route-names.enum';
import {SchoolService} from '../../services/school/school.service';
import {School} from '../../classes/school.class';
import {LocalStorage} from '../../storage.class';
import {AuthenticationService} from '../../services/auth/authentication.service';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

    saving = false;
    user: User;
    form: UntypedFormGroup;
    roles: Role[];
    schools: School[];
    moreRoles = false;
    moreSchools = false;
    AuthorisationService = AuthorisationService;
    LocalStorage = LocalStorage;

    constructor(private userService: UserService,
                private authorisationService: AuthorisationService,
                private authenticationService: AuthenticationService,
                private schoolService: SchoolService,
                private route: ActivatedRoute,
                private confirmDialogService: ConfirmDialogService,
                private router: Router) {
    }

    loginAs() {
        this.authenticationService.loginAsUser(this.user.id).subscribe(token => {
            this.authenticationService.switchToProfile(token.data).subscribe(() => {
                this.router.navigate([Routenames.profile]);
            });
        });
    }

    ngOnInit() {
        this.authorisationService.getRoles().subscribe(roles => this.roles = roles);
        this.schoolService.getSchools().subscribe(s => this.schools = s.data);
        this.route.params.subscribe(params => {
            const userId = +params['userId'];
            if (userId) {
                this.getUser(userId);
            } else {
                this.user = new User();
                this.user.roles = [];
                this.setFormGroup(this.user);
            }
        });
    }

    save() {
        Object.assign(this.user, this.form.value);
        this.saving = true;
        this.userService.saveUser(this.user).subscribe(user => {
            this.saving = false;
            this.router.navigateByUrl(Routenames.users);
        }, error => {
            this.saving = false;
            this.confirmDialogService.confirm('Er ging iets fout', error.message);
        });
    }

    private getUser(userId = null) {
        this.userService.getUser(userId).subscribe(user => {
            this.user = user.data;
            this.setFormGroup(this.user);
            this.moreRoles = false;
            this.user.roles.forEach(role => {
                if (!LocalStorage.user.roles.find(p => p.id === role.id)) {
                    this.moreRoles = true;
                }
            });
            this.user.direct_schools.forEach(school => {
                if (!LocalStorage.user.anySchools.find(p => p.is_direct && +p.id === +school.id)) {
                    this.moreSchools = true;
                }
            });
            if (this.moreRoles || this.moreSchools) {
                this.form.disable();
            }
        });
    }

    private setFormGroup(user: User) {
        this.form = new UntypedFormGroup({
            name: new UntypedFormControl(user.name, [Validators.required, Validators.minLength(3)]),
            email: new UntypedFormControl(user.email, [Validators.required, Validators.email]),
            roleIds: new UntypedFormControl(user.roles.map(r => r.id)),
            schoolIds: new UntypedFormControl(user.direct_schools ? user.direct_schools.map(s => s.id) : null)
        });
    }
}
