import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {User} from '../../classes/user.class';
import {ChatService} from '../../services/chat.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CodaltComponent} from '../../codalt.component';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {Classroom} from '../../classes/classroom.class';
import {map} from 'rxjs/operators';
import {requiredConditional} from '../../validators/required-conditional.validator';
import {Chat} from '../../classes/chat';
import {MatSelectionListChange} from '@angular/material/list';
import {AuthorisationService} from '../../services/auth/authorisation.service';

@Component({
    selector: 'app-new-chat-dialog',
    templateUrl: './new-chat-dialog.component.html',
    styleUrls: ['./new-chat-dialog.component.scss']
})
export class NewChatDialogComponent extends CodaltComponent implements OnInit {

    form: UntypedFormGroup;
    users: User[];
    classrooms: Classroom[];
    searchQuery = '';
    fcType: UntypedFormControl;
    fcClassrooms: UntypedFormControl;
    fcUsers: UntypedFormControl;
    fcUsersTemp: UntypedFormControl;
    fcSearch: UntypedFormControl;

    loading = true;

    constructor(public dialogRef: MatDialogRef<NewChatDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public chat: Chat,
                private classroomService: ClassroomService,
                private chatService: ChatService) {
        super();
        if (!this.chat) {
            this.chat = new Chat();
        }
    }

    ngOnInit(): void {
        this.fcType = new UntypedFormControl(this.chat.chat_classrooms?.length > 0 ? 'classrooms' : 'users');
        this.fcUsers = new UntypedFormControl(this.chat.chat_users.map(u => u.user_id), requiredConditional(this.fcType, 'users'));
        this.fcUsersTemp = new UntypedFormControl(this.chat.chat_users.map(u => u.user_id), requiredConditional(this.fcType, 'users'));
        this.fcClassrooms = new UntypedFormControl(this.chat.chat_classrooms.map(c => c.classroom_id), requiredConditional(this.fcType, 'classrooms'));
        this.fcSearch = new UntypedFormControl();
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(this.chat.id),
            name: new UntypedFormControl(this.chat.name, Validators.required),
            users: this.fcUsers,
            classrooms: this.fcClassrooms,
            search: this.fcSearch,
            type: this.fcType
        });
        this.fcType.valueChanges.subscribe(value => {
            this.Utils.triggerValidation(this.fcUsers);
            this.Utils.triggerValidation(this.fcClassrooms);
        });
        this.chatService.getUsers().subscribe(users => {
            this.users = users.data.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            setTimeout(() => {
                this.loading = false;
            });
        });

        if (AuthorisationService.hasFeature('classroomList')) {
            this.classroomService.getClassrooms().pipe(map(c => c.data)).subscribe(classrooms => {
                this.classrooms = classrooms;
            });
        }
        this.subscriptions.add(this.fcSearch.valueChanges.subscribe(() => {
            this.fcUsersTemp.setValue(this.fcUsers.value);
        }));
    }

    startChat() {
        if (this.fcType.value === 'users') {
            this.fcClassrooms.setValue([]);
        }
        if (this.fcType.value === 'classrooms') {
            this.fcUsers.setValue([]);
        }
        this.chatService.saveChat(this.form.value).subscribe(chat => {
            this.dialogRef.close(chat.data);
        });
    }

    selectionChange(event: MatSelectionListChange) {
        const ids = this.fcUsers.value as number[] || [];
        event.options.forEach(option => {
            if (option.selected) {
                if (ids.indexOf(option.value) === -1) {
                    ids.push(option.value);
                }
            } else {
                ids.splice(ids.indexOf(option.value), 1);
            }
        });
        this.fcUsers.setValue(ids);
    }


}
