<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-16">
            <div class="transactions mt-3 mb-3">
                <div class="transaction head">
                    <div class="info">
                        <h2 class="news__title">Transacties</h2>
                    </div>
                    <div class="action" *ngIf="!otherUser">
                        <button class="button" routerLink="/{{Routenames.transactionTopUp}}">Opwaarderen</button>
                    </div>
                    <div class="action" *ngIf="otherUser && totalAmount > 0 && AuthorisationService.hasFeature('refundCurrentCredit')">
                        <button class="button" (click)="refund()">Resterend tegoed terugbetalen</button>
                    </div>
                </div>
                <div class="transaction saldo">
                    <div>
                        <span class="ml-3 font-weight-bold" *ngIf="otherUser">{{otherUser.name}}</span>
                    </div>
                    <div class="amount">
                        Huidig saldo: &euro; <b>{{totalAmount  | number: '1.2-2'}}</b>
                    </div>
                </div>
                <div class="transaction" *ngFor="let transaction of transactions">
                    <div class="icon">
                        <mat-icon mat-list-icon>euro</mat-icon>
                    </div>
                    <div class="info">
                        <div>
                            {{transaction.description}}
                            <ng-container *ngIf="transaction.payment_request?.payment_default?.low_permissions">
                                op {{transaction.payment_request.created_at | date : 'EE d MMM yyyy'}}
                            </ng-container>
                        </div>
                        <div class="date">{{transaction.updated_at | date}}</div>
                    </div>
                    <div class="amount">
                        <i class="fas fa-coins mr-3"
                           matTooltip="Contant betaald"
                           *ngIf="transaction.cash"></i>
                        <i class="fas fa-clock mr-3"
                           matTooltip="Terugbetaling in afwachting"
                           *ngIf="transaction.status === 'pending' && transaction.transaction_id && transaction.amount < 0"></i>
                        <span [class.add]="transaction.amount > 0">
                            {{transaction.amount | currency : 'EUR'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
