import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Routenames} from '../route-names.enum';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Job} from '../classes/job.class';
import {Event} from '../classes/event.class';
import {Article} from '../classes/article.class';
import {ArticleService} from '../services/article.service';
import {JobService} from '../services/job.service';
import {EventService} from '../services/event.service';
import {CodaltComponent} from '../codalt.component';
import {Survey} from '../classes/survey.class';
import {Utils} from '../utils.class';
import {SurveyService} from '../services/survey.service';
import {SurveyQuestion} from '../classes/surveyquestion.class';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {EventarticlejobService} from '../services/eventarticlejob.service';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {formatDate} from '@angular/common';
import {validTime} from '../validators/valid-time.validator';
import {requiredConditionalFn} from '../validators/required-conditional-fn.validator';
import {handleTimeValueChange} from '../utils/handle-time-value-change';

@Component({
    selector: 'app-survey-edit',
    templateUrl: './survey-edit.component.html',
    styleUrls: ['./survey-edit.component.scss']
})
export class SurveyEditComponent extends CodaltComponent implements OnInit {

    currentDate = new Date();
    typeArticle = false;
    typeEvent = false;
    typeJob = false;
    typeName = '';
    saving = false;
    typeObj: Article | Event | Job;
    typeService: EventarticlejobService;
    faQuestions = new UntypedFormArray([], Validators.required);
    fc = {
        questions: this.faQuestions,
        end_date: new UntypedFormControl(),
        end_date_time: new UntypedFormControl(),
        end_description: new UntypedFormControl()
    };
    form = new UntypedFormGroup(this.fc);

    constructor(private router: Router,
                private route: ActivatedRoute,
                private articleService: ArticleService,
                private jobService: JobService,
                private eventService: EventService,
                private surveyService: SurveyService,
                private confirmDialogService: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        const firstPart = this.route.snapshot.url[0].path;
        this.typeName = firstPart;
        if (firstPart === Routenames.articles) {
            this.typeArticle = true;
            this.typeService = this.articleService;
        } else if (firstPart === Routenames.events) {
            this.typeEvent = true;
            this.typeService = this.eventService;
        } else if (firstPart === Routenames.jobs) {
            this.typeJob = true;
            this.typeService = this.jobService;
        }

        this.subscriptions.add(this.typeService.get(this.route.snapshot.url[1].path).subscribe((typeObj) => {
            this.typeObj = typeObj.data;
            this.typeObj.survey = this.typeObj.survey || {
                article_id: this.typeArticle ? this.typeObj.id : null,
                event_id: this.typeEvent ? this.typeObj.id : null,
                job_id: this.typeJob ? this.typeObj.id : null
            } as Survey;
            this.typeObj.survey.questions = this.typeObj.survey.questions || [];
            this.typeObj.survey.questions.forEach(question => {
                this.addQuestion(question);
            });
            this.fc.end_description.setValue(this.typeObj.survey?.end_description);
            this.fc.end_date.setValue(this.typeObj.survey.end_date ? this.typeObj.survey.end_date : null);
            this.fc.end_date_time = new UntypedFormControl(
                this.typeObj.survey.end_date ? formatDate(this.typeObj.survey.end_date, 'HH:mm', 'nl') : null,
                {
                    validators: [validTime(this.fc.end_date), requiredConditionalFn(() => {
                        return !!this.fc.end_date.value;
                    })], updateOn: 'blur'
                }
            );
            if (this.typeObj.survey.end_date && this.currentDate > new Date(this.typeObj.survey.end_date)) {
                this.fc.end_date.disable();
                this.fc.end_date_time.disable();
            }
            this.subscriptions.add(this.fc.end_date_time.valueChanges.subscribe(
                value => handleTimeValueChange(value, this.fc.end_date_time)
            ));
            this.subscriptions.add(this.form.valueChanges.subscribe(() => {
                if (this.fc.end_date_time.value && this.fc.end_date.value) {
                    const time = this.fc.end_date_time.value.split(':');
                    if (time.length === 2) {
                        const date = new Date(this.fc.end_date.value);
                        date.setHours(time[0]);
                        date.setMinutes(time[1]);
                        if (date.toString() !== (new Date(this.fc.end_date.value)).toString()) {
                            this.fc.end_date.setValue(date);
                        }
                    }
                }
            }));
        }, () => {
        }));
    }

    addQuestion(question?: SurveyQuestion) {
        const newHash = Math.random().toString(36).substr(2, 9);
        this.faQuestions.push(new UntypedFormGroup({
            hash: new UntypedFormControl(question?.hash || newHash),
            type: new UntypedFormControl(question?.type, Validators.required, null),
            required: new UntypedFormControl(question ? question.required : true),
            unique: new UntypedFormControl(question?.unique),
            multiple: new UntypedFormControl(question?.multiple),
            question: new UntypedFormControl(question?.question, Validators.required),
            options: new UntypedFormControl(question?.options),
            addOption: new UntypedFormControl(),
            limit: new UntypedFormControl(question?.limit),
            limitCount: new UntypedFormControl(question?.limitCount),
            limitStrict: new UntypedFormControl(question?.limitStrict),
            quantityQuestion: new UntypedFormControl(question?.quantityQuestion)
        }));
        if (question?.hash) {
            this.faQuestions.at(this.faQuestions.length - 1).disable();
            this.faQuestions.at(this.faQuestions.length - 1).get('addOption').enable();
        }
    }

    addOption(fg: AbstractControl) {
        const newValue = fg.get('addOption').value;
        const otherOptions = fg.get('options').value || [];
        if (otherOptions.length === 0) {
            fg.get('options').setValue(otherOptions);
        }
        if (otherOptions.indexOf(newValue) === -1 && newValue.length > 0) {
            otherOptions.push(newValue);
            fg.get('addOption').setValue('');
        }
        event.preventDefault();
    }

    duplicateQuestion(index: number) {
        const newQuestion = (this.faQuestions.at(index) as UntypedFormGroup).getRawValue();
        delete newQuestion.hash;
        this.addQuestion(newQuestion);
    }

    removeQuestion(index: number) {
        if (this.faQuestions.at(index).dirty || !!this.typeObj.survey.questions[index]) {
            this.confirmDialogService.confirm(
                'Vraag verwijderen',
                'Weet je zeker dat je deze vraag wilt verwijderen?',
                'Verwijderen',
                'Behouden').then(() => {
                this.faQuestions.removeAt(index);
            }, () => {
            });
        } else {
            this.faQuestions.removeAt(index);
        }
    }

    removeOption(fg: AbstractControl, optionIndex: number) {
        (fg.get('options').value as []).splice(optionIndex, 1);
    }

    save() {
        Utils.triggerValidation(this.form);
        Object.assign(this.typeObj.survey, this.form.getRawValue());
        if (this.form.valid) {
            this.subscriptions.add(this.surveyService.save(this.typeObj.survey).subscribe(() => {
                this.form.markAsPristine();
                this.router.navigate([this.typeName, this.typeObj.slug]);
            }));
        }
    }

    public openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.form.dirty) {
                this.confirmDialogService.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen opslaan of verwerpen?`,
                    'Wijzigingen opslaan',
                    'Wijzigingen verwerpen').then(() => {
                    this.save();
                    resolve(false);
                }, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    reorderOptions(fg: AbstractControl, event: CdkDragDrop<string[]>) {
        const options = fg.get('options').value;
        const movedOption = options[event.previousIndex];
        options.splice(event.previousIndex, 1);
        options.splice(event.currentIndex, 0, movedOption);
    }

    reorderQuestions(event: CdkDragDrop<string[]>) {
        const movedOption = this.faQuestions.controls[event.previousIndex];
        this.faQuestions.controls.splice(event.previousIndex, 1);
        this.faQuestions.insert(event.currentIndex, movedOption);
    }

}
