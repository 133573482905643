import {Component, Input, OnInit} from '@angular/core';
import {Student} from '../../classes/student.class';
import {LocalStorage} from '../../storage.class';
import {Event} from '../../classes/event.class';
import {CodaltComponent} from '../../codalt.component';
import {Utils} from '../../utils.class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AfterSchoolService} from '../../services/after-school.service';
import {Routenames} from '../../route-names.enum';
import {Router} from '@angular/router';
import {EventSubscription} from '../../classes/event-subscription';
import {startWith} from 'rxjs/operators';

@Component({
    selector: 'app-event-subscribe',
    templateUrl: './event-subscribe.component.html',
    styleUrl: './event-subscribe.component.scss'
})
export class EventSubscribeComponent extends CodaltComponent implements OnInit {

    @Input() event: Event;

    students: Student[];

    form: FormGroup<{
        event_id: FormControl<number>;
        studentIds: FormControl<number[]>;
        phone: FormControl<string>;
        comment: FormControl<string>;
        agree: FormControl<boolean>;
    }>;

    subscribed: EventSubscription[];

    eventStarted = false;
    subscribeEnded = false;

    disableNotCheckedStudents = false;

    constructor(private router: Router,
                private afterSchoolService: AfterSchoolService) {
        super();
    }

    ngOnInit() {
        this.subscribeEnded = new Date() > new Date(this.event.register_before);
        this.event.dateranges.forEach(date => {
            if (new Date() > date.start_date) {
                this.eventStarted = true;
            }
        });
        this.subscribed = this.event.subscriptions?.filter(s => !!s.transaction_id);
        this.students = LocalStorage.user?.students.filter(s =>
            s.classrooms.find(c => !!this.event.schools.find(s => s.id === c.school_id))
        ).filter(s => !this.subscribed.find(ss => ss.student_id === s.id));

        this.setForm();
    }

    private setForm() {
        this.form = new FormGroup({
            event_id: new FormControl(this.event.id),
            studentIds: new FormControl(this.event.subscriptions?.filter(s => !s.transaction_id)?.map(s => s.student_id)),
            phone: new FormControl(this.event.subscriptions[0]?.phone, Validators.required),
            comment: new FormControl(this.event.subscriptions[0]?.comment),
            agree: new FormControl(false, Validators.requiredTrue)
        });
        this.form.controls.studentIds.valueChanges.pipe(startWith(this.form.value.studentIds)).subscribe(studentsIds => {
            const resultingCount = studentsIds.length;

            if (this.event.max_subscriptions) {
                this.disableNotCheckedStudents = this.event.currentSubscriptionCount + resultingCount >= this.event.max_subscriptions;
            }
        });
    }

    subscribe() {
        Utils.triggerValidation(this.form);
        console.log(this.form);
        if (this.form.valid) {
            this.afterSchoolService.subscribe(this.form.value).subscribe(subscriptions => {
                if (subscriptions.data?.length) {
                    this.router.navigate([Routenames.afterSchoolOffer, Routenames.pay, this.event.id]);
                }
            });
        }
    }

}
