import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    transform<T>(values: T[], sortingOn: string): T[] {
        return values.sort((a, b) => {
            let aVal;
            let bVal;
            if (typeof sortingOn === 'string') {
                if (sortingOn.indexOf('.') !== -1) {
                    aVal = a;
                    bVal = b;
                    sortingOn.split('.').forEach(s => {
                        aVal = typeof aVal[s] !== 'undefined' ? aVal[s] : null;
                        bVal = bVal[s];
                    });
                } else {
                    aVal = a.hasOwnProperty(sortingOn) ? a[sortingOn] : a[sortingOn];
                    bVal = b.hasOwnProperty(sortingOn) ? b[sortingOn] : b[sortingOn];
                }
            }
            const nonUppercaseTypes = ['boolean', 'number'];
            if (!aVal) {
                return -1;
            }
            if (!bVal) {
                return 1;
            }
            if (!isNaN(aVal) && !isNaN(bVal)) {
                aVal = +aVal;
                bVal = +bVal;
            }
            if (nonUppercaseTypes.indexOf((typeof aVal)) !== -1) {
                if (aVal < bVal) {
                    return -1;
                }
                if (aVal > bVal) {
                    return 1;
                }
            } else {
                if (!aVal) {
                    return 0;
                }
                if (!bVal) {
                    return 0;
                }
                if (('' + aVal).toUpperCase() < ('' + bVal).toUpperCase()) {
                    return -1;
                }
                if (('' + aVal).toUpperCase() > ('' + bVal).toUpperCase()) {
                    return 1;
                }
            }
            return 0;
        });
    }

}
