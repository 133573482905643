import {Pipe, PipeTransform} from '@angular/core';
import {Student} from '../../classes/student.class';
import {ReadByParent} from '../../classes/article.class';

@Pipe({
    name: 'readByStudents'
})
export class ReadByStudentsPipe implements PipeTransform {

    transform<T>(students: Student[], readByParents: ReadByParent[]): { student: Student, readBy: ReadByParent[], readByParents: string }[] {
        return students.map(student => {
            const readBy = [...new Set(readByParents.filter(r => r.student_id === student.id).map(p => p.user_name))].join('\n');
            const notReadBy = [...new Set(student.users.filter(u => !readByParents.find(r => r.user_id === u.id)).map(p => p.name))].join('\n');
            return {
                student,
                readBy: readByParents.filter(r => r.student_id === student.id),
                readByParents: `
                ${readBy ? 'Gelezen:' : ''}
                ${readBy}
                ${notReadBy ? 'Niet Gelezen:' : ''}
                ${notReadBy}`
            };
        });
    }

}
