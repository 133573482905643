import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {PageService} from '../../services/page/page.service';
import {Settings} from '../../settings.class';
import {DragulaOptions, DragulaService} from 'ng2-dragula';

@Component({
    selector: 'app-page-tree',
    templateUrl: './page-tree.component.html',
    styleUrls: ['./page-tree.component.scss']
})
export class PageTreeComponent implements OnInit, OnDestroy {

    @Input() isChilds = false;

    @Input() pageData: any = [];
    @Output() pageDataChanged = new EventEmitter<any>();

    storage = Storage;
    settings = Settings;
    dragBagName = 'page-bag';
    dragClass = 'move';

    constructor(private router: Router,
                private pageService: PageService,
                private dragulaService: DragulaService) {
    }

    ngOnInit() {
        if (this.isChilds) {
            if (this.pageData && this.pageData.length > 0) {
                this.dragBagName = `child-bag-${this.pageData[0].id}`;
                this.dragClass = `m-child-bag-${this.pageData[0].id}`;
            }
        }
        if (this.pageData && this.pageData.length > 0) {
            this.dragulaService.createGroup(this.dragBagName, {
                moves: (el, container, handle) => {
                    return handle.className.indexOf(this.dragClass) > -1;
                }
            } as DragulaOptions);

            this.dragulaService.dropModel(this.dragBagName).subscribe(({targetModel}) => {
                this.pageData = targetModel;
                this.orderChanged();
            });
        }
    }

    orderChanged() {
        this.pageDataChanged.emit(this.pageData);
    }

    ngOnDestroy(): void {
        if (this.pageData && this.pageData.length > 0) {
            this.dragulaService.destroy(this.dragBagName);
        }
    }

    addPage(parentId) {
        this.router.navigate([`/pagina-aanpassen/new/${parentId}`]);
    }

    editPage(structureId) {
        this.router.navigate([`/pagina-aanpassen/${structureId}`]);
    }

    removePage(pageId, index, list) {
        if (window.confirm('Weet je zeker dat je deze pagina wilt verwijderen?')) {
            this.pageService.removePage(pageId).then(function () {
                list.splice(index, 1);
            });
        }

    }

    duplicate(pageId) {
        this.pageService.duplicate(pageId).then(resp => {
            this.pageService.emitPageTreeChangeEvent();
        });

    }

    history(pageId) {
        this.router.navigate([`/pagina-historie/${pageId}`]);
    }

}
