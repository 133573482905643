import {Injectable} from '@angular/core';
import {Settings} from '../settings.class';
import {HttpClient} from '@angular/common/http';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LocalStorage} from "../storage.class";

@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    constructor(private http: HttpClient) {

    }

    logError(error) {
        this.sendData(error, LocalStorage.version ?? 'unknown');
    }

    private sendData(error, versionInfo?: any) {
        try {
            this.http.post(`${Settings.API_ENDPOINT}logging/app`, {
                app_version: versionInfo,
                exception: error,
                url: window?.location?.href
            }).pipe(
                catchError(err => {

                    return of([]);
                })
            ).subscribe();
        } catch (e) {

        }
    }
}
