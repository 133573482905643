import {UntypedFormControl} from '@angular/forms';

export function requiredConditional(fc: UntypedFormControl, value: any) {
    return (control: UntypedFormControl) => {
        if ((!control.value || (Array.isArray(control.value) && control.value.length < 1)) && fc.value === value) {
            return {required: true};
        }
        return null;
    };
}
