import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {SortingComponent} from '../../sorting.component';


@Component({
    selector: 'app-users-overview',
    templateUrl: './users-overview.component.html',
    styleUrls: ['./users-overview.component.scss']
})
export class UsersOverviewComponent extends SortingComponent<User> implements OnInit {

    AuthorisationService = AuthorisationService;
    editFeatureName = 'userSave';
    authFeatureName = 'authorisationFeatureToRole';

    constructor(private userService: UserService,
                private confirmDialog: ConfirmDialogService) {
        super();
    }

    ngOnInit() {
        this.userService.getUsers().subscribe(users => {
            this.list = users.data;
            this.sortList('name');
        });
    }

    deleteUser(event, user) {
        event.preventDefault();
        event.stopPropagation();
        this.confirmDialog.confirm('Verwijderen',
            `Weet je zeker dat je de gebruiker ${user.name} wilt verwijderen?`, 'Ja').then(() => {
            this.userService.deleteUser(user.id).subscribe(() => {
                const index = this.list.indexOf(user);
                this.list.splice(index, 1);
            });
        });
    }

}
