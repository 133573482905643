import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {PaymentDefault} from '../classes/payment-default';
import {Classroom} from '../classes/classroom.class';
import {Student} from '../classes/student.class';
import {PaymentRequest} from '../classes/payment-request';
import {formatDate} from '@angular/common';

@Injectable()
export class PaymentDefaultService {

    constructor(private apiService: ApiService) {
    }

    getRequestOptions() {
        return this.apiService.getCall$<PaymentDefault[]>('payments/defaults/request-options');
    }

    getRemainOptions() {
        return this.apiService.getCall$<{
            classroom: Classroom[],
            students: Student[],
            defaultPayments: PaymentDefault[]
        }>('payments/defaults/remain-options');
    }

    saveRemain(defaultPaymentId, studentIds, date) {
        return this.apiService.postCall$('payments/defaults/remain', {
            defaultPaymentId,
            studentIds,
            date: (date ? formatDate(date, 'yyyy-MM-d', 'NL') : null)
        });
    }

    getRemain(defaultPaymentId, date?: Date) {
        return this.apiService.getCall$<PaymentRequest>('payments/defaults/remain', {defaultPaymentId, date: (date ? formatDate(date, 'yyyy-MM-d', 'NL') : '')});
    }

    checkPayment(id) {
        return this.apiService.postCall$('payments/check-payment', {id});
    }

    createPayment(amount: number) {
        return this.apiService.postCall$<{
            url: string
        }>('payments/create-payment', {amount});
    }

    getPaymentDefaults() {
        return this.apiService.getCall$<PaymentDefault[]>('payments/defaults');
    }

    getPaymentDefault(id: number) {
        return this.apiService.getCall$<PaymentDefault>(`payments/defaults/${id}`);
    }

    savePaymentDefault(paymentDefault: PaymentDefault) {
        return this.apiService.postCall$<PaymentDefault>('payments/defaults', paymentDefault);
    }
}
