import {Component, DoCheck, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Structure} from '../../classes/structure.class';
import {Settings} from '../../settings.class';
import {Utils} from '../../utils.class';
import {LocalStorage} from '../../storage.class';
import {CustobType} from '../../classes/custob-type.class';

@Component({
    selector: 'app-element-preview',
    templateUrl: './element-preview.component.html',
    styleUrls: ['./element-preview.component.scss']
})
export class ElementPreviewComponent implements OnInit, DoCheck, OnDestroy {

    @Input() struct: Structure;
    @Input() content: any;
    @Input() domain: string;

    @Input() elementHolder: number;

    oldStructId = null;
    storage = LocalStorage;

    structcontent: any = {};
    structsettings: any = {};

    settings: Settings = Settings;

    utils: Utils = Utils;

    currentEditing: any;

    videoUrl: any;

    custobTypes: CustobType[];

    iframeurl;

    custobs = {};

    @ViewChild('iframeholder', {static: true})
    iframeholder: ElementRef;

    @ViewChild('iframe', {static: true})
    iframe: ElementRef;

    prevHeight = 600;

    public loading = true;

    private increasedTicks = 0;

    private lastIncrease = 0;

    private initialContentJson: any;
    private heightInterval;

    constructor(private sanitizer: DomSanitizer) {

    }

    @Input()
    public set elementHolderLastChanged(version: number) {


        let currentContentJson = JSON.stringify(this.structcontent);
        if (currentContentJson != this.initialContentJson && this.struct) {

            this.initialContentJson = currentContentJson;

            this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(
                LocalStorage.school.fullUrl + '/single-element-preview/' + this.elementHolder + '/' +
                this.struct.id + '?v=' + version
            );
            this.loading = true;
        }
    }

    ngDoCheck() {


        if (this.struct.id != this.oldStructId) {
            this.oldStructId = this.struct.id;
            if (!this.content[this.struct.id])
                this.content[this.struct.id] = {'content': ''};

            this.structcontent = this.content[this.struct.id];

        }

    }

    setHeight() {
        try {
            if (this.iframe.nativeElement.contentWindow && this.iframe.nativeElement.contentWindow.document.body != null) {
                const usawotipreview = this.iframe.nativeElement.contentWindow.document.getElementById('qq');
                if (usawotipreview && !this.loading && this.increasedTicks < 20) {
                    if (this.prevHeight !== usawotipreview.offsetHeight) {
                        this.increasedTicks++;
                    }
                    let increase = usawotipreview.offsetHeight - this.prevHeight;
                    if (increase !== this.lastIncrease) {
                        this.lastIncrease = increase;
                        this.prevHeight = usawotipreview.offsetHeight;
                    }
                }
            }
        } catch (e) {

        }

    }

    onload(event) {
        if (event.target.src !== '') {
            this.loading = false;
        }
    };

    ngOnInit() {

        this.heightInterval = setInterval(() => {
            this.setHeight();
        }, 1500);

        if (!this.content[this.struct.id])
            this.content[this.struct.id] = {'content': ''};

        this.structcontent = this.content[this.struct.id];

        if (this.structcontent.hasOwnProperty('video_url')) {
            this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                'https://www.youtube.com/embed/' +
                this.structcontent['video_url'].split('?v=').reverse()[0]
            );
        }

        this.elementHolderLastChanged = (new Date).getTime();
    }

    ngOnDestroy() {
        clearInterval(this.heightInterval);
    }
}
