import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {ChatMessagingComponent} from './chat/chat-messaging/chat-messaging.component';

@Injectable()
export class CanDeactivateChat<T extends ChatMessagingComponent> {

    constructor() {
    }

    canDeactivate(
        component: T,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.form) {
            return component.openChangesBackActionCheck();
        }
        return true;
    }
}
