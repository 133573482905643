import {Injectable} from '@angular/core';

import {ApiService} from './../api/api.service';
import {Custob} from '../../classes/custob.class';

@Injectable()
export class CustobService {

    constructor(public apiService: ApiService) {
    }

    getAll(custobTypeId, limit: number = null): Promise<Array<Custob>> {
        return this.apiService.getCall(`custob/all`, {
            custobTypeId: custobTypeId
        });
    }

    getSingle(custobId): Promise<Custob> {
        return this.apiService.getCall(`custob/detail/${custobId}`);
    }

    save(custob: Custob): Promise<Object> {
        return this.apiService.postCall(`custob/save`, {
            custob: custob
        });
    }

    delete(custobId: number): Promise<Object> {
        return this.apiService.postCall(`custob/delete`, {
            custobId: custobId
        });
    }

    saveOrder(ids: Array<number>): Promise<Object> {
        return this.apiService.postCall(`custob/save-order`, {
            ids: ids
        });
    }
}
