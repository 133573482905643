import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginFormState} from '../login-form-state.enum';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Subscription} from "rxjs";
import {Routenames} from "../../route-names.enum";
import {LocalStorage} from '../../storage.class';
import {CordovaService} from '../../services/cordova.service';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-link-login',
    templateUrl: './link-login.component.html',
    styleUrls: ['./link-login.component.scss']
})
export class LinkLoginComponent implements OnInit, OnDestroy {

    LoginFormState = LoginFormState;

    loginFormState: LoginFormState = LoginFormState.FillIn;
    errorMessage = null;
    subscriptions = new Subscription();

    loginForm = new UntypedFormGroup({
        email: new UntypedFormControl(AuthenticationService.filledInEmail, [Validators.required, Validators.email])
    });

    constructor(private confirmDialog: ConfirmDialogService,
                private authService: AuthenticationService,
                private router: Router,
                private cordovaSerice: CordovaService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.cordovaSerice.setBackbuttonExitAction();
        this.subscriptions.add(this.route.params.subscribe(params => {
            if (params['token']) {
                this.authService.logout();
                this.authService.refresh(params['token']).subscribe(() => {
                    this.router.navigate([Routenames.home], {replaceUrl: true});
                }, error => {
                    this.loginFormState = LoginFormState.Error;
                    if (LocalStorage.getUserToken()) {
                        this.authService.refresh().subscribe(() => {
                            this.router.navigate([Routenames.home], {replaceUrl: true});
                        }, error => {
                            this.loginFormState = LoginFormState.Error;
                            this.invalidToken();
                        });
                    } else {
                        this.invalidToken();
                    }
                });
            }
        }));

        this.subscriptions.add(this.loginForm.get('email').valueChanges.subscribe((val: string) => {
            if (val.indexOf(' ') !== -1) {
                this.loginForm.get('email').setValue(val.replace(' ', ''));
            }
        }));
    }

    invalidToken() {
        this.confirmDialog.confirm(
            'Ongeldige link',
            'De gebruikte links is niet (meer) geldig.',
            'Oké'
        ).then(() => {

        }, () => {

        });
    }

    ngOnDestroy() {
        AuthenticationService.filledInEmail = this.loginForm.get('email').value;
        this.subscriptions.unsubscribe();
    }

    login() {
        Utils.triggerValidation(this.loginForm);
        if (this.loginForm.valid) {
            this.errorMessage = null;
            this.loginFormState = LoginFormState.Login;
            this.authService.loginEmail(this.loginForm.value.email).subscribe(
                () => {
                    this.loginFormState = LoginFormState.Send;
                }, failReason => {
                    this.confirmDialog.confirm(
                        'Fout',
                        failReason.message,
                        'Oké',
                        null
                    );
                    this.loginFormState = LoginFormState.FillIn;
                });
        }
    }

    forgotPassword() {
        this.router.navigate(['wachtwoord-vergeten']);
        return false;
    }

}
