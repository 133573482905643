<div class="container white" *ngIf="showGeneralOverview">
    <div class="d-flex align-items-center justify-content-between mb-3">
        <h2 class="mb-0">Overzicht scholen</h2>
    </div>
    <div class="row">
        <div class="col">
            <app-payment-schools></app-payment-schools>
        </div>
    </div>
</div>
<div class="container white">
    <div class="d-flex align-items-center justify-content-between mb-3">
        <h2 class="mb-0">Overzicht betaalverzoeken</h2>
    </div>
    <div>
        <small>Klik op een regel om naar het betreffende betalingsverzoek te springen en de details in te zien.</small>
    </div>
    <div class="row mb-3">
        <div class="col">
            <app-payment-details [overview]="overview"></app-payment-details>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-between mb-3">
        <h2 class="mb-0">Openstaand saldo</h2>
    </div>
    <div>
        <small>Let op: saldo wordt gedeeld tussen alle Kindwijs scholen indien ouders leerlingen hebben op meerdere scholen</small>
    </div>
    <div class="row">
        <div class="col">
            <table class="table table-striped" *ngIf="overview">
                <thead>
                <tr>
                    <th (click)="sortList('name')">
                        Naam
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="name"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('email')">
                        E-mailadres
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="email"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('phone')">
                        Telefoonnummer
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="phone"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('amount')">
                        Openstaand Kindwijs saldo
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="amount"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('deleted_at')">
                        Account beëindigd
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let payment of list" style="cursor:pointer;">
                    <td>{{payment.name}}</td>
                    <td>{{payment.email}}</td>
                    <td>{{payment.phone}}</td>
                    <td class="text-right">
                        {{payment.amount | currency : 'EUR'}}
                    </td>
                    <td>
                        {{payment.deleted_at ? '' : '-'}}
                        {{payment.deleted_at | date:'EEE d MMM yyyy, H:mm'}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
