import {EventEmitter, Injectable} from '@angular/core';
import {ChatMessage} from '../classes/chat-message';
import {WebsocketService} from './websocket.service';
import {ApiService} from './api/api.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChatMessageService {

    public totalCount = 0;
    private items: ChatMessage[] = [];
    private itemChanged = new EventEmitter<number[]>();
    private currentChatId: number;
    private retryTimeoutPointer;
    private init = false;

    constructor(private websocket: WebsocketService, private apiService: ApiService) {
        this.websocketInit();
        this.websocket.websocketOpen.subscribe(isOpen => {
            if (!isOpen) {
                this.retryWebsocketInit();
            } else if (this.currentChatId) {
                this.requestMessages(this.currentChatId);
            }

        });
    }

    clearData() {
        this.totalCount = 0;
        this.items = [];
    }

    public getList(chatId: number): Observable<ChatMessage[]> {
        if (!this.init) {
            this.websocketInit();
        }
        return new Observable<ChatMessage[]>((observer) => {
            const itemChangedSubscription = this.itemChanged.subscribe(() => {
                observer.next(this.items);
            }, () => {
                observer.error();
            });

            if (chatId !== this.currentChatId) {
                this.currentChatId = chatId;
                this.items = [];
            } else if (this.items?.length) {
                observer.next(this.items);
            }
            this.requestMessages(chatId);

            return {
                unsubscribe(): void {
                    itemChangedSubscription.unsubscribe();
                }
            };
        });
    }

    public requestMessages(chatId: number, fromId: string | number = '', limit = 25) {
        this.websocket.doRequest('chatMessage', {
            chatId,
            limit,
            fromId
        }, 'get');
    }

    public newMessage(message: ChatMessage) {
        return this.apiService.postCall$('chats/message', message);
    }

    public lastRead(messageId, chatId) {
        return this.apiService.postCall$('chats/message/read', {messageId, chatId});
    }

    public deleteMessage(ids: number[]) {
        return this.apiService.deleteCall$<boolean>(`chats/message`, {ids});
    }

    private retryWebsocketInit() {
        this.init = false;
        if (this.retryTimeoutPointer) {
            clearTimeout(this.retryTimeoutPointer);
        }
        this.retryTimeoutPointer = setTimeout(() => {
            this.websocketInit();
        }, 1000);
    }

    private websocketInit() {
        this.init = true;
        this.websocket.getWebsocket<ChatMessageData>(['chatMessage']).subscribe(
            (wsItems) => {
                if (wsItems?.success) {
                    this.websocketHandling(wsItems.data);
                } else if (!!wsItems?.reinit) {
                    this.retryWebsocketInit();
                }
            },
            () => this.retryWebsocketInit(),
            () => this.retryWebsocketInit()
        );
    }

    private websocketHandling(wsItems: ChatMessageData) {
        if (typeof wsItems !== 'undefined') {
            if (wsItems.messages.some(m => m.chat_id === +this.currentChatId)) {
                this.totalCount = wsItems.count;
            }
        }
        if (this.items && wsItems && wsItems.messages) {
            wsItems.messages.filter(i => +i.chat_id === +this.currentChatId).forEach(item => {
                const currentItem = this.items.find(p => p.id === +item.id);
                if (currentItem) {
                    Object.assign(currentItem, item);
                } else {
                    this.items.push(item);
                }
            });
            this.items.sort((a, b) => {
                const aa = new Date(a.created_at);
                const bb = new Date(b.created_at);
                if (aa.getTime() < bb.getTime()) {
                    return -1;
                }
                if (aa.getTime() > bb.getTime()) {
                    return 1;
                }
                return 0;
            });
            this.itemChanged.emit(wsItems.messages.map(i => +i.id));
        }
    }
}

export class ChatMessageData {
    messages: ChatMessage[];
    count: number;
}
