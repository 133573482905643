<div class="items" [class.active]="childActive" [class.has-unread]="unreadOtherSchool.size">
    <virtual-scroller #virtualScroll (vsEnd)="scroll($event)" [items]="articles" class="item-list">
        <div *ngFor="let article of virtualScroll.viewPortItems" class="item"
             routerLink="/{{Routenames.articles}}/{{article.slug}}">
            <app-codalt-image [path]="article.id + '/' + article.image"
                              [thumb]="article.image_thumb"
                              class="detail__image">
            </app-codalt-image>
            <div [class.alert]="article.alert" [class.employee]="article.access_level === 'EMPLOYEE'"
                 class="detail__block">
                <h3 [class.unread]="!article.read" class="item__title">{{article.title}}</h3>
                <div class="details">
                    <time>
                        {{article.publish_date | date:'EE d MMM yyyy HH:mm'}}
                    </time>
                    <div class="article-info">{{article.user.name}}
                        <ng-container
                            *ngFor="let classroom of article.groupedClassrooms; let last = last; let first = first">
                            {{first ? ' | ' : ''}} {{classroom}}{{last ? '' : ','}}
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="item-spacer"></div>
        <div class="title">
            <h2>
                {{future ? 'Ingepland' : 'Nieuws'}}
            </h2>
            <div class="unread-school" *ngIf="unreadOtherSchool.size">
                <div class="unread-title">
                    Ongelezen nieuws
                    in {{unreadOtherSchool.size > 1 ? 'andere scholen' : 'andere school'}}
                </div>
                <div class="unread-schools">
                    <ng-container *ngFor="let unread of unreadOtherSchool | keyvalue; let last = last">
                        <span class="school"
                              (click)="toSchool(unread.key)">{{unread.key?.name}} ({{unread.value}})</span>{{last ? '' : ','}}
                    </ng-container>
                </div>
            </div>
            <mat-form-field *ngIf="LocalStorage.school && LocalStorage.school.type !== 'master'">
                <mat-label>Groepen</mat-label>
                <mat-select [formControl]="fcFilter" multiple>
                    <mat-option value="all">Toon alle groepen</mat-option>
                    <mat-option value="own" *ngIf="LocalStorage.school.is_direct">Toon eigen groepen</mat-option>
                    <mat-option value="school">Toon schoolbreed</mat-option>
                    <mat-option [value]="classroom.id" *ngFor="let classroom of classrooms">
                        Groep {{classroom.classroom_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </virtual-scroller>
    <div class="floating-buttons">
        <button *ngIf="AuthorisationService.hasFeature('createArticle')"
                color="primary"
                mat-fab
                matTooltip="{{future ? 'Nieuws uit het verleden' : 'Toekomstig nieuws'}}"
                matTooltipPosition="before"
                routerLink="/{{Routenames.articles}}/{{future ? '' : 'toekomst'}}">
            <mat-icon [class.fa-clock-o]="!future" [class.fa-history]="future" class="fas"></mat-icon>
        </button>
        <button *ngIf="AuthorisationService.hasFeature('createArticle')" color="primary" mat-fab
                routerLink="/{{Routenames.articles}}/nieuw/bewerken">
            <mat-icon class="fas fa-plus"></mat-icon>
        </button>
    </div>


    <div *ngIf="articles.length === 0 && !loading" class="not-found">
        Er zijn nog geen nieuwsberichten geplaatst
    </div>
</div>
<div class="router-outlet" [class.active]="childActive">
    <router-outlet (activate)="active(true)"
                   (deactivate)="childClosed($event);">
    </router-outlet>
</div>
