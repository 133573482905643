import {Component, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {Event} from '../../classes/event.class';
import {EventService} from '../../services/event.service';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Routenames} from '../../route-names.enum';
import {CodaltComponent} from '../../codalt.component';
import {Image} from '../../classes/image.class';
import {ImageViewerDialogComponent} from '../../image-viewer-dialog/image-viewer-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ImageViewerData} from '../../image-viewer-dialog/image-viewer-data';
import {startWith} from 'rxjs/operators';
import {Document} from '../../classes/document.class';
import {Settings} from '../../settings.class';
import {PhoneService} from '../../services/phone-service/phone.service';
import {AfterSchoolService} from '../../services/after-school.service';
import {EventSubscription} from '../../classes/event-subscription';

declare var cordova;

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.component.html',
    styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent extends CodaltComponent implements OnInit {

    loading = false;
    event: Event;
    deleting = false;
    eventStarted = false;

    constructor(private eventService: EventService,
                private afterSchoolService: AfterSchoolService,
                private classroomService: ClassroomService,
                private location: Location,
                private router: Router,
                public phoneService: PhoneService,
                private confirmDialogService: ConfirmDialogService,
                private matDialog: MatDialog,
                private dialog: ConfirmDialogService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school && (!this.event || (this.event && this.event.slug != params['id']))) {
                this.getEvent(params['id']);
            }
        }));
    }

    openDocument(doc: Document) {
        const url = Settings.API_ENDPOINT +
            'file/file?secure=eventDocument' +
            '&path=/' + this.event.id + '/' + doc.path +
            '&school=' + LocalStorage?.school?.id +
            '&access_token=' + LocalStorage.getUserToken();
        if (typeof cordova !== 'undefined') {
            window['PreviewAnyFile'].previewPath(
                win => console.log("open status", win),
                error => console.error("open failed", error),
                url, {name: 'file.pdf'}
            );
        } else {
            window.open(url);
        }
    }

    delete() {
        this.dialog.confirm('Verwijderen',
            `Weet je zeker dat je dit kalender item wilt verwijderen?`, 'Ja', 'Nee').then(() => {
            this.eventService.delete(this.event.id).subscribe(() => {
                this.deleting = true;
                this.router.navigateByUrl(Routenames.events);
            });
        }, () => {
        });
    }

    openImage(image: Image) {
        const dialogRef = this.matDialog.open(ImageViewerDialogComponent, {
            panelClass: 'image-viewer',
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            hasBackdrop: true,
            data: {
                images: this.event.images,
                secure: 'event',
                viewIndex: this.event.images.indexOf(image)
            } as ImageViewerData
        });

    }

    switchToSchool() {
        LocalStorage.school = LocalStorage.schools.find(p => +p.id === +this.event.school_id);
    }

    refund(eventSubscription: EventSubscription) {
        this.dialog.confirm('Terugbetalen',
            `Weet je zeker dat je de inschrijving van ${eventSubscription.student.name} wilt terugbetalen?`, 'Ja, terugbetalen', 'Nee').then(() => {
            this.afterSchoolService.refund(eventSubscription.id).subscribe(subscription => {
                Object.assign(eventSubscription, subscription.data);
            });
        }, () => {
        });
    }

    unsubscribe(eventSubscription: EventSubscription) {
        this.dialog.confirm('Uitschrijven zonder terugbetalen',
            `Weet je zeker dat je ${eventSubscription.student.name} wilt uitschrijven?`, 'Ja, uitschrijven', 'Nee').then(() => {
            this.afterSchoolService.unsubscribe(eventSubscription.id).subscribe(subscription => {
                Object.assign(eventSubscription, subscription.data);
            });
        }, () => {
        });
    }

    unsubscribeWithRefund(eventSubscription: EventSubscription) {
        this.dialog.confirm('Uitschrijven en terugbetalen',
            `Weet je zeker dat je ${eventSubscription.student.name} wilt uitschrijven en terugbetalen?`, 'Ja, uitschrijven', 'Nee').then(() => {
            this.afterSchoolService.unsubscribe(eventSubscription.id).subscribe(subscription => {
                Object.assign(eventSubscription, subscription.data);
                this.afterSchoolService.refund(eventSubscription.id).subscribe(subscription => {
                    Object.assign(eventSubscription, subscription.data);
                });
            });
        }, () => {
        });
    }

    private getEvent(slug) {
        this.eventService.get(slug).subscribe((event) => {
            this.event = event.data;
            this.location.replaceState(`${Routenames.events}/${event.data.slug}`);
            if (!event.data.global) {
                LocalStorage.school = LocalStorage.schools.find(p => +p.id === +this.event.school_id);
            }
            this.event.dateranges.forEach(date => {
                if (new Date() > date.start_date) {
                    this.eventStarted = true;
                }
            });
        }, error => {
            this.confirmDialogService.confirm('Fout', error.message, 'Terug naar overzicht', null).then(() => {
                this.router.navigateByUrl(Routenames.events);
            });
        });
    }
}
