<div class="dialog-container">
    <div class="dialog-content d-flex flex-column">
        <div class="title d-flex justify-content-between">
            <div>
                Kopieer selectie naar nieuwsitem
            </div>
            <button class="close" mat-dialog-close="" mat-icon-button tabindex="-1">
                <mat-icon class="fas fa-close"></mat-icon>
            </button>
        </div>
        <div class="d-flex flex-column h-100 overflow-hidden">
            <ng-select [items]="articles"
                       class="p-3"
                       bindLabel="title"
                       bindValue="id"
                       [formControl]="fcArticleId"
                       placeholder="Selecteer een artikel">
            </ng-select>
            <mat-list class="flex-fill overflow-auto">
                <div class="pb-0" mat-subheader>Te kopiëren media</div>
                <mat-list-item class="message-line mb-3" *ngFor="let message of data.messages; let last = last">
                    <div class="message" mat-line>
                        <app-chat-message [message]="message"></app-chat-message>
                    </div>
                </mat-list-item>
            </mat-list>
            <button color="primary" mat-fab (click)="copy()" [disabled]="saving">
                <mat-icon *ngIf="!saving" class="far fa-copy"></mat-icon>
                <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
            </button>
        </div>
    </div>
</div>
