import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageService} from '../services/page/page.service';
import {Settings} from '../settings.class';
import {LocalStorage} from '../storage.class';

@Component({
    selector: 'app-page-management',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit, OnDestroy {

    pageSubscription: any;
    pageData: any = [];
    emptyPageData: number;
    settings: Settings = Settings;
    saveOrderTimeOutId = null;

    constructor(private pageManagementService: PageService) {
    }

    ngOnInit() {

        this.pageSubscription = this.pageManagementService.getPageTreeEmitter()
                                    .subscribe(() => this.getPages());

        LocalStorage.schoolChange.subscribe(() => {
            this.getPages();
        });
        this.getPages();
    }

    getPages() {

        this.pageData = [];
        this.emptyPageData = 1;

        if (LocalStorage.school) {
            this.pageManagementService.getAllPages().then(pages => {
                this.pageData = pages.data;
                this.emptyPageData = pages.data ? pages.data.length : 0;
            });
        }

    }

    saveOrder() {
        if (this.saveOrderTimeOutId) {
            clearTimeout(this.saveOrderTimeOutId);
        }


        this.saveOrderTimeOutId = setTimeout(() => {
            this.pageManagementService.saveOrder(this.pageData).then(response => {
            });
        }, 500);

    }

    ngOnDestroy() {
    }

}
