<mat-nav-list class="pt-0">
    <a (click)="openLink('remove')" *ngIf="options.remove" mat-list-item>
        <div class="d-flex">
            <mat-icon class="mr-2 fas fa-trash" mat-list-icon></mat-icon>
            <span mat-line>Verwijder foto</span>
        </div>
    </a>
    <a (click)="openLink('rotate')" *ngIf="options.rotate" mat-list-item>
        <div class="d-flex">
            <mat-icon class="mr-2 fas fa-rotate-left" mat-list-icon></mat-icon>
            <span mat-line>Foto draaien</span>
        </div>
    </a>
    <a (click)="openLink('camera')" mat-list-item>
        <div class="d-flex">
            <mat-icon class="mr-2 fa-duotone fa-camera-retro" mat-list-icon></mat-icon>
            <span mat-line>Foto maken</span>
        </div>
    </a>
    <a (click)="openLink('gallery')" mat-list-item>
        <div class="d-flex">
            <mat-icon class="mr-2 fa-duotone fa-image" mat-list-icon></mat-icon>
            <span mat-line>Foto kiezen uit galerij</span>
        </div>
    </a>
    <mat-divider></mat-divider>
    <a (click)="openLink('video')" *ngIf="options.video" mat-list-item>
        <div class="d-flex">
            <mat-icon class="mr-2 fa-duotone fa-camcorder" mat-list-icon></mat-icon>
            <span mat-line>Video maken</span>
        </div>
    </a>
    <a (click)="openLink('video-gallery')" *ngIf="options.video" mat-list-item>
        <div class="d-flex">
            <mat-icon class="mr-2 fa-duotone fa-films" mat-list-icon></mat-icon>
            <span mat-line>Video kiezen uit galerij</span>
        </div>
    </a>
    <mat-divider></mat-divider>
    <a (click)="openLink('document')" *ngIf="options.document" mat-list-item>
        <div class="d-flex">
            <mat-icon class="mr-2 fa-duotone fa-file" mat-list-icon></mat-icon>
            <span mat-line>Document uploaden</span>
        </div>
    </a>
</mat-nav-list>
