export class Video {
    id: number;
    path: string;
    thumb: string;
    location: string;
    event_id: number;
    article_id: number;
    job_id: number;
    chat_id: number;
    processed: boolean;
    order: number;

    updated_at: Date;
}
