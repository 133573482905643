<section class="detail">
    <div class="container-fluid pt-3 item-edit">
        <div class="col bg-white pb-2">
            <div class="row" *ngIf="discussion">
                <div class="col-auto pt-3 no-outline pr-0 curpoint"
                     routerLink="/{{Routenames.discussions}}/{{discussion?.id}}">
                    <i class="fas fa-2x fa-angle-left"></i>
                </div>
                <div class="col pt-3">
                    <h2 class="news__title">{{discussion.name}} {{LocalStorage.school?.name}}</h2>
                </div>
                <div class="col-auto pt-4">
                    <mat-checkbox [(ngModel)]="showAllClassrooms" (change)="changeShowAll()">
                        Toon alle groepen
                    </mat-checkbox>
                </div>
                <div class="col-auto" *ngIf="!showAllClassrooms">
                    <ng-select [(ngModel)]="visibleClassrooms"
                               [clearable]="true"
                               [items]="classrooms"
                               [markFirst]="true"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               bindLabel="classroom_name">
                    </ng-select>
                </div>
            </div>
            <div *ngIf="discussion.discussiontimes">
                <table class="table table-striped table-hover table-fixed">
                    <thead>
                    <ng-container *ngFor="let tr of ['reference-tr', 'sticky-tr']">
                        <tr id="{{tr}}" *ngIf="tr === 'reference-tr' || columnWith">
                            <th width="150">
                                Tijdstip
                            </th>
                            <th *ngFor="let classroom of visibleClassrooms; let i = index;"
                                [style.width.px]="tr === 'reference-tr' ? null : columnWith[i+1]"
                                [matTooltip]="'Voor groep ' + classroom.classroom_name + ' zijn voor ' + classroom.subscribed +
                    ' van de ' + classroom.students.length + ' leerlingen oudergesprekken ingepland.' +
                    ' Er zijn ' + classroom.available + ' beschikbare tijdsvensters. '">
                                Groep {{classroom.classroom_name}}<br>
                                {{classroom.subscribed}} / {{classroom.students.length}}
                            </th>
                        </tr>
                    </ng-container>
                    </thead>
                    <tbody>
                    <tr *ngFor="let time of times">
                        <td>{{time | date: 'EE d MMM HH:mm'}}</td>
                        <td (click)="changePlanning(classroom, timeObjs.get(classroom.id + '_' + time))"
                            *ngFor="let classroom of visibleClassrooms"
                            [class.temp-unavailable]="timeObjs.get(classroom.id + '_' + time).tempUnavailable"
                            [class.temp-available]="timeObjs.get(classroom.id + '_' + time).tempUnavailable === false"
                            [class.unavailable]="timeObjs.get(classroom.id + '_' + time).unavailable"
                            [contextMenuValue]="timeObjs.get(classroom.id + '_' + time)"
                            [contextMenu]="basicMenu">
                            {{(classroom.students | byId: timeObjs.get(classroom.id + '_' + time).student_id)?.name || '-'}}
                        </td>
                    </tr>
                    </tbody>
                    <thead>
                    <tr>
                        <th width="150">
                            Groep
                        </th>
                        <th *ngFor="let classroom of visibleClassrooms">
                            Groep {{classroom.classroom_name}}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th width="150">
                            Nog niet<br>ingeschreven<br>leerlingen
                        </th>
                        <th *ngFor="let classroom of visibleClassrooms">
                            <ng-container *ngFor="let student of classroom.not_subscribed">
                                {{student.name}}<br>
                            </ng-container>
                        </th>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>

<context-menu [disabled]="!AuthorisationService.hasFeature('unavailableDateClassroom')">
    <ng-template [passive]="true" contextMenuItem let-item>
        <h4 class="mb-0">
            Groep {{(allClassrooms | byId:item.classroom_id)?.classroom_name}}
        </h4>
    </ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>
    <ng-template (execute)="toggleItemAvailable($event.value)" contextMenuItem let-item [visible]="showWhenNoStudent">
        <div (mouseenter)="toggleItemTempAvailable(item)" (mouseout)="resetTempAvailable()">
            {{item.datetime | date: 'HH:mm'}} {{item.unavailable ? 'wel' : 'niet'}} beschikbaar
        </div>
    </ng-template>
    <ng-template (execute)="unavailableDateClassroom($event.value)" contextMenuItem let-item>
        <div (mouseenter)="toggleDayTempAvailable(item)" (mouseout)="resetTempAvailable()">
            {{item.datetime | date: 'd MMM'}} {{item.unavailable ? 'wel' : 'niet'}} beschikbaar
        </div>
    </ng-template>
    <ng-template (execute)="unavailablePrevClassroom($event.value)" contextMenuItem let-item>
        <div (mouseenter)="togglePreviousTempAvailable(item)" (mouseout)="resetTempAvailable()">
            {{getPrevious(item)?.datetime | date: 'd MMM HH:mm'}}
            - {{item.datetime | date: 'HH:mm'}} {{item.unavailable ? 'wel' : 'niet'}} beschikbaar
        </div>
    </ng-template>
    <ng-template (execute)="unavailableNextClassroom($event.value)" contextMenuItem let-item>
        <div (mouseenter)="toggleNextTempAvailable(item)" (mouseout)="resetTempAvailable()">
            {{item.datetime | date: 'd MMM HH:mm'}}
            - {{getNext(item)?.datetime | date: 'HH:mm'}} {{item.unavailable ? 'wel' : 'niet'}} beschikbaar
        </div>
    </ng-template>
</context-menu>
