import {Injectable} from '@angular/core';

import {ApiService} from './../api/api.service';
import {Structure} from '../../classes/structure.class';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable()
export class StructureService {

    constructor(public apiService: ApiService) {
    }

    getAll(limit: number = null, custobTypeId: number = null): Observable<Structure[]> {
        return this.apiService.getCall$<Structure[]>(`structure/all`).pipe(map((structures => {
            if (custobTypeId) {
                var filtered = structures.data.filter(p => p.useAtCustobTypeIds.hasOwnProperty(custobTypeId));
            }


            return filtered || structures.data;

        })));
    }

    getAllInObject(limit: number = null): Promise<Object> {
        return new Promise((resolve, reject) => {
            let structobj = {};
            this.getAll().subscribe(structures => {
                structures.forEach(structure => {
                    structobj[structure.identifier] = structure;
                });
                resolve(structobj);

            });
        });
    }

    getSingle(structureId) {
        return this.apiService.getCall$<Structure>(`structure/detail/${structureId}`);
    }

    save(structure: Structure): Promise<Object> {
        return this.apiService.postCall(`structure/save`,
            {
                structure: structure
            });
    }
}
