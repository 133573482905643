<div [class.page-edit-enabled]="pageEditEnabled"
     [style.max-width]="pageEditEnabled ? sanitizer.bypassSecurityTrustStyle('calc(100% - ' + Settings.sidemodalWidth + 'px)') : ''"
     class="holder">
    <div (click)="backToParentUser()" *ngIf="LocalStorage.parentUser && LocalStorage.user" class="parent-user">
        Je hebt als {{LocalStorage.parentUser.name}} het account van {{LocalStorage.user.name}} overgenomen.
        Klik hier om terug te gaan naar je eigen account.
    </div>
    <div class="topbar navbar">
        <div class="topbar__flex container">
            <a class="topbar__brand" routerLink="/">
                <svg-icon src="assets/svg/kindwijs-small.svg"></svg-icon>
            </a>
            <ng-container *ngIf="loggedIn">
                <div *ngIf="showMenuItems.page" [class.show]="websiteExpanded" class="dropdown d-none d-lg-block">
                    <div (click)="resetExpanded();websiteExpanded=!websiteExpanded" class="topbar__link" role="button">
                        <span>Website beheren</span>
                        <i class="ml-1 fa fa-angle-down"></i>
                    </div>
                    <div [class.show]="websiteExpanded" aria-labelledby="schoolselect" class="dropdown-menu">
                        <a *ngIf="showMenuItems.pageEdit" class="dropdown-menu__item" routerLink="/{{Routenames.pageManagement}}">Pagina's</a>
                        <a *ngIf="showMenuItems.jobs" class="dropdown-menu__item" routerLink="/{{Routenames.jobs}}">Vacatures</a>
                        <a *ngIf="showMenuItems.pageSettings" class="dropdown-menu__item" routerLink="/{{Routenames.schoolSettings}}">Instellingen</a>
                        <a *ngIf="showMenuItems.permissions" class="dropdown-menu__item" routerLink="/{{Routenames.permissions}}">Toestemmingen</a>
                        <a *ngIf="showMenuItems.formHistory" class="dropdown-menu__item" routerLink="/{{Routenames.formHistory}}">Formulieren</a>
                        <a *ngIf="showMenuItems.pageStructure" class="dropdown-menu__item" routerLink="structures">Structures</a>
                    </div>
                </div>
                <div *ngIf="showMenuItems.parnassysMain" [class.show]="parnassysExpanded" class="dropdown d-none d-md-block">
                    <div (click)="resetExpanded();parnassysExpanded=!parnassysExpanded" class="topbar__link" role="button">
                        <span>Gebruikersbeheer</span>
                        <i class="ml-1 fa fa-angle-down"></i>
                    </div>
                    <div [class.show]="parnassysExpanded" aria-labelledby="schoolselect" class="dropdown-menu">
                        <a *ngIf="showMenuItems.parnassysUsers" class="dropdown-menu__item" routerLink="{{Routenames.parnassysUsers}}">
                            Gebruikers
                        </a>
                        <a *ngIf="showMenuItems.parnassysStudents" class="dropdown-menu__item" routerLink="{{Routenames.parnassysStudent}}">
                            Leerlingen
                        </a>
                        <a *ngIf="showMenuItems.parnassysAuth" class="dropdown-menu__item" routerLink="{{Routenames.authRoles}}">
                            Autorisaties
                        </a>
                        <a *ngIf="showMenuItems.parnassysClassrooms" class="dropdown-menu__item" routerLink="{{Routenames.parnassysClassrooms}}">
                            Groepen
                        </a>
                        <a *ngIf="showMenuItems.parnassysLog" class="dropdown-menu__item" routerLink="{{Routenames.authLog}}">
                            Log
                        </a>
                    </div>
                </div>
                <div *ngIf="showMenuItems.discussions" class="d-none d-lg-block">
                    <div class="topbar__link" role="button" routerLink="{{Routenames.discussionsPlanner}}">
                        Oudergesprekkenplanner
                    </div>
                </div>
                <div *ngIf="showMenuItems.costsMaster" class="d-none d-lg-block">
                    <div class="topbar__link" role="button" routerLink="/{{Routenames.payments}}/{{Routenames.overview}}">
                        Kostenoverzicht
                    </div>
                </div>
                <div *ngIf="showMenuItems.absence" class="d-none d-lg-block">
                    <div class="topbar__link" role="button" routerLink="/{{Routenames.absence}}">
                        Absentie
                    </div>
                </div>
                <div *ngIf="showMenuItems.remainDirect" class="d-none d-lg-block">
                    <div class="topbar__link" role="button" routerLink="/{{Routenames.lowPermissionPayments}}">
                        Overblijven
                    </div>
                </div>
                <div *ngIf="showMenuItems.costs" [class.show]="paymentsExpanded" class="dropdown d-none d-sm-block">
                    <div (click)="resetExpanded();paymentsExpanded=!paymentsExpanded" class="topbar__link" role="button">
                        Kosten
                        <i class="ml-1 fa fa-angle-down"></i>
                    </div>
                    <div [class.show]="paymentsExpanded" aria-labelledby="schoolselect" class="dropdown-menu">
                        <a *ngIf="showMenuItems.paymentRequests" class="dropdown-menu__item" routerLink="/{{Routenames.payments}}">
                            Betaalverzoeken
                        </a>
                        <a *ngIf="showMenuItems.paymentDefaults" class="dropdown-menu__item" routerLink="/{{Routenames.defaultPayments}}">
                            Standaard - betalingen
                        </a>
                        <a *ngIf="showMenuItems.paymentLowPermission" class="dropdown-menu__item" routerLink="/{{Routenames.lowPermissionPayments}}">
                            Overblijven
                        </a>
                        <a *ngIf="showMenuItems.addCashCredit" class="dropdown-menu__item" routerLink="/{{Routenames.transactionTopUp}}/{{Routenames.cash}}">
                            Contant opwaarderen
                        </a>
                        <a *ngIf="showMenuItems.paymentsOverview" class="dropdown-menu__item" routerLink="/{{Routenames.payments}}/{{Routenames.overview}}">
                            Overzicht
                        </a>
                    </div>
                </div>
                <div class="fill"></div>
                <div class="fill"></div>
                <div *ngIf="!pageEditEnabled"
                     [class.show]="schoolExpanded"
                     class="dropdown schools-dropdown"
                     matBadge="{{(schoolsWithUnread+chatUnreadCount) > 99 ? '99+' : (schoolsWithUnread+chatUnreadCount)}}"
                     [matBadgeHidden]="(schoolsWithUnread+chatUnreadCount) < 1 || LocalStorage.user?.anySchools?.length < 2"
                     matbadge
                     matBadgePosition="before">
                    <div (click)="schoolExpandedToggle()" [class.cursor-default]="LocalStorage.user?.anySchools?.length <= 1"
                         class="topbar__button" id="schoolselect" role="button">
                        <span>{{LocalStorage.school?.prefix}} {{LocalStorage.school?.name}}</span>
                        <i *ngIf="LocalStorage.user?.anySchools?.length > 1" class="fa fa-angle-down"></i>
                    </div>
                    <div [class.show]="schoolExpanded" aria-labelledby="schoolselect" class="dropdown-menu">
                        <div (click)="setSchool(school)" *ngFor="let school of LocalStorage.user?.anySchools" class="dropdown-menu__item">
                            {{school.name}}
                            <span *ngIf="discussionOpenCount.get(school.id) || articleUnreadCounts.get(school.id) || chatUnreadCounts.get(school.id)">
                                ({{discussionOpenCount.get(school.id) + articleUnreadCounts.get(school.id) + chatUnreadCounts.get(school.id)}})
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <main (click)="resetExpanded()" class="main">
        <div class="main__triangle">
            <svg-icon src="assets/svg/kindwijs-triangle.svg"></svg-icon>
        </div>
        <div class="progress-holder">
            <mat-progress-bar *ngIf="CodaltInterceptor.loadingCount > 0" mode="indeterminate"></mat-progress-bar>
        </div>
        <div #holder (scroll)="onScroll($event)" class="router-outlet">
            <router-outlet (activate)="routedComponent = $event"></router-outlet>
        </div>
    </main>
    <nav [style.right.px]="pageEditEnabled ? Settings.sidemodalWidth : 0" class="nav">
        <div class="container">
            <ul class="list">
                <li *ngFor="let bm of bottomMenu"
                    [class.active]="bm.active" (click)="openNavItem(bm)" class="item">
                    <div class="link" [matBadge]="bm.badge" [matBadgeHidden]="!bm.badge || bm.name === 'chat'" matBadgeSize="small">
                        <i class="fa-duotone fa-2x {{bm.icon}}"></i>
                        <span class="text">{{bm.name}}</span>
                        <div class="badge icon-{{bm.icon}}" *ngIf="bm.badge && bm.name === 'chat'">{{bm.badge}}</div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</div>
