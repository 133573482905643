import {Component, OnInit} from '@angular/core';
import {AbsenceCount, AbsenceService} from '../services/absence.service';
import {SortingComponent} from '../sorting.component';

@Component({
    selector: 'app-absence-counts',
    templateUrl: './absence-counts.component.html',
    styleUrls: ['./absence-counts.component.scss']
})
export class AbsenceCountsComponent extends SortingComponent<AbsenceCount> implements OnInit {

    absenceCounts: AbsenceCount[];

    constructor(private absenceService: AbsenceService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.absenceService.getAbsenceCounts().subscribe(absenceCounts => {
            this.list = absenceCounts.data;
            this.currentSort = 'name';
            this.desc = false;
        }));
    }

}
