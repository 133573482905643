<div class="container bg-white mt-2 item-edit" *ngIf="questions">
    <div>
        <h2 class="news__title">Toestemmingen</h2>
        <h4 class="text-capitalize"></h4>
    </div>
    <div>
        <p *ngIf="questions.length === 0">
            Je hebt nog geen vragen toegevoegd.
        </p>
        <div (cdkDropListDropped)="reorderQuestions($event)" cdkDropList>
            <ng-container *ngFor="let question of questions; index as i">
                <div class="row  question" *ngIf="question" cdkDrag>
                    <div class="col-1 move-question" cdkDragHandle>
                        <div>Vraag {{i + 1}}</div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <h3>Vraag {{i + 1}} - {{question.name}}</h3>
                            </div>
                            <button mat-icon-button color="primary" class="col-auto curpoint" routerLink="/{{Routenames.permissionStats}}/{{question.id}}">
                                <mat-icon class="fa-regular fa-square-poll-horizontal"></mat-icon>
                            </button>
                            <button mat-icon-button color="primary" class="col-auto curpoint" routerLink="/{{Routenames.permissions}}/{{question.id}}">
                                <mat-icon class="fa fa-edit"></mat-icon>
                            </button>
                            <button mat-icon-button color="primary" class="col-auto curpoint" (click)="duplicateQuestion(i)">
                                <mat-icon class="fa fa-copy"></mat-icon>
                            </button>
                            <button mat-icon-button color="primary" class="col-auto curpoint" (click)="removeQuestion(i)">
                                <mat-icon class="fa fa-trash"></mat-icon>
                            </button>
                        </div>
                        <div class="row">
                            <div class="col-auto">
                                Startdatum: {{question.start_date | date : 'd MMM yyyy'}}
                            </div>
                            <div class="col-auto" *ngIf="question.end_date">
                                Einddatum: {{question.end_date | date : 'd MMM yyyy'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto">
                                Voor groepen:
                                <span *ngFor="let classroom of question.classrooms; let last = last">
                                    {{classroom.classroom_name}}{{last ? '' : ','}}
                                </span>
                                <span *ngIf="!question.classrooms?.length">Schoolbreed</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto">
                                Beschrijving:<br>
                                <small [innerHTML]="question.description | nl2br"></small>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>
        </div>
        <button mat-flat-button color="primary" (click)="addQuestion()">Vraag toevoegen</button>
    </div>
</div>
