import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export class EventEditRequiredRules {
    static start_time = (formGroup: UntypedFormGroup) => {
        return !formGroup.get('all_day').value;
    };

    static end_time = EventEditRequiredRules.start_time;
}

export function RequiredRule(formGroup: UntypedFormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const getControlName = (c: AbstractControl): string | null => {
            if (c && c.parent) {
                const formGroup = c.parent.controls;
                return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
            }
        };

        // controlName is empty on initializing because parent has not been set yet
        const controlName = getControlName(control);
        if (controlName) {
            const rule = EventEditRequiredRules[controlName];
            if (!rule) {
                throw ('No rule defined for ' + controlName + ', please define rule in planning-detail-dialog.required-rules.ts');
            }

            if (
                (control.value == null || control.value === undefined
                    || (Array.isArray(control.value) && control.value.length < 1)) &&
                rule.call(null, formGroup)
            ) {
                return {required: true};
            }
            return null;
        }
        return {required: true};
    };


}
