import {Component, OnInit} from '@angular/core';
import {startWith} from 'rxjs/operators';
import {SortingComponent} from '../../sorting.component';
import {Router} from '@angular/router';
import {LocalStorage} from '../../storage.class';
import {Routenames} from '../../route-names.enum';
import {PaymentDefault} from '../../classes/payment-default';
import {PaymentDefaultService} from '../../services/payment-default.service';

@Component({
    selector: 'app-default-payments',
    templateUrl: './default-payments.component.html',
    styleUrls: ['./default-payments.component.scss']
})
export class DefaultPaymentsComponent extends SortingComponent<PaymentDefault> implements OnInit {

    paymentRequests: PaymentRequest[];

    constructor(private paymentDefaultService: PaymentDefaultService,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)).subscribe(() => {
            this.subscriptions.add(this.paymentDefaultService.getPaymentDefaults().subscribe(requests => {
                this.list = requests.data;
            }));
        }));
    }

    editPayment(id?: number) {
        this.router.navigate([Routenames.defaultPayments, Routenames.editPart, id ? id : Routenames.newPart]);
    }

}
