import {Component, OnInit} from '@angular/core';
import {PaymentService} from '../../services/payment.service';
import {Transaction} from '../../classes/transaction';
import {CodaltComponent} from '../../codalt.component';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-transaction-overview',
    templateUrl: './transaction-overview.component.html',
    styleUrls: ['./transaction-overview.component.scss']
})
export class TransactionOverviewComponent extends CodaltComponent implements OnInit {

    transactions: Transaction[];
    totalAmount: number;

    otherUser: User;

    constructor(private route: ActivatedRoute,
                private userService: UserService,
                private confirmDialog: ConfirmDialogService,
                private paymentService: PaymentService) {
        super();
    }

    ngOnInit(): void {
        this.getTransactions();
    }

    private getTransactions() {
        let transactionCall$;
        if (this.route.snapshot.url[1].path === 'gebruikers') {
            transactionCall$ = this.paymentService.getUserTransactions(this.route.snapshot.params['id']);
            this.userService.getUser(this.route.snapshot.params['id']).subscribe(user => {
                this.otherUser = user.data;
            });
        } else {
            transactionCall$ = this.paymentService.getUserTransactions();
        }
        transactionCall$.subscribe(transactions => {
            this.transactions = transactions.data;
            this.totalAmount = this.transactions.map(t => t.amount).reduce((total, current) => {
                return total + current;
            }, 0);
        });
    }

    refund() {
        this.confirmDialog.confirm('Tegoed terugbetalen',
            `Weet je zeker dat je het overgebleven tegoed van ${this.otherUser.name} wilt terugbetalen?<br><br>
                De terugbetaling wordt binnen 2 dagen overgemaakt op het rekeningnummer waar vandaan voor het laatst is opgewaardeerd.`, 'Terugbetalen', 'Annuleren').then(() => {
            this.paymentService.refundCurrentCredit(this.otherUser.id).subscribe(transaction => {
                this.getTransactions();
            });
        });

    }

}
