<div *ngIf="LocalStorage.school" class="container white">

    <h1>Instellingen</h1>
    <h2>{{LocalStorage.school.prefix}} {{LocalStorage.school.name}}</h2>

    <div *ngIf="form" class="row justify-content-center">
        <div class="col">
            <form [formGroup]="form">
                <div class="mb-2 font-weight-bold">Ouders mogen chats aanmaken met andere ouders:</div>
                <mat-radio-group formControlName="chat_preference">
                    <mat-radio-button value="student_parents">Ouders van andere kinderen in de klas</mat-radio-button>
                    <br>
                    <mat-radio-button value="all_parents">Alle ouders binnen de school</mat-radio-button>
                    <br>
                    <mat-radio-button value="no_parents">Niet chatten met andere ouders</mat-radio-button>
                </mat-radio-group>
                <div class="mt-3 mb-2 font-weight-bold">Reageren op nieuwsberichten:</div>
                <div>
                    <mat-checkbox color="primary" formControlName="replies">Reageren inschakelen</mat-checkbox>
                </div>
                <small>
                    Als reageren hier wordt ingeschakeld kan per bericht reageren worden ingeschakeld.<br>
                    Als reageren hier wordt uitgeschakeld zijn reacties bij geen enkel bericht
                    binnen {{LocalStorage.school.name}} zichtbaar.
                </small>
                <div class="mt-3 mb-2 font-weight-bold">Absentiemodule:</div>
                <div>
                    <mat-checkbox color="primary" formControlName="absences">Absentiemodule inschakelen</mat-checkbox>
                </div>
                <div class="mt-3 font-weight-bold">Sociale media:</div>
                <mat-form-field class="w-100">
                    <mat-label>Facebookpagina (URL) van de school</mat-label>
                    <input formControlName="facebook_url" matInput type="text">
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Twitterpagina (URL) van de school</mat-label>
                    <input formControlName="twitter_url" matInput type="text">
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Linkedin (URL) van de school</mat-label>
                    <input formControlName="linkedin_url" matInput type="text">
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Instagram (URL) van de school</mat-label>
                    <input formControlName="instagram_url" matInput type="text">
                </mat-form-field>
                <div class="mt-3 font-weight-bold">Footer:</div>
                <mat-form-field *ngIf="LocalStorage.school.type === 'master'" class="w100">
                    <mat-label>Privacyverklaring (footer)</mat-label>
                    <mat-select formControlName="privacy_url">
                        <mat-option *ngFor="let page of pageList" [value]="page.url">
                            {{page.page}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngIf="LocalStorage.school.type === 'subsite'">

                    <div class="row">
                        <div class="col-8">
                            <mat-form-field class="w-100">
                                <mat-label>Tekst bij logo 1 in footer (bv keurmerk)</mat-label>
                                <input formControlName="badge1_title" matInput type="text">
                            </mat-form-field>
                            <mat-form-field (dblclick)="editLink('badge1_url')" class="w100">
                                <mat-label>Link bij logo 1 in footer</mat-label>
                                <input disabled formControlName="badge1_url" matInput readonly type="text">
                                <mat-icon (click)="editLink('badge1_url')" class="fa fa-pencil-alt curpoint"
                                          matSuffix></mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <file-manager (callback)="fileUploaded($event, 'badge1_image')"
                                          fileType="footer"></file-manager>
                            <br><br>
                            <div class="p-2 curpoint text-center">
                                <i (click)="form.controls['badge1_image'].setValue(null)" class="fas fa-trash"></i>
                            </div>
                        </div>
                        <div *ngIf="form.controls['badge1_image'].value" class="col-12">
                            <img class="badgeimg"
                                 src="{{LocalStorage.school.fullUrl + form.controls['badge1_image'].value}}"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <mat-form-field class="w-100">
                                <mat-label>Tekst bij logo 2 in footer (bv keurmerk)</mat-label>
                                <input formControlName="badge2_title" matInput type="text">
                            </mat-form-field>
                            <mat-form-field (dblclick)="editLink('badge2_url')" class="w100">
                                <mat-label>Link bij logo 2 in footer</mat-label>
                                <input disabled formControlName="badge2_url" matInput readonly type="text">
                                <mat-icon (click)="editLink('badge2_url')" class="fa fa-pencil-alt curpoint"
                                          matSuffix></mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <file-manager (callback)="fileUploaded($event, 'badge2_image')"
                                          fileType="footer"></file-manager>
                            <br><br>
                            <div class="p-2 curpoint text-center">
                                <i (click)="form.controls['badge2_image'].setValue(null)" class="fas fa-trash"></i>
                            </div>
                        </div>
                        <div *ngIf="form.controls['badge2_image'].value" class="col-12">
                            <img class="badgeimg"
                                 src="{{LocalStorage.school.fullUrl + form.controls['badge2_image'].value}}"/>
                        </div>
                    </div>
                </ng-container>


                <button (click)="save()" [disabled]="form.invalid || saving" color="primary" mat-raised-button>
                    Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
                </button>
                <br><br>
                <i>Overige gegevens kunnen worden aangepast door Codalt</i>
            </form>
        </div>
    </div>
</div>
