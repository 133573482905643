import {Component, OnInit} from '@angular/core';
import {LocalStorage} from '../storage.class';
import {HttpClient} from '@angular/common/http';
import {Routenames} from '../route-names.enum';
import {CodaltComponent} from '../codalt.component';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent extends CodaltComponent implements OnInit {

    LocalStorage = LocalStorage;
    Routenames = Routenames;
    version: { datetime: string };

    constructor(private http: HttpClient) {
        super();
    }

    ngOnInit(): void {
        this.http.get('assets/version.json').subscribe(versionInfo => {
            this.version = versionInfo as { datetime: string };
        });
    }
}
