<section class="chat">
    <div class="container">
        <div class="row justify-content-center h-100">
            <mat-progress-bar *ngIf="!datasource" mode="indeterminate"></mat-progress-bar>
            <div *ngIf="datasource" class="message-line">
                <div (click)="editChat()" class="chat-info">
                    <div class="arrow-back curpoint pl-1 pr-3 pt-1"
                         routerLink="/{{archive ? Routenames.chatArchive : Routenames.chat}}">
                        <i class="fas fa-2x fa-angle-left"></i>
                    </div>
                    <div *ngIf="archive" class=" pl-1 pr-3 pt-1 only-admin">
                        <i class="far fa-box-archive"></i>
                    </div>
                    <div class="arrow-back curpoint pl-1 pr-3 pt-1 only-admin"
                         *ngIf="chat.only_admin_messages || !chat.school_id">
                        <i class="far fa-lock"
                           matTooltip="{{chatAdmin ? 'Je kunt berichten plaatsen omdat je beheerder bent van deze chat' : 'Alleen beheerders kunnen berichten plaatsen'}}"></i>
                    </div>
                    <div class="info curpoint">
                        <div class="title">{{chat?.name}}</div>
                        <div *ngIf="chat && chat.chat_classrooms?.length < 1" class="users overflow-ellipsis">
                            <ng-container *ngFor="let user of chat.chat_users;  let last = last;">
                                {{user?.user?.name}}{{last ? '' : ', '}}
                            </ng-container>
                        </div>
                        <div *ngIf="chat && chat.chat_classrooms?.length > 0" class="users overflow-ellipsis">
                            Leerkrachten en ouders uit:
                            <ng-container *ngFor="let classroom of chat.chat_classrooms;  let last = last;">
                                {{classroom.classroom.classroom_name}}{{last ? '' : ', '}}
                            </ng-container>
                        </div>
                    </div>
                    <ng-container *ngIf="selectedItems.size === 0">
                        <div *ngIf="(chat.chat_users | byId:ownUserId:'user_id')?.mute"
                             class="d-flex align-items-center mr-2">
                            <i class="fa-2x fa-duotone fa-bell-slash float-right"></i>
                        </div>
                    </ng-container>
                    <div *ngIf="selectedItems.size > 0" class="actions">
                        <button (click)="addToArticle($event)"
                                *ngIf="enableAddToArticle"
                                mat-icon-button
                                matTooltip="Voeg afbeeldingen toe aan nieuwsbericht">
                            <mat-icon class="fas fa-newspaper"></mat-icon>
                        </button>
                        <button (click)="copyMessage($event)"
                                *ngIf="enableCopy"
                                mat-icon-button
                                matTooltip="Kopieër berichten">
                            <mat-icon class="fas fa-copy"></mat-icon>
                        </button>
                        <button (click)="readInfo($event)"
                                *ngIf="enableInfo"
                                mat-icon-button
                                matTooltip="Bericht informatie">
                            <mat-icon class="fas fa-info-circle"></mat-icon>
                        </button>
                        <button (click)="deleteMessages($event)"
                                *ngIf="enableDelete"
                                mat-icon-button
                                matTooltip="Verwijder berichten">
                            <mat-icon class="fas fa-trash"></mat-icon>
                        </button>
                    </div>
                </div>
                <div class="messages">
                    <div (mousedown)="select($event, message)"
                         (press)="selectStart(message)"
                         (touchstart)="select($event,message)"
                         *uiScroll="let message of datasource"
                         [class.not-loaded]="!message?.id"
                         [class.deleted]="message.deleted_at"
                         [class.own]="ownUserId === message.user_id"
                         [class.selected]="selectedItems && selectedItems.has(message.id)"
                         [class.unread]="(ownUserId === message.user_id && lastReadAllUser < message.id) || (ownUserId !== message.user_id && lastReadCurrentUser < message.id)"
                         class="message">
                        <div>
                            <div class="info" *ngIf="message?.id">
                                <div *ngIf="message.user_id !== ownUserId" class="name">
                                    {{usersMap.get(message.user_id)?.name || 'Onbekende gebruiker'}}
                                </div>
                                <div></div>
                                <div class="date">
                                    <ng-container *ngIf="!Utils.isToday(message.updated_at)">
                                        <span class="d-none d-sm-block">
                                            {{message.updated_at | date:'d MMM yyyy H:mm'}}
                                        </span>
                                        <span class="d-block d-sm-none">
                                          {{message.updated_at | date:'d MMM H:mm'}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="Utils.isToday(message.updated_at)">
                                        {{message.updated_at  | date:'H:mm'}}
                                    </ng-container>
                                </div>
                            </div>
                            <div class="text" *ngIf="!message?.id">
                                bezig met laden
                            </div>
                            <app-chat-message
                                [usersMap]="usersMap"
                                *ngIf="message?.id"
                                [id]="message?.id"
                                (openImage)="openImage($event)"
                                [message]="message"></app-chat-message>
                        </div>
                    </div>
                </div>
                <div class="old" *ngIf="!chat.school_id">
                    <div class="d-flex align-items-center">
                        <i class="fa-light fa-triangle-exclamation mr-2"></i>
                    </div>
                    <div>
                        Deze chat is verouderd. Je kunt hier geen berichten meer versturen.
                    </div>
                </div>

                <div class="old" *ngIf="archive">
                    <div class="d-flex align-items-center">
                        <i class="fa-light fa-triangle-exclamation mr-2"></i>
                    </div>
                    <div>
                        Deze chat is gearchiveerd. Je kunt hier geen berichten meer versturen.
                    </div>
                </div>

                <div class="uploading" *ngIf="uploadingFiles.size">
                    Bezig met uploaden van media
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>

                <div class="d-flex composer">
                    <form [formGroup]="form" class="flex-fill mr-2">
                        <mat-form-field appearance="fill" class="w-100 mt-3">
                            <textarea #autosize="cdkTextareaAutosize"
                                      (keydown)="sendMessage($event)"
                                      [readonly]="sending || (chat.only_admin_messages && !chatAdmin) || !chat.school_id || archive"
                                      cdkAutosizeMaxRows="5"
                                      cdkAutosizeMinRows="1"
                                      cdkTextareaAutosize
                                      formControlName="message"
                                      matInput
                                      placeholder="{{((chat.only_admin_messages && !chatAdmin) || !chat.school_id) ? 'Je kunt alleen berichten lezen' : 'Typ een bericht'}}"></textarea>
                            <mat-error [control]="form.get('message')"></mat-error>
                            <ng-container
                                    *ngIf="(!chat.only_admin_messages || chatAdmin) && chat.school_id && !archive">
                                <file-manager class="curpoint attachment-button" *ngIf="chat.id && isCordova"
                                              matsuffix
                                              [directUpload]="true"
                                              [multiple]="true"
                                              [allMedia]="true"
                                              path="{{chat.id}}"
                                              [referenceItem]="chat.id"
                                              icon="fa-regular fa-paperclip"
                                              secure="chat">
                                </file-manager>

                                <button class="attachment-button" matsuffix color="primary" mat-icon-button
                                        [matMenuTriggerFor]="menu" *ngIf="!isCordova">
                                    <mat-icon class="fa-regular fa-paperclip"></mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" xPosition="before" class="upload-menu">


                                    <file-manager class="curpoint" *ngIf="chat.id"
                                                  [directUpload]="true"
                                                  [multiple]="true"
                                                  [allMedia]="true"
                                                  path="{{chat.id}}"
                                                  [referenceItem]="chat.id"
                                                  icon="fa-duotone fa-image"
                                                  iconText="Foto"
                                                  secure="chat">
                                    </file-manager>


                                    <file-manager *ngIf="!isCordova && chat.id" class="curpoint"
                                                  iconText="Video"
                                                  icon="fa-duotone fa-films"
                                                  [reference]="FileTypes.video"
                                                  [referenceItem]="chat.id"
                                                  [directUpload]="true"
                                                  [video]="true"
                                                  [multiple]="false"
                                                  path="{{chat.id}}"
                                                  secure="chat">
                                    </file-manager>

                                    <file-manager *ngIf="!isCordova && chat.id" class="curpoint"
                                                  iconText="Document"
                                                  icon="fa-duotone fa-file"
                                                  [reference]="FileTypes.document"
                                                  [referenceItem]="chat.id"
                                                  [directUpload]="true"
                                                  [multiple]="false"
                                                  path="{{chat.id}}"
                                                  secure="chat">
                                    </file-manager>

                                </mat-menu>
                            </ng-container>
                        </mat-form-field>
                    </form>
                    <div class="d-flex align-items-center justify-content-center"
                         *ngIf="(!chat.only_admin_messages || chatAdmin) && chat.school_id && !archive">
                        <button class="send-button" (click)="sendMessage()" [disabled]="sending || form.invalid"
                                mat-icon-button>
                            <mat-icon *ngIf="!sending" class="fa-solid fa-paper-plane-top send-ico"></mat-icon>
                            <mat-icon *ngIf="sending" class="fas fa-circle-notch fa-spin"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
