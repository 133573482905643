<div class="form-comp">

    <div class="row">
        <mat-form-field class="col">
            <input [(ngModel)]="structcontent['title']" matInput placeholder="Titel van het formulier" type="text">
        </mat-form-field>
        <mat-form-field class="col">
            <input [(ngModel)]="structcontent['email']" matInput placeholder="Ontvanger (e-mailadres)" type="text">
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field class="w100">
                <input [(ngModel)]="structcontent['buttontext']" matInput placeholder="Tekst op verstuur knop"
                       type="text">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col pb-3">
            <mat-checkbox [(ngModel)]="structcontent['recaptcha']">Gebruik ReCaptcha (voorkomt spam)</mat-checkbox>
        </div>
    </div>
    <b>Velden</b><br>
    <ul [(dragulaModel)]="structcontent['items']" class="list-group"
        dragula="form-fields-bag-{{structcontentParentIdentifier}}">
        <ng-container *ngFor="let item of structcontent['items']">
            <li (click)="currentEditing = item" [ngClass]="{'active' : currentEditing == item}"
                class="list-group-item curpoint">
                <i class="move fa fa-bars"></i>
                {{item?.fieldName || 'Nog geen naam opgegeven'}}
            </li>
        </ng-container>

    </ul>
    <button (click)="currentEditing=multi('items', structcontent); currentEditing.fieldName = ''; currentEditing.fieldType = 'text';"
            class="add-button w100" class="w100" color="primary" mat-button
            type="button">
        Veld toevoegen
    </button>

    <div *ngIf="currentEditing" class="p-2">
        <div class="d-flex">
            <mat-form-field class="flex-even pr-1">
                <input [(ngModel)]="currentEditing.fieldName" id="fieldName" matInput
                       placeholder="Naam"
                       required type="text">
            </mat-form-field>
            <mat-checkbox *ngIf="currentEditing.fieldType !== 'title' && currentEditing.fieldType !== 'infotext'"
                          [(ngModel)]="currentEditing.hideFieldName" class="flex-even pl-1 mt-3">
                Naam niet tonen
            </mat-checkbox>
        </div>
        <div class="d-flex">
            <mat-form-field class="pr-1 flex-even">
                <mat-select [(value)]="currentEditing.fieldType" placeholder="Veldtype">
                    <mat-option selected="text" value="text">Tekstveld</mat-option>
                    <mat-option value="smalltext">Klein tekstveld</mat-option>
                    <mat-option value="date">Datum</mat-option>
                    <mat-option value="textarea">Tekstveld (meerdere regels)</mat-option>
                    <mat-option value="email">E-mailadres</mat-option>
                    <mat-option value="options">Meerdere opties</mat-option>
                    <mat-option value="title">Tussenkop</mat-option>
                    <mat-option value="infotext">Informatieve tekst</mat-option>
                    <mat-option value="attachment">Bijlage</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="currentEditing.fieldType !== 'title' && currentEditing.fieldType !== 'infotext'" class="pl-1 flex-even">
                <input [(ngModel)]="currentEditing.placeholder" matInput placeholder="Placeholder als het veld leeg is" type="text">
            </mat-form-field>
        </div>
        <div *ngIf="currentEditing.fieldType == 'infotext'" class="d-flex">
            <mat-form-field class="pr-1 w-100">
                <textarea [(ngModel)]="currentEditing.infotext"
                          id="fieldInfoText" matInput
                          rows="5"
                          placeholder="Informatieve tekst" type="text">
                </textarea>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="col">
                <div *ngIf="currentEditing.fieldType == 'options'" class="options-list">
                    <b class="pb-1">Opties</b><br>
                    <div [(dragulaModel)]="currentEditing.options"
                         dragula="form-fields-bag--{{structcontentParentIdentifier}}">
                        <div *ngFor="let option of currentEditing.options; let i = index" class="option">
                            <div class="move">
                                <i class="move fa fa-bars"></i>
                            </div>
                            <div class="spacer">
                                <mat-form-field class="w100">
                                    <input [(ngModel)]="option.name" matInput placeholder="Optie {{i+1}}" type="text">
                                </mat-form-field>
                            </div>
                            <div>
                                <button (click)="removePage(option, currentEditing.options)" mat-icon-button
                                        type="button">
                                    <i aria-hidden="true" class="fa fa-trash"></i>
                                </button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <button (click)="multi('options', currentEditing);" class="w100 add-button"
                            color="primary" mat-button type="button">
                        Optie toevoegen
                    </button>
                    <div class="row">
                        <div class="col userselectnone">
                            <mat-checkbox *ngIf="currentEditing.fieldType === 'options'" [(ngModel)]="currentEditing.multiple">
                                Meerdere opties mogelijk
                            </mat-checkbox>
                        </div>
                    </div>
                </div>


                <div class="d-flex">
                    <div class="flex-even userselectnone pt-2" *ngIf="currentEditing.fieldType !== 'title' && currentEditing.fieldType !== 'infotext'">
                        <mat-checkbox [(ngModel)]="currentEditing.required">Veld is verplicht</mat-checkbox>
                    </div>
                    <div class="flex-even pl-1 pb-3">
                        <button (click)="removePage(currentEditing, structcontent['items'])" color="warn"
                                mat-raised-button>
                            Verwijder dit veld
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
