<div class="container bg-white mt-2 item-edit" *ngIf="typeObj">
    <div>
        <h2 class="news__title">Vragenlijst bewerken</h2>
        <h4 class="text-capitalize">{{typeName}}: {{typeObj?.title}}</h4>
    </div>
    <div *ngIf="form">
        <form [formGroup]="form">
            <b> Enquête in te vullen tot: </b>
            <div class="row">
                <div class="col-md-6 d-flex mt-2 mb-3">
                    <mat-form-field (click)="pickerS.open()" class="col-auto flex-grow-1">
                        <mat-label>Einddatum</mat-label>
                        <input [matDatepicker]="pickerS" [formControl]="fc.end_date" matInput>
                        <mat-datepicker-toggle [for]="pickerS" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #pickerS></mat-datepicker>
                        <mat-error [control]="fc.end_date"></mat-error>
                        <mat-hint>
                            Leeg laten voor geen einde aan deze enquête
                        </mat-hint>
                    </mat-form-field>
                    <mat-form-field class="col-auto">
                        <mat-label>Eindtijd</mat-label>
                        <input [formControl]="fc.end_date_time" matInput placeholder="hh:mm" type="text">
                        <mat-error [control]="fc.end_date_time"></mat-error>
                    </mat-form-field>
                    <div *ngIf="(fc.end_date.value || fc.end_date_time.value) && currentDate < fc.end_date.value">
                        <button mat-icon-button (click)="fc.end_date.setValue(null); fc.end_date_time.setValue(null)">
                            <mat-icon class="fas fa-close"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="mb-3" *ngIf="fc.end_date.value">
                <b>Tekst na verstrijken datum: </b>
                <simple-tiny [content]="fc.end_description"
                             [showMenuBar]="false"
                             [height]="250"
                             elementId="end_description"
                             ngDefaultControl
                             propertyName="value"
                ></simple-tiny>
                <mat-error [control]="fc.end_description"></mat-error>
            </div>

            <p *ngIf="faQuestions.length === 0">
                Je hebt nog geen vragen toegevoegd.
            </p>
            <div (cdkDropListDropped)="reorderQuestions($event)" cdkDropList>
                <ng-container *ngFor="let fgQuestion of faQuestions.controls; index as i">
                    <div class="row  question" *ngIf="fgQuestion" [formGroup]="fgQuestion" cdkDrag>
                        <div class="col-1 move-question" cdkDragHandle>
                            <div>Vraag {{i + 1}}</div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <h3>Vraag {{i + 1}} - {{fgQuestion.get('question').value}}</h3>
                                </div>
                                <div class="col-auto curpoint" (click)="duplicateQuestion(i)">
                                    <i class="fa fa-copy"></i>
                                </div>
                                <div class="col-auto curpoint" (click)="removeQuestion(i)">
                                    <i class="fa fa-trash"></i>
                                </div>
                            </div>
                            <div class="row">
                                <mat-form-field class="col-auto flex-grow-1">
                                    <mat-label>Soort vraag</mat-label>
                                    <mat-select formControlName="type">
                                        <mat-option value="open">Open vraag</mat-option>
                                        <mat-option value="options">Vraag met vaste opties</mat-option>
                                        <mat-option value="boolean">Ja / nee vraag</mat-option>
                                        <mat-option value="boolean-quantity">Ja / nee vraag met aantal</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-checkbox formControlName="required" class="col-auto mt-4">Verplicht</mat-checkbox>
                            </div>
                            <div class="row">
                                <mat-form-field class="col-auto flex-grow-1">
                                    <mat-label>Vraag</mat-label>
                                    <input formControlName="question" matInput type="text" autocomplete="false">
                                    <mat-error [control]="fgQuestion.get('question')"></mat-error>
                                </mat-form-field>
                            </div>
                            <ng-container *ngIf="fgQuestion.get('type').value === 'boolean-quantity' || fgQuestion.get('type').value === 'boolean'">
                                <div class="row" *ngIf="fgQuestion.get('type').value === 'boolean-quantity'">

                                    <mat-form-field class="col-auto flex-grow-1">
                                        <mat-label>Aantal vraag</mat-label>
                                        <input formControlName="quantityQuestion" matInput type="text">
                                        <mat-error [control]="fgQuestion.get('quantityQuestion')"></mat-error>
                                        <mat-hint>b.v. Hoeveel kinderen kunt u meenemen?</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-checkbox class="col-auto mt-4 pl-0" formControlName="limit">
                                        Aantal positieve antwoorden limiteren
                                    </mat-checkbox>
                                    <mat-form-field class="col-auto flex-grow-1" *ngIf="fgQuestion.get('limit').value">
                                        <mat-label>Aantal</mat-label>
                                        <input formControlName="limitCount"
                                               appDecimalInput
                                               [allowNegative]="false"
                                               decimalInput="0" matInput type="text">
                                        <mat-error [control]="fgQuestion.get('limitCount')"></mat-error>
                                    </mat-form-field>
                                    <mat-checkbox class="mt-4" formControlName="limitStrict"
                                                  *ngIf="fgQuestion.get('limit').value && fgQuestion.get('type').value === 'boolean-quantity'">
                                        Nooit overschrijden
                                    </mat-checkbox>
                                    <p *ngIf="fgQuestion.get('limitStrict').value">
                                        Let op: de laatste ouder mag geen hoger aantal ingeven dan nog beschikbaar, en kan zo mogelijk niet invoeren hoeveel bijvoorbeeld plekken in
                                        een auto er daadwerkelijk beschikbaar zijn.
                                    </p>
                                </div>
                            </ng-container>
                            <div class="row" *ngIf="fgQuestion.get('type').value === 'options'">
                                <div class="col-24 col-md">
                                    <h3>Opties vraag {{i + 1}}</h3>
                                    <table class="table table-striped">
                                        <thead>
                                        <tr>
                                            <th class="text-center" width="50">Sortering</th>
                                            <th>Optie</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody (cdkDropListDropped)="reorderOptions(fgQuestion, $event)" cdkDropList>
                                        <tr *ngFor="let option of fgQuestion.get('options').value; index as oi" cdkDrag>
                                            <td cdkDragHandle class="text-center curmove">
                                                <i class="fa fa-arrows-alt"></i>
                                            </td>
                                            <td>{{option}}</td>
                                            <td class="text-center curpoint" (click)="removeOption(fgQuestion, oi)" *ngIf="!fgQuestion.get('options').disabled">
                                                <i class="fa fa-trash"></i>
                                            </td>
                                            <td class="text-center" *ngIf="fgQuestion.get('options').disabled"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">
                                                <i class="fa fa-plus"></i>
                                            </td>
                                            <td>
                                                <input type="text" formControlName="addOption" (keydown.enter)="addOption(fgQuestion)"/>
                                            </td>
                                            <td class="text-center curpoint" (click)="addOption(fgQuestion)">
                                                <i class="fa fa-check"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-24 col-md">
                                    <div>
                                        <mat-checkbox formControlName="multiple" class="mt-4">
                                            Meerdere opties per persoon
                                        </mat-checkbox>
                                    </div>
                                    <div>
                                        <mat-checkbox formControlName="unique" class="mt-4">
                                            Opties eenmalig kiesbaar
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <button mat-flat-button color="primary" (click)="addQuestion()">Vraag toevoegen</button>
        </form>
    </div>
    <div class="floating-buttons">
        <button routerLink="/{{typeName}}/{{typeObj.slug}}/bewerken" [disabled]="saving" color="primary" mat-fab>
            <mat-icon class="fas fa-newspaper-o"></mat-icon>
        </button>
        <button (click)="save()" [disabled]="saving" color="primary" mat-fab>
            <mat-icon *ngIf="!saving" class="fas fa-save"></mat-icon>
            <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
        </button>
    </div>
</div>
