import {Injectable} from '@angular/core';
import {Student} from '../../classes/student.class';
import {ApiService} from '../api/api.service';

@Injectable()
export class StudentService {

    constructor(public apiService: ApiService) {
    }

    getStudent(id: number) {
        return this.apiService.getCall$<Student>(`students/${id}`);
    }

    getAll(onlyselectedschool: boolean) {
        return this.apiService.getCall$<Student[]>('students', {onlyselectedschool: onlyselectedschool});
    }

    getCount() {
        return this.apiService.getCall$<{studentcount_all: number, studentcount_selected: number}>('students/count');
    }

}
