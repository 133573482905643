export class Settings {

    static sidemodalWidth = 500;
    public static readonly unauthorizedAllowedUrls = [
        'wachtwoord-vergeten',
        'invite',
        'inloggen',
        'register'
    ];
    static readonly MAX_BALANCE = 75;
    static readonly PRICE_MOLLIE = .32;
    private static readonly _LOCAL_API_ENDPOINT = 'http://app.kindwijs.localhost/api/';
    private static readonly _PROD_API_ENDPOINT = 'https://app.kindwijs.org/api/';
    static readonly API_ENDPOINT: string = Settings.isLocalHost() ? Settings._LOCAL_API_ENDPOINT : Settings._PROD_API_ENDPOINT;
    private static readonly _LOCAL_WS_ENDPOINT = 'ws://localhost:1337';
    private static readonly _PROD_WS_ENDPOINT = 'wss://app.kindwijs.org/websocket/';
    public static readonly WS_ENDPOINT: string = Settings.isLocalHost() ? Settings._LOCAL_WS_ENDPOINT : Settings._PROD_WS_ENDPOINT;

    static isLocalHost() {
        return !window['cordova'] && (location.hostname === 'app.kindwijs.localhost' || location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '::1');
    }


    public static privacyText = new Map<string, string>([
        ['Openbaar', `Als u kiest voor ‘openbaar’ mogen wij foto's van uw kind gebruiken op alle uitingen van de school.
                        We kunnen de foto's onder andere gebruiken op de website, social media en advertenties.
                        Uiteraard gaat de school hier respectvol en met aandacht voor het kind mee om.
                        Als het niet eens bent met een uiting, kunt u altijd om verwijdering vragen.`],
        ['Semi-openbaar', `Als u kiest voor ‘semi-openbaar’ delen we foto's van uw kind op gecontroleerde plaatsen of is uw kind niet individueel herkenbaar.
                        Herkenbare foto's gebruiken we in een ruimte op school, in onze nieuwsbrief
                        of in een presentatie voor scholing van een leerkracht.
                        Groepsfoto's waarop uw kind niet individueel herkenbaar is mogen we overal delen.`],
        ['Besloten', `Als u kiest voor ‘besloten’, delen we geen informatie en foto's van uw kind.
                        Foto's van uw kind worden alleen gebruikt voor het maken van cadeautjes voor (groot)ouders,
                        het afscheid in groep 8 en bewaard in ParnasSys en de Kindwijs App.`]
    ]);
}
