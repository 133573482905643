import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Field} from '../classes/field.class';
import {Utils} from '../utils.class';
import {DragulaService} from 'ng2-dragula';
import {DragulaOptions} from 'dragula';

@Component({
    selector: 'app-field-list-edit',
    templateUrl: './field-list-edit.component.html',
    styleUrls: ['./field-list-edit.component.scss']
})
export class FieldListEditComponent implements OnInit, OnDestroy {

    @Input('field') field: Field;
    @Input('values') inputValues: Object;
    Utils = Utils;

    constructor(private dragulaService: DragulaService) {
    }

    ngOnInit() {
        const dragulaOptions: DragulaOptions = {
            moves: function (el, container, handle) {
                return handle.className.indexOf('move') > -1;
            }
        };
        this.dragulaService.createGroup('bag-' + this.field.id, dragulaOptions);

        if (!this.inputValues[this.field.id]) {
            this.inputValues[this.field.id] = [];
        }


        if (this.inputValues[this.field.id].length < this.field.minrepeat) {
            for (let repeat = this.inputValues[this.field.id].length; repeat < this.field.minrepeat; repeat++) {
                this.inputValues[this.field.id].push({x: 'x'});
            }
        }
    }

    ngOnDestroy(): void {
        this.dragulaService.destroy('bag-' + this.field.id);
    }

    mayDeleteOne() {
        return this.inputValues[this.field.id].length > this.field.minrepeat;
    }

    mayAddOne() {
        return this.inputValues[this.field.id].length < this.field.maxrepeat;
    }

}
