export class Field {
    id: string;
    name: string;
    type: string;
    options: Array<any>;
    minrepeat: number;
    maxrepeat: number;
    children: any;
    customvalue: boolean;
    default: string;
    type_custob_type: number;
    title: string;
    text: string;
}
