<div class="login-container">
    <div class="login">
        <form [formGroup]="loginForm" [style.fontSize.px]="19" class="login-form">
            <h2 class="mb-3">Inloggen</h2>
            <mat-form-field id="email">
                <input formControlName="email" matInput name="email" placeholder="Vul hier je e-mailadres in"
                       type="text">
                <mat-label>E-mailadres</mat-label>
                <mat-error [control]="loginForm.get('email')"></mat-error>
            </mat-form-field>
            <mat-form-field id="password">
                <mat-label>Wachtwoord</mat-label>
                <input formControlName="password" matInput name="password" placeholder="Wat is je wachtwoord?"
                       type="password">
                <mat-error [control]="loginForm.get('password')"></mat-error>
            </mat-form-field>
            <div class="login-buttons">
                <button (click)="login()" class="w-100 big dark" color="primary" id="btn-login"
                        mat-flat-button>
                    <ng-container *ngIf="loginFormState == LoginFormState.FillIn">
                        Inloggen
                    </ng-container>
                    <ng-container *ngIf="loginFormState == LoginFormState.Login">
                        Aan het inloggen...
                    </ng-container>
                </button>
                <button (click)="forgotPassword()" class="mr-2 w-100  mt-3" id="btn-lost-password" mat-button>
                    Wachtwoord vergeten
                </button>
            </div>
            <div *ngIf="errorMessage" id="errorMessage">
                <i class="fa fa-warning"></i>{{errorMessage}}
            </div>
        </form>
    </div>
</div>
