import {Component, Input, OnInit} from '@angular/core';
import {Field} from '../classes/field.class';
import {Utils} from "../utils.class";
import {CustobType} from '../classes/custob-type.class';

@Component({
    selector: 'app-fields',
    styleUrls: ['./fields.component.scss'],
    templateUrl: './fields.component.html'
})
export class FieldsComponent implements OnInit {

    @Input('fields') fields: Array<Field>;
    @Input('fieldsParentIdentifier') fieldsParentIdentifier: number;
    @Input('fieldsParent') fieldsParent: Object;
    @Input() custobTypes: CustobType[];

    Utils = Utils;

    noDefaultValuesTypes = [
        'options-radio',
        'options-checkbox',
        'options-dropdown',
        'fieldlist'
    ];

    fieldTypes = [
        {
            value: 'text',
            name: 'Tekst (1 regel)'
        }, {
            value: 'textarea',
            name: 'Tekst (meerdere regels)'
        }, {
            value: 'wysiwyg',
            name: 'Opgemaakte tekst'
        }, {
            value: 'number',
            name: 'Nummer'
        }, {
            value: 'datetime',
            name: 'Datum en tijd'
        }, {
            value: 'link',
            name: 'Link'
        }, {
            value: 'boolean-radio',
            name: 'Ja / nee (radio)'
        }, {
            value: 'boolean-checkbox',
            name: 'Ja / nee (checkbox)'
        }, {
            value: 'options-radio',
            name: 'Opties (radio)'
        }, {
            value: 'options-dropdown',
            name: 'Opties (dropdown)'
        }, {
            value: 'options-checkbox',
            name: 'Opties (checkbox)'
        }, {
            value: 'image',
            name: 'Foto'
        }, {
            value: 'document',
            name: 'Document'
        }, {
            value: 'documents',
            name: 'Meerdere Documenten'
        }, {
            value: 'images',
            name: 'Meerdere foto\'s'
        }, {
            value: 'custob',
            name: 'Custom object'
        }, {
            value: 'custob_type',
            name: 'Custom type'
        }, {
            value: 'fieldlist',
            name: 'Herhalende lijst'
        }, {
            value: 'form',
            name: 'Formulier (configureerbaar)'
        }, {
            value: 'structure-form',
            name: 'Formulier (vast)'
        },
        {
            value: 'seo',
            name: 'SEO'
        },
        {
            value: 'infotext',
            name: 'Informatieve tekst'
        }
    ];

    ngOnInit(): void {
        if (!this.fields) {
            if (this.fieldsParent && this.fieldsParentIdentifier) {
                this.fields = this.fieldsParent[this.fieldsParentIdentifier] = [];
            }
        }
    }

    getNewId(type: string) {
        let sameTypes = this.fields.filter(field => field.type == type);
        let highestUsed = 0;
        sameTypes.forEach(sameTypeField => {
            let id = sameTypeField.id.replace(type + "-", "");
            if (isFinite(+id) && +id > highestUsed) {
                highestUsed = +id;
            }
        });

        return type + '-' + (highestUsed + 1);
    }

    addField() {
        let defaultType = this.fieldTypes[0].value;
        let newField = new Field();
        newField.id = this.getNewId(defaultType);
        newField.type = defaultType;
        this.fields.push(
            newField
        );
    }

    handleRadioButtonChange(option: any, options: Array<any>) {
        options.forEach(option => {
            option.selected = false;
        });
        option.selected = true;
    }

    handleCheckboxChange(option: any, e: any) {
        option.selected = e.target.checked;
    }

    addOption(field: any) {
        if (typeof field.options == 'undefined')
            field.options = [];

        field.options.push({name: '', selected: false});
    }

}