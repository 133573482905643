import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StructureService} from '../services/structure/structure.service';
import {Structure} from '../classes/structure.class';
import {CustobTypeService} from '../services/custob-type/custob-type.service';
import {CustobType} from '../classes/custob-type.class';
import {Utils} from '../utils.class';
import {LocalStorage} from '../storage.class';

@Component({
    selector: 'structure-edit',
    templateUrl: './structure-edit.component.html',
    styleUrls: ['./structure-edit.component.css']
})
export class StructureEditComponent implements OnInit {
    custobTypes: CustobType[];

    sub: any;
    structure: Structure;
    LocalStorage = LocalStorage;

    public Utils = Utils;

    constructor(private structureService: StructureService,
                private custobTypeService: CustobTypeService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            if (+params['id']) {
                this.structureService.getSingle(+params['id']).subscribe((type) => {
                    this.structure = type.data;
                    if (typeof this.structure.fields == undefined || this.structure.fields == null) {
                        this.structure.fields = [];
                    }
                    if (typeof this.structure.useAtCustobTypeIds == undefined || this.structure.useAtCustobTypeIds == null) {
                        this.structure.useAtCustobTypeIds = {};
                    }
                });
            } else {
                this.structure = new Structure();
                this.structure.fields = [];
            }
        });

        /*this.custobTypeService.getAll().then(custobTypes => {
            this.custobTypes = custobTypes;
        }); */
    }

    save() {
        this.structureService.save(this.structure).then((result) => {
            this.router.navigate([`/structures`]);
        });
    }

}
