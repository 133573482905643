import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageEditComponent} from './page-edit/page-edit.component';
import {DomSanitizer} from '@angular/platform-browser';
import {Settings} from './settings.class';
import {School} from './classes/school.class';
import {CodaltInterceptor} from './codalt.interceptor';
import {LocalStorage} from './storage.class';
import {AuthenticationService} from './services/auth/authentication.service';
import {CodaltComponent} from './codalt.component';
import {AuthorisationService} from './services/auth/authorisation.service';
import {NavigationEnd, Router} from '@angular/router';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {combineLatest, Subscription} from 'rxjs';
import {Routenames} from './route-names.enum';
import {ChatService} from './services/chat.service';
import {LoggerService} from './services/logger.service';
import {HttpClient} from '@angular/common/http';
import {UserService} from './services/user/user.service';
import {ArticleService} from './services/article.service';
import {DiscussionService} from './services/discussion.service';

declare const cordova;
declare const IonicDeeplink;
declare const FirebasePlugin;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends CodaltComponent implements OnInit, OnDestroy {
    title = 'app';

    currentComponent: Component;
    @ViewChild('holder', {static: true})
    holder: ElementRef;
    Settings = Settings;
    pageEditEnabled = false;
    schools: School[];
    CodaltInterceptor = CodaltInterceptor;
    paymentsExpanded = false;
    parnassysExpanded = false;
    websiteExpanded = false;
    schoolExpanded = false;
    LocalStorage = LocalStorage;
    loggedIn = false;
    chatSubs = new Subscription();
    firebaseSubs = new Subscription();
    showMenuItems = {
        parnassysMain: false,
        parnassysUsers: false,
        parnassysClassrooms: false,
        parnassysStudents: false,
        parnassysLog: false,
        parnassysAuth: false,
        pageStructure: false,
        jobs: false,
        pageSettings: false,
        permissions: false,
        formHistory: false,
        pageEdit: false,
        page: false,
        discussions: false,
        costs: false,
        costsMaster: false,
        paymentRequests: false,
        paymentDefaults: false,
        paymentLowPermission: false,
        addCashCredit: false,
        paymentsOverview: false,
        remainDirect: false,
        absence: false
    };
    bottomMenu = [];
    private currentComponentSubscriptions: Subscription;
    private lastNotification = null;

    chatUnreadCounts = new Map<number, number>();
    articleUnreadCounts = new Map<number, number>();
    discussionOpenCount = new Map<number, number>();
    schoolsWithUnread = 0;
    chatUnreadCount = 0;

    constructor(public sanitizer: DomSanitizer,
                private authenticationService: AuthenticationService,
                private articleService: ArticleService,
                private discussionService: DiscussionService,
                private userService: UserService,
                private router: Router,
                private ngZone: NgZone,
                private chatService: ChatService,
                private firebase: FirebaseX,
                private http: HttpClient,
                private loggerService: LoggerService) {
        super();

        if (typeof cordova === 'undefined' && +window.location.port !== 4207) {
            document.domain = Settings.isLocalHost() ? 'kindwijs.localhost' : 'kindwijs.org';
        }

        this.subscriptions.add(this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.checkActiveBottomMenuItem(event);
            }
        }));
        this.http.get('assets/version.json').subscribe(versionInfo => {
            LocalStorage.version = versionInfo as {datetime: string};
        });

    }

    set routedComponent(routedComonent: Component) {
        this.resetExpanded();

        this.currentComponentSubscriptions?.unsubscribe();
        this.currentComponentSubscriptions = new Subscription();
        this.currentComponent = routedComonent;

        if (this.currentComponent instanceof PageEditComponent) {

            let currentComponent = (this.currentComponent as PageEditComponent);
            this.currentComponentSubscriptions.add(
                currentComponent.doScroll.subscribe((scrollPos) => {
                    this.holder.nativeElement.scrollTop = scrollPos;
                })
            );
            this.currentComponentSubscriptions.add(
                currentComponent.showSideBar.subscribe(event => {
                    this.pageEditEnabled = event;
                })
            );
        } else {
            this.pageEditEnabled = false;
        }
    };

    schoolExpandedToggle() {
        this.resetExpanded();
        const schoolFixedSubroutes = [
            Routenames.events,
            Routenames.articles,
            Routenames.formHistory,
            Routenames.permissions
        ];
        const urlParts = this.router.url.split('/');
        const foundRoute = !!schoolFixedSubroutes.find(r => r === urlParts[1]);
        const foundSubroute = foundRoute && !!urlParts[2];
        if (LocalStorage.user.anySchools.length > 1
            && !foundSubroute) {
            this.schoolExpanded = !this.schoolExpanded;
        }
    }

    backToParentUser() {
        this.authenticationService.switchToParent();
        setTimeout(() => {
            this.router.navigate([Routenames.parnassysUsers]);
        }, 500);
    }

    resetExpanded() {
        this.parnassysExpanded = false;
        this.websiteExpanded = false;
        this.schoolExpanded = false;
        this.paymentsExpanded = false;
    }

    setSchool(school: School) {
        LocalStorage.school = school;
        this.parnassysExpanded = false;
        this.websiteExpanded = false;
        this.schoolExpanded = false;
        this.paymentsExpanded = false;
    }

    ngOnInit() {
        this.subscriptions.add(ArticleService.reloadUnread.subscribe(() => {
            this.updateUnreadOrOpen();
        }));
        if (typeof cordova !== 'undefined') {
            this.firebase.hasPermission().then(hasPermission => {
                if (!hasPermission) {
                    this.firebase.grantPermission().then(() => {
                        this.loggerService.logError('Firebase permissions granted');
                    }, () => {
                        this.loggerService.logError('Firebase permissions refused');
                    });
                }
            });
        }
        this.loggedIn = !!LocalStorage.getUserToken();
        if (this.loggedIn) {
            this.check();
            this.refreshIfNeeded();
        }
        this.subscriptions.add(this.authenticationService.onLogin.subscribe(loggedIn => {
            this.loggedIn = loggedIn;
            if (loggedIn) {
                this.updateMenu();
                this.setChatSubs();
                this.setFirebaseSubs();
            } else {
                this.bottomMenu = [];
            }
            this.chatService.clearData();
        }));
        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.updateMenu();
        }));
        const routesToMatch = {};
        this.router.config.forEach(route => {
            if (typeof route.path !== 'undefined' && route.path.length > 2) {
                routesToMatch['/' + route.path] = {
                    target: route.path
                };
            }
        });
        if (typeof IonicDeeplink !== 'undefined') {
            IonicDeeplink.route(routesToMatch, (match) => {
                this.ngZone.run(() => {
                    this.router.navigate([`${match['$link']['path']}`]);
                });
            }, (nomatch) => {
            });
        }
        setTimeout(() => {
            CodaltInterceptor.loadingCount = 0;
        }, 3000);

        document.addEventListener('pause', () => {
            setTimeout(() => {
                console.log('pause app component');
                this.chatService.disconnect();
            });
        });
        document.addEventListener('resume', () => {
            setTimeout(() => {
                console.log('resume app component');
                CodaltInterceptor.loadingCount = 0;
                if (this.lastNotification) {
                    this.runNotification(this.lastNotification);
                    this.lastNotification = null;
                }
                this.chatService.disconnect();
                this.setChatSubs();
                this.updateUnreadOrOpen();
                this.refreshIfNeeded();
            });
        }, false);
        document.addEventListener('visibilitychange', () => {
            if (!document.hidden) {
                this.refreshIfNeeded();
            }
        });

    }

    public check() {
        if (!LocalStorage.schools || LocalStorage.schools.length === 0) {
            this.loggedIn = false;
            LocalStorage.logoutUser();
            this.bottomMenu = [];
        } else {
            this.setChatSubs();
            this.setFirebaseSubs();
            this.updateMenu();
            this.userService.getProfile().subscribe(user => {
                LocalStorage.setUser(user.data);
                LocalStorage.schools = user.data.anySchools;
                this.updateMenu();
            });
        }
    };

    refreshIfNeeded() {
        const refreshNotIfBefore = new Date();
        refreshNotIfBefore.setDate(refreshNotIfBefore.getDate() - 3);
        setTimeout(() => {
            const tokenDate = new Date(LocalStorage.getTokenDate());
            if (tokenDate < refreshNotIfBefore) {
                this.authenticationService.refresh().subscribe(() => {
                    this.chatService.disconnect();
                    this.check();
                }, () => {
                    this.check();
                    this.chatService.disconnect();
                });
            }
        }, (2 + (Math.random() * 3)) * 1000);
    }

    public onScroll = function (event) {
        if (typeof event.srcElement === 'undefined') {
            event.srcElement = event.target;
        }
        // When currentComponent is PageEditComponent we pass the scrollEvent to the router-outletted Component
        if (this.currentComponent instanceof PageEditComponent) {
            this.currentComponent.scroll(event);
        }
    };

    openNavItem(bm) {
        if (this.router.url.substr(1) === bm.routerLink || this.router.url === bm.routerLink) {
            window.scrollTo(0, 0);
            document.querySelectorAll('.router-outlet, .item-list').forEach(elem => {
                elem.scrollTo(0, 0);
            });
            setTimeout(() => {
                LocalStorage.bottomMenuReload.emit();
            });
        }
        this.router.navigateByUrl(bm.routerLink);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.currentComponentSubscriptions?.unsubscribe();
        this.chatSubs?.unsubscribe();
    }

    private checkActiveBottomMenuItem(event: NavigationEnd | Router) {
        const firstPart = event.url.split('/')[1];
        this.bottomMenu.forEach(bottmMenuItem => {
            bottmMenuItem.active = (bottmMenuItem.routerLink === firstPart || bottmMenuItem.routerLink === event.url);
        });
    }

    private setChatSubs() {
        setTimeout(() => {
            try {
                if (LocalStorage.user) {
                    this.chatSubs?.unsubscribe();
                    this.chatSubs = new Subscription();
                    this.chatSubs.add(this.chatService.getList().subscribe(chats => {
                        this.chatUnreadCount = chats.map(chat => chat?.lastMessage && chat?.lastMessage?.id > (chat?.chat_users?.find(u => u.user_id === LocalStorage.user.id))?.last_read)
                                                    .filter(c => !!c).length;

                        LocalStorage.user?.anySchools.forEach(school => {
                            const count = chats.filter(c => c.school_id === school.id)
                                               .map(chat => chat?.lastMessage && chat?.lastMessage?.id > (chat?.chat_users?.find(u => u.user_id === LocalStorage.user.id))?.last_read)
                                               .filter(c => !!c).length;

                            this.chatUnreadCounts.set(school.id, count);
                        });
                        const menuItem = this.bottomMenu.find(m => m.name === 'chat');
                        if (menuItem) {
                            menuItem.badge = this.chatUnreadCount;
                            if (typeof FirebasePlugin !== 'undefined') {
                                FirebasePlugin.setBadgeNumber(this.schoolsWithUnread + this.chatUnreadCount);
                            }
                        }
                    }));
                }
            } catch (e) {
                this.loggerService.logError(e);
                console.log(e);
            }
        }, 200);
    }


    private setFirebaseSubs() {
        try {
            if (typeof cordova !== 'undefined') {
                this.firebaseSubs?.unsubscribe();
                this.firebaseSubs = new Subscription();
                this.firebase.getToken().then(token => this.authenticationService.updateFirebaseToken(token).subscribe(), () => {
                });
                this.firebaseSubs.add(this.firebase.onTokenRefresh().subscribe(() => {
                    this.firebase.getToken().then(token => this.authenticationService.updateFirebaseToken(token).subscribe(), () => {
                    });
                }));

                this.firebaseSubs.add(this.firebase.onMessageReceived().subscribe(data => {
                    this.lastNotification = data;
                    if (data.tap) {
                        this.lastNotification = null;
                        this.runNotification(data);
                    }
                }));
            }
        } catch (error) {
            this.ngZone.run(() => {
                this.loggerService.logError(error?.stack || error?.message);
            });
            console.log(error);
        }

    }

    private runNotification(data) {
        this.ngZone.run(() => {
            if (data.school_id && LocalStorage.schools?.length > 0) {
                this.setSchool(LocalStorage.schools.find(s => s.id === +data.school_id));
            }
            this.ngZone.run(() => {
                this.router.navigateByUrl(data.url);
            });
        });
    }

    private updateMenu() {
        const bottomMenu = [
            {
                name: 'home',
                icon: 'fa-home',
                active: false,
                badge: 0,
                routerLink: this.Routenames.home
            }, {
                name: 'nieuws',
                icon: 'fa-newspaper',
                active: false,
                badge: 0,
                routerLink: this.Routenames.articles
            }, {
                name: 'kalender',
                icon: 'fa-calendar',
                active: false,
                badge: 0,
                routerLink: this.Routenames.events
            }, {
                name: 'profiel',
                icon: 'fa-user',
                active: false,
                badge: 0,
                routerLink: this.Routenames.profile
            }
        ];

        if (AuthorisationService.hasFeature('chat')) {
            bottomMenu.splice(3, 0, {
                name: 'chat',
                icon: 'fa-comments',
                active: false,
                badge: this.bottomMenu?.find(m => m.name === 'chat')?.badge,
                routerLink: this.Routenames.chat
            });
        }

        if (LocalStorage.school?.type === 'offer') {
            bottomMenu.splice(bottomMenu.findIndex(b => b.name === 'nieuws'), 1);
        }

        this.bottomMenu = bottomMenu;
        this.showMenuItems.parnassysMain = AuthorisationService.hasAnyFeature([
            'parnassyslist', 'studentlist', 'classroomListParnassys', 'authenticationLog'
        ]);
        this.showMenuItems.parnassysClassrooms = AuthorisationService.hasFeature('classroomList');
        this.showMenuItems.parnassysStudents = AuthorisationService.hasFeature('studentlist');
        this.showMenuItems.parnassysUsers = AuthorisationService.hasFeature('parnassyslist');
        this.showMenuItems.parnassysLog = AuthorisationService.hasFeature('authenticationLog');
        this.showMenuItems.parnassysAuth = AuthorisationService.hasFeature('authorisationFeatureToRole');
        if (typeof cordova === 'undefined') {
            this.showMenuItems.page = AuthorisationService.hasAnyFeature([
                'pageList', 'pageSaveStructure', 'schoolSave'
            ]);
            this.showMenuItems.pageEdit = AuthorisationService.hasFeature('pageList');
            this.showMenuItems.pageSettings = AuthorisationService.hasFeature('schoolSave');
            this.showMenuItems.pageStructure = AuthorisationService.hasFeature('pageSaveStructure');
        }
        this.showMenuItems.formHistory = AuthorisationService.hasFeature('formHistory');
        this.showMenuItems.jobs = AuthorisationService.hasFeature('editJob');
        this.showMenuItems.discussions = AuthorisationService.hasFeature('listDiscussions') && LocalStorage.school?.type === 'subsite';

        this.showMenuItems.costsMaster = AuthorisationService.hasAnyFeature(['paymentsOverview', 'paymentsOverviewSchool']) && LocalStorage.school?.type === 'master';

        this.showMenuItems.paymentDefaults = AuthorisationService.hasFeature('paymentsDefaults') && LocalStorage.school?.type !== 'master';
        this.showMenuItems.paymentRequests = AuthorisationService.hasFeature('paymentsRequests') && LocalStorage.school?.type !== 'master';
        this.showMenuItems.paymentLowPermission = AuthorisationService.hasFeature('paymentsDefaultsRemain') && LocalStorage.school?.type !== 'master';

        this.showMenuItems.remainDirect = this.showMenuItems.paymentLowPermission &&
            [this.showMenuItems.addCashCredit, this.showMenuItems.paymentRequests, this.showMenuItems.paymentDefaults].filter(f => f).length === 0
            && LocalStorage.school?.type !== 'master';

        this.showMenuItems.costs = AuthorisationService.hasAnyFeature([
            'paymentsDefaults', 'paymentsRequests', 'paymentsDefaultsRemain', 'transactionsAddCash'
        ]) && LocalStorage.school?.type !== 'master' && !this.showMenuItems.remainDirect;

        this.showMenuItems.addCashCredit = AuthorisationService.hasFeature('transactionsAddCash') && LocalStorage.school?.type !== 'master';
        this.showMenuItems.paymentsOverview = AuthorisationService.hasFeature('paymentsOverview');
        this.showMenuItems.permissions = AuthorisationService.hasFeature('permissionsEdit');
        this.showMenuItems.absence = LocalStorage.school?.absences;

        this.checkActiveBottomMenuItem(this.router);

        this.updateUnreadOrOpen();
    }

    updateUnreadOrOpen() {
        if (LocalStorage.user) {
            this.subscriptions.add(combineLatest([this.discussionService.getOpenDiscussionCount(), this.articleService.unreadArticleCount()]).subscribe(([open, unread]) => {
                let schoolsTotalUnread = 0;
                open.data.forEach(open => {
                    this.discussionOpenCount.set(open.school_id, open.open);
                    schoolsTotalUnread += open.open;
                });

                unread.data.forEach(count => {
                    this.articleUnreadCounts.set(count.school_id, count.unread);
                    schoolsTotalUnread += count.unread;
                });
                this.schoolsWithUnread = schoolsTotalUnread;

                const menuItemNews = this.bottomMenu.find(bm => bm.name === 'nieuws');
                if (menuItemNews) {
                    const countNews = this.articleUnreadCounts.get(LocalStorage.school?.id);
                    menuItemNews.badge = countNews ? `${countNews > 9 ? '9+' : countNews}` : null;
                }
                const menuItemHome = this.bottomMenu.find(bm => bm.name === 'home');
                const countHome = this.discussionOpenCount.get(LocalStorage.school?.id);
                menuItemHome.badge = countHome ? `${countHome > 9 ? '9+' : countHome}` : null;

                if (typeof FirebasePlugin !== 'undefined') {
                    FirebasePlugin.setBadgeNumber(this.schoolsWithUnread + this.chatUnreadCount);
                }
            }));
        }
    }
}
