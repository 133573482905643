import {Component, NgZone, OnInit} from '@angular/core';
import {LocalStorage} from '../storage.class';
import {CodaltComponent} from '../codalt.component';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UserService} from '../services/user/user.service';
import {User} from '../classes/user.class';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends CodaltComponent implements OnInit {

    schoolForms = new Map<number, UntypedFormGroup>();
    LocalStorage = LocalStorage;
    form: UntypedFormGroup;
    saving = false;
    loading = false;
    urlFriendlyCalendarUrl = '';
    profileImage = LocalStorage.school?.fullUrl + LocalStorage.user.profile_image;

    private schoolFormArray = new UntypedFormArray([]);

    constructor(private userService: UserService, private ngZone: NgZone) {
        super();
    }

    ngOnInit() {
        this.userService.getProfile().subscribe(user => {
            LocalStorage.setUser(user.data);
            this.urlFriendlyCalendarUrl = encodeURIComponent('Kindwijs - ' + LocalStorage.user.name + ' (' + LocalStorage.user.email + ')');
            this.createForm(user.data);
        });
    }

    save() {
        this.saving = true;
        const values = this.form.value;
        values['updated_at'] = LocalStorage.user.updated_at;
        values.show_on_site = values.show_on_site || false;
        this.userService.saveProfile(values).subscribe(user => {
            LocalStorage.setUser(user.data);
            this.saving = false;
        }, error => {
            this.saving = false;
        });
    }

    fileUploaded(event, field) {
        setTimeout(() => {
            const pathname = event ? event.file.pathname : null;
            this.saving = true;
            this.form.get(field).setValue(pathname);
            this.userService.saveProfileImage(pathname).subscribe(user => {
                LocalStorage.setUser(user.data);
                setTimeout(() => {
                    this.ngZone.run(() => {
                        if (LocalStorage.user.profile_image) {
                            this.profileImage = LocalStorage.school?.fullUrl + LocalStorage.user.profile_image;
                        } else {
                            this.profileImage = null;
                        }
                        this.saving = false;
                        this.loading = false;
                    });
                });
            }, error => {
                this.saving = false;
                this.loading = false;
            });

        });
    }

    private createForm(user: User) {
        this.form = new UntypedFormGroup({
            profile_image: new UntypedFormControl(user.profile_image),
            schools: this.schoolFormArray,
            chat_only_worktimes: new UntypedFormControl(LocalStorage.user.chat_only_worktimes),
            workday_0: new UntypedFormControl(LocalStorage.user.workday_0),
            workday_1: new UntypedFormControl(LocalStorage.user.workday_1),
            workday_2: new UntypedFormControl(LocalStorage.user.workday_2),
            workday_3: new UntypedFormControl(LocalStorage.user.workday_3),
            workday_4: new UntypedFormControl(LocalStorage.user.workday_4),
            workday_5: new UntypedFormControl(LocalStorage.user.workday_5),
            workday_6: new UntypedFormControl(LocalStorage.user.workday_6)
        });

        if (LocalStorage.user.schools) {
            LocalStorage.user.schools.forEach(school => {

                const formGroup = new UntypedFormGroup({
                    school_id: new UntypedFormControl(school.id),
                    function_one: new UntypedFormControl(school.pivot.function_one),
                    function_two: new UntypedFormControl(school.pivot.function_two)
                });
                this.schoolForms.set(school.id, formGroup);
                this.schoolFormArray.push(formGroup);
            });
        }
    }
}
