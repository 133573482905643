import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges} from '@angular/core';
import {Video} from '../classes/video.class';
import {Settings} from '../settings.class';
import {LocalStorage} from '../storage.class';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnChanges {

    @Input() video: Video = null;
    @Input() secureType = 'article';

    thumbPath: string = '';
    videoPath: string = '';

    @Input() onlyThumb = false;

    constructor(private ref: ElementRef, private cdref: ChangeDetectorRef) {
        this.cdref.detach();
    }

    play() {
        if ((document.getElementById('video-' + this.video.id) as HTMLVideoElement)) {
            (document.getElementById('video-' + this.video.id) as HTMLVideoElement).playsInline = true;
            (document.getElementById('video-' + this.video.id) as HTMLVideoElement).play();
        }
    }

    stop() {
        (document.getElementById('video-' + this.video.id) as HTMLVideoElement)?.pause();
    }


    ngOnChanges(): void {
        if (this.video.id) {
            setTimeout(() => {
                if (this.video.chat_id) {
                    this.secureType = 'chat';
                }
                this.thumbPath = this.getImagePath();
                this.videoPath = this.getVideoPath();
                this.cdref.detectChanges();
                this.cdref.reattach();
            });
        }
    }

    getVideoPath() {
        if (this.video.path) {
            return Settings.API_ENDPOINT +
                'file/video?secure=' + this.secureType +
                '&path=/' + (this.video.article_id || this.video.chat_id) + '/' + this.video.path +
                '&school=' + LocalStorage?.school?.id +
                '&access_token=' + LocalStorage.getUserToken();
        }
        return null;
    }

    getImagePath() {
        if (this.video.path) {
            return Settings.API_ENDPOINT +
                'file/thumb?secure=' + this.secureType +
                '&path=/' + (this.video.article_id || this.video.chat_id) + '/' + this.video.thumb +
                '&school=' + LocalStorage?.school?.id +
                '&access_token=' + LocalStorage.getUserToken();
        }
        return null;
    }
}
