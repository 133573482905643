import {Component, HostListener, Input, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {Event} from '../../classes/event.class';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {AfterSchoolService} from '../../services/after-school.service';
import {User} from '../../classes/user.class';

@Component({
    selector: 'app-event-presence',
    templateUrl: './event-presence.component.html',
    styleUrl: './event-presence.component.scss'
})
export class EventPresenceComponent extends CodaltComponent implements OnInit {

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.faPresence.dirty) {
            this.afterSchoolService.presence(this.faPresence.value.filter(p => !!p.presence)).subscribe();
        }
    }

    @Input() event: Event;

    userMap = new Map<number, User>();
    presenceMap = new Map<string, FormGroup<fgPresence>>();
    fcDaterangeId = new FormControl<number>(null);
    faPresence = new FormArray<FormGroup<fgPresence>>([]);

    todaySelectionAvailable = new Date();
    mayNotRegisterPresence = true;
    subscribeEnded = false;

    constructor(private afterSchoolService: AfterSchoolService) {
        super();
        this.todaySelectionAvailable.setHours(24);
    }

    ngOnInit() {
        this.subscribeEnded = new Date() > new Date(this.event.register_before);
        this.event.dateranges.forEach(date => {
            (this.event.all_subscriptions ?? this.event.subscriptions)?.forEach(sub => {
                const user = date.presence.find(p => p.student_id === sub.student_id)?.user;
                if (user) {
                    this.userMap.set(user.id, user);
                }
                const presence = new FormGroup({
                    events_daterange_id: new FormControl(date.id),
                    presence: new FormControl(date.presence.find(p => p.student_id === sub.student_id)?.presence),
                    student_id: new FormControl(sub.student_id),
                    user_id: new FormControl(date.presence.find(p => p.student_id === sub.student_id)?.user_id)
                });
                const twoHourbeforeStart = new Date(date.start_date);
                twoHourbeforeStart.setHours(twoHourbeforeStart.getHours() - 2);

                if (!this.event.all_subscriptions && presence.value.presence === 'present' || twoHourbeforeStart < new Date()) {
                    presence.disable();
                }
                this.faPresence.push(presence);
                this.presenceMap.set(`${date.id}-${sub.student_id}`, presence);
            });
            if (this.todaySelectionAvailable > date.start_date) {
                this.mayNotRegisterPresence = false;
                this.fcDaterangeId.setValue(date.id);
            }
        });
        if(this.subscribeEnded && !this.event.all_subscriptions){
            this.todaySelectionAvailable.setFullYear(9999);
            this.mayNotRegisterPresence = false;
        }

        this.faPresence.valueChanges.pipe(debounceTime(1000)).subscribe(presenceList => {
            this.faPresence.markAsPristine();
            if (!this.event.all_subscriptions) {
                this.afterSchoolService.presence(presenceList).subscribe();
            } else {
                this.afterSchoolService.presence(presenceList.filter(p => !!p.presence)).subscribe();
            }
        });
    }
}

interface fgPresence {
    events_daterange_id: FormControl<number>;
    student_id: FormControl<number>;
    presence: FormControl<'present' | 'absent'>;
    user_id: FormControl<number>;
}
