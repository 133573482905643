import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageService} from '../services/page/page.service';
import {StructureService} from '../services/structure/structure.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Settings} from '../settings.class';
import {ElementCreateComponent} from '../elements/element-create/element-create.component';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {ElementsComponent} from '../elements/elements.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SeoOptionsDialogComponent} from './seo-options-dialog/seo-options-dialog.component';
import {Page} from '../classes/page.class';
import {Structure} from '../classes/structure.class';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {LocalStorage} from '../storage.class';
import {Subscription} from 'rxjs';

export function checkChangeSideModalContent(fromState: string, toState: string, element: any, params: {
    [key: string]: any
}): boolean {
    // notice that `element` and `params` are also available here
    return toState !== fromState && fromState !== 'void';
}

@Component({
    selector: 'app-page-edit',
    templateUrl: 'page-edit.component.html',
    styleUrls: ['page-edit.scss'],
    animations: [trigger('sideModelContent', [
        transition(checkChangeSideModalContent, [
            animate('0.35s', keyframes([
                style({backgroundColor: 'transparent', offset: 0}),
                style({backgroundColor: '#a7a7a7', offset: 0.25}),
                style({backgroundColor: 'transparent', offset: 1})
            ]))
        ])
    ])]
})
export class PageEditComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('structureSettingsModal', {static: true}) public structureSettingsModal: ElementCreateComponent;
    pageId: any = 0;
    page: Page;
    pageDepth = 0;
    storage = Storage;
    type: string;
    action = '';
    actionstay = '';
    Settings = Settings;
    fullUrl = "";
    editingStructure: Structure;
    initialEditingStructure: Structure;
    public doScroll = new EventEmitter<number>();
    public showSideBar = new EventEmitter<boolean>();
    public dragging = false;
    pageLastChanged = (new Date()).getTime();
    structures: Structure[];
    availableParentPages: Array<Object>;
    accesibilityOptions = [
        {
            value: 0,
            text: 'Online',
            icon: 'fa-eye'
        },
        {
            value: 1,
            text: 'Offline',
            icon: 'fa-ban'
        },
        {
            value: 2,
            text: 'Onzichtbaar',
            icon: 'fa-eye-slash'
        }
    ];
    confirmDialogOpen = false;
    @ViewChild('pageName') private _pageName: ElementRef;
    private sub: any;
    private subscriptions = new Subscription();
    // We need to access the displayed structures to calculate which is displayed based on scrollPosition
    @ViewChild('blocksContainer', {static: true})
    private blocksContainer: ElementRef;
    @ViewChild('elements', {static: true})
    private elements: ElementsComponent;

    @ViewChild('pageurlcontainer')
    private pageurlcontainer: ElementRef;

    @ViewChild('urlInput')
    private urlInput: ElementRef;

    constructor(public pageService: PageService,
                public structureService: StructureService,
                private route: ActivatedRoute,
                private router: Router,
                private confirmDialogService: ConfirmDialogService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar) {


    }

    copyUrl() {
        this.urlInput.nativeElement.focus();
        this.urlInput.nativeElement.select();
        if (document.execCommand("copy")) {
            this.pageurlcontainer.nativeElement.className += " copied pastcopied";
            setTimeout(() => {
                this.pageurlcontainer.nativeElement.className = this.pageurlcontainer.nativeElement.className.replace(/ copied/g, "");
            }, 1000);
            setTimeout(() => {
                this.pageurlcontainer.nativeElement.className = this.pageurlcontainer.nativeElement.className.replace(/ pastcopied/g, "");
            }, 10000);
        }
    }

    ngAfterViewInit() {
        if (this.page) {
            this._pageName.nativeElement.focus();
        }
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.pageId = +params['id'];
            const parentId = +params['parent'];


            if (!this.pageId) {
                this.page = {
                    id: null,
                    icon: null,
                    name: '',
                    content: {},
                    structure: [],
                    accessibility: 0,
                    order: 99
                } as Page;

                if (parentId) {
                    this.page.parent_page_id = parentId;
                }

                this.page.content = {'space-1': {'height': '50'}};

                this.action = 'Pagina toevoegen en terug';
                this.actionstay = 'Pagina toevoegen';


                return;
            }
            if (LocalStorage.school) {
                this.loadPage();
            }
        });

        this.subscriptions.add(LocalStorage.schoolChange.subscribe(() => {
            this.loadPage();
        }));

    }

    create(stay = true, editFieldsExtended: Structure = null, elements: Structure[] = null) {
        let snackBarRef = this.snackBar.open('Aan het opslaan...');
        if (elements) {
            this.page.structure = elements;
        }
        if (editFieldsExtended != null && editFieldsExtended.insertIndex) {
            this.page.structure.splice(editFieldsExtended.insertIndex, 0, editFieldsExtended);
        }


        this.pageService.createPage(this.page).subscribe(response => {
            snackBarRef.dismiss();
            if (!this.pageId) {
                this.router.navigate(['/pagina-aanpassen', response['data']['id']]);
            }
            if (!stay) {
                this.router.navigate(['/pagina-management']);
            } else {
                this.page.updated_at = response.data['updated_at'];
                this.page.name = response.data['name'];
                this.page.page_url = response.data['page_url'];
                this.pageLastChanged = (new Date()).getTime();
                setTimeout(() => this.actionstay = 'Opslaan', 1500);
            }
        }, failure => {
            if (typeof failure.data['overwrite'] !== 'undefined' && failure.data['overwrite']) {
                this.confirmDialogService.confirm(
                    'Wijzigingen zijn niet opgeslagen',
                    failure['message'],
                    'Mijn pagina als kopie opslaan',
                    'Mijn wijzigingen verwerpen')
                    .then(dummy => {
                        this.page.id = null;
                        // Set accessibility to Offline
                        this.page.accessibility = 1;
                        this.page.name = this.page.name + ' (Kopie i.v.m. conflict)';
                        this.pageService.createPage(this.page).subscribe(response => {
                            this.router.navigate(['/pagina-aanpassen', response['data']['id']]);
                            this.actionstay = 'Opslaan';
                        });
                    }, dummy => {
                        this.loadPage();
                    });
            } else {
                this.confirmDialogService.confirm('Wijzigingen zijn niet opgeslagen', failure['message'], 'Oke', null);
            }
        });
    }

    depth(page) {
        let depth = 0;
        if (page) {
            if (page.children && page.children.length > 0) {
                depth = 1;

                page.children.forEach((subitem) => {
                    if (subitem.children && subitem.children.length > 0) {
                        depth = 2;
                    }
                });
            }
        }
        return depth;
    }

    scroll(event) {
        if (this.elements) {
            this.elements.scroll(event);
        }
    }

    currentPageObject(pageId, pages) {

        let curPage = null;
        pages.forEach(head => {
            if (head.id === pageId) {
                curPage = head;
            } else {
                head.children.forEach(sub1 => {
                    if (sub1.id === pageId) {
                        curPage = sub1;
                    } else {
                        sub1.children.forEach(sub2 => {
                            if (sub2.id === pageId) {
                                curPage = sub2;
                            }
                        });
                    }
                });
            }
        });

        return curPage;
    }

    seoOptionsDialog() {
        let page = new Page();
        Object.assign(page, this.page);
        let dialogRef = this.dialog.open(SeoOptionsDialogComponent, {
            width: '600px',
            data: {containerObject: page}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                Object.assign(this.page, page);
                this.create(true);
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private loadPage() {
        if (this.pageId) {
            this.pageService.getPage(this.pageId).subscribe(response => {
                this.action = 'Opslaan en terug';
                this.actionstay = 'Opslaan';
                this.page = response.data as Page;
                this.fullUrl = LocalStorage.school.fullUrl + '/' + this.page.page_url;

                // Empty object is converted to array by PHP JSONEncode. We need to fix this clientside.
                if (this.page.content instanceof Array) {
                    this.page.content = {};
                }
            });
        }


        this.pageService.getAllPages().then(pages => {
            this.availableParentPages = pages.data;
            const cpage = this.currentPageObject(this.pageId, pages.data);
            this.pageDepth = this.depth(cpage);
        });
        this.structureService.getAll().subscribe(structures => {
            this.structures = structures.filter(p => p.use_at_pages == true);
        });

    }

}
