import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SEO} from '../../classes/seo.class';

@Component({
    selector: 'app-seo-options-dialog',
    templateUrl: './seo-options-dialog.component.html',
    styleUrls: ['./seo-options-dialog.component.scss']
})
export class SeoOptionsDialogComponent implements OnInit {

    public containerObject = {};
    public key = 'seo';

    public seo: SEO;

    constructor(public dialogRef: MatDialogRef<SeoOptionsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { containerObject: Object, key: string }) {
    }

    ngOnInit() {
        this.containerObject = this.data['containerObject'];
        if (this.data['key']) {
            this.key = this.data['key'];
        }
        if (!this.containerObject[this.key]) {
            this.containerObject[this.key] = new SEO();
        }
        this.seo = this.containerObject[this.key];
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    fileUploaded(event) {
        this.seo[event['fileType']] = event.file['pathname'];
    }
}
