import {User} from "./user.class";
import {Student} from "./student.class";
import {School} from './school.class';

export class Classroom {
    public id: number;

    public classroom_name: string;

    public groupment: string;

    public school_year: string;

    public school_id: number;

    public created_at: string;

    public updated_at: string;

    public deleted_at: string;

    public comment: string;

    public users: User[];

    public students: Student[];

    public school: School;

    color: string;
}
