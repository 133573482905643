<div *ngFor="let field of fields" class="singlefield">
    <mat-form-field *ngIf="field.type == 'text'" class="w100">
        <input [(ngModel)]="values[field.id]" [name]="field.id" [placeholder]="field.name" matInput type="text">
    </mat-form-field>

    <mat-form-field *ngIf="field.type == 'number'" class="w100">
        <input [(ngModel)]="values[field.id]" [name]="field.id" [placeholder]="field.name" matInput type="number">
    </mat-form-field>

    <ng-container *ngIf="field.type == 'datetime'">
        <h1>{{field.name}}</h1>
        <app-time (timeChange)="setval(values, field.id, $event)" [time]="values[field.id]"></app-time>
    </ng-container>

    <mat-form-field *ngIf="field.type == 'textarea'" class="w100">
        <textarea [(ngModel)]="values[field.id]" [name]="field.id" [placeholder]="field.name" matInput matTextareaAutosize></textarea>
    </mat-form-field>

    <div *ngIf="field.type == 'link'" class="w100">
        <mat-form-field (dblclick)="editLink(values, field.id)" class="w100">
            <input [(ngModel)]="values[field.id]" [placeholder]="field.name" disabled matInput readonly type="text">
            <mat-icon (click)="editLink(values, field.id)" class="fa fa-pencil-alt curpoint" matSuffix></mat-icon>
        </mat-form-field>

    </div>
    <div *ngIf="field.type == 'wysiwyg'" class="row">
        <div class="col">
            <h1>{{field.name}}</h1>
            <simple-tiny [content]="values"
                         [elementId]="field.id"
                         [propertyName]="field.id"
                         ngDefaultControl
            ></simple-tiny>
            <br>
        </div>
    </div>
    <div *ngIf="field.type == 'boolean-radio'" class="row">
        <div class="col">
            <mat-slide-toggle [(ngModel)]="values[field.id]">
                {{field.name}}
            </mat-slide-toggle>
        </div>
    </div>

    <mat-form-field *ngIf="field.type == 'options-dropdown'" class="w100">
        <mat-select [(ngModel)]="values[field.id]" [placeholder]="field.name">
            <mat-option *ngFor="let option of field.options" [value]="option.value || option.name">
                {{option.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="field.type == 'options-radio'">
        <h1>{{field.name}}</h1>
        <mat-radio-group [(ngModel)]="values[field.id]" class="flex-column">
            <mat-radio-button *ngFor="let option of field.options" [name]="field.id"
                              [value]="option.value || option.name">
                {{option.name}}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div *ngIf="field.type == 'image'" class="row">

        <div class="col">
            <label>{{field.name}}</label>
            <div class="clearfix"></div>
            <div class="images-overview">
                <div [style.background-image]="(values[field.id] ? Storage.school.fullUrl + values[field.id] : null) | safeBgUrl"
                     [style.background-size]="values[field.id] ? 'cover' : null"
                     class="image">
                    <div (click)="values[field.id]=null" class="delete">
                        <i class="fa fa-2x fa-trash"></i>
                    </div>
                    <file-manager (callback)="fileUploaded($event)" [fileType]="field.id"></file-manager>
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="field.type == 'document'" class="row">
        <div class="col">
            <label>{{field.name}}</label>
            <div class="col">
                <file-manager (callback)="fileUploaded($event, false)" [fileType]="field.id" [selectImage]="false"
                              [selectImage]="false"></file-manager>
            </div>
            <div class="clearfix"></div>
            <div class="documents-overview">
                <div class="document">
                    <div class="flex-box">
                        <div class="icon">
                            <i class="fa fa-file-alt"></i>
                        </div>
                        <div *ngIf="values[field.id]">
                            <input [(ngModel)]="values[field.id].name" [placeholder]="values[field.id]?.filename" matInput type="text">
                            {{values[field.id].filename}}
                        </div>
                    </div>
                    <div (click)="values[field.id]=null" class="delete">
                        <i class="fa fa-2x fa-trash"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="field.type == 'documents'" class="row">

        <div class="col">
            <label>{{field.name}}</label>
            <div class="col mb-3">
                <file-manager (callback)="fileUploaded($event, false)" [fileType]="field.id" [multiple]="true"
                              [selectImage]="false"></file-manager>
            </div>
            <div [(dragulaModel)]='values[field.id]' class="documents-overview" dragula='bag-{{field.id}}'>
                <div *ngFor="let image of values[field.id]"
                     class="document">
                    <div class="d-flex cursor-ns-resize p-1">
                        <div class="icon align-items-center d-flex p-1"><i class="fa fa-2x fa-file-alt"></i></div>
                        <div *ngIf="image" class="flex-grow-1 d-flex p-1 flex-column">
                            <input [(ngModel)]="image.name" [placeholder]="image.filename" class="border-bottom m-1 p-1" matInput type="text">
                            <div class="m-1 p-1">{{image.filename}}</div>
                        </div>
                        <div (click)="Utils.removePage(image, values[field.id])" class="delete p-1 pl-3  align-items-center d-flex">
                            <i class="fa fa-2x fa-trash"></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="field.type == 'images'" class="row">
        <div class="col">
            <label>{{field.name}}</label>
            <div class="col">
                <file-manager (callback)="fileUploaded($event)" [fileType]="field.id" [multiple]="true"></file-manager>
            </div>
            <div class="clearfix"></div>
            <div [(dragulaModel)]='values[field.id]' class="images-overview" dragula='bag-{{field.id}}'>
                <div *ngFor="let image of values[field.id]"
                     [style.background-image]="(image ? Storage.school.fullUrl + image : null) | safeBgUrl"
                     [style.background-size]="image ? 'cover' : null"
                     class="image mb-3">
                    <div (click)="Utils.removePage(image, values[field.id])" class="delete">
                        <i class="fa fa-2x fa-trash"></i>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="field.type == 'fieldlist'">
        <app-field-list-edit [field]="field" [values]="values"></app-field-list-edit>
        <div class="clearfix"></div>
    </div>
    <ng-container *ngIf="field.type == 'custob'">
        <div class="row">
            <div class="col">
                <mat-form-field class="w100">
                    <mat-select [(ngModel)]="values[field.id]" [placeholder]="field.name">
                        <mat-option value="">Geen</mat-option>
                        <mat-option *ngFor="let custob of getCustobs(values['custob_type'] || field['type_custob_type'])" [value]="custob.id">
                            {{Utils.getFirstText(getCustobTypes(), (values['custob_type'] || field['type_custob_type']), custob)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <button color="primary"
                        mat-raised-button
                        routerLink="/custob/{{field['type_custob_type']}}/{{values[field.id]}}">
                    Bewerk dit item
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="field.type == 'custob_type'">
        <mat-form-field class="w100">
            <mat-select [(ngModel)]="values[field.id]" [placeholder]="field.name">
                <mat-option value="">Geen</mat-option>
                <mat-option *ngFor="let custobType of getCustobTypes()" [value]="custobType.id">
                    {{custobType.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button color="primary"
                mat-raised-button
                routerLink="/custob/{{field['type_custob_type']}}/{{values[field.id]}}">
            Bewerk {{getCustobTypeNameById(values[field.id])}}
        </button>
    </ng-container>

    <ng-container *ngIf="field.type == 'form'">
        <div class="row">
            <div class="col">
                <h1>{{field.name}}</h1>
            </div>
        </div>
        <div>
            <app-form [structcontentParentIdentifier]="field.id" [structcontentParent]="values"></app-form>
        </div>
    </ng-container>

    <ng-container *ngIf="field.type == 'seo'">
        <div class="row">
            <div class="col">
                <h1>{{field.name}}</h1>
            </div>
        </div>
        <div>
            <button (click)="seoOptionsDialog()" color="primary" mat-raised-button>SEO instellingen wijzigen</button>
        </div>
        <br>
    </ng-container>

    <ng-container *ngIf="field.type === 'infotext'">
        <h3>{{field.title}}</h3>
        <div [innerHTML]="field.text"></div>
    </ng-container>
</div>
