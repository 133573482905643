<mat-form-field class="timeDateInp">
    <input (click)="picker.open()"
           (dateChange)="changeDate($event)"
           (focus)="picker.open()"
           [matDatepicker]="picker"
           [ngModel]="dateTime"
           matInput
           name="date"
           placeholder="dd-mm-jjjj"
    >
    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<mat-form-field class="timeNumberInp" style="width: 100px">
    <input (change)="change($event)"
           (keydown.enter)="change($event)"
           [value]="time"
           matInput
           placeholder="UU:MM"
           type="text">
</mat-form-field>

<i (click)="clear()" class="fa fa-close"></i>
