import {Component, OnInit} from '@angular/core';
import {PermissionQuestion} from '../classes/permission-question';
import {combineLatest} from 'rxjs';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {ClassroomService} from '../services/classroom/classroom.service';
import {PermissionService} from '../services/permission.service';
import {CodaltComponent} from '../codalt.component';
import {LocalStorage} from '../storage.class';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Routenames} from '../route-names.enum';

@Component({
    selector: 'app-permission-questions',
    templateUrl: './permission-questions.component.html',
    styleUrls: ['./permission-questions.component.scss']
})
export class PermissionQuestionsComponent extends CodaltComponent implements OnInit {

    saving = false;

    questions: PermissionQuestion[];

    constructor(private router: Router,
                private confirmDialogService: ConfirmDialogService,
                private classroomService: ClassroomService,
                private permissionService: PermissionService) {
        super();
    }

    ngOnInit(): void {
        this.getData();
        LocalStorage.schoolChange.subscribe(() => {
            this.getData();
        });
    }


    private getData() {
        const classrooms$ = this.classroomService.getClassrooms();
        const questions$ = this.permissionService.getPermissionQuestions();

        this.subscriptions.add(combineLatest([classrooms$, questions$])
            .subscribe(([classrooms, questions]) => {
                this.questions = questions.data || [];
            }));
    }

    reorderQuestions(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
        this.subscriptions.add(this.permissionService.savePermissionQuestion(this.questions).subscribe((questions) => {
            this.questions = questions.data;
        }));
    }

    duplicateQuestion(index: number) {
        const copyQuestion = new PermissionQuestion();
        Object.assign(copyQuestion, this.questions[index]);
        delete copyQuestion.id;
        delete copyQuestion.start_date;
        delete copyQuestion.end_date;
        copyQuestion.name = `Kopie - ${copyQuestion.name}`;
        this.questions.push(copyQuestion);
        this.confirmDialogService.confirm(
            'Gekopieerd',
            'De vraag is gekopieerd en bevindt zich onderaan in de lijst.',
            'Sluiten',
            null).then(() => {

        }, () => {
        });
    }

    removeQuestion(index: number) {
        if (!!this.questions[index]) {
            this.confirmDialogService.confirm(
                'Vraag verwijderen',
                'Weet je zeker dat je deze vraag wilt verwijderen?',
                'Verwijderen',
                'Behouden').then(() => {
                this.questions.splice(index, 1);
                this.subscriptions.add(this.permissionService.savePermissionQuestion(this.questions).subscribe((questions) => {
                    this.questions = questions.data;
                }));
            }, () => {
            });
        }
    }

    addQuestion() {
        const newQuestion = new PermissionQuestion();
        this.questions.push(newQuestion);
        this.permissionService.savePermissionQuestion(this.questions).subscribe(questions => {
            this.questions = questions.data;
            this.router.navigateByUrl(`/${Routenames.permissions}/${this.questions[this.questions.length - 1].id}`);
        });
    }
}
