<div class="container mt-3" *ngIf="paymentRequest">
    <div class="row justify-content-center ">
        <div class="col-md-12 payment p-4">
            <div>
                <h2 class="news__title">Betaalverzoek {{paymentRequest?.name}}</h2>
            </div>
            <div>
                <div>
                    <b>Bedrag: </b>{{paymentRequest.priceToPay | currency : 'EUR'}}
                    <small *ngIf="paymentRequest.priceToPay > paymentRequest.price">
                        ({{paymentRequest.students.length}} x {{paymentRequest.price | currency : 'EUR'}})
                    </small>
                </div>
                <div *ngIf="paymentRequest.groupedClassrooms?.length">
                    <b>Voor groepen: </b>
                    <ng-container *ngFor="let classroom of paymentRequest.groupedClassrooms; let first = first">
                        {{first ? '' : ', '}}{{classroom}}
                    </ng-container>
                </div>
                <div>
                    <b>Voor leerlingen: </b>
                    <ng-container *ngFor="let student of paymentRequest?.students; let first = first;">
                        {{first ? '' : ', '}}{{student.name}}
                    </ng-container>
                </div>
                <div *ngIf="paymentRequest.price_higher || paymentRequest.price_lower">
                    <mat-form-field class="w-100 mt-2">
                        <mat-icon matPrefix mat-list-icon class="mr-3">euro</mat-icon>
                        <mat-label>Bedrag per leerling</mat-label>
                        <input [formControl]="fc.price"
                               appDecimalInput
                               [allowNegative]="false"
                               decimalInput="2" matInput type="text">
                        <mat-error [control]="fc.price"></mat-error>
                        <mat-hint>
                            Het bedrag mag
                            <ng-container *ngIf="paymentRequest.price_lower">
                                lager
                            </ng-container>
                            <ng-container *ngIf="paymentRequest.price_lower && paymentRequest.price_higher">
                                of
                            </ng-container>
                            <ng-container *ngIf="paymentRequest.price_higher">
                                hoger
                            </ng-container>
                            zijn dan het voorgestelde bedrag.
                        </mat-hint>
                    </mat-form-field>
                    <div class="mt-2">
                        <b>Totaal: {{(fc.price.value * paymentRequest.students.length) | currency : 'EUR'}}</b>
                    </div>
                </div>
                <div class="mt-4" *ngIf="currentSaldo < paymentRequest.priceToPay">
                    Uw saldo is te laag om dit verzoek te betalen. Waardeer eerst uw saldo op.
                </div>
                <div class="d-flex justify-content-between mt-4">
                    <div class="amount" [class.low-saldo]="currentSaldo < paymentRequest.priceToPay">
                        Huidig saldo: &euro; {{currentSaldo | number: '1.2-2'}}
                    </div>
                    <button class="button--primary" (click)="pay()" [disabled]="form.invalid">
                        {{(fc.price.value * paymentRequest.students.length) > currentSaldo ? 'Opwaarderen en betalen' : 'Betalen'}}
                        <mat-icon *ngIf="paying" class="fas fa-circle-notch fa-spin"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
