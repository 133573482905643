import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Transaction} from '../classes/transaction';
import {PaymentRequest} from '../classes/payment-request';
import {map} from 'rxjs/operators';
import {LocalStorage} from '../storage.class';
import {Student} from '../classes/student.class';

@Injectable()
export class PaymentService {

    constructor(private apiService: ApiService) {
    }

    notPaidStudents(paymentId) {
        return this.apiService.getCall$<Student[]>(`payments/not-paid-students/${paymentId}`);
    }

    getStudents() {
        return this.apiService.getCall$<Student[]>('payments/students');
    }

    payPaymentRequest(paymentId, price?: number) {
        return this.apiService.postCall$ <{
            transactions: Transaction[],
            credit: number
        }>(`payments/pay`, {paymentId, price}).pipe(map(result => {
            const user = LocalStorage.user;
            user.transactionAmount = result.data.credit;
            LocalStorage.setUser(user);
            return result;
        }));
    }

    getPaymentRequests() {
        return this.apiService.getCall$<PaymentRequest[]>('payments/requests');
    }

    getPaymentRequest(id: number) {
        return this.apiService.getCall$<PaymentRequest>(`payments/requests/${id}`);
    }

    savePaymentRequest(paymentRequest: PaymentRequest) {
        return this.apiService.postCall$<PaymentRequest>('payments/requests', paymentRequest);
    }

    getOwnPaymentRequests() {
        return this.apiService.getCall$<PaymentRequest[]>('payments/requests/own');
    }

    getUserTransactions(userId?: number) {
        return this.apiService.getCall$<Transaction[]>(`transactions${userId ? '/' + userId : ''}`);
    }

    getPaymentRequestTransactions(paymentRequestId?: number) {
        return this.apiService.getCall$<Transaction[]>(`transactions/payment-request/${paymentRequestId}`);
    }

    getDefaultPaymentRequestTransactions(paymentRequestId?: number) {
        return this.apiService.getCall$<Transaction[]>(`transactions/default-payment-request/${paymentRequestId}`);
    }

    checkPayment(id) {
        return this.apiService.postCall$<{ transactionAmount: number }>('transactions/check-payment', {id})
            .pipe(map(result => {
                const user = LocalStorage.user;
                user.transactionAmount = result.data.transactionAmount;
                LocalStorage.setUser(user);
                return result;
            }));
    }

    createPayment(amount: number, extraAmount?: number, paymentRequestId?: number, eventId?: number) {
        const params = {amount, extraAmount};
        if(paymentRequestId){
            params['paymentRequestId'] = paymentRequestId;
        }
        if(eventId){
            params['eventId'] = eventId;
        }
        return this.apiService.postCall$<{
            url: string
        }>('transactions/create-payment', params);
    }

    refundCurrentCredit(user_id: number) {
        return this.apiService.postCall$<Transaction>('transactions/create-refund', {user_id});
    }

    addCashCredit(amount, payed_by) {
        return this.apiService.postCall$<Transaction>('transactions/add-cash', {amount, payed_by});
    }

    overview() {
        return this.apiService.getCall$<paymentOverview[]>('payments/overview');
    }

    openSaldo() {
        return this.apiService.getCall$<userWithSaldo[]>('payments/open-saldo');
    }

    schoolsOverview(month?: Date) {
        return this.apiService.getCall$<{
            overview: paymentSchoolOverview[],
            totals: paymentSchoolOverview[],
            saldo: { amount_in: number; amount_out: number; }
        }>('payments/overview/schools', {month: month.toISOString()});
    }
}

export class paymentSchoolOverview {
    school_id: number;
    school: string;
    amount_in: number;
    amount_out: number;
    saldo: number;
    cash: number;
}

export class paymentOverview {
    school_id: number;
    school: string;
    payment: string;
    payment_id: number;
    count: number;
    amount: number;
    month_count: number;
    month_amount: number;
    last_month_count: number;
    last_month_amount: number;
    type: "payments" | "defaultPayments" | "offer"
}

export class userWithSaldo {
    amount: number;
    email: string;
    id: number;
    name: string;
    phone: string;
    deleted_at: string;
}
