import {Structure} from './structure.class';
import {SEO} from './seo.class';

export class Page {

    public id: number;

    public name: string;

    public icon: string;

    public type: string;

    public parent_page_id: number;

    public school_id: number;

    public structure: Structure[] = [];

    public content: Object = {};

    public accessibility: number;

    public order: number;

    public page_url: string;

    public updated_at: string;

    public seo: SEO;

}

