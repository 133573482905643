<div class="container mt-3">
    <div class="row justify-content-center ">
        <div class="col-md-12 payment p-4">
            <div>
                <h2 class="news__title">Betaalverzoeken</h2>
                <p *ngIf="!payments">
                    Aan het laden...
                </p>
                <p *ngIf="payments?.length === 0">
                    Er zijn geen actieve betaalverzoeken. Bekijk eerder betaalde verzoeken in je transacties.
                </p>
                <div class="news-home mb-3">
                    <div *ngFor="let payment of payments"
                         class="item"
                         routerLink="/{{Routenames.paymentRequests}}/{{Routenames.pay}}/{{payment.id}}">
                        <h3 class="item__title">{{payment.name}} {{payment.priceToPay | currency : 'EUR'}}</h3>
                        <div class="item__details">
                            <time class="item__date">
                                Vervaldatum: {{payment.pay_before | date}}
                            </time>
                            <div class="item__author">
                                <ng-container *ngFor="let student of payment.students; let last = last; let first = first">
                                    {{student.name}}{{last ? '' : ','}}
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <button class="button mb-3" routerLink="/{{Routenames.transactionsOverview}}">Mijn transacties</button>
            </div>
        </div>
    </div>
</div>
