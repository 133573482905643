import {UntypedFormControl, ValidatorFn} from '@angular/forms';

export function validTime(dateControl?: UntypedFormControl): ValidatorFn {
    return (control: UntypedFormControl) => {
        let valid = false;
        if (!dateControl?.value) {
            valid = true;
        }
        if (control.value) {
            const splitted = control.value.split(':'), hour = +splitted[0], minute = +splitted[1];
            valid = (splitted.length === 2 && hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59);
        }
        return valid ? null : {validTime: true};
    };
}
