<div>
    <h3>{{field.name}}</h3>
    <p>
        <i>Deze lijst bestaat uit minimaal {{field.minrepeat}} en maximaal {{field.maxrepeat}} items</i>
    </p>
    <div [(dragulaModel)]='inputValues[field.id]' dragula='bag-{{field.id}}'>
        <div *ngFor="let item of inputValues[field.id]; let i = index" class="field-list">
            <div class="head">
                <div class="move">
                    <i class="move fa fa-bars"></i>
                </div>
                <div class="title">Item {{i + 1}}</div>
                <div class="spacer"></div>
                <div (click)="Utils.removePage(item, inputValues[this.field.id])" [hidden]="!mayDeleteOne()" class="delete ml-3">
                    <i aria-hidden="true" class="fa fa-trash"></i>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-fields-edit [fields]="field.children" [values]="item"></app-fields-edit>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col txtcenter">
            <p>
                <i [hidden]="mayAddOne()">Je kunt geen items meer toevoegen (maximum van {{field.maxrepeat}} bereikt)</i>
            </p>
            <button (click)="inputValues[this.field.id].push({ x: 'x' })" [hidden]="!mayAddOne()" class="add-button" color="primary" mat-button>
                Item toevoegen
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
</div>
