import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Job} from '../classes/job.class';
import {Image} from '../classes/image.class';
import {Document} from '../classes/document.class';
import {EventarticlejobService} from './eventarticlejob.service';

@Injectable()
export class JobService implements EventarticlejobService {

    constructor(private apiService: ApiService) {
    }

    getList(limit?: number, skip?: number) {
        return this.apiService.getCall$<Job[]>(`jobs/${limit}/${skip}`);
    }

    get(slug: string) {
        return this.apiService.getCall$<Job>(`jobs/${slug}`);
    }

    delete(id: number) {
        return this.apiService.deleteCall$(`jobs/${id}`);
    }

    save(job: Job) {
        return this.apiService.postCall$<Job>('jobs', job);
    }

    reserve() {
        return this.apiService.postCall$<Job>('jobs/reserve');
    }

    addImage(id, path) {
        return this.apiService.postCall$<Image>('jobs/add-image', {id, path});
    }

    sortImages(job: Job) {
        return this.apiService.postCall$<Image>('jobs/sort-images', {
            id: job.id,
            images: job.images.map(p => p.id)
        });
    }

    rmImage(id) {
        return this.apiService.deleteCall$(`jobs/rm-image/${id}`);
    }

    addDocument(id, path) {
        return this.apiService.postCall$<Document>('jobs/add-document', {id, path});
    }

    sortDocuments(job: Job) {
        return this.apiService.postCall$<Document>('jobs/sort-documents', {
            id: job.id,
            documents: job.documents.map(p => p.id)
        });
    }

    rmDocument(id) {
        return this.apiService.deleteCall$(`jobs/rm-document/${id}`);
    }
}
