@if (icon) {
    @if (directUpload) {
        @if (directCamera) {
            <button (click)="chooseSource($event)" [hidden]="hide" mat-icon-button type="button">
                <mat-icon class="{{icon}}"></mat-icon>
            </button>
        } @else {
            <div class="d-flex">
                <button class="curpoint" mat-icon-button *ngIf="!iconText" (click)="uploadInput.click()">
                    <mat-icon class="{{icon}}"></mat-icon>
                </button>
                <button class="curpoint w-100 text-left" mat-button *ngIf="iconText" (click)="uploadInput.click()">
                    <mat-icon class="{{icon}} mr-2"></mat-icon>
                    {{iconText}}
                </button>
                <button (click)="callback.emit(null)" *ngIf="remove" mat-icon-button type="button">
                    <mat-icon class="fas fa-trash"></mat-icon>
                </button>
            </div>
        }
    } @else {
        <button (click)="openModal()" [hidden]="hide" mat-icon-button type="button">
            <mat-icon class="{{icon}}"></mat-icon>
        </button>
    }
} @else {
    @if (directUpload && (!directCamera || (!video) || reference === FileType.document)) {
        <button class="curpoint" mat-raised-button (click)="uploadInput.click()">
            {{multiple ? 'Bestanden selecteren' : 'Bestand selecteren'}}
        </button>
    } @else if (!directUpload) {
        <button (click)="openModal()" [hidden]="hide" mat-raised-button type="button">
            {{multiple ? 'Bestanden selecteren' : 'Bestand selecteren'}}
        </button>
    }
    @if (directCamera && (!isIos || video) && !hideCamera){
        <button *ngIf="video" (click)="openModal(null, true, true)" class="ml-2" mat-raised-button type="button">
            Bestand selecteren
        </button>
        <button (click)="openModal(null, true)" class="ml-2" mat-raised-button type="button">
            Camera
        </button>
    }
}

<input #uploadInput id="uploadInput" (change)="uploadFile($event)" [multiple]="multiple" style="position: fixed; top: -99999px; right: -99999px" type="file">
