import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {PrivacyPreference} from '../../classes/student.class';
import {UserService} from '../../services/user/user.service';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LocalStorage} from '../../storage.class';
import {Utils} from '../../utils.class';
import {formatDate} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {PermissionQuestion} from '../../classes/permission-question';
import {PermissionService} from '../../services/permission.service';
import {Routenames} from '../../route-names.enum';

@Component({
    selector: 'app-privacy-question',
    templateUrl: './privacy-question.component.html',
    styleUrls: ['./privacy-question.component.scss']
})
export class PrivacyQuestionComponent extends CodaltComponent implements OnInit {

    question: PermissionQuestion;

    form: FormGroupPrivacy;
    saving = false;
    isNew = true;

    constructor(private userService: UserService,
                private route: ActivatedRoute,
                private router: Router,
                private permissionService: PermissionService,
                private confirmDialog: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe((params: { id }) => {
            this.subscriptions.add(this.permissionService.getPermissionQuestionsForStudent(params.id).subscribe(question => {
                this.question = question.data;

                const uniquePreferences = [];
                const classroomIdsForQuestion = this.question.classrooms.map(c => c.id);
                const students = classroomIdsForQuestion?.length ? LocalStorage.user.students.filter(s => s.classrooms.filter(c => classroomIdsForQuestion.indexOf(c.id) !== -1).length) : LocalStorage.user.students;
                const studentFormArray = new UntypedFormArray(students.map(student => {
                    const currentPermission = student?.permissions?.find(p => p.permission_question_id === this.question.id)?.answer;
                    if (currentPermission) {
                        uniquePreferences.push(currentPermission);
                    }

                    return new UntypedFormGroup({
                        preference: new UntypedFormControl(currentPermission, Validators.required),
                        studentId: new UntypedFormControl(student.id)
                    }) as FormGroupStudentPreference;
                })) as FormArrayPref;
                this.isNew = uniquePreferences.length < 1;
                this.form = new UntypedFormGroup({
                    questionId: new UntypedFormControl(this.question.id),
                    allSame: new UntypedFormControl(this.isNew ? null : false, Validators.required),
                    allSamePreference: new UntypedFormControl(uniquePreferences?.length ? uniquePreferences[0] : null),
                    students: studentFormArray,
                    iKnow: new UntypedFormControl(null, Validators.required)
                }) as FormGroupPrivacy;
                this.form.controls.allSamePreference.valueChanges.subscribe((value: PrivacyPreference) => {
                    if (this.form.controls.allSame.value) {
                        this.form.controls.students.controls.forEach((studentPreferences: FormGroupStudentPreference) => {
                            studentPreferences.controls.preference.setValue(value);
                        });
                    }
                });
                if (this.question.deadline_date && Utils.getTimeOrNull(this.question.deadline_date) < Utils.getTimeOrNull(new Date())) {
                    this.form.disable();
                }
            }));
        }));


    }

    save() {
        this.saving = true;
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.permissionService.savePermissionForQuestion(this.form.value).subscribe(user => {
                LocalStorage.setUser(user.data);
                this.saving = false;
                this.isNew = false;
                this.form.controls.allSame.setValue(false);
                this.router.navigateByUrl(`/${Routenames.myPrivacy}`);
            }, error => {
                this.confirmDialog.confirm(
                    'Fout bij het opslaan',
                    `Reden: ${error.message}`,
                    'Sluiten',
                    null)
                    .then(() => {

                    }, () => {

                    });
                this.saving = false;
            });
        } else {
            this.saving = false;
        }
    }

    showHistory(studentId) {
        this.subscriptions.add(this.permissionService.getPermissionHistory(studentId, this.question.id).subscribe(history => {
            let content = '';
            history.data.forEach(h => {
                content += `<p class="mb-1">${formatDate(h.created_at, 'd MMMM yyyy HH:mm', 'nl')}<br class="d-inline d-md-none"> <b>${h.answer}</b> door ${h.user?.name}</p>`;
            });
            this.confirmDialog.confirm(
                'Geschiedenis',
                content,
                'Sluiten', null, '650px')
                .then(() => {

                });
        }));
    }
}

export type FormGroupPrivacy = UntypedFormGroup & {
    controls: {
        allSame: UntypedFormControl,
        allSamePreference: UntypedFormControl & { value: PrivacyPreference },
        students: FormArrayPref,
        iKnow: UntypedFormControl
    }
};

export type FormGroupStudentPreference = UntypedFormGroup & {
    controls: {
        preference: UntypedFormControl & { value: PrivacyPreference },
        studentId: UntypedFormControl & { value: number }
    }
};

export type FormArrayPref = UntypedFormArray & { controls: FormGroupStudentPreference[] };

