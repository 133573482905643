import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {combineLatest, Subscription} from 'rxjs';
import {Classroom} from '../../classes/classroom.class';
import {Discussion} from '../../classes/discussion.class';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DiscussionService} from '../../services/discussion.service';
import {LocalStorage} from '../../storage.class';
import {startWith} from 'rxjs/operators';
import {Routenames} from '../../route-names.enum';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Discussiontime} from '../../classes/discussiontime.class';
import {Student} from '../../classes/student.class';
import {StudentService} from '../../services/student/student.service';
import {UserService} from '../../services/user/user.service';
import {MatDialog} from '@angular/material/dialog';
import {DiscussionPlanComponent} from './discussion-plan/discussion-plan.component';

import {ClassroomForDiscussion} from '../../classes/classroom-for-discussion.class';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {Utils} from '../../utils.class';
import {ContextMenuComponent, ContextMenuService} from '@perfectmemory/ngx-contextmenu';

@Component({
    selector: 'app-discussion-detail',
    templateUrl: './discussion-detail.component.html',
    styleUrls: ['./discussion-detail.component.scss']
})
export class DiscussionDetailComponent extends CodaltComponent implements OnInit {

    columnWith: number[];

    subscriptions = new Subscription();
    classrooms: ClassroomForDiscussion[];
    visibleClassrooms: ClassroomForDiscussion[];
    showAllClassrooms = true;
    saving = false;
    discussion = new Discussion();
    classroomIds: number[];
    times: number[];
    timeObjs = new Map<string, Discussiontime>();
    allStudents: Student[];
    loading = false;
    allClassrooms: ClassroomForDiscussion[];
    @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent<any>;
    private discussionId: number;

    constructor(private classroomService: ClassroomService,
                private router: Router,
                private route: ActivatedRoute,
                private studentService: StudentService,
                private userService: UserService,
                private matDialog: MatDialog,
                private confirmDialogService: ConfirmDialogService,
                private contextMenuService: ContextMenuService<any>,
                private ref: ElementRef,
                private discussionService: DiscussionService) {
        super();
        window.addEventListener('scroll', this.scrollFunc, true);
    }

    scrollFunc = () => {
        if (!this.isInViewport(this.ref.nativeElement.querySelector('#reference-tr'))) {
            const collumnWith = [];
            this.ref.nativeElement.querySelectorAll('#reference-tr > th').forEach((r, i) => {
                collumnWith[i] = r.clientWidth + 1;
            });
            this.columnWith = collumnWith;
        } else {
            this.columnWith = null;
        }
    };

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (!this.loading && LocalStorage.school && (!this.discussion || (this.discussion && this.discussion.id != params['id']))) {
                this.loading = true;
                this.discussionId = +params['id'];
                this.getDiscussion();
            } else if (this.discussion) {
                LocalStorage.updateSelectedSchoolIfWrong(this.discussion.school_id);
            }
        }));
    }

    changeShowAll() {
        if (this.showAllClassrooms) {
            this.visibleClassrooms = this.classrooms;
        } else {
            this.visibleClassrooms = [];
        }
    }

    getPrevious(item: Discussiontime) {
        if (!item) {
            return;
        }
        const sameClassroom = this.discussion.discussiontimes.filter(p => p.classroom_id === item.classroom_id);
        for (let i = sameClassroom.indexOf(item); i >= 0; i--) {
            if (sameClassroom[i].unavailable !== item.unavailable
                || !!sameClassroom[i].student_id
                || Utils.getDatestringOrNull(sameClassroom[i].datetime) !== Utils.getDatestringOrNull(item.datetime)) {
                const firstToUse = sameClassroom[i + 1];
                return firstToUse;
            }
        }
        return sameClassroom[0];
    }

    getNext(item: Discussiontime) {
        if (!item) {
            return;
        }
        const sameClassroom = this.discussion.discussiontimes.filter(p => p.classroom_id === item.classroom_id);
        for (let i = sameClassroom.indexOf(item); i < sameClassroom.length; i++) {
            if (sameClassroom[i].unavailable !== item.unavailable
                || !!sameClassroom[i].student_id
                || Utils.getDatestringOrNull(sameClassroom[i].datetime) !== Utils.getDatestringOrNull(item.datetime)) {
                const firstToUse = sameClassroom[i - 1];
                return firstToUse;
            }
        }
        return sameClassroom[sameClassroom.length - 1];
    }

    changePlanning(classroom: Classroom, time: Discussiontime) {
        const dialogRef = this.matDialog.open(DiscussionPlanComponent, {
            panelClass: 'discussion-detail',
            width: AuthorisationService.hasFeature('unavailableDateClassroom') ? '950px' : '550px',
            height: 'auto',
            maxWidth: '100vw',
            hasBackdrop: true,
            data: {
                classroom: classroom,
                time: time,
                discussion: this.discussion,
                classrooms: this.classrooms
            }
        }).afterClosed().subscribe(() => {
            this.getDiscussion();
        });

    }

    unavailableDateClassroom(item: Discussiontime) {
        this.discussionService.unavailableDateClassroom(item).subscribe(() => {
            this.getDiscussion();
        });
    }

    unavailablePrevClassroom(item: Discussiontime) {
        this.discussionService.unavailablePrevClassroom(item).subscribe(() => {
            this.getDiscussion();
        });
    }

    unavailableNextClassroom(item: Discussiontime) {
        this.discussionService.unavailableNextClassroom(item).subscribe(() => {
            this.getDiscussion();
        });
    }

    resetTempAvailable() {
        this.discussion.discussiontimes.map(dt => dt.tempUnavailable = null);
    }

    toggleItemTempAvailable(item: Discussiontime) {
        this.resetTempAvailable();
        item.tempUnavailable = !item.unavailable;
    }

    toggleDayTempAvailable(item: Discussiontime) {
        this.resetTempAvailable();
        this.discussion.discussiontimes
            .filter(dt => dt.classroom_id === item.classroom_id
                && Utils.getDatestringOrNull(dt.datetime) === Utils.getDatestringOrNull(item.datetime)
                && !item.student_id)
            .map(dt => dt.tempUnavailable = !item.unavailable);
    }

    togglePreviousTempAvailable(item: Discussiontime) {
        this.resetTempAvailable();

        const previous = this.getPrevious(item);
        const previousTime = Utils.getTimeOrNull(previous.datetime);
        const thisTime = Utils.getTimeOrNull(item.datetime);

        this.discussion.discussiontimes
            .filter(dt => dt.classroom_id === item.classroom_id
                && Utils.getTimeOrNull(dt.datetime) >= previousTime
                && Utils.getTimeOrNull(dt.datetime) <= thisTime
                && !item.student_id)
            .map(dt => dt.tempUnavailable = !item.unavailable);
    }

    toggleNextTempAvailable(item: Discussiontime) {
        this.resetTempAvailable();

        const next = this.getNext(item);
        const nextTime = Utils.getTimeOrNull(next.datetime);
        const thisTime = Utils.getTimeOrNull(item.datetime);

        this.discussion.discussiontimes
            .filter(dt => dt.classroom_id === item.classroom_id
                && Utils.getTimeOrNull(dt.datetime) <= nextTime
                && Utils.getTimeOrNull(dt.datetime) >= thisTime
                && !item.student_id)
            .map(dt => dt.tempUnavailable = !item.unavailable);
    }

    toggleItemAvailable(item: Discussiontime) {
        item.unavailable = !item.unavailable;
        this.discussionService.updateTime(item).subscribe(() => {
            this.getDiscussion();
        }, error => {
            this.confirmDialogService.confirm('Fout', error.message, 'Terug naar overzicht', null);
        });
    }

    private isInViewport(element) {
        if (!element) {
            return false;
        }
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 55 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    private getDiscussion() {
        const classroom$ = this.discussionService.getClassrooms();
        const discussion$ = this.discussionService.get(this.discussionId);

        combineLatest([classroom$, discussion$]).subscribe(
            ([respClassrooms, respDiscussion]) => {
                this.contextMenuService.closeAll();
                this.allClassrooms = respClassrooms.data as ClassroomForDiscussion[];
                this.discussion = respDiscussion.data;
                LocalStorage.updateSelectedSchoolIfWrong(this.discussion.school_id);
                this.classroomIds = [...new Set(this.discussion.discussiontimes.map(p => p.classroom_id))];
                const timesSet = [...new Set(this.discussion.discussiontimes.map(p => p.datetime))];
                this.times = timesSet.map(p => new Date(p).getTime());
                this.visibleClassrooms = this.classrooms = this.allClassrooms.filter(c => this.classroomIds.indexOf(c.id) !== -1);
                this.timeObjs = new Map(this.discussion.discussiontimes.map((obj) => {
                    return [obj.classroom_id + '_' + new Date(obj.datetime).getTime(), obj];
                }));
                this.classrooms.forEach(classroom => {
                    classroom.subscribed = [...new Set(this.discussion.discussiontimes
                        .filter(p => p.classroom_id === classroom.id
                            && classroom.students.find(s => s.id === p.student_id))
                        .map(p => p.student_id))].length;
                    classroom.available = this.discussion.discussiontimes.filter(p => p.classroom_id === classroom.id && !p.student_id && !p.unavailable).length;
                    classroom.not_subscribed = classroom.students.filter(student => !this.discussion.discussiontimes.find(p => p.student_id === student.id && p.classroom_id === classroom.id));
                });
                LocalStorage.school = LocalStorage.schools.find(p => +p.id === +this.discussion.school_id);
            }, error => {
                this.confirmDialogService.confirm('Fout', error.message, 'Terug naar overzicht', null).then(() => {
                    this.router.navigateByUrl(Routenames.discussionsPlanner);
                });
            });
    }

    showWhenNoStudent(item: Discussiontime) {
        return !item.student_id;
    }
}
