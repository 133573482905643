import {Component, OnInit} from '@angular/core';
import {paymentOverview, PaymentService, userWithSaldo} from '../../services/payment.service';
import {LocalStorage} from '../../storage.class';
import {SortingComponent} from '../../sorting.component';

@Component({
    selector: 'app-payments-overview',
    templateUrl: './payments-overview.component.html',
    styleUrls: ['./payments-overview.component.scss']
})
export class PaymentsOverviewComponent extends SortingComponent<userWithSaldo> implements OnInit {

    showGeneralOverview = false;
    overview: paymentOverview[];


    constructor(private paymentService: PaymentService) {
        super();
    }

    ngOnInit(): void {
        LocalStorage.schoolChange.subscribe(school => {
            this.init();
        });
        this.init();
    }

    private init() {
        this.paymentService.overview().subscribe(data => {
            this.overview = data.data;
            this.showGeneralOverview = LocalStorage.school.type === 'master';
        });
        this.paymentService.openSaldo().subscribe(data => {
            this.list = data.data;
        });
    }
}
