import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Utils} from "../utils.class";
import {DragulaOptions, DragulaService} from 'ng2-dragula';


@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {

    @Input('structcontent')
    structcontent: Object;

    @Input('structcontentParentIdentifier')
    structcontentParentIdentifier: number;

    @Input('structcontentParent')
    structcontentParent: Object;

    currentEditing: any;

    public Utils = Utils;

    noDefaultValuesTypes = [
        'options-radio',
        'options-checkbox',
        'options-dropdown',
        'fieldlist'
    ];

    fieldTypes = [
        {
            value: "text",
            name: "Tekst (1 regel)"
        }, {
            value: "textarea",
            name: "Tekst (meerdere regels)"
        }, {
            value: "wysiwyg",
            name: "Opgemaakte tekst"
        }, {
            value: "number",
            name: "Nummer"
        }, {
            value: "boolean-radio",
            name: "Ja / nee (radio)"
        }, {
            value: "boolean-checkbox",
            name: "Ja / nee (checkbox)"
        }, {
            value: "options-radio",
            name: "Opties (radio)"
        }, {
            value: "options-dropdown",
            name: "Opties (dropdown)"
        }, {
            value: "options-checkbox",
            name: "Opties (checkbox)"
        }, {
            value: "attachment",
            name: "Bijlage"
        }, {
            value: "image",
            name: "Foto"
        }, {
            value: "images",
            name: "Meerdere foto's"
        }, {
            value: "custob",
            name: "Custom object"
        }, {
            value: "custob_type",
            name: "Custom type"
        }, {
            value: "fieldlist",
            name: "Herhalende lijst"
        }, {
            value: "form",
            name: "Formulier"
        }
    ];

    constructor(private dragulaService: DragulaService) {

    }

    ngOnInit(): void {
        this.currentEditing = null;
        if (!this.structcontent) {
            if (this.structcontentParent && this.structcontentParentIdentifier) {
                if (this.structcontentParent[this.structcontentParentIdentifier]) {
                    this.structcontent = this.structcontentParent[this.structcontentParentIdentifier];
                } else {
                    this.structcontent = this.structcontentParent[this.structcontentParentIdentifier] = {
                        items: []
                    };
                }
            }
        }
        this.dragulaService.createGroup('form-fields-bag-' + this.structcontentParentIdentifier, {
            moves: function (el, container, handle) {
                return handle.className.indexOf('move') > -1;
            }
        } as DragulaOptions);
        this.dragulaService.createGroup('form-fields-bag--' + this.structcontentParentIdentifier, {
            moves: function (el, container, handle) {
                return handle.className.indexOf('move') > -1;
            }
        } as DragulaOptions);

    }

    ngOnDestroy(): void {
        this.dragulaService.destroy('form-fields-bag-' + this.structcontentParentIdentifier);
        this.dragulaService.destroy('form-fields-bag--' + this.structcontentParentIdentifier);
    }

    removePage(item, list) {
        var index = list.indexOf(item);
        this.removePageOnIndex(list, index);
    }

    multi(objectName, object) {

        let newObject = {content: ""};

        if (object != null && object != undefined) {
            if (typeof object[objectName] == "undefined") {
                object[objectName] = [];
            }
            object[objectName].push(newObject);
        } else {
            this.structcontent[objectName].push(newObject);
        }

        return newObject;

    }

    private removePageOnIndex(list, index) {
        list.splice(index, 1);
        this.currentEditing = null;
    }

}
