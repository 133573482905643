import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {PaymentDefaultService} from '../../services/payment-default.service';
import {LocalStorage} from '../../storage.class';
import {Student} from '../../classes/student.class';
import {Classroom} from '../../classes/classroom.class';
import {PaymentDefault} from '../../classes/payment-default';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {startWith} from 'rxjs/operators';
import {Utils} from '../../utils.class';
import {PaymentRequest} from '../../classes/payment-request';
import {MatSelectionListChange} from '@angular/material/list';

@Component({
    selector: 'app-remain-payments',
    templateUrl: './remain-payments.component.html',
    styleUrls: ['./remain-payments.component.scss']
})
export class RemainPaymentsComponent extends CodaltComponent implements OnInit {

    today = new Date();


    students: Student[];
    classrooms: Classroom[];
    defaultPayments: PaymentDefault[];

    form: UntypedFormGroup;
    fc: {
        studentIds: UntypedFormControl,
        defaultPaymentId: UntypedFormControl,
        studentIdsTemp: UntypedFormControl,
        date: UntypedFormControl
    };

    searchControl = new UntypedFormControl();

    saving = false;
    paymentRequest: PaymentRequest;

    constructor(private paymentDefaultService: PaymentDefaultService) {
        super();
        Utils.setTime(this.today, 23, 59);
    }

    ngOnInit(): void {
        this.subscriptions.add(LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)).subscribe(() => {
            this.subscriptions.add(this.paymentDefaultService.getRemainOptions().subscribe(data => {
                this.students = data.data.students;
                this.classrooms = data.data.classroom;
                this.defaultPayments = data.data.defaultPayments;

                this.fc = {
                    defaultPaymentId: new UntypedFormControl(this.defaultPayments?.length === 1 ? this.defaultPayments[0].id : null),
                    studentIds: new UntypedFormControl([]),
                    studentIdsTemp: new UntypedFormControl([]),
                    date: new UntypedFormControl(new Date())
                };
                this.form = new UntypedFormGroup(this.fc);
                if (this.fc.defaultPaymentId?.value) {
                    this.getRemainForType();
                }
                this.subscriptions.add(this.fc.defaultPaymentId.valueChanges.subscribe(value => {
                    if (!this.saving && this.paymentRequest?.payment_default !== value) {
                        this.getRemainForType();
                    }
                }));
                this.subscriptions.add(this.fc.date.valueChanges.subscribe(() => {
                    if (!this.saving) {
                        this.getRemainForType();
                    }
                }));

            }));
        }));
        this.subscriptions.add(this.searchControl.valueChanges.subscribe(() => {
            this.fc.studentIdsTemp.setValue(this.fc.studentIds.value);
        }));
    }

    studentSelectionChange(event: MatSelectionListChange) {
        const studentIds = this.fc.studentIds.value as number[] || [];
        event.options.forEach(option => {
            if (option.selected) {
                if (studentIds.indexOf(option.value) === -1) {
                    studentIds.push(option.value);
                }
            } else {
                studentIds.splice(studentIds.indexOf(option.value), 1);
            }
        });
        this.fc.studentIds.setValue(studentIds);
    }

    getRemainForType() {
        this.paymentDefaultService.getRemain(this.fc.defaultPaymentId.value, this.fc.date.value).subscribe(data => {
            this.paymentRequest = data.data;
            this.fc.studentIds.setValue(this.paymentRequest?.transactions.map(t => t.payed_for));
            this.fc.studentIdsTemp.setValue(this.paymentRequest?.transactions.map(t => t.payed_for));
        });
    }

    save() {
        this.saving = true;
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.paymentDefaultService.saveRemain(
                this.fc.defaultPaymentId.value,
                this.fc.studentIds.value,
                this.fc.date.value
            ).subscribe(() => {
                this.saving = false;
            }, () => {
                this.saving = false;
            });
        } else {
            this.saving = false;
        }
    }

    filterDates = (d: Date | null): boolean => {
        const day = (d || new Date()).getDay();
        const afterThisDate = Utils.setTime(new Date(), 23, 59);
        afterThisDate.setUTCMonth(afterThisDate.getUTCMonth() - 1);
        afterThisDate.setUTCDate(0);
        return day !== 0 && (Utils.getTimeOrNull(d) > Utils.getTimeOrNull(afterThisDate)) && d < this.today;
    };
}
